// Masks //

export const STANDARD_INTERNATIONAL_CODING_SYSTEM = {
    mask: "9999"
}

export const PATIENT_INITIALS = {
    mask: "a-a-a"
}

export const PARTIAL_DATE_MASK = {
    mask: "ab/ab/cccc"
}

// Format Chars


export const PARTIAL_DATE_CHARS = {
    'a': '[0-9]|U',
    'b': '[0-9]|K',
    'c': '[0-9]'

}

export const LANGUAGE_SPOKEN = {
    mask: "99"
}

export const NUMBER_XXX = {
    mask: "999"
}