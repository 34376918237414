import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import { createEpicMiddleware } from "redux-observable";
import { routerMiddleware } from 'react-router-redux'
import thunk from "redux-thunk";
import { AppSettings } from "../helpers/AppSettings";

export const configureStore = (reducer, rootEpic, browserHistory) => {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && AppSettings.buildMode == "Development" ?
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 500 }) :
        compose;

    const epicMiddleware = createEpicMiddleware(rootEpic);
    const router = routerMiddleware(browserHistory);
    let middleware = applyMiddleware(epicMiddleware, thunk, router);

    if (AppSettings.buildMode == "Development") {
        middleware = applyMiddleware(epicMiddleware, thunk, logger, router);
    }

    return createStore(
        reducer,
        composeEnhancers(
            middleware
        )
    );
};