import * as React from "react";
import { generateOptionsFromLookup, findLookup } from "./lookupHelper";
import { Modal } from "../components/common/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../constants/fontAwesomeIcons";
import { setModalTitle } from "./modalHelper";
import { convertToShortDateString } from "./date";
import { ModalSize } from "../enumerations/ModalSize";
import { FileState } from "../dtos/Fig.dtos"

export function formatBytes(bytes, decimals) {
    if (bytes == 0) {
        return '0 Bytes'
    }

    var k = 1000,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const
    SOURCE_DOCUMENT_FILE_CONFIGURATION: any = (form, changeForm, reduxFormName, lookups, item, index, itemArray, gridComponent) => {

        const isOpenModal: boolean = form.openedModals && form.openedModals.some((i) => i == index.toString()) ? true : false
        const openModal = () => {

            if (!isOpenModal) {
                let array: any[] = [];
                if (form.openedModals) {
                    array = [...form.openedModals];
                }
                array.push(index.toString())
                changeForm(reduxFormName + ".openedModals", array);
            }

        };

        const closeModal = () => {
            if (isOpenModal) {
                let array: any[] = [];
                array = [...form.openedModals];

                array.splice(array.indexOf(index.toString()), 1);
                changeForm(reduxFormName + ".openedModals", array);
            }

        };

        const hasTempFile: boolean = item.fileInformation && item.fileInformation.state == FileState.Success && item.fileInformation.temporaryFile;
        const hasSourceFile: boolean = item.exists;

        const fileSize = hasTempFile ?
            item.fileInformation.temporaryFile.fileSize :
            item.fileSize ? item.fileSize :
                0
        const fileName = hasTempFile ?
            item.fileInformation.temporaryFile.name :
            item.name

        const fileType = item.fileType

        const fileTypeLookups = generateOptionsFromLookup(findLookup("SourceDocument", "FileType", lookups));
        let fileTypeDisplay = fileTypeLookups ?
            fileTypeLookups[fileType - 1] ?
                fileTypeLookups[fileType - 1].label :
                null :
            null

        const fileDateUploaded = hasTempFile ?
            item.fileInformation.temporaryFile.dateUploaded :
            item.dateEntered

        const fileContentType = hasTempFile ?
            item.fileInformation.temporaryFile.contentType :
            item.contentType

        const image = hasTempFile ?
            item.fileInformation.temporaryFile.image :
            item.image

        const imageApi = hasTempFile ?
            "/api/file/temp/get/" + item.fileInformation.temporaryFile.id :
            "/source-document/" + item.id;

        return <div>
            {
                !hasTempFile && !hasSourceFile ?
                    null :
                    <div className="d-flex">
                        <div className="btn btn-primary mr-2" onClick={openModal}>
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SEARCH} fixedWidth />
                                                </div>
                        {
                            hasSourceFile ?
                                <a title="Download File" href={"/source-document/download/" + item.id} target="_blank" className="btn btn-primary">
                                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLOUD_DOWNLOAD} fixedWidth />
                                                        </a> :
                                null
                        }
                        <Modal open={isOpenModal} disableCloseOnOverlayClick={true} onClose={closeModal} size={ModalSize.Md}>
                            <div className="modal-header">
                                <h5 className="modal-title">{setModalTitle(undefined, "File Information")}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="file-information-container">
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            <strong>File Name:</strong> {fileName}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>File Size</strong>: {formatBytes(fileSize, 2)}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>File Content Type</strong>: {fileContentType}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>File Upload Type</strong>: {fileTypeDisplay}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Date Uploaded</strong>: {convertToShortDateString(fileDateUploaded)}

                                        </li>
                                        {
                                            image && imageApi ?
                                                <li className="list-group-item">
                                                    <div className="text-center">
                                                        <img className="file-image" src={imageApi} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="File Upload" />
                                                    </div>
                                                </li> :
                                                null
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={closeModal}>Close</button>
                            </div>
                        </Modal>
                    </div>
            }

        </div>
    }

