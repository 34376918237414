import * as React from 'react';
import { connect } from "react-redux";
import { actions, Form } from "react-redux-form";
import { routerActions } from 'react-router-redux';
import Alert from 'react-s-alert';
import { bindActionCreators } from "redux";
import * as WebsiteSettingsActions from '../../actions/websiteSettings';
import { NotificationAlert } from "../../components/common/index";
import { CheckboxFormInput } from "../../components/form/index";
import { RestrictedLayout } from "../../components/layouts/index";
import { FontAwesomeIcons } from '../../constants/fontAwesomeIcons';
import * as Dtos from '../../dtos/Fig.dtos';
import { RequestState } from "../../enumerations/RequestState";
import { renderRegistrationFormSaveBlock, renderRegistrationFormSaveError, renderRegistrationFormSaveSuccess } from "../../helpers/alert";
import { RegistrationComponent, renderFormControls } from "../../helpers/formHelper";
import { RegistrationFormComponent } from "../../interfaces/forms/IRegistrationComponent";
import { IFigState } from '../../reducers';
import { IRegistrationFormState } from "../../reducers/reactReduxForms/index";
import { initialWebsiteSettingFormState, IWebsiteSettingReduxFormState } from "../../reducers/reactReduxForms/websiteSettingsForm";

interface IWebsiteSettingsPageProps {

    form: Dtos.WebsiteSetting & IRegistrationFormState;
    reduxFormState: IWebsiteSettingReduxFormState;

    websiteSetting: Dtos.WebsiteSetting;

    loadingSettings: boolean;
    loadingSettingsSuccess: boolean;
    loadingSettingsFailure: boolean;

    savingSettings: boolean;
    savingSettingsSuccess: boolean;
    savingSettingsFailure: boolean;
}

interface IWebsiteSettingsPageActions {
    load: WebsiteSettingsActions.IWebsiteSettingsLoadActionCreator;
    save: WebsiteSettingsActions.IWebsiteSettingsSetActionCreator;
    clear: WebsiteSettingsActions.IWebsiteSettingsClearActionCreator;

    
    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;
}

type WebsiteSettingsPageProps = IWebsiteSettingsPageProps & IWebsiteSettingsPageActions;

const formName: string = "WebsiteSetting";
const reduxFormName: string = "reduxForms.websiteSetting";

class WebsiteSettingsPage extends React.Component<WebsiteSettingsPageProps, any> {

    constructor(props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentWillMount() {
    }

    componentDidMount() {
        const {
            load
        } = this.props;

        load();
    }

    componentWillReceiveProps(nextProps: IWebsiteSettingsPageProps) {
    }

    componentDidUpdate(prevProps: IWebsiteSettingsPageProps, prevState) {
        console.log('WebsiteSettingsPage.componentDidUpdate', this.props.websiteSetting)
        
        const {
            websiteSetting,
            loadForm,
            loadingSettings,
            loadingSettingsSuccess,
            navigateReplace,
            form,
            reduxFormState,
            navigate,
            savingSettings,
            savingSettingsFailure,
            savingSettingsSuccess
        } = this.props;

        if (!loadingSettings && prevProps.loadingSettings) {
            if (loadingSettingsSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingSettings && prevProps.savingSettings) {
            if (savingSettingsSuccess) {
                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                if (form.saveAndReturn) {

                    navigate("/registration" )
                }
                else if (!form.saveAndReturn) {
                    this.loadForm();
                }
            }
            else {
                Alert.error(
                    <NotificationAlert
                        alertContent={renderRegistrationFormSaveError()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
            
        }
        
    }

    componentWillUpdate(nextProps: IWebsiteSettingsPageProps) {
    }

    componentWillUnmount() {
       
        const {
            resetForm,
            loadForm
        } = this.props
        
        loadForm(reduxFormName, initialWebsiteSettingFormState);
        resetForm(reduxFormName);
    }

    render() {
        return <RestrictedLayout
            subMenuItems={undefined}
            loading={false}
        >
            {this.renderContent()}
        </RestrictedLayout>
    }

    renderContent() {
        const {
            form,
            changeForm
        } = this.props;

        const formDisabled: boolean = false;


        const formComponents: { [index: string]: RegistrationFormComponent } = {
            performanceStatusHeading: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className="row p-4 border border-top-0 border-left-0 border-right-0">
                    <h3>Participant Details Settings</h3>
                </div>
            },
            enableGroup2: {
                type: RegistrationComponent.RegistrationControl,
                inputType: CheckboxFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    label: "Enabled"
                },
                inputLabel: "Entry to Group Two (post Chemo-RT) only to be enabled when required to ensure participant numbers for FET2/FET3 analysis [i.e to account for drop out from Group 1 (pre Chemo-RT)]",
            },
        };

        return <div>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "websiteSetting", formComponents, [], [], [], changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>;
    }

    handleOnSubmit(data: Dtos.WebsiteSetting) {
        const {
            save
        } = this.props;

        if (data) {
            let changes: Dtos.WebsiteSetting = {} as Dtos.WebsiteSetting;
            save(Object.assign({ ...data }, changes));
        }
    }


    handleOnSubmitFailed(data: Dtos.WebsiteSetting) {
        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    onCancel() {
        const {
            navigate
        } = this.props;

        navigate("/registration");
    }

    loadForm() {
        const {
            loadForm,
            websiteSetting
        } = this.props

        let extraData: Dtos.WebsiteSetting = {} as Dtos.WebsiteSetting;

        loadForm(reduxFormName, Object.assign({ ...websiteSetting }, extraData));
    }

}

const mapStateToProps = (state: IFigState, ownProps: WebsiteSettingsPageProps): IWebsiteSettingsPageProps => {

    return {
        form: state.reduxForms.websiteSetting,
        reduxFormState: state.reduxForms.formState.websiteSetting,

        websiteSetting: !(state.websiteSettings.data instanceof Array) ? state.websiteSettings.data : undefined,
        
        loadingSettings: state.websiteSettings.loadState && state.websiteSettings.loadState.status == RequestState.Pending,
        loadingSettingsSuccess: state.websiteSettings.loadState && state.websiteSettings.loadState.status == RequestState.Success,
        loadingSettingsFailure: state.websiteSettings.loadState && state.websiteSettings.loadState.status == RequestState.Failure,
    
        savingSettings: state.websiteSettings.saveState && state.websiteSettings.saveState.status == RequestState.Pending,
        savingSettingsSuccess: state.websiteSettings.saveState && state.websiteSettings.saveState.status == RequestState.Success,
        savingSettingsFailure: state.websiteSettings.saveState && state.websiteSettings.saveState.status == RequestState.Failure,

    };
};

const mapDispatchToProps = (dispatch): IWebsiteSettingsPageActions => {
    return {
        load: bindActionCreators(WebsiteSettingsActions.LoadWebsiteSettings, dispatch),
        save: bindActionCreators(WebsiteSettingsActions.SetWebsiteSettings, dispatch),
        clear: bindActionCreators(WebsiteSettingsActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(WebsiteSettingsPage);