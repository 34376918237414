import { IAuthorisationDefinition } from "../interfaces/authentication/authorisationDefinition";
import * as AuthenticationConstants from '../constants/authentication';

export const TROG_USER: IAuthorisationDefinition = {
    institutions: [
        AuthenticationConstants.INSTITUTION_TROG
    ],
    groups: undefined,
    roles: undefined
}

export const SUPER_USER: IAuthorisationDefinition = {
    groups: [
        AuthenticationConstants.SUPER_USER
    ],
    institutions: undefined,
    roles: undefined
}

export const TRIAL_COORDINATOR_AUTH: IAuthorisationDefinition = {
    groups: undefined,
    institutions: undefined,
    roles: AuthenticationConstants.TRIAL_COORDINATOR_ROLE
}

export const MEDICAL_REVIEWER_AUTH: IAuthorisationDefinition = {
    groups: undefined,
    institutions: undefined,
    roles: AuthenticationConstants.MEDICAL_REVIEWER_ROLE
}

export const PRINCIPAL_INVESTIGATOR_AUTH: IAuthorisationDefinition = {
    groups: undefined,
    institutions: undefined,
    roles: AuthenticationConstants.PRINCIPAL_INVESTIGATOR_ROLE
}

export const CO_INVESTIGATOR_AUTH: IAuthorisationDefinition = {
    groups: undefined,
    institutions: undefined,
    roles: AuthenticationConstants.CO_INVESTIGATOR_ROLE
}

export const TRIAL_COORDINATOR_BACKUP_AUTH: IAuthorisationDefinition = {
    groups: undefined,
    institutions: undefined,
    roles: AuthenticationConstants.TRIAL_COORDINATOR_BACKUP_ROLE
}

export const TROG_RESTRICTED_AUTH: IAuthorisationDefinition = {
    groups: undefined,
    institutions: undefined,
    roles: AuthenticationConstants.TROG_RESTRICTED_ROLES
}

export const PHARMACIST_AUTH: IAuthorisationDefinition = {
    groups: undefined,
    institutions: undefined,
    roles: AuthenticationConstants.PHARMACIST_ROLE
}

export const TROG_ADMIN_USER: IAuthorisationDefinition = {
    institutions: [
        AuthenticationConstants.INSTITUTION_TROG
    ],
    groups: undefined,
    roles: undefined
}

export const TROG_MANAGEMENT_USER: IAuthorisationDefinition = {
    institutions: [
        AuthenticationConstants.INSTITUTION_TROG
    ],
    groups: undefined,
    roles: undefined
}

export const TROG_CRA_USER: IAuthorisationDefinition = {
    institutions: [
        AuthenticationConstants.INSTITUTION_TROG
    ],
    groups: undefined,
    roles: undefined
}