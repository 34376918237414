import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_GLIOBLASTOMADETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL,
    GlioblastomaDetailFormPageAction
} from '../../../actions/pages/registration/glioblastomaDetailFormPage';


export interface IGlioblastomaDetailFormPageState {
    messageModalOpen: boolean;
}

const initialState: IGlioblastomaDetailFormPageState = {
    messageModalOpen: false
};


function toggleMessageModal(state: IGlioblastomaDetailFormPageState): IGlioblastomaDetailFormPageState {
    const newState: IGlioblastomaDetailFormPageState = update(state, {
        messageModalOpen: {
            $set: !state.messageModalOpen
        }
    });

    return newState;
}

const GlioblastomaDetailFormPageReducer: Reducer<IGlioblastomaDetailFormPageState> = (state: IGlioblastomaDetailFormPageState = initialState, action: GlioblastomaDetailFormPageAction) => {
    switch (action.type) {
        case ACTION_GLIOBLASTOMADETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL:
            return toggleMessageModal(state);
    }

    return state;
}

export default GlioblastomaDetailFormPageReducer;