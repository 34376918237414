import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
import { ISourceDocumentModalState } from "./sourceDocument";

    
export const initialEligibilityCriteriaFormState: Dtos.EligibilityCriteriaForm & IRegistrationFormState & ISourceDocumentModalState = {
    id: null,
    patientId: null,
    confirmedBy: null,
    confirmedDate: null,
    ageGreaterEighteen: null,
    histologicalGBMOrIDH: null,
    mgmt: null,
    ecog: null,
    lifeExpectancyGreater12Weeks: null,
    adequateBoneMarrowReserver: null,
    capableOfChildbearing: null,
    complyStudyRequirements: null,
    writtenConsent: null,
    radiotherapySuitable: null,
    undergoingRadiotherapy: null,
    commencedChemoradiationLessThanSevenWeeksBeforeSurgery: null,
    suitableForAdjuvantTMZTherapy: null,
    implantedMedicalElectronicDevice: null,
    concurrentConditions: null,
    historyOfAnotherMalignancyWithin2Years: null,
    priorChemotherapyWithin2Years: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    openedModals: [],
    sourceDocuments: [],
}

export interface IEligibilityCriteriaReduxFormState {
    id: IFieldState<number>,
    patientId: IFieldState<number>,
    confirmedBy: IFieldState<string>,
    confirmedDate: IFieldState<string>,
    ageGreaterEighteen: IFieldState<number>,
    histologicalGBMOrIDH: IFieldState<number>,
    mgmt: IFieldState<number>,
    ecog: IFieldState<number>,
    lifeExpectancyGreater12Weeks: IFieldState<number>,
    adequateBoneMarrowReserver: IFieldState<number>,
    capableOfChildbearing: IFieldState<number>,
    complyStudyRequirements: IFieldState<number>,
    writtenConsent: IFieldState<number>,
    radiotherapySuitable: IFieldState<number>,
    undergoingRadiotherapy: IFieldState<number>,
    commencedChemoradiationLessThanSevenWeeksBeforeSurgery: IFieldState<number>,
    suitableForAdjuvantTMZTherapy: IFieldState<number>,
    implantedMedicalElectronicDevice: IFieldState<number>,
    concurrentConditions: IFieldState<number>,
    historyOfAnotherMalignancyWithin2Years: IFieldState<number>,
    priorChemotherapyWithin2Years: IFieldState<number>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    openedModals: IFieldState<string[]>
}
