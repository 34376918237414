import { JsonServiceClient, IRequestFilterOptions } from '@servicestack/client';
import { store } from '../index';
import { LoadUser } from '../helpers/user';
import { RouterReplace } from '../helpers/router';
import { IFigState } from '../reducers';
declare var global; //populated from package.json/jest

const jsonServiceClient = new JsonServiceClient(global.BaseUrl || '/');

jsonServiceClient.responseFilter = (res: Response) => {
    if (store) {
        var state: IFigState = store.getState() as any;
    
        if (res.headers.has("openiddict-user-status") &&
            res.headers.get("openiddict-user-status") == "unauthenticated" &&
            state.user.data) {
            console.log("user logged out");
            console.log(res);
            LoadUser(store);
        }
        else if (res.headers.has("openiddict-user-update") &&
            res.headers.get("openiddict-user-update") == "true") {
            console.log("user updated");
            console.log(res);
            LoadUser(store);
        }
    }
}

jsonServiceClient.exceptionFilter = (res: Response, error: any) => {
    if (res.status == 401) {
        window.location.href = "/auth/openiddict?ReturnUrl=" + encodeURIComponent(window.location.pathname + window.location.search);
    }
    else if (res.status == 403 ||
        res.status == 404) {
        RouterReplace(store, "/error/" + res.status);
    }
}

export var client = jsonServiceClient;
