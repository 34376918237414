import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_TIMEPOINTS_LOAD_ALL,
    ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS,
    ACTION_TIMEPOINTS_CLEAR,
    loadSuccess,
    loadFailure,
    ITimepointClearAction,
    ITimepointsLoadAllAction,
    ITimepointLoadFailureAction,
    ITimepointLoadSuccessAction
} from "../actions/timepoint";
import { timepointApi } from "../services/timepoints";


export const
    getTimepointsEpic = (action$): Observable<ITimepointLoadSuccessAction | ITimepointLoadFailureAction> => {
        return action$
            .ofType(ACTION_TIMEPOINTS_LOAD_ALL)
            .mergeMap((action: ITimepointsLoadAllAction) => {
                return timepointApi
                    .getTimepoints()
                    .map(response =>
                        loadSuccess(response.timepoints)
                    )
                    .catch(error =>
                        Observable.of(loadFailure(error.responseStatus))
                    )
            });
    },
    getTimepointsWithDrugsEpic = (action$): Observable<ITimepointLoadSuccessAction | ITimepointLoadFailureAction> => {
        return action$
            .ofType(ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS)
            .mergeMap((action: ITimepointsLoadAllAction) => {
                return timepointApi
                    .getTimepointsWithDrugs()
                    .map(response =>
                        loadSuccess(response.timepoints)
                    )
                    .catch(error =>
                        Observable.of(loadFailure(error.responseStatus))
                    )
            });
    };



