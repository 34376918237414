import { UpdateOn, ValidateOn } from "react-redux-form";

export const mapProps = {
  valid: ({ fieldValue }) => fieldValue.valid,
  touched: ({ fieldValue }) => fieldValue.touched,
  focused: ({ fieldValue }) => fieldValue.focus,
  inputValue: ({ fieldValue }) => fieldValue.value,
  //format: ({ fieldValue }) => fieldValue.value === '' ? null : fieldValue.value
};

export const validateOn: ValidateOn[] = ["blur", "change"];
export const updateOn: UpdateOn[] = ["change"];
export const showErrors = { touched: true };