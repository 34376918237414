import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Fig.dtos';

import {
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_SUCCESS,
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_FAILURE,
    ACTION_REGISTRATIONFORMSUMMARIES_CLEAR,
    RegistrationFormSummaryAction
} from '../actions/registrationFormSummary';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface IRegistrationFormSummaryState {
    data: Dtos.RegistrationFormSummary | Dtos.RegistrationFormSummary[],
    loadState: IRequestState;
    saveState: IRequestState;
}

const initialState: IRegistrationFormSummaryState = {
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IRegistrationFormSummaryState, isLoading: boolean): IRegistrationFormSummaryState {
    const newState: IRegistrationFormSummaryState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IRegistrationFormSummaryState, data: Dtos.RegistrationFormSummary | Dtos.RegistrationFormSummary[]): IRegistrationFormSummaryState {
    const newState: IRegistrationFormSummaryState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: IRegistrationFormSummaryState, responseStatus: Dtos.ResponseStatus): IRegistrationFormSummaryState {
    const newState: IRegistrationFormSummaryState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IRegistrationFormSummaryState): IRegistrationFormSummaryState {
    const newState: IRegistrationFormSummaryState = update(initialState, {});

    return newState;
}

const RegistrationFormSummaryReducer: Reducer<IRegistrationFormSummaryState> = (state: IRegistrationFormSummaryState = initialState, action: RegistrationFormSummaryAction) => {
    switch (action.type) {
        case ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER:
            return loading(state, true);
        case ACTION_REGISTRATIONFORMSUMMARIES_LOAD_SUCCESS:
            return loadSuccess(state, action.data);
        case ACTION_REGISTRATIONFORMSUMMARIES_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_REGISTRATIONFORMSUMMARIES_CLEAR:
            return clear(state);
    }

    return state;
}

export default RegistrationFormSummaryReducer;