import * as React from 'react';
import './SaeNotifyPage.scss';

import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Control, Form, actions } from 'react-redux-form';
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';

import { flow } from "lodash";
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';

import * as SaeActions from '../../../actions/sae';
import * as InstitutionActions from '../../../actions/institution';
import * as TrialUserActions from '../../../actions/trialUser';

import * as Authentication from '../../../helpers/authentication';

import * as FigDtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { ITreeNode, ITree } from "../../../components/common/Tree/Tree";
import { CamelToKebabCase } from "../../../functions/string";

//import { getPatientStatusText } from "../../../helpers/patient";
import { getSaeStatusText, getSaeManagerState } from "../../../helpers/sae";

import { setModalTitle } from "../../../helpers/modalHelper";
import { convertToShortDateString } from '../../../helpers/date';
//import { clearSaeNotifyPage } from '../../../actions/pages/sae/saeViewPage';
//import SaeManager from '../../../components/sae/SaeManager/SaeManager';
import { LoadTrialUsersByInstitutionRoleIds, LoadTrialUsersByRoleIds, LoadTrialUsersByInstitution } from '../../../actions/trialUser';
import { Investigators } from '../../../constants/trialUserDefinitions';
import { trialUserApi } from '../../../services/trialUser';
import { INotifyTrialUserReduxFormState, INotifyTrialUserFormState } from '../../../reducers/reactReduxForms/notifyTrialUsersForm';
import { TextAreaFormInput, CheckboxFormInput, TextFormInput } from '../../../components/form';
import { NotificationAlert } from '../../../components/common';
import { SaeForm } from '../../../interfaces/forms/ISaeForm';
import { TrialUserType } from '../../../enumerations/TrialUserType';
import { getInstitutionName } from '../../../helpers/institution';
import { sanitizeUsernameForReactState } from '../../../helpers/trialUser';


interface ISaeNotifyPageParams {
    saeId: number;
    institutionCode: string;
    trialUserType: number;
}

interface ISaeNotifyPageProps {
    location: Location;
    match: match<ISaeNotifyPageParams>;
    history: History;
    saeId: number;
    institutionCode: string;
    trialUserType: number;

    sae: FigDtos.Sae;
    trialUsers: FigDtos.TrialUser[];

    loadingSae: boolean;
    loadSaeSuccess: boolean;
    loadSaeFailure: boolean;

    institution: FigDtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    loadingTrialUsers: boolean;
    loadTrialUsersSuccess: boolean;
    loadTrialUsersFailure: boolean;

    processingSaeNotify: boolean
    notifySaeSuccess: boolean;
    notifySaeFailure: boolean;

    user: FigDtos.User
}


interface ISaeNotifyPageActions {
    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    loadSae: SaeActions.ISaesLoadBySaeIdActionCreator;
    clearSae: SaeActions.ISaesClearActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    loadTrialUsersByInstitution: TrialUserActions.ITrialUsersLoadByInstitutionActionCreator;
    clearTrialUsers: TrialUserActions.ITrialUsersClearActionCreator;

    processSaeNotify: SaeActions.ISaesProcessNotifyActionCreator;

    resetNotifyForm: typeof actions.reset;
}

type SaeNotifyPageProps = ISaeNotifyPageProps & ISaeNotifyPageActions;

const notifyReduxFormName = "reduxForms.notifyTrialUsersForm"

class SaeNotifyPage extends React.PureComponent<SaeNotifyPageProps, any> {

    constructor(props: SaeNotifyPageProps) {
        super(props);

        this.clearSae = this.clearSae.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearTrialUsers = this.clearTrialUsers.bind(this);
    }

    componentDidMount() {
        const {
            loadSae,
            saeId,
            institutionCode,
            loadInstitution,
            loadTrialUsersByInstitution
        } = this.props;

        if (saeId) {
            loadSae(saeId);
        }

        if (institutionCode) {
            loadInstitution(institutionCode);

            loadTrialUsersByInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: SaeNotifyPageProps) {
    }

    componentDidUpdate(prevProps: SaeNotifyPageProps) {
        const {
            sae,
            navigateReplace,
            institutionCode,
            loadInstitution,
            loadTrialUsersByInstitution,
            processingSaeNotify,
            notifySaeSuccess

        } = this.props;

        if (sae &&
            sae.saeId &&
            prevProps.sae &&
            prevProps.sae.saeId == null) {

            navigateReplace("/sae/" + institutionCode + "/view-sae" + sae.saeId);
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {

            loadInstitution(institutionCode);
            loadTrialUsersByInstitution(institutionCode);
        }

        if (!processingSaeNotify && prevProps.processingSaeNotify && notifySaeSuccess) {
            Alert.success(<NotificationAlert
                alertContent={this.renderNotifySuccess()}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

    }

    componentWillUpdate(nextProps: SaeNotifyPageProps) {
    }

    componentWillUnmount() {
        const {
            //clearSaeNotifyPage
        } = this.props;

        this.clearInstitution();
        this.clearSae();
        this.clearTrialUsers();
        this.clearNotifyFormData();
    }

    render() {

        const {
            loadingSae,
            loadingInstitution,
            loadingTrialUsers,
            processingSaeNotify
        } = this.props

        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={(loadingSae || loadingInstitution || loadingTrialUsers)}
            saving={processingSaeNotify}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {

        const {
            sae,
            institutionCode,
            loadingSae,
            loadSaeSuccess,
            loadSaeFailure,
            clearSae,
            institution,
            user,
            saeId,
            trialUsers,
            navigate,
            navigateReplace
        } = this.props;

        return <div>
            {
                this.showNotifyForm() ?
                <div className="notify-sae">
                    <div className="notify-header">
                        Please select recipients from the list below, enter notes if required, and click Send.
                    </div>
                    <div className="notify-form">
                            <Form model={notifyReduxFormName} onSubmit={(val) => this.handleSubmit(val)} onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}>
                            {
                                <div className="mt-2 mb-2">
                                {
                                    trialUsers &&
                                    trialUsers.filter(
                                        trialUser =>
                                            Authentication.isTrialUserEnabledForSaeNotification(trialUser)
                                    ).map((trialUser, index) => {

                                        return <Control.checkbox
                                            key={sanitizeUsernameForReactState(trialUser.username)}
                                            component={CheckboxFormInput}
                                            model={`.usersToNotify[${sanitizeUsernameForReactState(trialUser.username)}]`}
                                            controlProps={{
                                                className: undefined,
                                                label: trialUser.displayname,
                                                name: `notifyTrialUsersForm.usersToNotify.${sanitizeUsernameForReactState(trialUser.username)}`,
                                                id: `notifyTrialUsersForm.usersToNotify.${sanitizeUsernameForReactState(trialUser.username)}`
                                            }}
                                            mapProps={{
                                                valid: ({ fieldValue }) => fieldValue.valid,
                                                touched: ({ fieldValue }) => fieldValue.touched,
                                                focused: ({ fieldValue }) => fieldValue.focus,
                                                value: ({ fieldValue }) => fieldValue.value
                                            } as any}
                                        />
                                    })
                                }
                                </div>
                            }
                            {
                                trialUsers &&
                                <div className="mt-2 mb-2">
                                    <span className="mt-2 mb-2"><strong>TROG Notes:</strong></span>
                                    <Control.textarea
                                        key={"form-notes"}
                                        component={TextAreaFormInput}
                                        model={`.notesToReturn`}
                                        controlProps={{
                                            className: undefined,
                                            label: "TROG Notes",
                                            name: `notifyTrialUsersForm.notesToReturn.name`,
                                            id: `notifyTrialUsersForm.notesToReturn.id`
                                        }}
                                        mapProps={{
                                            valid: ({ fieldValue }) => fieldValue.valid,
                                            touched: ({ fieldValue }) => fieldValue.touched,
                                            focused: ({ fieldValue }) => fieldValue.focus,
                                            value: ({ fieldValue }) => fieldValue.value
                                        } as any}
                                    />
                                </div>
                            }
                            <button className="btn btn-primary">Send</button>
                        </Form>
                    </div>
                    </div> :
                    this.showPostFormRender() ?
                        <div>
                            <div className="alert alert-info d-flex">
                                <div className="mr-2">
                                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                                </div>
                                <span>The notification email has been sent.</span>
                        </div>
                        <div>
                            <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); navigate("/sae/" + sae.institutionCode + "/view-sae/" + sae.saeId) }} className="btn btn-sm btn-primary mr-2">Return to SAE Summary</div>
                        </div>
                        </div> : 
                        <div className="alert alert-danger d-flex">
                            There are currently no trial users connected to {institution && institution.institutionName ? institution.institutionName : 'the institution'}. Please contact your system administrator.
                        </div>
            }
        </div>
    }

    clearSae() {
        this.props.clearSae();
    }

    handleSubmit(notifyTrialUserForm: INotifyTrialUserFormState) {

        const {
            trialUsers,
            processSaeNotify,
            institutionCode,
            saeId,
            trialUserType
        } = this.props;

        const selectedUsers = trialUsers.filter(tu => notifyTrialUserForm.usersToNotify && notifyTrialUserForm.usersToNotify[sanitizeUsernameForReactState(tu.username)]);

        if (!selectedUsers ||
            selectedUsers.length == 0) {
            Alert.warning(<NotificationAlert
                alertContent={this.renderNoUsersSelected()}
                icon={FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE}
            />);
        }
        else {
            let formData: FigDtos.SaeNotifyFormData = {
                trialUsers: selectedUsers,
                notes: notifyTrialUserForm.notesToReturn
            };
            let trialUserTypeToReturn: FigDtos.TrialUserType = undefined;

            processSaeNotify(formData, institutionCode, saeId, FigDtos.TrialUserType.Investigators);

        }
        
    }

    handleOnSubmitFailed(notifyTrialUserForm: INotifyTrialUserFormState) {
        Alert.danger(<NotificationAlert
            alertContent="Unable to notify users. Please contact your system administrator."
            icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
        />);
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    clearTrialUsers() {
        this.props.clearTrialUsers();
    }

    clearNotifyFormData() {
        this.props.resetNotifyForm(notifyReduxFormName);
    }

    showNotifyForm(): boolean {
        const {
            sae,
            trialUsers,
            loadingSae,
            loadSaeSuccess,
            loadingTrialUsers,
            loadTrialUsersSuccess,
            notifySaeSuccess
        } = this.props


        //debugger;

        if (sae && trialUsers && trialUsers.length > 0 && trialUsers.filter(trialUser => Authentication.isTrialUserEnabledForSaeNotification(trialUser)).length > 0 && (!loadingSae && loadSaeSuccess) && (!loadingTrialUsers && loadTrialUsersSuccess) && !notifySaeSuccess) {
            return true;
        }

        return false;
    }

    showPostFormRender(): boolean {
        const {
            notifySaeSuccess
        } = this.props

        if (notifySaeSuccess) {
            return true;
        }

        return false;

    }

    createSubMenuItems(): INavItem[] {

        const {
            sae,
            institutionCode,
            institution
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = '';
        if (sae) {
            if (sae.saeInitialData.saeNumber) {
                displayIdentifier = sae.randNo + ' (SAE No: ' + sae.saeInitialData.saeNumber + ')'
            }
            else {
                displayIdentifier = sae.randNo;
            }
        }

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/sae/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier
            }
        ]
    }

    renderNoUsersSelected(): any {

        const {
            saeId
        } = this.props

        return <div>Please select recipients to notify.</div>
    }

    renderNotifySuccess(): any {

        const {
            saeId
        } = this.props

        return <div>Notification email sent.</div>
    }
}


const mapStateToProps = (state: IFigState, ownProps: SaeNotifyPageProps): ISaeNotifyPageProps => {

    let trialUsers: FigDtos.TrialUser[] = state.trialUsers.data instanceof Array ? state.trialUsers.data as FigDtos.TrialUser[] : undefined

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        saeId: ownProps.match ? ownProps.match.params.saeId : undefined,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,
        trialUserType: ownProps.match ? ownProps.match.params.trialUserType : undefined,

        sae: !(state.saes.data instanceof Array) ? state.saes.data : undefined,
        trialUsers: trialUsers,
        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined,

        loadingSae: state.saes.loadState && state.saes.loadState.status == RequestState.Pending,
        loadSaeSuccess: state.saes.loadState && state.saes.loadState.status == RequestState.Success,
        loadSaeFailure: state.saes.loadState && state.saes.loadState.status == RequestState.Failure,

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,


        loadingTrialUsers: state.trialUsers.loadState && state.trialUsers.loadState.status == RequestState.Pending,
        loadTrialUsersSuccess: state.trialUsers.loadState && state.trialUsers.loadState.status == RequestState.Success,
        loadTrialUsersFailure: state.trialUsers.loadState && state.trialUsers.loadState.status == RequestState.Failure,

        processingSaeNotify: state.saes.updateState && state.saes.updateState.status == RequestState.Pending && state.saes.updateState.action == "NotifyTrialUsers",
        notifySaeSuccess: state.saes.updateState && state.saes.updateState.status == RequestState.Success && state.saes.updateState.action == "NotifyTrialUsers",
        notifySaeFailure: state.saes.updateState && state.saes.updateState.status == RequestState.Failure && state.saes.updateState.action == "NotifyTrialUsers",

        user: state.user.data
    };
};

const mapDispatchToProps = (dispatch): ISaeNotifyPageActions => {
    return {

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadSae: bindActionCreators(SaeActions.LoadSaesBySaeId, dispatch),
        clearSae: bindActionCreators(SaeActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),
      
        loadTrialUsersByInstitution: bindActionCreators(TrialUserActions.LoadTrialUsersByInstitution, dispatch),
        clearTrialUsers: bindActionCreators(TrialUserActions.Clear, dispatch),

        processSaeNotify: bindActionCreators(SaeActions.processSaeNotify, dispatch),
        resetNotifyForm: bindActionCreators(actions.reset, dispatch)
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(SaeNotifyPage);