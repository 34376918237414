
import * as React from 'react';
import { Router, Route, Switch, match, matchPath } from 'react-router';

import * as AuthenticationHelpers from '../../../helpers/authentication';
import * as Dtos from '../../../dtos/Fig.dtos';
import { OverlayLoading, NotificationAlert } from '..';
import { IAuthorisationDefinition } from '../../../interfaces/authentication/authorisationDefinition';
import { FontAwesomeIcons } from '../../../constants/fontAwesomeIcons';
import Alert from 'react-s-alert';
import { IFigState } from '../../../reducers';
import { connect } from 'react-redux';

interface IWhenAuthenticated {

}

interface IAuthenticatedFnProps {
    user?: Dtos.User;
    loadingUser?: boolean;
    loadUserSuccess?: boolean;
    loadUserFailure?: boolean;
    checkedAuthentication?: boolean;
    children?: (user: Dtos.User, authenticationHelpers: typeof AuthenticationHelpers) => React.ReactNode;
}

interface IAuthenticatedFnActions {

}

type AuthenticatedFnProps = IAuthenticatedFnProps & IAuthenticatedFnActions

class AuthenticatedFn extends React.Component<AuthenticatedFnProps, any> {

    render() {
        const {
            user,
            loadingUser,
            loadUserFailure,
            loadUserSuccess,
            checkedAuthentication,
            children
        } = this.props;

        if (!checkedAuthentication) {
            return <OverlayLoading />;
        }
        else {
            if (children) {
                return children(user!, AuthenticationHelpers);
            }

            return null;
        }
    }

    computeMatch(location, path, strict, exact) {
        const pathname = location.pathname;

        return matchPath(pathname, { path, strict, exact });
    }
}

const mapStateToProps = (state: IFigState, ownProps: AuthenticatedFnProps): IAuthenticatedFnProps => {

    return {

    };
};

const mapDispatchToProps = (dispatch): IAuthenticatedFnActions => {
    return {

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedFn);