import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Fig.dtos';

import {
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_SUCCESS,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_FAILURE,

    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE,
    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_SUCCESS,
    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_FAILURE,

    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE,
    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_SUCCESS,
    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_FAILURE,

    ACTION_ELIGIBILITYCRITERIAFORMS_CLEAR,
    EligibilityCriteriaFormAction
} from '../../actions/forms/eligibilityCriteriaForm';

import { RequestState } from "../../enumerations/RequestState";
import { IRequestState } from "../index";
import { updateFailureIndexToId } from "../../helpers/formErrorHelper";

export interface IEligibilityCriteriaFormState {
    formData: Dtos.EligibilityCriteriaForm | Dtos.EligibilityCriteriaForm[],
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    createState: IRequestState;
}

const initialState: IEligibilityCriteriaFormState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IEligibilityCriteriaFormState, isLoading: boolean): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IEligibilityCriteriaFormState,
    formData: Dtos.EligibilityCriteriaForm | Dtos.EligibilityCriteriaForm[],
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[], responseStatus: Dtos.ResponseStatus
): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: IEligibilityCriteriaFormState, responseStatus: Dtos.ResponseStatus): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function saving(state: IEligibilityCriteriaFormState, isSaving: boolean): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IEligibilityCriteriaFormState,
    formData: Dtos.EligibilityCriteriaForm | Dtos.EligibilityCriteriaForm[],
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus
): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IEligibilityCriteriaFormState, responseStatus: Dtos.ResponseStatus): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        }
    });

    return newState;
}

function creating(state: IEligibilityCriteriaFormState, isCreating: boolean): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IEligibilityCriteriaFormState,
    formData: Dtos.EligibilityCriteriaForm,
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IEligibilityCriteriaFormState, responseStatus: Dtos.ResponseStatus): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IEligibilityCriteriaFormState): IEligibilityCriteriaFormState {
    const newState: IEligibilityCriteriaFormState = update(initialState, {});

    return newState;
}

const EligibilityCriteriaFormReducer: Reducer<IEligibilityCriteriaFormState> = (state: IEligibilityCriteriaFormState = initialState, action: EligibilityCriteriaFormAction) => {
    switch (action.type) {
        case ACTION_ELIGIBILITYCRITERIAFORMS_LOAD:
        case ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID:
        case ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID:
        case ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER:
            return loading(state, true);
        case ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_ELIGIBILITYCRITERIAFORMS_SAVE:
            return saving(state, true);
        case ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_ELIGIBILITYCRITERIAFORMS_CREATE:
            return creating(state, true);
        case ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_ELIGIBILITYCRITERIAFORMS_CLEAR:
            return clear(state);
    }

    return state;
}

export default EligibilityCriteriaFormReducer;