import * as Dtos from '../dtos/Fig.dtos';

/**
 * 
 * @param patientStatus
 */
export function getPatientStatusText(patientStatus?: Dtos.PatientStatus): string | undefined {
    if (patientStatus) {
        switch (+patientStatus) {
            case Dtos.PatientStatus.PreEntry:
                return "Pre-Entry";
            case Dtos.PatientStatus.PreRegistered:
                return "Pre-Registered";
            case Dtos.PatientStatus.Submitted:
                return "Submitted for Eligibility Review";
            case Dtos.PatientStatus.Registered:
                return "Registered";
            case Dtos.PatientStatus.Eligible:
                return "Eligible";
            case Dtos.PatientStatus.Randomised:
                return "Randomised";
            case Dtos.PatientStatus.Ineligible:
                return "Ineligible";
            default:
                return undefined;
        }
    }

    return undefined;
}

export function getPatientAge(dateOfBirth: Date): number | undefined {

    if (dateOfBirth) {
        let today: Date = new Date();
        let birthday: Date = new Date(dateOfBirth);
        let age: number = today.getFullYear() - birthday.getFullYear();
        let month: number = today.getMonth() - birthday.getMonth();

        if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
            age--;
        }

        if (age <= 0) {
            return undefined;
        }

        return age;
    }

    return undefined;

}

export function isWaiverStatusConfiguration(patientStatusConfigurationAction: Dtos.PatientStatusConfigurationAction): boolean {
    if (patientStatusConfigurationAction) {
        switch (patientStatusConfigurationAction) {
            case Dtos.PatientStatusConfigurationAction.WaiverPatient:
                return true;
            case Dtos.PatientStatusConfigurationAction.WaiverPatientField:
                return true;
            case Dtos.PatientStatusConfigurationAction.WaiverPatientForm:
                return true;
            case Dtos.PatientStatusConfigurationAction.WaiverPatientRule:
                return true;
            default:
                return false;
        }
    }

    return false;
}

export function isRegisterPatientStatusConfiguration(patientStatusConfigurationAction: Dtos.PatientStatusConfigurationAction): boolean {
    if (patientStatusConfigurationAction) {
        switch (patientStatusConfigurationAction) {
            case Dtos.PatientStatusConfigurationAction.RegisterPatient:
                return true;
            default:
                return false;
        }
    }

    return false;
}

export function getLatestPatientWaiverConfiguration(patient: Dtos.Patient): Dtos.PatientStatusConfiguration | undefined {
    if (patient &&
        patient.patientStatusConfigurations) {

        let latestPatientStatusConfiguration: Dtos.PatientStatusConfiguration | undefined = undefined;

        // filter by waiverPatientStatus and other conditions first to reduce the array
        let filteredStatusConfigurations: Dtos.PatientStatusConfiguration[] = patient
            .patientStatusConfigurations
            .filter(ps => ps.logItem == true && ps.patientStatusConfigurationAction == Dtos.PatientStatusConfigurationAction.WaiverPatient);

        filteredStatusConfigurations.sort((a, b) => {
            return a.id - b.id
        });

        latestPatientStatusConfiguration = filteredStatusConfigurations[0];
        return latestPatientStatusConfiguration;
    }

    return undefined
}