import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_DEMOGRAPHICDETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL,
    DemographicDetailFormPageAction
} from '../../../actions/pages/registration/demographicDetailFormPage';


export interface IDemographicDetailFormPageState {
    messageModalOpen: boolean;
}

const initialState: IDemographicDetailFormPageState = {
    messageModalOpen: false
};


function toggleMessageModal(state: IDemographicDetailFormPageState): IDemographicDetailFormPageState {
    const newState: IDemographicDetailFormPageState = update(state, {
        messageModalOpen: {
            $set: !state.messageModalOpen
        }
    });

    return newState;
}

const DemographicDetailFormPageReducer: Reducer<IDemographicDetailFormPageState> = (state: IDemographicDetailFormPageState = initialState, action: DemographicDetailFormPageAction) => {
    switch (action.type) {
        case ACTION_DEMOGRAPHICDETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL:
            return toggleMessageModal(state);
    }

    return state;
}

export default DemographicDetailFormPageReducer;