import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Fig.dtos";

export const usersApi = {
    getUser: (): Observable<Dtos.GetUserResponse> => {
        //Create the request.
        let request: Dtos.GetUser = new Dtos.GetUser();

        //Send request.
        const response: Promise<Dtos.GetUserResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}