
import * as React from 'react';
import * as ReactDom from 'react-dom';
import classNames from "classnames";
import { addEventListener } from 'consolidated-events';
import { copyDocumentStyles, copyDocumentStylesLegacy, copyDocumentStylesToString } from "../../../helpers/styles";


interface IExternalWindowProps {
    onClose?: () => void;
    onOpen?: () => void;
    title: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}

//https://hackernoon.com/using-a-react-16-portal-to-do-something-cool-2a2d627b0202
class ExternalWindow extends React.PureComponent<IExternalWindowProps, any> {
    private containerEl: HTMLDivElement;
    private externalWindow: Window;
    // @ts-ignore
    private removeEventListener: Function = undefined;

    constructor(props) {
        super(props);
        // STEP 1: create a container <div>
        this.containerEl = document.createElement('div');
        // @ts-ignore
        this.externalWindow = null;
        this.closeWindow = this.closeWindow.bind(this);
    }
  
    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDom.createPortal(this.props.children, this.containerEl);
    }

    componentDidMount() {

        // STEP 3: open a new browser window and store a reference to it

        const {
            x,
            y,
            height,
            width
        } = this.props

        const derivedX: number = x ? x : 0;
        const derivedY: number = y ? y : 200;
        const derivedWidth: number = width ? width : 600;
        const derivedHeight: number = height ? height : 400;

        // @ts-ignore
        this.externalWindow = window.open('', '', 'width=' + derivedWidth + ',height=' + derivedHeight + ',left=' + derivedX + ',right=' + derivedY + '');
        
        const targetDocument = this.externalWindow.document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'html', null);
        const targetBody = this.externalWindow.document.createElementNS('http://www.w3.org/1999/xhtml', 'body');


        const styles = copyDocumentStylesToString(document);

        this.externalWindow.document.open().write('<html><head>' + styles + '</head><body><div id="targetDiv"></div></body></html>');
        this.externalWindow.document.close();

        try {
            // @ts-ignore
            this.externalWindow.document.getElementById('targetDiv').appendChild(this.containerEl);
        } catch (e) {
            if (this.containerEl.outerHTML) {
                // @ts-ignore
                this.externalWindow.document.getElementById('targetDiv').innerHTML = this.containerEl.outerHTML;
            } else {
                console.log(e);
            }
        }
        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        //this.externalWindow.document.body.appendChild(this.containerEl);

        this.externalWindow.document.title = this.props.title;

        if (this.props.onOpen) {
            this.externalWindow.addEventListener('load', () => {
                // @ts-ignore
                this.props.onOpen()
            });
        }

        if (this.props.onClose) {
            this.externalWindow.addEventListener('beforeunload', () => {
                // @ts-ignore
                this.props.onClose()
            });
        }

        this.removeEventListener = addEventListener(
            window,
            'beforeunload',
            this.closeWindow,
            { capture: true },
        );

    }

    componentWillUnmount() {
        // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
        // So we tidy up by closing the window
        this.closeWindow();
        if (this.removeEventListener) { this.removeEventListener(); }
    }

    closeWindow() {
        this.externalWindow.close();
    }


}

export default ExternalWindow