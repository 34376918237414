import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';

import {
    ACTION_PREREGISTRATIONFORMS_CLEAR,
    ACTION_PREREGISTRATIONFORMS_LOAD,
    ACTION_PREREGISTRATIONFORMS_LOAD_BY_ID,
    ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_ID,
    ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_PREREGISTRATIONFORMS_LOAD_FAILURE,
    ACTION_PREREGISTRATIONFORMS_LOAD_SUCCESS,

    ACTION_PREREGISTRATIONFORMS_SAVE,
    ACTION_PREREGISTRATIONFORMS_SAVE_FAILURE,
    ACTION_PREREGISTRATIONFORMS_SAVE_SUCCESS,

    ACTION_PREREGISTRATIONFORMS_CREATE,
    ACTION_PREREGISTRATIONFORMS_CREATE_SUCCESS,
    ACTION_PREREGISTRATIONFORMS_CREATE_FAILURE,

    IPreRegistrationFormLoadAction,
    IPreRegistrationFormClearAction,
    IPreRegistrationFormLoadByIdAction,
    IPreRegistrationFormLoadByPatientIdAction,
    IPreRegistrationFormLoadByPatientIdentifierAction,
    IPreRegistrationFormLoadFailureAction,
    IPreRegistrationFormLoadSuccessAction,

    IPreRegistrationFormSaveAction,
    IPreRegistrationFormSaveSuccessAction,
    IPreRegistrationFormSaveFailureAction,

    IPreRegistrationFormCreateAction,
    IPreRegistrationFormCreateSuccessAction,
    IPreRegistrationFormCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure,

} from "../../actions/forms/preRegistrationForm";

import {
    preRegistrationFormApi
} from "../../services/Forms/preRegistrationForm";

export const
    getPreRegistrationFormsEpic = (action$): Observable<IPreRegistrationFormLoadSuccessAction | IPreRegistrationFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PREREGISTRATIONFORMS_LOAD)
            .mergeMap((action: IPreRegistrationFormLoadAction) => {
                return preRegistrationFormApi
                    .getPreRegistrationForms()
                    .map(response =>
                        // @ts-ignore
                        LoadSuccess(response.preRegistrationForms, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPreRegistrationFormsByIdEpic = (action$): Observable<IPreRegistrationFormLoadSuccessAction | IPreRegistrationFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PREREGISTRATIONFORMS_LOAD_BY_ID)
            .mergeMap((action: IPreRegistrationFormLoadByIdAction) => {
                return preRegistrationFormApi
                    .getPreRegistrationFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.preRegistrationForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPreRegistrationFormsByPatientIdEpic = (action$): Observable<IPreRegistrationFormLoadSuccessAction | IPreRegistrationFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_ID)
            .mergeMap((action: IPreRegistrationFormLoadByPatientIdAction) => {
                return preRegistrationFormApi
                    .getPreRegistrationFormByPatientId(action.patientId)
                    .map(response =>
                        LoadSuccess(response.preRegistrationForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPreRegistrationFormsByPatientIdentifierEpic = (action$): Observable<IPreRegistrationFormLoadSuccessAction | IPreRegistrationFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_IDENTIFIER)
            .mergeMap((action: IPreRegistrationFormLoadByPatientIdentifierAction) => {
                return preRegistrationFormApi
                    .getPreRegistrationFormByPatientIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.preRegistrationForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setPreRegistrationFormsEpic = (action$): Observable<IPreRegistrationFormSaveSuccessAction | IPreRegistrationFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_PREREGISTRATIONFORMS_SAVE)
            .mergeMap((action: IPreRegistrationFormSaveAction) => {
                return preRegistrationFormApi
                    .setPreRegistrationForm(action.formData, action.institutionCode, action.patientIdentifier)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.preRegistrationForm, response.registrationFormState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createPreRegistrationFormsEpic = (action$): Observable<IPreRegistrationFormCreateSuccessAction | IPreRegistrationFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_PREREGISTRATIONFORMS_CREATE)
            .mergeMap((action: IPreRegistrationFormCreateAction) => {
                return preRegistrationFormApi
                    .createPreRegistrationForm()
                    .map(response =>
                        CreateSuccess(response.preRegistrationForm, response.registrationFormState, response.registrationFormProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }





