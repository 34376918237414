import { client } from "../../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../../dtos/Fig.dtos";

export const eligibilityCriteriaFormApi = {
    getEligibilityCriteriaForms: (): Observable<Dtos.GetEligibilityCriteriaFormsResponse> => {
        //Create the request.
        let request: Dtos.GetEligibilityCriteriaForms = new Dtos.GetEligibilityCriteriaForms();

        //Send request.
        const response: Promise<Dtos.GetEligibilityCriteriaFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getEligibilityCriteriaFormById: (id: number): Observable<Dtos.GetEligibilityCriteriaFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetEligibilityCriteriaFormById = new Dtos.GetEligibilityCriteriaFormById();

        request.id = id;
        //Send request.
        const response: Promise<Dtos.GetEligibilityCriteriaFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getEligibilityCriteriaFormByPatientId: (patientId: number): Observable<Dtos.GetEligibilityCriteriaFormByPatientIdResponse> => {
        //Create the request.
        let request: Dtos.GetEligibilityCriteriaFormByPatientId = new Dtos.GetEligibilityCriteriaFormByPatientId();

        request.patientId = patientId;

        //Send request.
        const response: Promise<Dtos.GetEligibilityCriteriaFormByPatientIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getEligibilityCriteriaFormByPatientIdentifier: (identifier: string): Observable<Dtos.GetEligibilityCriteriaFormByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetEligibilityCriteriaFormByPatientIdentifier = new Dtos.GetEligibilityCriteriaFormByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetEligibilityCriteriaFormByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setEligibilityCriteriaForm: (EligibilityCriteriaForm: Dtos.EligibilityCriteriaForm,
        institutionCode: string,
        patientIdentifier: string): Observable<Dtos.SetEligibilityCriteriaFormResponse> => {

        let request: Dtos.SetEligibilityCriteriaForm = new Dtos.SetEligibilityCriteriaForm();

        request.eligibilityCriteriaForm = EligibilityCriteriaForm;
        request.institutionCode = institutionCode;
        request.patientIdentifier = patientIdentifier;

        // send request
        const response: Promise<Dtos.SetEligibilityCriteriaFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createEligibilityCriteriaForm: (): Observable<Dtos.CreateEligibilityCriteriaFormResponse> => {
        //Create the request.
        let request: Dtos.CreateEligibilityCriteriaForm = new Dtos.CreateEligibilityCriteriaForm();

        //Send request.
        const response: Promise<Dtos.CreateEligibilityCriteriaFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}