import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Fig.dtos";

export const fileApi = {
    uploadTempFile: (file: File, onProgressUpdate: (loaded: number, total: number) => void, onUploadSuccess: (guid: string) => void, onUploadFailure: () => void): void => {
        let xhr: XMLHttpRequest = new XMLHttpRequest();

        if (xhr.upload) {

            // progress bar
            xhr.upload.addEventListener("progress", function (e) {
                //var pc = parseInt(100 - (e.loaded / e.total * 100));
                //progress.style.backgroundPosition = pc + "% 0";
                //console.log(e);
                onProgressUpdate(e.loaded, e.total);
            }, false);

            // file received/failed
            xhr.onreadystatechange = function (e) {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        onUploadSuccess("");
                    }
                    else {
                        onUploadFailure();
                    }
                }
            };

            xhr.open("POST", "/api/file/temp/upload", true);
            xhr.setRequestHeader("X-FILENAME", file.name);
            xhr.send(file);
        }
    },
    /** @description Gets a donation object from the server.
     * @return {Observable}
     */
    getTempFileInfo: (guid: string): Observable<Dtos.GetTempFileInfoResponse> => {
        //Create the request.
        let getTempFileInfo: Dtos.GetTempFileInfo = new Dtos.GetTempFileInfo();

        getTempFileInfo.guid = guid;

        return Observable.from(client.get(getTempFileInfo));
    }

};