import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialPreRegistrationFormState: Dtos.PreRegistrationForm & IRegistrationFormState = {
    id: null,
    patientId: null,
    patientInitials: null,
    gender: null,
    dateOfBirth: null,
    registrationGroup: null,
    medicalOncologistName: null,
    radiationOncologistName: null,
    medicareConsent: null,
    dateOfServicesAustraliaDataConsent: null,
    consentDetails: null,
    startDateOfDataCollection: null,
    stopDateOfDataCollection: null,
    dateWrittenInformedConsent: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface IPreRegistrationReduxFormState {
    id: IFieldState<number>;
    patientId: IFieldState<number>,
    patientInitials: IFieldState<string>,
    gender: IFieldState<number>,
    dateOfBirth: IFieldState<string>,
    registrationGroup: IFieldState<number>,
    medicalOncologistName: IFieldState<string>,
    radiationOncologistName: IFieldState<string>,
    medicareConsent: IFieldState<number>,
    dateOfServicesAustraliaDataConsent: IFieldState<string>,
    consentDetails: IFieldState<number>,
    startDateOfDataCollection: IFieldState<string>,
    stopDateOfDataCollection: IFieldState<string>,
    dateWrittenInformedConsent: IFieldState<string>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>
}