import * as React from 'react';
import './PatientSummaryPage.scss';

import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';

import { flow } from "lodash";
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';
import * as InstitutionActions from '../../../actions/institution';
import * as PatientSummaryPageActions from '../../../actions/pages/registration/patientSummaryPage';
import * as RegistrationFormSummaryActions from '../../../actions/registrationFormSummary';
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';

import * as Dtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { PatientPanel, Tree, TreeNode, ListGroupItem, NotificationAlert, Modal } from "../../../components/common/index";
import { ITreeNode, ITree } from "../../../components/common/Tree/Tree";
import { CamelToKebabCase } from "../../../functions/string";
import { getPatientStatusText, isWaiverStatusConfiguration, isRegisterPatientStatusConfiguration, getLatestPatientWaiverConfiguration } from "../../../helpers/patient";
import { setModalTitle } from "../../../helpers/modalHelper";
import { IPatientStatusModalData } from "../../../reducers/interfaces/IPatientStatusModalData";
import { ModalSize } from '../../../enumerations/ModalSize';
import { actions, Form, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { IPatientStatusModalFormState } from '../../../reducers/reactReduxForms/patientStatusModalForm';
import { convertToShortDateString } from '../../../helpers/date';



interface IPatientSummaryPageParams {
    identifier: string;
    institutionCode: string;
}

interface IPatientSummaryPageActions {
    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    clearPatient: PatientActions.IPatientClearActionCreator;
    clearPatientUpdate: () => void;
    loadPatient: PatientActions.IPatientLoadByIdentifierActionCreator;
    setPatientStatusById: PatientActions.IPatientSetStatusByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    updateSummaryTreeState: PatientSummaryPageActions.IPatientSummaryPageUpdateSummaryTreeStateActionCreator;
    setPatientIdentifier: PatientSummaryPageActions.IPatientSummaryPageSetPatientIdentifierActionCreator;
    clearPatientSummaryPageState: PatientSummaryPageActions.IPatientSummaryPageClearActionCreator;

    loadRegistrationFormSummaries: RegistrationFormSummaryActions.IRegistrationFormSummariesLoadByPatientIdentifierActionCreator;
    clearRegistrationFormSummaries: RegistrationFormSummaryActions.IRegistrationFormSummariesClearActionCreator;

    setPatientStatusModalViewState: PatientSummaryPageActions.IPatientSummaryPageSetPatientStatusModalViewStateActionCreator;
    setPatientStatusModalPatientId: PatientSummaryPageActions.IPatientSummaryPageSetPatientStatusModalPatientIdActionCreator;
    setPatientStatusModalNewStatus: PatientSummaryPageActions.IPatientSummaryPageSetPatientStatusModalNewStatusActionCreator;
    setPatientStatusModalPatientSiteNumber: PatientSummaryPageActions.IPatientSummaryPageSetPatientStatusModalPatientSiteNumberActionCreator;
    setPatientStatusModalStatusConfiguration: PatientSummaryPageActions.IPatientSummaryPageSetPatientStatusModalStatusConfigurationActionCreator;

    resetPatientStatusModalForm: typeof actions.reset

    setRenderTree: PatientSummaryPageActions.IPatientSummaryPageSetRenderTreeActionCreator;

    resendEmailForPatient: PatientActions.IResendEmailForPatientByIdActionCreator;
}

interface IPatientSummaryPageProps {
    location: Location;
    match: match<IPatientSummaryPageParams>;
    history: History;
    identifier: string;
    institutionCode: string;

    patient: Dtos.Patient;
    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;
    updatingPatient: boolean;
    updatePatientSuccess: boolean;
    updatePatientFailure: boolean;
    updatePatientFailureError: string;

    institution: Dtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    summaryTreeState: any;
    registrationFormSummaries: Dtos.RegistrationFormSummary[];
    renderTree: boolean;
    storedIdentifier: string;

    loadingRegistrationFormSummaries: boolean;
    loadRegistrationFormSummariesSuccess: boolean;
    loadRegistrationFormSummariesFailure: boolean;

    patientStatusModalOpen: boolean;
    patientStatusModalData: IPatientStatusModalData;

    user: Dtos.User

    patientStatusConfigurations: Dtos.PatientStatusConfiguration[];

    waiveredPatient: boolean;

    resendingPatientEmail: boolean
    resendingPatientEmailSuccess: boolean;
    resendingPatientEmailFailure: boolean;
}

interface IRegistrationFormSummaryNode {
    name: string,
    icon?: React.ReactNode,
    answeredQuestions: number,
    totalQuestions: number,
    errors: Dtos.ResponseError[],
    ineligibleErrors: Dtos.ResponseError[],
    url: string
}

interface IValidationErrorNode {
    name,
    icon?: React.ReactNode,
    ruleset: string,
    validationClassName: string,
    validationErrors: any
}

const patientStatusModalFormName = "reduxForms.patientStatusModalForm";

type PatientSummaryPageProps = IPatientSummaryPageProps & IPatientSummaryPageActions;

class PatientSummaryPage extends React.PureComponent<PatientSummaryPageProps, any> {

    constructor(props: PatientSummaryPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearRegistrationFormSummaries = this.clearRegistrationFormSummaries.bind(this);
        this.clearPatientSummaryPageState = this.clearPatientSummaryPageState.bind(this);
        this.onSummaryTreeToggle = this.onSummaryTreeToggle.bind(this);
        this.clearPatientStatusModal = this.clearPatientStatusModal.bind(this);
    }

    componentDidMount() {
        const {
            loadPatient,
            identifier,
            institutionCode,
            loadInstitution,
            loadRegistrationFormSummaries,
            storedIdentifier,
            setPatientIdentifier,

        } = this.props;


        this.clearPatientStatusModal();
        this.expandPatientSummaryTree();
        if (storedIdentifier != identifier) {
            this.clearPatientSummaryPageState();
        }

        if (identifier) {
            setPatientIdentifier(identifier);
            loadPatient(identifier);
            loadRegistrationFormSummaries(identifier)
        }

        if (institutionCode) {
            loadInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: PatientSummaryPageProps) {
    }

    componentDidUpdate(prevProps: PatientSummaryPageProps) {
        const {
            patient,
            loadPatient,
            loadingPatient,
            loadPatientSuccess,
            loadPatientFailure,
            navigate,
            navigateReplace,
            identifier,
            institutionCode,
            loadInstitution,
            loadRegistrationFormSummaries,
            clearPatientSummaryPageState,
            setPatientIdentifier,
            updatingPatient,
            updatePatientSuccess,
            patientStatusModalOpen,
            patientStatusModalData,
            clearPatientUpdate,
            loadingRegistrationFormSummaries,
            loadRegistrationFormSummariesSuccess,
            resendingPatientEmail,
            resendingPatientEmailSuccess,
            resendingPatientEmailFailure
        } = this.props;

        if (patient &&
            patient.registrationNumber &&
            prevProps.patient &&
            prevProps.patient.registrationNumber == null) {

            clearPatientUpdate()

            navigateReplace("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/patient-summary");
        }

        if ((identifier && prevProps.identifier != identifier)) {

            clearPatientSummaryPageState();
            setPatientIdentifier(identifier);
            loadPatient(identifier);
            loadRegistrationFormSummaries(identifier);
            this.expandPatientSummaryTree();
        }

        if (!updatingPatient && prevProps.updatingPatient &&
            updatePatientSuccess) {
            loadPatient(identifier);
            loadRegistrationFormSummaries(identifier);
            this.expandPatientSummaryTree();
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }

        if (!patientStatusModalOpen && prevProps.patientStatusModalOpen) {
            this.clearPatientStatusModal();
        }

        if (!loadingPatient && (prevProps.loadingPatient && prevProps.updatePatientSuccess)) {
            Alert.success(<NotificationAlert
                alertContent={this.renderPatientStatusUpdateSuccess()}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

        if (!resendingPatientEmail && prevProps.resendingPatientEmail) {
            if (resendingPatientEmailSuccess) {
                Alert.success(<NotificationAlert
                    alertContent={this.renderResendEmailSuccess()}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            } else if (resendingPatientEmailFailure) {
                Alert.error(<NotificationAlert
                    alertContent={this.renderResendEmailFailure()}
                    icon={FontAwesomeIcons.Solid.CHECK}
                />);
            }
        }

        // save failed error
        if (!updatingPatient && prevProps.updatingPatient &&
            !updatePatientSuccess) {
            Alert.error(<NotificationAlert
                alertContent={this.renderPatientStatusUpdateFailure()}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

        if (!loadingRegistrationFormSummaries && prevProps.loadingRegistrationFormSummaries && loadRegistrationFormSummariesSuccess) {
            this.expandPatientSummaryTree();
        }
    }

    componentWillUpdate(nextProps: PatientSummaryPageProps) {
    }

    componentWillUnmount() {

        this.clearInstitution();
        this.clearPatient();
        this.clearRegistrationFormSummaries();
        this.clearPatientStatusModal();
        this.clearPatientStatusModalForm();
    }

    render() {

        const {
            loadingPatient,
            loadingInstitution,
            loadingRegistrationFormSummaries,
            updatePatientSuccess,
            updatingPatient
        } = this.props

        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={((!this.showPatient() || loadingPatient || loadingRegistrationFormSummaries) && !updatePatientSuccess) || loadingInstitution}
            saving={updatingPatient || ((loadingPatient || loadingRegistrationFormSummaries) && updatePatientSuccess)}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {

        const {
            patient,
            institutionCode,
            loadingPatient,
            loadPatientSuccess,
            loadPatientFailure,
            clearPatient,
            identifier,
            institution,
            loadingRegistrationFormSummaries,
            registrationFormSummaries,
            loadRegistrationFormSummariesSuccess,
            user,
            waiveredPatient,
            resendEmailForPatient
        } = this.props;

        const invalidPatient = (registrationFormSummaries &&
            registrationFormSummaries.filter(rfs => rfs.errors.length > 0).length > 0);

        return <div>
            {
                this.showPatient() && patient &&
                <div>
                    <PatientPanel
                        identifier={patient.registrationNumber ? patient.registrationNumber : patient.siteNumber.toString()}
                        institutionCode={institutionCode}
                        institutionName={institution ? institution.institutionName : undefined}
                        patientInitials={patient.initials}
                        patientStatus={patient.status}
                        registrationDate={patient.registrationDate}
                    />

                    {
                        waiveredPatient &&
                        <div className="patient-info-wrapper">
                            <h2>Patient Waivered</h2>
                            <div className="patient-status-config-table">
                                {this.renderPatientSumnmaryTable()}
                            </div>
                        </div>
                    }
                    {
                        this.showPatientSummary() ?
                            this.renderPatientSummaryTree() :
                            null
                    }
                    <div className="mt-2 mb-2 d-flex flex-row-reverse">
                        {
                            AuthenticationHelper.isTrogOrSuperUser(user) &&
                            !waiveredPatient &&
                            (patient.status == Dtos.PatientStatus.Ineligible || invalidPatient) &&
                            <div className="btn btn-primary mr-2" onClick={() => {
                                this.createPatientStatusModal(Dtos.PatientStatus.Registered, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.WaiverPatient)
                            }}>Register Patient by Waiver</div>
                        }
                        {
                            AuthenticationHelper.isTrogOrSuperUser(user) &&
                            patient.status != Dtos.PatientStatus.Ineligible && patient.status != Dtos.PatientStatus.Randomised &&
                            <div className="btn btn-primary mr-2" onClick={() => { this.createPatientStatusModal(Dtos.PatientStatus.Ineligible, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.IneligiblePatient) }}>Set Patient as Ineligible</div>
                        }
                        {
                            AuthenticationHelper.isTrogOrSuperUser(user) &&
                            patient.status == Dtos.PatientStatus.Submitted &&
                            <>
                                <div className="btn btn-primary mr-2" onClick={() => { this.reassignToSitePatient() }}>Reassign to site</div>
                                <div className="btn btn-primary mr-2" onClick={() => { this.registerPatient() }}>Register Patient</div>
                            </>
                        }
                        {
                            patient.status == Dtos.PatientStatus.PreRegistered ?
                                <div className="btn btn-primary mr-2" onClick={() => { this.submitPatient() }}>Submit Patient for Eligibility Review</div> : null
                        }
                        {
                            AuthenticationHelper.isTrogOrSuperUser(user) &&
                            (patient.status == Dtos.PatientStatus.Registered || patient.status == Dtos.PatientStatus.Submitted) &&
                            <div className="btn btn-secondary mr-2" onClick={() => {
                                resendEmailForPatient(patient.id)
                            }}>Re-send email</div>
                        }
                    </div>
                </div>
            }
            {
                this.renderPatientStatusModal()
            }
        </div>
    }
    

    clearPatient() {
        this.props.clearPatient();
    }



    clearInstitution() {
        this.props.clearInstitution();
    }

    clearRegistrationFormSummaries() {
        this.props.clearRegistrationFormSummaries();
    }

    clearPatientSummaryPageState() {
        this.props.clearPatientSummaryPageState();
    }

    clearPatientStatusModalForm() {
        this.props.resetPatientStatusModalForm(patientStatusModalFormName)
    }

    clearPatientStatusModal() {
        this.props.setPatientStatusModalViewState(false);
        this.props.setPatientStatusModalPatientId(undefined!);
        this.props.setPatientStatusModalNewStatus(undefined!);
    }

    showPatientSummary(): boolean {
        const {
            loadingPatient,
            updatingPatient,
            loadPatientSuccess,
            updatePatientSuccess,
            loadingRegistrationFormSummaries,
            loadRegistrationFormSummariesSuccess,
            renderTree
        } = this.props

        if ((updatePatientSuccess || (!loadingRegistrationFormSummaries && loadRegistrationFormSummariesSuccess)) && renderTree) {
            return true;
        }

        return false;
    }

    showPatient(): boolean {
        const {
            loadingPatient,
            updatingPatient,
            loadPatientSuccess,
            updatePatientSuccess
        } = this.props

        if (updatePatientSuccess || (!loadingPatient && loadPatientSuccess)) {
            return true;
        }

        return false;
    }

    renderResendEmailSuccess(): any {
        const {
            patient
        } = this.props

        return <div>{"Patient " +
            (patient.registrationNumber ? patient.registrationNumber : patient.siteNumber) +
            " email was resent "
        }</div>

    }

    renderResendEmailFailure(): any {
        const {
            patient
        } = this.props

        return <div>{"Resend email was not successful"}</div>

    }

    renderPatientStatusUpdateSuccess(): any {
        const {
            patient
        } = this.props

        return <div>{"Patient " +
            (patient.registrationNumber ? patient.registrationNumber : patient.siteNumber) +
            " has been successfully set to the new status of " +
            getPatientStatusText(patient.status)
        }</div>

    }

    renderPatientStatusUpdateFailure(): any {
        const {
            patient,
            updatePatientFailure,
            updatePatientFailureError
        } = this.props

        console.log('updatePatientFailure', updatePatientFailureError);

        return <div>{updatePatientFailureError || "Patient status change was not successful"}</div>

    }

    renderPatientRegisteredSuccess(): any {

        const {
            patient
        } = this.props

        return <div>{"Patient" +
            patient.registrationNumber ? patient.registrationNumber : patient.siteNumber +
            "has been successfully registered"
        }</div>
    }

    renderPatientInvalidRegister(): any {

        const {
            patient,
            registrationFormSummaries
        } = this.props

        const patientIdentifier = patient.registrationNumber ? patient.registrationNumber : patient.siteNumber

        return <div>Patient {patientIdentifier} is unable to be registered. Please ensure all required form fields are answered and that no form errors exist.</div>
    }

    renderPatientInvalidSubmit(): any {

        const {
            patient,
            registrationFormSummaries
        } = this.props

        const patientIdentifier = patient.registrationNumber ? patient.registrationNumber : patient.siteNumber

        return <div>Patient {patientIdentifier} is unable to be submitted. Please ensure all required form fields are answered and that no form errors exist.</div>
    }

    renderPatientInvalidEligable(): any {

        const {
            patient,
            registrationFormSummaries
        } = this.props

        const patientIdentifier = patient.registrationNumber ? patient.registrationNumber : patient.siteNumber

        return <div>Patient {patientIdentifier} is unable to be set as eligible. Please ensure all required form fields are answered and that no form errors exist.</div>
    }

    renderPatientInvalidRandomise(): any {

        const {
            patient,
            registrationFormSummaries
        } = this.props

        const patientIdentifier = patient.registrationNumber ? patient.registrationNumber : patient.siteNumber

        return <div>Patient {patientIdentifier} is unable to be randomised. Please ensure all required form fields are answered and that no form errors exist.</div>
    }

    renderPatientSumnmaryTable(): any {
        const {
            patient,
            waiveredPatient
        } = this.props;

        let latestWaiveredPatientStatusConfiguration: Dtos.PatientStatusConfiguration = getLatestPatientWaiverConfiguration(patient);

        return <div>
            {

                patient && waiveredPatient && latestWaiveredPatientStatusConfiguration ?
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <th>Reason</th>
                                <td>{latestWaiveredPatientStatusConfiguration.reason}</td>
                            </tr>
                            <tr>
                                <th>Date of Waiver</th>
                                <td>{convertToShortDateString(latestWaiveredPatientStatusConfiguration.dateEntered)}</td>
                            </tr>
                        </tbody>
                    </table> :
                    null
            }
        </div>
    }

    handleSubmitPatientStatusForm(patientStatusModalForm: IPatientStatusModalFormState) {

        const {
            patient,
            patientStatusModalData,
            setPatientStatusById,
            setPatientStatusModalViewState,
        } = this.props;

        patientStatusModalData.patientStatusConfiguration.reason = patientStatusModalForm.reason;

        this.setPatientStatus(patientStatusModalData.newStatus, patientStatusModalData.patientId, patientStatusModalData.patientStatusConfiguration);
        setPatientStatusModalViewState(false);

        this.clearPatientStatusModalForm();

    }

    handleOnSubmitFailed(patientstatusModalForm: IPatientStatusModalFormState) {
        const {

        } = this.props;

        Alert.error(<NotificationAlert
            alertContent="Unable to process form. Please contact your system administrator"
            icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
        />);
    }

    setPatientEligable() {
        const {
            patient,
            identifier,
            institution,
            registrationFormSummaries,
            setPatientStatusById,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            setPatientStatusModalViewState,
            setPatientStatusModalPatientSiteNumber,
            waiveredPatient
        } = this.props

        const invalidPatient =
            (!waiveredPatient && (registrationFormSummaries &&
                registrationFormSummaries.filter(rfs => rfs.errors.length > 0).length > 0));

        // Safe to register patient
        if (patient &&
            patient.status == Dtos.PatientStatus.Registered &&
            !invalidPatient) {

            this.createPatientStatusModal(Dtos.PatientStatus.Eligible, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.EligiblePatient);

        }
        else { // errors stopping patient registration -> show alert
            Alert.error(<NotificationAlert
                maxWidth={500}
                minWidth={500}
                alertContent={this.renderPatientInvalidEligable()}
                icon={FontAwesomeIcons.Regular.TIMES}
            />);
        }
    }

    randomisePatient() {
        const {
            patient,
            identifier,
            institution,
            registrationFormSummaries,
            setPatientStatusById,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            setPatientStatusModalViewState,
            setPatientStatusModalPatientSiteNumber,
            waiveredPatient
        } = this.props

        const invalidPatient = (
            !waiveredPatient && (registrationFormSummaries &&
                registrationFormSummaries.filter(rfs => rfs.errors.length > 0).length > 0));

        // Safe to register patient
        if (patient &&
            patient.status == Dtos.PatientStatus.Eligible &&
            !invalidPatient) {

            this.createPatientStatusModal(Dtos.PatientStatus.Randomised, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.RegisterPatient);

        }
        else { // errors stopping patient registration -> show alert
            Alert.error(<NotificationAlert
                maxWidth={500}
                minWidth={500}
                alertContent={this.renderPatientInvalidRandomise()}
                icon={FontAwesomeIcons.Regular.TIMES}
            />);
        }
    }

    submitPatient() {

        const {
            patient,
            identifier,
            institution,
            registrationFormSummaries,
            setPatientStatusById,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            setPatientStatusModalViewState,
            setPatientStatusModalPatientSiteNumber
        } = this.props

        const invalidPatient = (registrationFormSummaries &&
            registrationFormSummaries.filter(rfs => rfs.errors.length > 0).length > 0);

        // Safe to submite patient
        if (patient &&
            patient.status == Dtos.PatientStatus.PreRegistered &&
            !invalidPatient) {

            this.createPatientStatusModal(Dtos.PatientStatus.Submitted, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.SubmitPatient);

        }
        else { // errors stopping patient submission -> show alert
            Alert.error(<NotificationAlert
                maxWidth={500}
                minWidth={500}
                alertContent={this.renderPatientInvalidSubmit()}
                icon={FontAwesomeIcons.Regular.TIMES}
            />);
        }
    }

    reassignToSitePatient() {
        const {
            patient,
            identifier,
            institution,
            registrationFormSummaries,
            setPatientStatusById,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            setPatientStatusModalViewState,
            setPatientStatusModalPatientSiteNumber
        } = this.props

        // Safe to re-assign patient
        if (patient &&
            patient.status == Dtos.PatientStatus.Submitted) {
            this.createPatientStatusModal(Dtos.PatientStatus.PreRegistered, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.ReassignToSite);

        }
        else { // errors stopping patient submission -> show alert
            Alert.error(<NotificationAlert
                maxWidth={500}
                minWidth={500}
                alertContent={this.renderPatientInvalidSubmit()}
                icon={FontAwesomeIcons.Regular.TIMES}
            />);
        }
    }

    registerPatient() {

        const {
            patient,
            identifier,
            institution,
            registrationFormSummaries,
            setPatientStatusById,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            setPatientStatusModalViewState,
            setPatientStatusModalPatientSiteNumber
        } = this.props

        const invalidPatient = (registrationFormSummaries &&
            registrationFormSummaries.filter(rfs => rfs.errors.length > 0).length > 0);

        // Safe to register patient
        if (patient &&
            patient.status == Dtos.PatientStatus.Submitted &&
            !invalidPatient) {

            this.createPatientStatusModal(Dtos.PatientStatus.Registered, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.RegisterPatient);

        }
        else { // errors stopping patient registration -> show alert
            Alert.error(<NotificationAlert
                maxWidth={500}
                minWidth={500}
                alertContent={this.renderPatientInvalidRegister()}
                icon={FontAwesomeIcons.Regular.TIMES}
            />);
        }
    }

    createPatientStatusModal(
        newStatus: Dtos.PatientStatus,
        patientId: number,
        patientSiteNumber: number,
        patientStatusConfigurationAction: Dtos.PatientStatusConfigurationAction) {
        const {
            patient,
            identifier,
            institution,
            registrationFormSummaries,
            setPatientStatusById,
            setPatientStatusModalViewState,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            setPatientStatusModalPatientSiteNumber,
            setPatientStatusModalStatusConfiguration
        } = this.props

        if (!patientId) {
            patientId = patient.id;
        }

        if (newStatus && patientId && patientSiteNumber) {

            setPatientStatusModalNewStatus(newStatus);
            setPatientStatusModalPatientId(patientId);
            setPatientStatusModalPatientSiteNumber(patientSiteNumber);
            setPatientStatusModalViewState(true);

            let psc: Partial<Dtos.PatientStatusConfiguration> = {
                patientId: patientId,
                logItem: isWaiverStatusConfiguration(patientStatusConfigurationAction) ? false : true,
                patientStatusConfigurationAction: patientStatusConfigurationAction,
            };

            setPatientStatusModalStatusConfiguration(psc);

        }

    }

    setPatientStatus(
        newStatus: Dtos.PatientStatus,
        patientId: number,
        statusConfiguration: Partial<Dtos.PatientStatusConfiguration>) {
        const {
            patient,
            identifier,
            institution,
            registrationFormSummaries,
            setPatientStatusById,
        } = this.props

        if (!patientId) {
            patientId = patient.id;
        }


        setPatientStatusById(patientId, newStatus, statusConfiguration);


    }

    createSubMenuItems(): INavItem[] {

        const {
            identifier,
            institutionCode,
            institution,
            patient
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = ''
        if (patient) {
            if (patient.registrationNumber) {
                displayIdentifier = patient.registrationNumber;
            }
            else if (patient.siteNumber) {
                displayIdentifier = patient.siteNumber.toString();
            }
        }


        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/registration/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier
            }
        ]
    }


    renderPatientSummaryTree() {

        const {
            updateSummaryTreeState,
            summaryTreeState,
            registrationFormSummaries,
        } = this.props

        return <div className="tree-container">
            <Tree
                treeData={this.createSummaryTreeData()}
                onToggle={updateSummaryTreeState}
                toggleState={summaryTreeState}
            />
        </div>
    }

    onSummaryTreeToggle(node, toggled, treeData) {

        const {
            updateSummaryTreeState,

        } = this.props

        node.active = true;
        let treeDataModified = treeData;
        if (node.children) {

            node.toggled = toggled;
            treeDataModified = Object.assign({}, this.state.data);
        }

        updateSummaryTreeState(treeDataModified);

    }


    createSummaryTreeData(): ITree {

        const {
            registrationFormSummaries,
            institutionCode,
            identifier,
            navigate,
            patient
        } = this.props

        const groupDecorator = (node: ITreeNode<IRegistrationFormSummaryNode>, toggled: boolean, onToggle: (id: string) => void) => {

            const answeredQuestionDigits: number = node.data.answeredQuestions.toString().length;
            const totalQuestionDigits: number = node.data.totalQuestions.toString().length;

            const answeredSpan = <span>
                {
                    answeredQuestionDigits < totalQuestionDigits ?
                        <span style={{ visibility: "hidden" }}>
                            {
                                "00000".slice(answeredQuestionDigits - totalQuestionDigits)
                            }
                        </span> :
                        null
                }
                {node.data.answeredQuestions}
            </span>

            const totalSpan = <span>
                {node.data.totalQuestions}
                {
                    totalQuestionDigits < answeredQuestionDigits ?
                        <span style={{ visibility: "hidden" }}>
                            {
                                "00000".slice(totalQuestionDigits - answeredQuestionDigits)
                            }
                        </span> :
                        null
                }
            </span>

            return <ListGroupItem onClick={() => { onToggle(node.id) }} className="-clickable">
                <div className="d-flex align-items-center">
                    {
                        node.subTree ?
                            <div style={{ transform: toggled ? "rotate(90deg)" : "rotate(0deg)", transition: "all .5s ease" }}>
                                {
                                    <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.ANGLE_RIGHT} />
                                }
                            </div> :
                            null

                    }
                    <strong className="ml-2">{node.data.name}</strong>
                    <div className="ml-auto text-center" style={{ width: "90px" }}>
                        {answeredSpan} / {totalSpan}
                    </div>
                </div>
            </ListGroupItem>
        }

        const formDecorator = (node: ITreeNode<IRegistrationFormSummaryNode>, toggled: boolean, onToggle: (id: string) => void) => {

            const answeredQuestionDigits: number = node.data.answeredQuestions.toString().length;
            const totalQuestionDigits: number = node.data.totalQuestions.toString().length;

            const answeredSpan = <span>
                {
                    answeredQuestionDigits < totalQuestionDigits ?
                        <span style={{ visibility: "hidden" }}>
                            {
                                "00000".slice(answeredQuestionDigits - totalQuestionDigits)
                            }
                        </span> :
                        null
                }
                {node.data.answeredQuestions}
            </span>

            const totalSpan = <span>
                {node.data.totalQuestions}
                {
                    totalQuestionDigits < answeredQuestionDigits ?
                        <span style={{ visibility: "hidden" }}>
                            {
                                "00000".slice(totalQuestionDigits - answeredQuestionDigits)
                            }
                        </span> :
                        null
                }
            </span>


            return <ListGroupItem onClick={() => {
                if (node.data.url) {
                    const url: string = "/registration/" + institutionCode + "/" + identifier + "/" + node.data.url;
                    navigate(url);
                }
            }}
                className={classNames(
                    "-clickable"
                )}
            >
                <div className="d-flex align-items-center">
                    {
                        node.data.icon
                    }
                    {
                        node.data.name
                    }
                    <div className="d-flex align-items-center form-summary-details ml-auto">
                        {
                            node.data.errors.length > 0 || node.data.ineligibleErrors.length > 0 ?
                                <div className="form-summary-details-info mr-2 d-inline-block text-info" onClick={event => { event.stopPropagation(); event.preventDefault(); onToggle(node.id) }}>
                                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_CIRCLE} />
                                </div> :
                                null
                        }
                        {
                            node.data.ineligibleErrors.length > 0 ?
                                <div className={classNames("badge badge-warning text-center mr-2")} style={{ width: "90px" }}
                                    onClick={event => { event.stopPropagation(); event.preventDefault(); onToggle(node.id) }}
                                >
                                    <span className="text-white">Ineligible</span>
                                </div> :
                                null
                        }
                        <div className={classNames("badge text-center",
                            { "badge-success": node.data.answeredQuestions == node.data.totalQuestions && node.data.ineligibleErrors.length == 0 },
                            { "badge-danger": node.data.errors.length > 0 },
                            { "badge-warning": node.data.errors.length == 0 && node.data.ineligibleErrors.length > 0 })} style={{ width: "90px" }}
                            onClick={event => { event.stopPropagation(); event.preventDefault(); onToggle(node.id) }}
                        >
                            <span className="text-white">{answeredSpan} / {totalSpan}</span>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        }

        const formErrorDecorator = (node: ITreeNode<IValidationErrorNode>, toggled: boolean, onToggle: (id: string) => void) => {

            return <ListGroupItem className={classNames(node.data.validationClassName)}>

                <strong>{node.data.name}</strong>
                <ul className="fa-ul">
                    {
                        node.data.validationErrors.map((failure, index) => {

                            return <li className="mb-2 mt-2" key={failure.fieldName + "-failure-" + index}>
                                <span className="fa-li"><FontAwesomeIcon icon={node.data.icon} /></span>
                                {failure.errorCode + ": " + failure.meta.errorMessageDetailed}
                            </li>
                        })
                    }
                </ul>
            </ListGroupItem>;
        }

        /*
        const registrationFormGroups: Set<string> = new Set(registrationFormSummaries.filter(rfs =>
            rfs.formGroup == 'Participant Details' ||
            (patient.status > 2 && rfs.formGroup == 'Registration') ||
            (patient.status > 3 && rfs.formGroup == 'Randomisation')).map(rfs => rfs.formGroup));
            */
        let rankOrderedRegistationFormSummaries: Dtos.RegistrationFormSummary[] =
            Object.assign(registrationFormSummaries);

        rankOrderedRegistationFormSummaries.sort(
            (a, b) => {
                if (a.rank > b.rank) {
                    return 1;
                }
                else if (a.rank < b.rank) {
                    return -1;
                }

                return 0;
            });

        const treeData: ITree = {

            children: rankOrderedRegistationFormSummaries.map(form => {
                let errorNodes: ITreeNode<IValidationErrorNode>[] = [];

                if (form.errors.length > 0) {
                    const errorNode: ITreeNode<IValidationErrorNode> = {
                        data: {
                            validationErrors: form.errors,
                            name: "Errors",
                            ruleset: undefined,
                            validationClassName: "list-group-item-danger",
                            icon: FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE
                        },
                        id: "form-" + form.formName + "-errors",
                        decorator: formErrorDecorator,
                        onToggle: undefined,
                        subTree: undefined
                    }

                    errorNodes.push(errorNode);
                }

                if (form.ineligibleErrors.length > 0) {
                    const ineligibleNode: ITreeNode<IValidationErrorNode> = {
                        data: {
                            name: "Ineligibility Errors",
                            ruleset: undefined,
                            validationClassName: "list-group-item-warning",
                            validationErrors: form.ineligibleErrors,
                            icon: FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE
                        },
                        id: "form-" + form.formName + "-ineligible",
                        decorator: formErrorDecorator,
                        onToggle: undefined,
                        subTree: undefined
                    }

                    errorNodes.push(ineligibleNode);
                }

                return {
                    id: "form-" + form.formName,
                    decorator: formDecorator,
                    data: {
                        name: form.formDisplayName,
                        icon: <FontAwesomeIcon icon={FontAwesomeIcons.Solid.FILE_ALT} fixedWidth className="mr-2" />,
                        totalQuestions: form.totalQuestions,
                        answeredQuestions: form.answeredQuestions,
                        errors: form.errors,
                        ineligibleErrors: form.ineligibleErrors,
                        url: CamelToKebabCase(form.formName)
                    },
                    subTree: errorNodes.length > 0 ?
                        {
                            children: errorNodes
                        } :
                        null
                } as ITreeNode<IRegistrationFormSummaryNode>
            })

        }

        return treeData;

    }

    renderPatientStatusModal() {

        const {
            patientStatusModalOpen,
            setPatientStatusModalViewState,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            patientStatusModalData,
            resetPatientStatusModalForm,
            identifier
        } = this.props

        if (patientStatusModalData && patientStatusModalData.patientId && patientStatusModalData.newStatus) {
            let bodyMessage: string = "";
            let isWaiverStatusModal: boolean | undefined = patientStatusModalData.patientStatusConfiguration &&
                patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction &&
                isWaiverStatusConfiguration(patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction) &&
                patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction == Dtos.PatientStatusConfigurationAction.WaiverPatient;

            let isNormalRegisterPatientModal: boolean | undefined = patientStatusModalData.patientStatusConfiguration &&
                patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction &&
                isRegisterPatientStatusConfiguration(patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction) &&
                patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction == Dtos.PatientStatusConfigurationAction.RegisterPatient;

            let showReason = true;
            if (isWaiverStatusModal) {
                console.log(patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction);
                bodyMessage = `Are you sure you want to waiver patient "${patientStatusModalData.patientSiteNumber}" to the "${getPatientStatusText(patientStatusModalData.newStatus)}" status ?`
            }
            else if (patientStatusModalData.newStatus == 2) {
                showReason = false;
                bodyMessage = `Are you sure you want to submit patient "${patientStatusModalData.patientSiteNumber}" for eligibiliy review?`
            }
            else if (isNormalRegisterPatientModal && patientStatusModalData.newStatus != 4 && patientStatusModalData.newStatus != 5) {
                showReason = false;
                bodyMessage = `Are you sure you want to register patient "${patientStatusModalData.patientSiteNumber}"?`
            }
            else if (patientStatusModalData.patientStatusConfiguration && patientStatusModalData.patientStatusConfiguration.patientStatusConfigurationAction == Dtos.PatientStatusConfigurationAction.ReassignToSite) {
                bodyMessage = `Are you sure you want to reassign the patient "${patientStatusModalData.patientSiteNumber}" back to the site?`
            }
            else {
                bodyMessage = `Are you sure you want to update patient "${identifier ? identifier : patientStatusModalData.patientSiteNumber}" to the new status of "${getPatientStatusText(patientStatusModalData.newStatus)}" ?`
            }

            return <Modal open={patientStatusModalOpen} disableCloseOnOverlayClick={true} onClose={() => { setPatientStatusModalViewState(false); }} size={ModalSize.Md}>
                <Form model={patientStatusModalFormName} onSubmit={(val) => this.handleSubmitPatientStatusForm(val)}>
                    <div className="modal-header">
                        <h5 className="modal-title">{setModalTitle(undefined, "Update Patient Status")}</h5>
                        <button type="button" className="close" aria-label="Close" onClick={() => { setPatientStatusModalViewState(false); this.clearPatientStatusModalForm(); }}>
                            <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                        </button>
                    </div>
                    <div className="modal-body">
                        {bodyMessage}
                        <div className="patient-status-form">
                            {showReason && (
                                <div className="mt-2 mb-2">
                                    <span className="mt-2 mb-2"><strong>{isNormalRegisterPatientModal ? "Notes:" : "Reason:"}</strong></span>
                                    <Control.textarea
                                        key={"form-reason"}
                                        component={TextAreaFormInput}
                                        model={`.reason`}
                                        controlProps={{
                                            className: undefined,
                                            label: "Reason",
                                            name: `${patientStatusModalFormName}.reason.name`,
                                            id: `${patientStatusModalFormName}.reason.id`
                                        }}
                                        mapProps={{
                                            valid: ({ fieldValue }) => fieldValue.valid,
                                            touched: ({ fieldValue }) => fieldValue.touched,
                                            focused: ({ fieldValue }) => fieldValue.focus,
                                            value: ({ fieldValue }) => fieldValue.value
                                        } as any}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-success">Confirm</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setPatientStatusModalViewState(false); this.clearPatientStatusModalForm(); }}>Close</button>
                    </div>
                </Form>
            </Modal>
        }
    }

    expandPatientSummaryTree() {

        const {
            registrationFormSummaries,
            updateSummaryTreeState,
            summaryTreeState,
            setRenderTree
        } = this.props

        let treeState: string[] = [];

        if (registrationFormSummaries) {

            const registrationFormGroups: Set<string> = new Set(registrationFormSummaries.map(rfs => rfs.formGroup));
            registrationFormGroups.forEach((item) => {
                //updateSummaryTreeState("group-" + item);
                treeState.push("group-" + item);
            });

            // call action to set render to true
            setRenderTree(true, treeState);

        }

        //return tree;
    }
}


const mapStateToProps = (state: IFigState, ownProps: PatientSummaryPageProps): IPatientSummaryPageProps => {

    let registrationFormSummaries: Dtos.RegistrationFormSummary[] | undefined = (state.registrationFormSummaries.data instanceof Array) ? state.registrationFormSummaries.data : undefined;

    //let expandedTree: string[] = expandedSummaryTree(registrationFormSummaries);

    let summaryTreeState: string[] | undefined = state.patientSummaryPage.summaryTreeState ? state.patientSummaryPage.summaryTreeState : undefined;

    let patient: Dtos.Patient | undefined = !(state.patients.data instanceof Array) ? state.patients.data : undefined;
    let patientStatusConfigurations: Dtos.PatientStatusConfiguration[] | undefined = patient && patient.patientStatusConfigurations ? patient.patientStatusConfigurations : undefined;

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        identifier: ownProps.match ? ownProps.match.params.identifier : undefined!,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined!,
        patient: patient!,
        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined!,
        loadingPatient: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,
        updatingPatient: state.patients.updateState && state.patients.updateState.status == RequestState.Pending,
        updatePatientSuccess: state.patients.updateState && state.patients.updateState.status == RequestState.Success,
        updatePatientFailure: state.patients.updateState && state.patients.updateState.status == RequestState.Failure,
        updatePatientFailureError: state.patients.updateState && state.patients.updateState.status == RequestState.Failure ? state.patients.updateState.errorMessage : "",

        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        summaryTreeState: summaryTreeState,
        registrationFormSummaries: registrationFormSummaries!,
        storedIdentifier: state.patientSummaryPage.patientIdentifier,
        loadingRegistrationFormSummaries: state.registrationFormSummaries.loadState && state.registrationFormSummaries.loadState.status == RequestState.Pending,
        loadRegistrationFormSummariesSuccess: state.registrationFormSummaries.loadState && state.registrationFormSummaries.loadState.status == RequestState.Success,
        loadRegistrationFormSummariesFailure: state.registrationFormSummaries.loadState && state.registrationFormSummaries.loadState.status == RequestState.Failure,

        patientStatusModalOpen: state.patientSummaryPage.patientStatusModalOpen,
        patientStatusModalData: state.patientSummaryPage.patientStatusModalData,
        patientStatusConfigurations: patientStatusConfigurations!,
        waiveredPatient: patientStatusConfigurations ? (patientStatusConfigurations.find(psc => psc.logItem == true && psc.patientStatusConfigurationAction == Dtos.PatientStatusConfigurationAction.WaiverPatient) != null) : false,

        user: state.user.data,

        renderTree: state.patientSummaryPage.renderTree,

        resendingPatientEmail: state.patients.resendEmailState && state.patients.resendEmailState.status == RequestState.Pending,
        resendingPatientEmailSuccess: state.patients.resendEmailState && state.patients.resendEmailState.status == RequestState.Success,
        resendingPatientEmailFailure: state.patients.resendEmailState && state.patients.resendEmailState.status == RequestState.Failure
    };
};

const mapDispatchToProps = (dispatch): IPatientSummaryPageActions => {
    return {

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadPatient: bindActionCreators(PatientActions.LoadPatientByIdentifier, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),
        clearPatientUpdate: bindActionCreators(PatientActions.Clear.bind(null, ["updateState"]), dispatch),
        setPatientStatusById: bindActionCreators(PatientActions.SetPatientStatusById, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        updateSummaryTreeState: bindActionCreators(PatientSummaryPageActions.updateSummaryTreeState, dispatch),
        setPatientIdentifier: bindActionCreators(PatientSummaryPageActions.setPatientIdentifier, dispatch),
        clearPatientSummaryPageState: bindActionCreators(PatientSummaryPageActions.clearPatientSummaryPage, dispatch),

        loadRegistrationFormSummaries: bindActionCreators(RegistrationFormSummaryActions.loadRegistrationFormSummariesByPatientIdentifier, dispatch),
        clearRegistrationFormSummaries: bindActionCreators(RegistrationFormSummaryActions.Clear, dispatch),

        setPatientStatusModalViewState: bindActionCreators(PatientSummaryPageActions.setPatientStatusModalViewState, dispatch),
        setPatientStatusModalPatientId: bindActionCreators(PatientSummaryPageActions.setPatientStatusModalPatientId, dispatch),
        setPatientStatusModalNewStatus: bindActionCreators(PatientSummaryPageActions.setPatientStatusModalNewStatus, dispatch),
        setPatientStatusModalPatientSiteNumber: bindActionCreators(PatientSummaryPageActions.setPatientStatusModalSiteNumber, dispatch),
        setPatientStatusModalStatusConfiguration: bindActionCreators(PatientSummaryPageActions.setPatientStatusModalStatusConfiguration, dispatch),

        resetPatientStatusModalForm: bindActionCreators(actions.reset, dispatch),
        setRenderTree: bindActionCreators(PatientSummaryPageActions.setRenderTree, dispatch),

        resendEmailForPatient: bindActionCreators(PatientActions.ResendEmailForPatientById, dispatch),
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(PatientSummaryPage);
