import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';

import {
    ACTION_DEMOGRAPHICDETAILFORMS_CLEAR,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_FAILURE,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_SUCCESS,

    ACTION_DEMOGRAPHICDETAILFORMS_SAVE,
    ACTION_DEMOGRAPHICDETAILFORMS_SAVE_FAILURE,
    ACTION_DEMOGRAPHICDETAILFORMS_SAVE_SUCCESS,

    ACTION_DEMOGRAPHICDETAILFORMS_CREATE,
    ACTION_DEMOGRAPHICDETAILFORMS_CREATE_SUCCESS,
    ACTION_DEMOGRAPHICDETAILFORMS_CREATE_FAILURE,

    IDemographicDetailFormLoadAction,
    IDemographicDetailFormClearAction,
    IDemographicDetailFormLoadByIdAction,
    IDemographicDetailFormLoadByPatientIdAction,
    IDemographicDetailFormLoadByPatientIdentifierAction,
    IDemographicDetailFormLoadFailureAction,
    IDemographicDetailFormLoadSuccessAction,

    IDemographicDetailFormSaveAction,
    IDemographicDetailFormSaveSuccessAction,
    IDemographicDetailFormSaveFailureAction,

    IDemographicDetailFormCreateAction,
    IDemographicDetailFormCreateSuccessAction,
    IDemographicDetailFormCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure


} from "../../actions/forms/demographicDetailForm";

import { demographicDetailFormApi } from "../../services/Forms/demographicDetailForm";


export const
    getDemographicDetailFormsEpic = (action$): Observable<IDemographicDetailFormLoadSuccessAction | IDemographicDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEMOGRAPHICDETAILFORMS_LOAD)
            .mergeMap((action: IDemographicDetailFormLoadAction) => {
                return demographicDetailFormApi
                    .getDemographicDetailForms()
                    .map(response =>
                        LoadSuccess(response.demographicDetailForms, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getDemographicDetailFormsByIdEpic = (action$): Observable<IDemographicDetailFormLoadSuccessAction | IDemographicDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID)
            .mergeMap((action: IDemographicDetailFormLoadByIdAction) => {
                return demographicDetailFormApi
                    .getDemographicDetailFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.demographicDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getDemographicDetailFormsByPatientIdEpic = (action$): Observable<IDemographicDetailFormLoadSuccessAction | IDemographicDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID)
            .mergeMap((action: IDemographicDetailFormLoadByPatientIdAction) => {
                return demographicDetailFormApi
                    .getDemographicDetailFormByPatientId(action.patientId)
                    .map(response =>
                        LoadSuccess(response.demographicDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getDemographicDetailFormsByPatientIdentifierEpic = (action$): Observable<IDemographicDetailFormLoadSuccessAction | IDemographicDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER)
            .mergeMap((action: IDemographicDetailFormLoadByPatientIdentifierAction) => {
                return demographicDetailFormApi
                    .getDemographicDetailFormByPatientIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.demographicDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setDemographicDetailFormsEpic = (action$): Observable<IDemographicDetailFormSaveSuccessAction | IDemographicDetailFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_DEMOGRAPHICDETAILFORMS_SAVE)
            .mergeMap((action: IDemographicDetailFormSaveAction) => {
                return demographicDetailFormApi
                    .setDemographicDetailForm(action.formData, action.institutionCode, action.patientIdentifier)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.demographicDetailForm, response.registrationFormState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createDemographicDetailFormsEpic = (action$): Observable<IDemographicDetailFormCreateSuccessAction | IDemographicDetailFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_DEMOGRAPHICDETAILFORMS_CREATE)
            .mergeMap((action: IDemographicDetailFormCreateAction) => {
                return demographicDetailFormApi
                    .createDemographicDetailForm()
                    .map(response =>
                        CreateSuccess(response.demographicDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }





