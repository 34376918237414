import * as Dtos from "../dtos/Fig.dtos";

export const
    loadTimepointsAll: ITimepointsLoadAllActionCreator = () => {
        return {
            type: ACTION_TIMEPOINTS_LOAD_ALL
        }
    },
    loadTimepointsAllWithDrugs: ITimepointsLoadAllWithDrugsActionCreator = () => {
        return {
            type: ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS
        }
    },
    loadSuccess: ITimepointLoadSuccessActionCreator = (data) => {
        return {
            type: ACTION_TIMEPOINTS_LOAD_SUCCESS,
            data
        }
    },
    loadFailure: ITimepointLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TIMEPOINTS_LOAD_FAILURE,
            responseStatus
        }
    },
    clear: ITimepointClearActionCreator = () => {
        return {
            type: ACTION_TIMEPOINTS_CLEAR
        }
    };

export const
    ACTION_TIMEPOINTS_LOAD_ALL = "ACTION_TIMEPOINTS_LOAD_ALL",
    ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS = "ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS",
    ACTION_TIMEPOINTS_LOAD_SUCCESS = "ACTION_TIMEPOINTS_LOAD_SUCCESS",
    ACTION_TIMEPOINTS_LOAD_FAILURE = "ACTION_TIMEPOINTS_LOAD_FAILURE",
    ACTION_TIMEPOINTS_CLEAR = "ACTION_TIMEPOINTS_CLEAR";

/* Actions */
export interface ITimepointsLoadAllAction {
    type: "ACTION_TIMEPOINTS_LOAD_ALL";
}

export interface ITimepointsLoadAllWithDrugsAction {
    type: "ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS";
}

export interface ITimepointClearAction {
    type: "ACTION_TIMEPOINTS_CLEAR";
}

export interface ITimepointLoadSuccessAction {
    type: "ACTION_TIMEPOINTS_LOAD_SUCCESS";
    data: Dtos.Timepoint[] | Dtos.TimepointWithDrugsList[];
}

export interface ITimepointLoadFailureAction {
    type: "ACTION_TIMEPOINTS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

/* Action Creators */
export interface ITimepointsLoadAllActionCreator {
    (): ITimepointsLoadAllAction;
}

export interface ITimepointsLoadAllWithDrugsActionCreator {
    (): ITimepointsLoadAllWithDrugsAction;
}

export interface ITimepointLoadSuccessActionCreator {
    (data: Dtos.Timepoint[] | Dtos.TimepointWithDrugsList[]): ITimepointLoadSuccessAction;
}

export interface ITimepointLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITimepointLoadFailureAction;
}

export interface ITimepointClearActionCreator {
    (): ITimepointClearAction;
}


/* Final Export of actions */

export type TimepointAction = ITimepointsLoadAllAction |
    ITimepointsLoadAllWithDrugsAction |
    ITimepointLoadSuccessAction |
    ITimepointLoadFailureAction |
    ITimepointClearAction;