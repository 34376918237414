import * as Dtos from "../../dtos/Fig.dtos";

export const
    LoadPatientReportedForms: IPatientReportedFormLoadActionCreator = () => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_LOAD
        }
    },
    LoadPatientReportedFormById: IPatientReportedFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_LOAD_BY_ID,
            id
        }
    },
    LoadPatientReportedFormByPatientId: IPatientReportedFormLoadByPatientIdActionCreator = (patientId) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_ID,
            patientId
        }
    },
    LoadPatientReportedFormByPatientIdentifier: IPatientReportedFormLoadByPatientIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: IPatientReportedFormLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus

        }
    },
    LoadFailure: IPatientReportedFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SavePatientReportedForm: IPatientReportedFormSaveActionCreator = (formData, institutionCode, patientIdentifier) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_SAVE,
            formData,
            institutionCode,
            patientIdentifier
        }
    },

    SaveSuccess: IPatientReportedFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },

    SaveFailure: IPatientReportedFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreatePatientReportedForm: IPatientReportedFormCreateActionCreator = () => {

        return {
            type: ACTION_PATIENTREPORTEDFORMS_CREATE
        }
    },

    CreateSuccess: IPatientReportedFormCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IPatientReportedFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IPatientReportedFormClearActionCreator = () => {
        return {
            type: ACTION_PATIENTREPORTEDFORMS_CLEAR
        }
    };


export const
    ACTION_PATIENTREPORTEDFORMS_LOAD = "ACTION_PATIENTREPORTEDFORMS_LOAD",
    ACTION_PATIENTREPORTEDFORMS_LOAD_BY_ID = "ACTION_PATIENTREPORTEDFORMS_LOAD_BY_ID",
    ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_ID = "ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_ID",
    ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_PATIENTREPORTEDFORMS_LOAD_SUCCESS = "ACTION_PATIENTREPORTEDFORMS_LOAD_SUCCESS",
    ACTION_PATIENTREPORTEDFORMS_LOAD_FAILURE = "ACTION_PATIENTREPORTEDFORMS_LOAD_FAILURE",

    ACTION_PATIENTREPORTEDFORMS_SAVE = "ACTION_PATIENTREPORTEDFORMS_SAVE",
    ACTION_PATIENTREPORTEDFORMS_SAVE_SUCCESS = "ACTION_PATIENTREPORTEDFORMS_SAVE_SUCCESS",
    ACTION_PATIENTREPORTEDFORMS_SAVE_FAILURE = "ACTION_PATIENTREPORTEDFORMS_SAVE_FAILURE",

    ACTION_PATIENTREPORTEDFORMS_CREATE = "ACTION_PATIENTREPORTEDFORMS_CREATE",
    ACTION_PATIENTREPORTEDFORMS_CREATE_SUCCESS = "ACTION_PATIENTREPORTEDFORMS_CREATE_SUCCESS",
    ACTION_PATIENTREPORTEDFORMS_CREATE_FAILURE = "ACTION_PATIENTREPORTEDFORMS_CREATE_FAILURE",

    ACTION_PATIENTREPORTEDFORMS_CLEAR = "ACTION_PATIENTREPORTEDFORMS_CLEAR";

export interface IPatientReportedFormLoadAction {
    type: "ACTION_PATIENTREPORTEDFORMS_LOAD";
}

export interface IPatientReportedFormLoadActionCreator {
    (): IPatientReportedFormLoadAction;
}

export interface IPatientReportedFormLoadByIdAction {
    type: "ACTION_PATIENTREPORTEDFORMS_LOAD_BY_ID";
    id: number;
}

export interface IPatientReportedFormLoadByIdActionCreator {
    (id: number): IPatientReportedFormLoadByIdAction;
}

export interface IPatientReportedFormLoadByPatientIdAction {
    type: "ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_ID";
    patientId: number;
}

export interface IPatientReportedFormLoadByPatientIdActionCreator {
    (patientId: number): IPatientReportedFormLoadByPatientIdAction;
}

export interface IPatientReportedFormLoadByPatientIdentifierAction {
    type: "ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface IPatientReportedFormLoadByPatientIdentifierActionCreator {
    (identifier: string): IPatientReportedFormLoadByPatientIdentifierAction;
}


export interface IPatientReportedFormLoadSuccessAction {
    type: "ACTION_PATIENTREPORTEDFORMS_LOAD_SUCCESS";
    formData: Dtos.PatientReportedForm | Dtos.PatientReportedForm[];
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;

}

export interface IPatientReportedFormLoadSuccessActionCreator {
    (
        formData: Dtos.PatientReportedForm | Dtos.PatientReportedForm[],
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IPatientReportedFormLoadSuccessAction;
}

export interface IPatientReportedFormLoadFailureAction {
    type: "ACTION_PATIENTREPORTEDFORMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientReportedFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientReportedFormLoadFailureAction;
}

export interface IPatientReportedFormSaveAction {
    type: "ACTION_PATIENTREPORTEDFORMS_SAVE";
    formData: Dtos.PatientReportedForm;
    institutionCode: string;
    patientIdentifier: string;

}

export interface IPatientReportedFormSaveActionCreator {
    (
        formData: Dtos.PatientReportedForm,
        institutionCode: string,
        patientIdentifier: string
    ): IPatientReportedFormSaveAction;
}

export interface IPatientReportedFormSaveSuccessAction {
    type: "ACTION_PATIENTREPORTEDFORMS_SAVE_SUCCESS";
    formData: Dtos.PatientReportedForm;
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientReportedFormSaveSuccessActionCreator {
    (
        formData: Dtos.PatientReportedForm,
        formState: Dtos.RegistrationFormState,
        responseStatus: Dtos.ResponseStatus
    ): IPatientReportedFormSaveSuccessAction;
}

export interface IPatientReportedFormSaveFailureAction {
    type: "ACTION_PATIENTREPORTEDFORMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPatientReportedFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientReportedFormSaveFailureAction;
}

export interface IPatientReportedFormCreateAction {
    type: "ACTION_PATIENTREPORTEDFORMS_CREATE"
}

export interface IPatientReportedFormCreateActionCreator {
    (): IPatientReportedFormCreateAction
}

export interface IPatientReportedFormCreateSuccessAction {
    type: "ACTION_PATIENTREPORTEDFORMS_CREATE_SUCCESS";
    formData: Dtos.PatientReportedForm;
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IPatientReportedFormCreateSuccessActionCreator {
    (
        formData: Dtos.PatientReportedForm,
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[]
    ): IPatientReportedFormCreateSuccessAction;
}

export interface IPatientReportedFormCreateFailureAction {
    type: "ACTION_PATIENTREPORTEDFORMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPatientReportedFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientReportedFormCreateFailureAction;
}

export interface IPatientReportedFormClearAction {
    type: "ACTION_PATIENTREPORTEDFORMS_CLEAR";
}

export interface IPatientReportedFormClearActionCreator {
    (): IPatientReportedFormClearAction;
}



export type PatientReportedFormAction = IPatientReportedFormLoadAction |
    IPatientReportedFormLoadByIdAction |
    IPatientReportedFormLoadByPatientIdAction |
    IPatientReportedFormLoadByPatientIdentifierAction |
    IPatientReportedFormLoadFailureAction |
    IPatientReportedFormLoadSuccessAction |
    IPatientReportedFormSaveAction |
    IPatientReportedFormSaveSuccessAction |
    IPatientReportedFormSaveFailureAction |
    IPatientReportedFormCreateAction |
    IPatientReportedFormCreateSuccessAction |
    IPatientReportedFormCreateFailureAction |
    IPatientReportedFormClearAction;
