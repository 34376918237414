import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Fig.dtos";

export const patientApi = {
    getPatients: (): Observable<Dtos.GetPatientsResponse> => {
        //Create the request.
        let request: Dtos.GetPatients = new Dtos.GetPatients();

        //Send request.
        const response: Promise<Dtos.GetPatientsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPatientsByInstitution: (institutionCode: string): Observable<Dtos.GetPatientsByInstitutionResponse> => {
        //Create the request.
        let request: Dtos.GetPatientsByInstitution = new Dtos.GetPatientsByInstitution();

        request.institutionCode = institutionCode;

        //Send request.
        const response: Promise<Dtos.GetPatientsByInstitutionResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPatientsByStatus: (status: Dtos.PatientStatus): Observable<Dtos.GetPatientsByStatusResponse> => {
        //Create the request.
        let request: Dtos.GetPatientsByStatus = new Dtos.GetPatientsByStatus();

        request.status = status;

        //Send request.
        const response: Promise<Dtos.GetPatientsByStatusResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPatientByIdentifier: (identifier: string): Observable<Dtos.GetPatientByIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetPatientByIdentifier = new Dtos.GetPatientByIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetPatientByIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPatientById: (id: number): Observable<Dtos.GetPatientByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPatientById = new Dtos.GetPatientById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.GetPatientByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setPatientStatusById: (id: number, status: Dtos.PatientStatus, statusConfiguration: Partial<Dtos.PatientStatusConfiguration>): Observable<Dtos.SetPatientStatusByIdResponse> => {
        //Create the request.
        let request: Dtos.SetPatientStatusById = new Dtos.SetPatientStatusById();

        request.id = id;
        request.status = status;
        request.patientStatusConfiguration = statusConfiguration as Dtos.PatientStatusConfiguration;

        //Send request.
        const response: Promise<Dtos.SetPatientStatusByIdResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    resendEmailForPatientById: (id: number): Observable<Dtos.ResendEmailForPatientByIdResponse> => {
        //Create the request.
        let request: Dtos.ResendEmailForPatientById = new Dtos.ResendEmailForPatientById();

        request.id = id;

        //Send request.
        const response: Promise<Dtos.ResendEmailForPatientByIdResponse> = client.post(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}