import * as Dtos from "../dtos/Fig.dtos";

export const
    LoadPatients: IPatientLoadActionCreator = () => {
        return {
            type: ACTION_PATIENTS_LOAD
        }
    },
    LoadPatientsByInstitution: IPatientLoadByInstitutionActionCreator = (institutionCode) => {
        return {
            type: ACTION_PATIENTS_LOAD_BY_INSTITUTION,
            institutionCode
        }
    },
    LoadPatientsByStatus: IPatientLoadByStatusActionCreator = (status) => {
        return {
            type: ACTION_PATIENTS_LOAD_BY_STATUS,
            status
        }
    },
    LoadPatientByIdentifier: IPatientLoadByIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_PATIENTS_LOAD_BY_IDENTIFIER,
            identifier
        }
    },
    LoadPatientById: IPatientLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PATIENTS_LOAD_BY_ID,
            id
        }
    },
    LoadSuccess: IPatientLoadSuccessActionCreator = (data) => {
        return {
            type: ACTION_PATIENTS_LOAD_SUCCESS,
            data
        }
    },
    LoadFailure: IPatientLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENTS_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: IPatientClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_PATIENTS_CLEAR,
            propertiesToClear
        }
    },
    SetPatientStatusById: IPatientSetStatusByIdActionCreator = (id, status, statusConfiguration) => {
        return {
            type: ACTION_PATIENT_SET_STATUS_BY_ID,
            id,
            status,
            statusConfiguration
        }
    },
    SetPatientStatusSuccess: IPatientSetStatusSuccessActionCreator = (statusUpdated) => {
        return {
            type: ACTION_PATIENT_SET_STATUS_SUCCESS,
            statusUpdated
        }
    },
    SetPatientStatusFailure: IPatientSetStatusFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PATIENT_SET_STATUS_FAILURE,
            responseStatus
        }
    },
    ResendEmailForPatientById: IResendEmailForPatientByIdActionCreator = (id) => {
        return {
            type: ACTION_PATIENTS_RESENDEMAIL_BY_ID,
            id
        }
    },
    ResendEmailForPatientSuccess: IResendEmailForPatientSuccessActionCreator = () => {
        return {
            type: ACTION_PATIENTS_RESENDEMAIL_SUCCESS
        }
    },
    ResendEmailForPatientFailure: IResendEmailForPatientFailureActionCreator = () => {
        return {
            type: ACTION_PATIENTS_RESENDEMAIL_FAILURE
        }
    }
    

export const
    ACTION_PATIENTS_LOAD = "ACTION_PATIENTS_LOAD",
    ACTION_PATIENTS_LOAD_BY_INSTITUTION = "ACTION_PATIENTS_LOAD_BY_INSTITUTION",
    ACTION_PATIENTS_LOAD_BY_STATUS = "ACTION_PATIENTS_LOAD_BY_STATUS",
    ACTION_PATIENTS_LOAD_BY_IDENTIFIER = "ACTION_PATIENTS_LOAD_BY_IDENTIFIER",
    ACTION_PATIENTS_LOAD_BY_ID = "ACTION_PATIENTS_LOAD_BY_ID",
    ACTION_PATIENTS_LOAD_SUCCESS = "ACTION_PATIENTS_LOAD_SUCCESS",
    ACTION_PATIENTS_LOAD_FAILURE = "ACTION_PATIENTS_LOAD_FAILURE",
    ACTION_PATIENTS_CLEAR = "ACTION_PATIENTS_CLEAR",

    ACTION_PATIENT_SET_STATUS_BY_ID = "ACTION_PATIENT_SET_STATUS_BY_ID",
    ACTION_PATIENT_SET_STATUS_SUCCESS = "ACTION_PATIENT_SET_STATUS_SUCCESS",
    ACTION_PATIENT_SET_STATUS_FAILURE = "ACTION_PATIENT_SET_STATUS_FAILURE",

    ACTION_PATIENTS_RESENDEMAIL_BY_ID = "ACTION_PATIENTS_RESENDEMAIL_BY_ID",
    ACTION_PATIENTS_RESENDEMAIL_SUCCESS = "ACTION_PATIENTS_RESENDEMAIL_SUCCESS",
    ACTION_PATIENTS_RESENDEMAIL_FAILURE = "ACTION_PATIENTS_RESENDEMAIL_FAILURE"

export interface IPatientLoadAction {
    type: "ACTION_PATIENTS_LOAD";
}

export interface IPatientLoadActionCreator {
    (): IPatientLoadAction;
}

export interface IPatientLoadByInstitutionAction {
    type: "ACTION_PATIENTS_LOAD_BY_INSTITUTION";
    institutionCode: string;
}

export interface IPatientLoadByInstitutionActionCreator {
    (institutionCode: string): IPatientLoadByInstitutionAction;
}

export interface IPatientLoadByStatusAction {
    type: "ACTION_PATIENTS_LOAD_BY_STATUS";
    status: Dtos.PatientStatus;
}

export interface IPatientLoadByStatusActionCreator {
    (status: Dtos.PatientStatus): IPatientLoadByStatusAction;
}

export interface IPatientLoadByIdentifierAction {
    type: "ACTION_PATIENTS_LOAD_BY_IDENTIFIER";
    identifier: string;
}

export interface IPatientLoadByIdentifierActionCreator {
    (identifier: string): IPatientLoadByIdentifierAction;
}

export interface IPatientLoadByIdAction{
    type: "ACTION_PATIENTS_LOAD_BY_ID";
    id: number;
}

export interface IPatientLoadByIdActionCreator {
    (id: number): IPatientLoadByIdAction;
}

export interface IPatientLoadSuccessAction {
    type: "ACTION_PATIENTS_LOAD_SUCCESS";
    data: Dtos.Patient | Dtos.Patient[];
}

export interface IPatientLoadSuccessActionCreator {
    (data: Dtos.Patient | Dtos.Patient[]): IPatientLoadSuccessAction;
}

export interface IPatientLoadFailureAction {
    type: "ACTION_PATIENTS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientLoadFailureAction;
}

export interface IPatientClearAction {
    type: "ACTION_PATIENTS_CLEAR";
    propertiesToClear?: string[];
}

export interface IPatientClearActionCreator {
    (propertiesToClear?: string[]): IPatientClearAction;
}

export interface IPatientSetStatusByIdAction {
    type: "ACTION_PATIENT_SET_STATUS_BY_ID",
    id: number,
    status: Dtos.PatientStatus,
    statusConfiguration: Partial<Dtos.PatientStatusConfiguration>
}

export interface IPatientSetStatusByIdActionCreator {
    (id: number, status: Dtos.PatientStatus, statusConfiguration: Partial<Dtos.PatientStatusConfiguration>): IPatientSetStatusByIdAction;
}

export interface IPatientSetStatusSuccessAction {
    type: "ACTION_PATIENT_SET_STATUS_SUCCESS";
    statusUpdated: boolean;
}

export interface IPatientSetStatusSuccessActionCreator {
    (statusUpdated: boolean): IPatientSetStatusSuccessAction;
}

export interface IPatientSetStatusFailureAction {
    type: "ACTION_PATIENT_SET_STATUS_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPatientSetStatusFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPatientSetStatusFailureAction;
}


export interface IResendEmailForPatientByIdAction {
    type: "ACTION_PATIENTS_RESENDEMAIL_BY_ID",
    id: number
}

export interface IResendEmailForPatientByIdActionCreator {
    (id: number): IResendEmailForPatientByIdAction;
}

export interface IResendEmailForPatientSuccessAction {
    type: "ACTION_PATIENTS_RESENDEMAIL_SUCCESS";
}

export interface IResendEmailForPatientSuccessActionCreator {
    (): IResendEmailForPatientSuccessAction;
}

export interface IResendEmailForPatientFailureAction {
    type: "ACTION_PATIENTS_RESENDEMAIL_FAILURE";
}

export interface IResendEmailForPatientFailureActionCreator {
    (): IResendEmailForPatientFailureAction;
}

export type PatientAction = IPatientLoadAction |
    IPatientLoadByInstitutionAction |
    IPatientLoadByIdentifierAction |
    IPatientLoadByIdAction |
    IPatientLoadByStatusAction |
    IPatientLoadFailureAction |
    IPatientLoadSuccessAction |
    IPatientClearAction | 
    IPatientSetStatusByIdAction | 
    IPatientSetStatusSuccessAction | 
    IPatientSetStatusFailureAction |
    IResendEmailForPatientByIdAction |
    IResendEmailForPatientSuccessAction |
    IResendEmailForPatientFailureAction ;
