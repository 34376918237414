import * as React from 'react';

import classNames from "classnames";
import './RegistrationPage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";

import ReactTable, { Column, RowInfo } from 'react-table';



import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';
import * as RegistrationPageActions from '../../../actions/pages/registration/registrationPage';
import * as InstitutionActions from '../../../actions/institution';

import * as Dtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { Link } from "../../../components/routing/index";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { getPatientAge, getPatientStatusText } from "../../../helpers/patient";
import { IPatientStatusModalData } from "../../../reducers/interfaces/IPatientStatusModalData";
import { setModalTitle } from "../../../helpers/modalHelper";
import { Modal, NotificationAlert } from "../../../components/common/index";

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { IInstitutionCodeParams } from '../../../interfaces/params/routeParams';
import { ModalSize } from '../../../enumerations/ModalSize';
import { Form, actions, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../../components/form';
import { IPatientStatusModalFormState } from '../../../reducers/reactReduxForms/patientStatusModalForm';

interface IRegistrationPageProps {

    location: Location;
    match: match<IInstitutionCodeParams>;
    history: History;
    institutionCode: string;

    // From state
    patients: Dtos.Patient[];
    loadingPatients: boolean;
    loadPatientsSuccess: boolean;
    loadPatientsFailure: boolean;

    updatingPatient: boolean;
    updatePatientSuccess: boolean;
    updatePatientFailure: boolean;

    institutions: Dtos.Institution[];
    loadingInstitutions: boolean;
    loadInstitutionsSuccess: boolean;
    loadInstitutionsFailure: boolean;

    expandIneligiblePatients: boolean;
    expandPreRegisteredPatients: boolean;
    expandRegisteredPatients: boolean;
    expandSubmittedPatients: boolean;
    expandEligablePatients: boolean;
    expandRandomisedPatients: boolean;

    // Derived from state
    ineligiblePatients: Dtos.Patient[];
    preRegisteredPatients: Dtos.Patient[];
    submittedPatients: Dtos.Patient[];
    registeredPatients: Dtos.Patient[];
    eligablePatients: Dtos.Patient[];
    randomisedPatients: Dtos.Patient[];

    countries: string[];
    country: string;
    lookups: Dtos.Lookup[];

    patientStatusModalOpen: boolean;
    patientStatusModalData: IPatientStatusModalData;

    user: Dtos.User
}

interface IRegistrationPageActions {
    clearPatients: PatientActions.IPatientClearActionCreator;
    loadPatients: PatientActions.IPatientLoadActionCreator;
    setPatientStatusById: PatientActions.IPatientSetStatusByIdActionCreator;

    toggleIneligible: RegistrationPageActions.IRegistrationPageToggleIneligibleActionCreator;
    togglePreRegistered: RegistrationPageActions.IRegistrationPageTogglePreRegisteredActionCreator;
    toggleRegistered: RegistrationPageActions.IRegistrationPageToggleRegisteredActionCreator;
    toggleSubmitted: RegistrationPageActions.IRegistrationPageToggleSubmittedActionCreator;
    toggleEligable: RegistrationPageActions.IRegistrationPageToggleEligableActionCreator;
    toggleRandomised: RegistrationPageActions.IRegistrationPageToggleRandomisedActionCreator;
    updateCountry: RegistrationPageActions.IRegistrationPageUpdateCountryActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    loadInstitutions: InstitutionActions.IInstitutionLoadActionCreator;
    clearInstitutions: InstitutionActions.IInstitutionClearActionCreator;

    setPatientStatusModalViewState: RegistrationPageActions.IRegistrationPageSetPatientStatusModalViewStateActionCreator;
    setPatientStatusModalPatientId: RegistrationPageActions.IRegistrationPageSetPatientStatusModalPatientIdActionCreator;
    setPatientStatusModalNewStatus: RegistrationPageActions.IRegistrationPageSetPatientStatusModalNewStatusActionCreator;
    setPatientStatusModalPatientSiteNumber: RegistrationPageActions.IRegistrationPageSetPatientStatusModalPatientSiteNumberActionCreator;
    setPatientStatusModalStatusConfiguration: RegistrationPageActions.IRegistrationPageSetPatientStatusModalStatusConfigurationActionCreator;

    resetPatientStatusModalForm: typeof actions.reset
}

type RegistrationPageProps = IRegistrationPageProps & IRegistrationPageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

const siteCodeColumnWidth: number = 100;
const patientNumberColumnWidth: number = 150;
const patientTemporaryNumberColumnWidth: number = 200;
const ageOfPatientColumnWidth: number = 150;
const patientInitialsColumnWidth: number = 100;

const patientStatusModalFormName = "reduxForms.patientStatusModalForm"

class RegistrationPage extends React.PureComponent<RegistrationPageProps, any> {

    constructor(props: RegistrationPageProps) {
        super(props);

        this.clearPatients = this.clearPatients.bind(this);
        this.clearInstitutions = this.clearInstitutions.bind(this);
        this.clearPatientStatusModal = this.clearPatientStatusModal.bind(this);
    }

    componentDidMount() {
        const {
            loadPatients,
            loadInstitutions,
            institutions,
            navigate,
            navigateReplace
        } = this.props;

        this.clearPatientStatusModal();

        loadPatients();
        loadInstitutions();

        if (institutions.length == 1) {
            navigateReplace("/registration/" + institutions[0].institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: RegistrationPageProps) {
    }

    componentDidUpdate(prevProps: RegistrationPageProps, prevState) {

        const {
            country,
            institutionCode,
            institutions,
            navigateReplace,
            patientStatusModalOpen,
            updatingPatient,
            updatePatientSuccess,
            loadPatients,
            loadInstitutions,
            patientStatusModalData
        } = this.props

        if (institutions.length == 1 &&
            !prevProps.institutionCode) {
            navigateReplace("/registration/" + institutions[0].institutionCode);
        }

        if (country != prevProps.country && institutionCode) {
            if (!this.getInstitution(institutionCode)) {
                navigateReplace("/registration");
            }
        }

        if (!updatingPatient && prevProps.updatingPatient &&
            updatePatientSuccess) {

            // reload patients
            loadPatients();

            Alert.success(<NotificationAlert
                alertContent={this.renderPatientStatusUpdateSuccess(patientStatusModalData.patientSiteNumber, patientStatusModalData.newStatus)}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

        if (!updatingPatient && !patientStatusModalOpen && prevProps.patientStatusModalOpen) {
            this.clearPatientStatusModal();
        }
    }

    componentWillUpdate(nextProps: RegistrationPageProps) {
    }

    componentWillUnmount() {

        this.clearPatients();
        this.clearInstitutions();
        this.clearPatientStatusModal();
        this.clearPatientStatusModalForm();
    }

    clearPatients() {
        this.props.clearPatients();
    }

    clearInstitutions() {
        this.props.clearInstitutions();
    }

    clearPatientStatusModalForm() {
        this.props.resetPatientStatusModalForm(patientStatusModalFormName)
    }

    clearPatientStatusModal() {
        this.props.setPatientStatusModalViewState(false);
        this.props.setPatientStatusModalPatientId(undefined!);
        this.props.setPatientStatusModalNewStatus(undefined!);
    }

    showPatients(): boolean {
        const {
            loadingPatients,
            updatingPatient,
            loadPatientsSuccess,
            updatePatientSuccess
        } = this.props

        if (updatePatientSuccess || (!loadingPatients && loadPatientsSuccess)) {
            return true;
        }

        return false;
    }

    render() {

        const {
            loadingPatients,
            loadingInstitutions,
            updatingPatient,
            updatePatientSuccess
        } = this.props;

        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={(loadingPatients && !updatePatientSuccess) || loadingInstitutions}
            saving={updatingPatient || (loadingPatients && updatePatientSuccess)}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            patients,
            ineligiblePatients,
            preRegisteredPatients,
            submittedPatients,
            registeredPatients,
            loadingPatients,
            loadPatientsSuccess,
            loadPatientsFailure,
            clearPatients,
            toggleIneligible,
            togglePreRegistered,
            toggleRegistered,
            toggleSubmitted,
            expandIneligiblePatients,
            expandPreRegisteredPatients,
            expandRegisteredPatients,
            expandEligablePatients,
            expandRandomisedPatients,
            institutions,
            countries,
            institutionCode,
            country,
            updateCountry,
            navigate,
            user
        } = this.props;

        const showCountry: boolean = countries && countries.length > 1

        return <div>
            <h1>Registration</h1>
            {
                !this.showPatients() ?
                    "Loading Patients" :
                    null
            }
            <div className="row">
                { // Country Dropdown
                    showCountry && this.showPatients() && AuthenticationHelper.isTrogOrSuperUser(user) ?
                        <div className={"col-6 mb-2"}>
                            <ReactSelectClass
                                placeholder={"Select a country..."}
                                value={country}
                                onChange={option => {
                                    if (option && option.value) {
                                        updateCountry(option.value);
                                    }
                                    else {
                                        updateCountry(undefined!);
                                    }
                                }}
                                options={
                                    countries.map((country) => {
                                        return {
                                            value: country,
                                            label: country
                                        };
                                    })
                                }
                            />
                        </div> :
                        null
                }
                { // Institution Dropdown
                    institutions && institutions.length >= 1 && this.showPatients() ?
                        <div className={"col-6 mb-2"}>
                            <ReactSelectClass
                                placeholder={"Select an institution..."}
                                value={institutionCode}
                                onChange={option => {
                                    if (option && option.value) {
                                        navigate("/registration/" + option.value);
                                    }
                                    else {
                                        navigate("/registration");
                                    }
                                }}
                                options={
                                    institutions.map((institution) => {
                                        return {
                                            value: institution.institutionCode,
                                            label: institution.institutionName
                                        };

                                    })
                                }
                            />
                        </div> :
                        null
                }
                {
                    institutionCode ?
                        <div className="col-3">
                            <Link className={"btn btn-primary"} url={"/registration/" + institutionCode + "/pre-registration-form"}>Create Patient</Link>
                        </div> :
                        null
                }
            </div>
            {
                this.showPatients() ?
                    <div className="patient-tables mt-2">
                        {
                            //AuthenticationHelper.isMedicalReviewer(user) ? null :
                            this.createPreRegisteredTable()
                        }
                        {
                            this.createSubmittedTable()
                        }
                        {
                            this.createRegisteredTable()
                        }
                        {
                            //AuthenticationHelper.isMedicalReviewer(user) ? null :
                            this.createIneligibleTable()
                        }
                    </div> :
                    null
            }
            {
                this.renderPatientStatusModal()
            }
        </div>
    }

    createTableHeader(icon, toggle, expanded, count, title) {
        return <div className={tableHeaderClass} style={tableStyleClass} onClick={toggle}>
            <div>
                <FontAwesomeIcon icon={!expanded ? FontAwesomeIcons.Solid.ANGLE_DOWN : FontAwesomeIcons.Solid.ANGLE_UP} fixedWidth transform="grow-16" className="mr-3" />
                {icon}
            </div>
            <span>{title}</span>
            <div className="ml-auto">
                <span className="badge badge-secondary text-white">Total Patients: {count}</span>
            </div>
        </div>;
    }

    createIneligibleTable() {

        const {
            ineligiblePatients,
            expandIneligiblePatients,
            toggleIneligible,
            user
        } = this.props

        if (!ineligiblePatients || ineligiblePatients.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no ineligible patients to view.</span>
            </div>
        }

        const showPagination = ineligiblePatients.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            {
                this.createTableHeader(
                    <span className="fa-layers fa-fw mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.USER} fixedWidth />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.TIMES} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
                    </span>,
                    toggleIneligible,
                    expandIneligiblePatients,
                    ineligiblePatients.length,
                    "Ineligible Patients"
                )}
            {
                expandIneligiblePatients ?

                    <ReactTable
                        data={ineligiblePatients}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : ineligiblePatients.length}
                        pageSize={showPagination ? undefined : ineligiblePatients.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no ineligible patients to display"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    const url: string = "/registration/" + rowInfo.row.institutionCode + '/' + rowInfo.row._original.temporaryNumber + "/patient-summary";
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={[
                            {

                                Header: 'Patient Number',
                                accessor: 'siteNumber', // String-based value accessors!
                                className: "d-flex align-items-center justify-content-center",
                                width: patientNumberColumnWidth
                            },
                            {
                                show: false, //show: AuthenticationHelper.isTrogOrSuperUser(user),
                                Header: 'Patient Temporary Number',
                                accessor: "temporaryNumber",
                                className: "d-flex align-items-center justify-content-center",
                                width: patientTemporaryNumberColumnWidth
                            },
                            {
                                Header: 'Initials',
                                accessor: 'initials', // String-based value accessors!
                                className: "d-flex align-items-center justify-content-center",
                                width: patientInitialsColumnWidth
                            },
                            {
                                show: false,
                                Header: 'Site Code',
                                accessor: 'institutionCode', // String-based value accessors!
                                className: "d-flex align-items-center justify-content-center",
                                Cell: (props) => <span className="badge badge-secondary text-white">{props.value}</span>,
                                width: siteCodeColumnWidth
                            },
                            {
                                id: "institutionName",
                                Header: "Institution",
                                accessor: (d: Dtos.Patient) => <span>{this.getInstitutionName(d.institutionCode)}</span>, // String-based value accessors!
                                className: "d-flex align-items-center justify-content-center",
                            },
                            {
                                id: "registrationGroupName",
                                accessor: "registrationGroupName", // String-based value accessors!
                                Header: "Registration Group",
                                className: "d-flex align-items-center justify-content-center",
                            },
                            {
                                show: AuthenticationHelper.isTrogOrSuperUser(user),
                                id: "PatientConfiguration",
                                Header: "Patient Configuration",
                                accessor: (patient: Dtos.Patient) =>
                                    <div>
                                        <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); this.createPatientStatusModal(patient.previousStatus, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.RestorePatient) }} className="btn btn-sm btn-primary mr-2">Restore</div>
                                        <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); this.createPatientStatusModal(Dtos.PatientStatus.Hidden, patient.id, patient.siteNumber, Dtos.PatientStatusConfigurationAction.HidePatient) }} className="btn btn-sm btn-primary mr-2">Hide</div>
                                    </div>,
                                className: "d-flex align-items-center justify-content-center",
                                Cell: (props) => <span>{props.value}</span>,
                            }
                        ]}
                    /> :
                    null
            }
        </div>

    }

    createSubmittedTable() {

        const {
            submittedPatients,
            toggleSubmitted,
            expandSubmittedPatients,
            user
        } = this.props

        if (!submittedPatients || submittedPatients.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no patients awaiting review.</span>
            </div>
        }

        const showPagination = submittedPatients.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            {
                this.createTableHeader(
                    <span className="fa-layers fa-fw mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.USER} fixedWidth />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CLOCK} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
                    </span>,
                    toggleSubmitted,
                    expandSubmittedPatients,
                    submittedPatients.length,
                    "Patients Awaiting Review"
                )}
            {
                expandSubmittedPatients ?
                    <ReactTable
                        data={submittedPatients}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : submittedPatients.length}
                        pageSize={showPagination ? undefined : submittedPatients.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no registered patients to display"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    const url: string = "/registration/" + rowInfo.row.institutionCode + '/' + rowInfo.row.temporaryNumber + "/patient-summary";
                                    console.log(url, rowInfo, rowInfo.row.temporaryNumber);
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {

                                    Header: 'Patient Number',
                                    accessor: 'siteNumber', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientNumberColumnWidth
                                },
                                {
                                    show: false, //show: AuthenticationHelper.isTrogOrSuperUser(user),
                                    Header: 'Patient Temporary Number',
                                    accessor: "temporaryNumber",
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientTemporaryNumberColumnWidth
                                },
                                {
                                    Header: 'Initials',
                                    accessor: 'initials', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientInitialsColumnWidth
                                },
                                {
                                    show: false,
                                    Header: 'Site Code',
                                    accessor: 'institutionCode', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span className="badge badge-secondary text-white">{props.value}</span>,
                                    width: siteCodeColumnWidth
                                },
                                {
                                    id: "institutionName",
                                    Header: "Institution",
                                    accessor: (d: Dtos.Patient) => <span>{this.getInstitutionName(d.institutionCode)}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "registrationGroupName",
                                    accessor: "registrationGroupName", // String-based value accessors!
                                    Header: "Registration Group",
                                    className: "d-flex align-items-center justify-content-center",
                                }
                            ]}
                    /> :
                    null
            }
        </div>
    }

    createRegisteredTable() {

        const {
            registeredPatients,
            preRegisteredPatients,
            toggleRegistered,
            expandRegisteredPatients
        } = this.props

        if (!registeredPatients || registeredPatients.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no registered patients to view.</span>
            </div>
        }

        const showPagination = registeredPatients.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            {
                this.createTableHeader(
                    <span className="fa-layers fa-fw mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.USER} fixedWidth />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
                    </span>,
                    toggleRegistered,
                    expandRegisteredPatients,
                    registeredPatients.length,
                    "Registered Patients"
                )}
            {
                expandRegisteredPatients ?
                    <ReactTable
                        data={registeredPatients}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : registeredPatients.length}
                        pageSize={showPagination ? undefined : registeredPatients.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no registered patients to display"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    const url: string = "/registration/" + rowInfo.row.institutionCode + '/' + rowInfo.row.registrationNumber + "/patient-summary";
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Patient Number',
                                    accessor: 'registrationNumber', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientNumberColumnWidth
                                },
                                {
                                    Header: 'Initials',
                                    accessor: 'initials', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientInitialsColumnWidth
                                },
                                {
                                    show: false,
                                    Header: 'Site Code',
                                    accessor: 'institutionCode', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span className="badge badge-secondary text-white">{props.value}</span>,
                                    width: siteCodeColumnWidth
                                },
                                {
                                    id: "institutionName",
                                    Header: "Institution",
                                    accessor: (d: Dtos.Patient) => <span>{this.getInstitutionName(d.institutionCode)}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "registrationGroupName",
                                    accessor: "registrationGroupName", // String-based value accessors!
                                    Header: "Registration Group",
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: 'Registration Date',
                                    accessor: 'registrationDate', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                },
                                /*{
                                    Header: 'Age of Patient',
                                    accessor: 'age', // String-based value accessors!                                   
                                    className: "d-flex align-items-center justify-content-center",
                                    width: ageOfPatientColumnWidth,
                                    Cell: (props) => {
                                        let patientAge: any = 'N/A';

                                        if (props.value) {
                                            patientAge = props.value;
                                        }

                                        return <span>{patientAge}</span>
                                    }
                                }*/
                            ]
                        }
                    /> :
                    null
            }
        </div>
    }

    createEligableTable() {

        const {
            eligablePatients,
            toggleEligable,
            expandEligablePatients,
            user
        } = this.props

        if (!eligablePatients || eligablePatients.length == 0) {
            return <div className="alert alert-info">
                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                <span>There are currently no eligible patients to view.</span>
            </div>
        }

        const showPagination = eligablePatients.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            {
                this.createTableHeader(
                    <span className="fa-layers fa-fw mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.USER} fixedWidth />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
                    </span>,
                    toggleEligable,
                    expandEligablePatients,
                    eligablePatients.length,
                    "Eligible Patients"
                )}
            {
                expandEligablePatients ?
                    <ReactTable
                        data={eligablePatients}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : eligablePatients.length}
                        pageSize={showPagination ? undefined : eligablePatients.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no eligible patients to display"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    const url: string = "/registration/" + rowInfo.row.institutionCode + '/' + rowInfo.row.temporaryNumber + "/patient-summary";
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Patient Number',
                                    accessor: 'registrationNumber', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientNumberColumnWidth
                                },
                                {
                                    show: false, //AuthenticationHelper.isTrogOrSuperUser(user),
                                    Header: 'Patient Temporary Number',
                                    accessor: "temporaryNumber",
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientTemporaryNumberColumnWidth
                                },
                                {
                                    Header: 'Initials',
                                    accessor: 'initials', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientInitialsColumnWidth
                                },
                                {
                                    show: false,
                                    Header: 'Site Code',
                                    accessor: 'institutionCode', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span className="badge badge-secondary text-white">{props.value}</span>,
                                    width: siteCodeColumnWidth
                                },
                                {
                                    id: "institutionName",
                                    Header: "Institution",
                                    accessor: (d: Dtos.Patient) => <span>{this.getInstitutionName(d.institutionCode)}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: 'Registration Date',
                                    accessor: 'registrationDate', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                },
                                {
                                    Header: 'Age of Patient',
                                    accessor: 'age', // String-based value accessors!                                   
                                    className: "d-flex align-items-center justify-content-center",
                                    width: ageOfPatientColumnWidth,
                                    Cell: (props) => {
                                        let patientAge: any = 'N/A';

                                        if (props.value) {
                                            patientAge = props.value;
                                        }

                                        return <span>{patientAge}</span>
                                    }
                                }
                            ]
                        }
                    /> :
                    null
            }
        </div>
    }

    createRandomisedTable() {

        const {
            randomisedPatients,
            toggleRandomised,
            expandRandomisedPatients
        } = this.props

        if (!randomisedPatients || randomisedPatients.length == 0) {
            return <div className="alert alert-info">
                <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                <span>There are currently no randomised patients to view.</span>
            </div>
        }

        const showPagination = randomisedPatients.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            {
                this.createTableHeader(
                    <span className="fa-layers fa-fw mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.USER} fixedWidth />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
                    </span>,
                    toggleRandomised,
                    expandRandomisedPatients,
                    randomisedPatients.length,
                    "Randomised Patients"
                )}
            {
                expandRandomisedPatients ?
                    <ReactTable
                        data={randomisedPatients}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : randomisedPatients.length}
                        pageSize={showPagination ? undefined : randomisedPatients.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no randomised patients to display"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    const url: string = "/registration/" + rowInfo.row.institutionCode + '/' + rowInfo.row.registrationNumber + "/patient-summary";
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Patient Number',
                                    accessor: 'registrationNumber', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientNumberColumnWidth
                                },
                                {
                                    Header: 'Initials',
                                    accessor: 'initials', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientInitialsColumnWidth
                                },
                                {
                                    show: false,
                                    Header: 'Site Code',
                                    accessor: 'institutionCode', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span className="badge badge-secondary text-white">{props.value}</span>,
                                    width: siteCodeColumnWidth
                                },
                                {
                                    id: "institutionName",
                                    Header: "Institution",
                                    accessor: (d: Dtos.Patient) => <span>{this.getInstitutionName(d.institutionCode)}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: 'Registration Date',
                                    accessor: 'registrationDate', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <div><span data-tip={convertToApproximateDateString(props.value)}>{convertToShortDateString(props.value)}</span><ReactTooltip /></div>
                                },
                                {
                                    Header: 'Age of Patient',
                                    accessor: 'age', // String-based value accessors!                                   
                                    className: "d-flex align-items-center justify-content-center",
                                    width: ageOfPatientColumnWidth,
                                    Cell: (props) => {
                                        let patientAge: any = 'N/A';

                                        if (props.value) {
                                            patientAge = props.value;
                                        }

                                        return <span>{patientAge}</span>
                                    }
                                }
                            ]
                        }
                    /> :
                    null
            }
        </div>
    }

    createPreRegisteredTable() {

        const {
            preRegisteredPatients,
            expandPreRegisteredPatients,
            togglePreRegistered,
            institutionCode,
            user
        } = this.props

        if (!preRegisteredPatients || preRegisteredPatients.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no pre-registered patients to view.</span>
            </div>
        }

        const showPagination = preRegisteredPatients.length > DEFAULT_PAGE_SIZE;

        return <div className="table-wrapper mb-3">
            {
                this.createTableHeader(
                    <span className="fa-layers fa-fw mr-2">
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Solid.USER} fixedWidth />
                        <FontAwesomeIcon icon={FontAwesomeIcons.Regular.QUESTION} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
                    </span>,
                    togglePreRegistered,
                    expandPreRegisteredPatients,
                    preRegisteredPatients.length,
                    "Pre-registered Patients"
                )}
            {
                expandPreRegisteredPatients ?

                    <ReactTable
                        key={"preregistration-" + institutionCode}
                        data={preRegisteredPatients}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : preRegisteredPatients.length}
                        pageSize={showPagination ? undefined : preRegisteredPatients.length}
                        showPagination={showPagination}
                        className="-striped -highlight -clickable mb-3"
                        noDataText="There are currently no pre-registered patients to display"
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {


                                    const url: string = "/registration/" + rowInfo.row.institutionCode + '/' + rowInfo.row.temporaryNumber + "/patient-summary";
                                    console.log(url, rowInfo, preRegisteredPatients);
                                    this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {

                                    Header: 'Patient Number',
                                    accessor: 'siteNumber', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientNumberColumnWidth
                                },
                                {
                                    show: false, //AuthenticationHelper.isTrogOrSuperUser(user),
                                    Header: 'Patient Temporary Number',
                                    accessor: "temporaryNumber",
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientTemporaryNumberColumnWidth
                                },
                                {
                                    Header: 'Initials',
                                    accessor: 'initials', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientInitialsColumnWidth
                                },
                                {
                                    show: false,
                                    Header: 'Site Code',
                                    accessor: 'institutionCode', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span className="badge badge-secondary text-white">{props.value}</span>,
                                    width: siteCodeColumnWidth
                                },
                                {
                                    id: "institutionName",
                                    Header: "Institution",
                                    accessor: (d: Dtos.Patient) => <span>{this.getInstitutionName(d.institutionCode)}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "registrationGroupName",
                                    accessor: "registrationGroupName", // String-based value accessors!
                                    Header: "Registration Group",
                                    className: "d-flex align-items-center justify-content-center",
                                }/*,
                                {
                                    Header: 'Age of Patient',
                                    accessor: 'age', // String-based value accessors!                                   
                                    className: "d-flex align-items-center justify-content-center",
                                    width: ageOfPatientColumnWidth,
                                    Cell: (props) => {
                                        let patientAge: any = 'N/A';

                                        if (props.value) {
                                            patientAge = props.value;
                                        }

                                        return <span>{patientAge}</span>
                                    }
                                }
                                {
                                    Header: 'Age of Patient',
                                    accessor: 'dateOfBirth', // String-based value accessors!
                                    Cell: (props) => {
                                        let patientAge: any = getPatientAge(props.value);

                                        if (!patientAge) {
                                            patientAge = 'N/A';
                                        }

                                        return <span>{patientAge}</span>
                                    },
                                    className: "d-flex align-items-center justify-content-center",
                                    width: ageOfPatientColumnWidth
                                }*/
                            ]}
                    /> :
                    null
            }
        </div>
    }

    createSubMenuItems(): INavItem[] {



        const {
            institutionCode,
            loadingInstitutions,
            loadingPatients,
            loadPatientsSuccess,
            loadInstitutionsSuccess
        } = this.props;

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionCode ? this.getInstitutionName(institutionCode)! : "All Institutions"
            }
        ]
    }

    getInstitutionName(institutionCode: string): string | undefined {

        const institution = this.getInstitution(institutionCode);

        if (institution) {
            return institution.institutionName;
        }

        return undefined;
    }

    getInstitution(institutionCode: string): Dtos.Institution | undefined {
        const { institutions } = this.props;

        if (institutions && institutionCode) {
            return institutions.find(i => i.institutionCode == institutionCode);
        }

        return undefined;
    }

    setPatientStatus(newStatus: Dtos.PatientStatus, patientId: number, statusConfiguration: Partial<Dtos.PatientStatusConfiguration>) {
        const {
            setPatientStatusById,
        } = this.props

        if (patientId) {
            setPatientStatusById(patientId, newStatus, statusConfiguration);
        }

    }

    createPatientStatusModal(
        newStatus: Dtos.PatientStatus,
        patientId: number,
        patientSiteNumber: number,
        patientStatusConfigurationAction: Dtos.PatientStatusConfigurationAction) {
        const {
            setPatientStatusById,
            setPatientStatusModalViewState,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            setPatientStatusModalPatientSiteNumber,
            setPatientStatusModalStatusConfiguration
        } = this.props

        if (patientId && patientSiteNumber) {

            setPatientStatusModalNewStatus(newStatus);
            setPatientStatusModalPatientId(patientId);
            setPatientStatusModalPatientSiteNumber(patientSiteNumber);
            setPatientStatusModalViewState(true);

            let psc: Partial<Dtos.PatientStatusConfiguration> = {
                patientId: patientId,
                logItem: true,
                patientStatusConfigurationAction: patientStatusConfigurationAction,
            };

            setPatientStatusModalStatusConfiguration(psc);
        }

    }

    renderPatientStatusModal() {
        const {
            patientStatusModalOpen,
            setPatientStatusModalViewState,
            setPatientStatusModalNewStatus,
            setPatientStatusModalPatientId,
            patientStatusModalData
        } = this.props

        if (patientStatusModalData && patientStatusModalData.patientId) {
            const derivedStatusDisplay = getPatientStatusText(patientStatusModalData.newStatus);
            let derivedBodyMessage: string = ""
            if (!derivedStatusDisplay) {
                derivedBodyMessage = "Are you sure you want to hide patient '" + patientStatusModalData.patientSiteNumber.toString() + "' ?";
            }
            else {
                derivedBodyMessage = "Are you sure you want to update patient '" + patientStatusModalData.patientSiteNumber.toString() + "' to the new status of '" + getPatientStatusText(patientStatusModalData.newStatus) + "' ?";
            }



            return <Modal open={patientStatusModalOpen} disableCloseOnOverlayClick={true} onClose={() => setPatientStatusModalViewState(false)} size={ModalSize.Md}>
                <Form model={patientStatusModalFormName} onSubmit={(val) => this.handleSubmitPatientStatusForm(val)}>
                    <div className="modal-header">
                        <h5 className="modal-title">{setModalTitle(undefined, "Update Patient Status")}</h5>
                        <button type="button" className="close" aria-label="Close" onClick={() => { setPatientStatusModalViewState(false); this.clearPatientStatusModalForm(); }}>
                            <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Regular.TIMES} />
                        </button>
                    </div>
                    <div className="modal-body">
                        {derivedBodyMessage}
                        <div className="patient-status-form">
                            <div className="mt-2 mb-2">
                                <span className="mt-2 mb-2"><strong>Reason:</strong></span>
                                <Control.textarea
                                    key={"form-reason"}
                                    component={TextAreaFormInput}
                                    model={`.reason`}
                                    controlProps={{
                                        className: undefined,
                                        label: "Reason",
                                        name: `${patientStatusModalFormName}.reason.name`,
                                        id: `${patientStatusModalFormName}.reason.id`
                                    }}
                                    mapProps={{
                                        valid: ({ fieldValue }) => fieldValue.valid,
                                        touched: ({ fieldValue }) => fieldValue.touched,
                                        focused: ({ fieldValue }) => fieldValue.focus,
                                        value: ({ fieldValue }) => fieldValue.value
                                    } as any}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-success">Confirm</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setPatientStatusModalViewState(false); this.clearPatientStatusModalForm(); }}>Close</button>
                    </div>
                </Form>
            </Modal>
        }
    }

    renderPatientStatusUpdateSuccess(patientSiteNumber: number, newStatus: Dtos.PatientStatus): any {

        let message: string = ""
        if (!newStatus) {
            message = "Patient " + patientSiteNumber.toString() + " has been hidden.";
        }
        else {
            message = "Patient " + patientSiteNumber.toString() + " has been successfully set to the new status of " + getPatientStatusText(newStatus);
        }

        return <div>{message}</div>

    }

    handleSubmitPatientStatusForm(patientStatusModalForm: IPatientStatusModalFormState) {
        const {
            patientStatusModalData,
            setPatientStatusById,
            setPatientStatusModalViewState,
        } = this.props;

        patientStatusModalData.patientStatusConfiguration.reason = patientStatusModalForm.reason;

        this.setPatientStatus(patientStatusModalData.newStatus, patientStatusModalData.patientId, patientStatusModalData.patientStatusConfiguration);
        setPatientStatusModalViewState(false);
        this.clearPatientStatusModalForm();

    }

    handleOnSubmitFailed(patientstatusModalForm: IPatientStatusModalFormState) {
        const {

        } = this.props;

        Alert.error(<NotificationAlert
            alertContent="Unable to process form. Please contact your system administrator"
            icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
        />);
    }
}

const filterPatientsByStatus = (patients: Dtos.Patient[], status: Dtos.PatientStatus): Dtos.Patient[] => {

    if (patients) {
        return patients.filter(p => p.status == status);
    }

    return [];
}

const filterPatientsByInstitutionCode = (patients: Dtos.Patient[], institutionCode: string): Dtos.Patient[] => {

    if (patients) {
        return patients.filter(p => !institutionCode || p.institutionCode == institutionCode);
    }

    return [];
}

const filterInstitutionsByCountry = (institutions: Dtos.Institution[], country: string): Dtos.Institution[] => {
    if (institutions) {
        return institutions.filter(i => !country || i.country == country);
    }

    return [];
}

const filterPatientsByCountry = (patients: Dtos.Patient[], institutions: Dtos.Institution[], country: string): Dtos.Patient[] => {

    if (patients) {
        return patients.filter(p => !country ||
            institutions && institutions.find(i => i.institutionCode == p.institutionCode && i.country == country));
    }

    return [];
}


const mapStateToProps = (state: IFigState, ownProps: RegistrationPageProps): IRegistrationPageProps => {
    let institutions: Dtos.Institution[] | undefined = state.institutions.data instanceof Array ? state.institutions.data as Dtos.Institution[] : undefined
    const countries: string[] = institutions ? Array.from(new Set(institutions.filter(c => c.country != undefined).map(country => country.country))) : [];

    institutions = filterInstitutionsByCountry(institutions!, state.registrationPage.country);
    const institutionCode: string | undefined = ownProps.match ? ownProps.match.params.institutionCode : undefined;

    const patients: Dtos.Patient[] | undefined =
        state.patients.data instanceof Array ?
            filterPatientsByCountry(filterPatientsByInstitutionCode(state.patients.data, institutionCode!), institutions, state.registrationPage.country) :
            undefined


    return {

        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined!,

        patients: patients!,
        ineligiblePatients: filterPatientsByStatus(patients!, Dtos.PatientStatus.Ineligible),
        preRegisteredPatients: filterPatientsByStatus(patients!, Dtos.PatientStatus.PreRegistered),
        submittedPatients: filterPatientsByStatus(patients!, Dtos.PatientStatus.Submitted),
        registeredPatients: filterPatientsByStatus(patients!, Dtos.PatientStatus.Registered),
        eligablePatients: filterPatientsByStatus(patients!, Dtos.PatientStatus.Eligible),
        randomisedPatients: filterPatientsByStatus(patients!, Dtos.PatientStatus.Randomised),
        expandIneligiblePatients: state.registrationPage.expandIneligiblePatients,
        expandPreRegisteredPatients: state.registrationPage.expandPreRegisteredPatients,
        expandRegisteredPatients: state.registrationPage.expandRegisteredPatients,
        expandSubmittedPatients: state.registrationPage.expandSubmittedPatients,
        expandEligablePatients: state.registrationPage.expandEligablePatients,
        expandRandomisedPatients: state.registrationPage.expandRandomisedPatients,

        loadingPatients: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientsSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientsFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,
        updatingPatient: state.patients.updateState && state.patients.updateState.status == RequestState.Pending,
        updatePatientSuccess: state.patients.updateState && state.patients.updateState.status == RequestState.Success,
        updatePatientFailure: state.patients.updateState && state.patients.updateState.status == RequestState.Failure,

        institutions: institutions,
        loadingInstitutions: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionsSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionsFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        countries: countries,
        country: state.registrationPage.country,

        lookups: state.registrationForms.preRegistrationForms.lookups,

        patientStatusModalOpen: state.registrationPage.patientStatusModalOpen,
        patientStatusModalData: state.registrationPage.patientStatusModalData,

        user: state.user.data
    };
};

const mapDispatchToProps = (dispatch): IRegistrationPageActions => {
    return {

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadPatients: bindActionCreators(PatientActions.LoadPatients, dispatch),
        clearPatients: bindActionCreators(PatientActions.Clear, dispatch),
        setPatientStatusById: bindActionCreators(PatientActions.SetPatientStatusById, dispatch),

        toggleIneligible: bindActionCreators(RegistrationPageActions.toggleIneligible, dispatch),
        togglePreRegistered: bindActionCreators(RegistrationPageActions.togglePreRegistered, dispatch),
        toggleRegistered: bindActionCreators(RegistrationPageActions.toggleRegistered, dispatch),
        toggleSubmitted: bindActionCreators(RegistrationPageActions.toggleSubmitted, dispatch),
        toggleEligable: bindActionCreators(RegistrationPageActions.toggleEligable, dispatch),
        toggleRandomised: bindActionCreators(RegistrationPageActions.toggleRandomised, dispatch),
        updateCountry: bindActionCreators(RegistrationPageActions.updateCountry, dispatch),

        loadInstitutions: bindActionCreators(InstitutionActions.LoadInstitutions, dispatch),
        clearInstitutions: bindActionCreators(InstitutionActions.Clear, dispatch),

        setPatientStatusModalViewState: bindActionCreators(RegistrationPageActions.setPatientStatusModalViewState, dispatch),
        setPatientStatusModalPatientId: bindActionCreators(RegistrationPageActions.setPatientStatusModalPatientId, dispatch),
        setPatientStatusModalNewStatus: bindActionCreators(RegistrationPageActions.setPatientStatusModalNewStatus, dispatch),
        setPatientStatusModalPatientSiteNumber: bindActionCreators(RegistrationPageActions.setPatientStatusModalSiteNumber, dispatch),
        setPatientStatusModalStatusConfiguration: bindActionCreators(RegistrationPageActions.setPatientStatusModalStatusConfiguration, dispatch),

        resetPatientStatusModalForm: bindActionCreators(actions.reset, dispatch)
    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
