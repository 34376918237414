import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleMessageModal: ISourceDocumentsFormPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORM_PAGE_TOGGLE_MESSAGE_MODAL
        }
    }

export const
    ACTION_SOURCEDOCUMENTSFORM_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_SOURCEDOCUMENTSFORM_PAGE_TOGGLE_MESSAGE_MODAL"

/* Actions */

export interface ISourceDocumentsFormPageToggleMessageModalAction {
    type: "ACTION_SOURCEDOCUMENTSFORM_PAGE_TOGGLE_MESSAGE_MODAL"
}

/* Action Creators */
export interface ISourceDocumentsFormPageToggleMessageModalActionCreator {
    (): ISourceDocumentsFormPageToggleMessageModalAction;
}


/* Final Export of actions */
export type SourceDocumentsFormPageAction = ISourceDocumentsFormPageToggleMessageModalAction;
