import './Header.scss';
import * as React from 'react';
import * as Dtos from '../../../dtos/Fig.dtos'
import { RequestState } from '../../../enumerations/RequestState';
import { connect } from 'react-redux';
import { FontAwesomeIcon, FontAwesomeIcons } from '../../../constants/fontAwesomeIcons';
import logo from '../../../assets/img/trog_logo.png'

interface IHeaderProps {
    header: string;
    user?: Dtos.User;
    userLoading?: boolean;
}

class Header extends React.PureComponent<IHeaderProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {
        
        const {
            header,
            user,
            userLoading
        } = this.props;

        return <div className="container-fluid page-container header">
            <div className="container">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <a href="https://www.trog.com.au/" className="no-mobile">

                                
                                    <img className="img-fluid header-logo-img" src={logo} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className="app-title">
                            {
                                header ?
                                    header :
                                    ''
                            }
                            </div>
                        <div className="app-login no-mobile">
                            {
                                user ?
                                    <div>
                                        <div className="font-weight-bold">{user.fullName}</div>
                                        <a href="/auth/logout">Logout <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SIGN_IN} /></a>
                                    </div> :
                                    <a href={"/auth/openiddict?ReturnUrl=" + encodeURIComponent(window.location.pathname + window.location.search)} className="">Login <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SIGN_IN} /></a>
                            }
                            
                        </div>
                    </div>
                </div >
            </div >
        </div >
    }
}

const mapStateToProps = (state: any, ownProps: IHeaderProps): IHeaderProps => {
    return {
        header: ownProps.header,
        user: state.user.data,
        userLoading: state.user.loadState.status == RequestState.Pending, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);