import * as Dtos from "../../dtos/Fig.dtos";

export const
    LoadGlioblastomaDetailForms: IGlioblastomaDetailFormLoadActionCreator = () => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_LOAD
        }
    },
    LoadGlioblastomaDetailFormById: IGlioblastomaDetailFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_ID,
            id
        }
    },
    LoadGlioblastomaDetailFormByPatientId: IGlioblastomaDetailFormLoadByPatientIdActionCreator = (patientId) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_ID,
            patientId
        }
    },
    LoadGlioblastomaDetailFormByPatientIdentifier: IGlioblastomaDetailFormLoadByPatientIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: IGlioblastomaDetailFormLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus

        }
    },
    LoadFailure: IGlioblastomaDetailFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveGlioblastomaDetailForm: IGlioblastomaDetailFormSaveActionCreator = (formData, institutionCode, patientIdentifier) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_SAVE,
            formData,
            institutionCode,
            patientIdentifier
        }
    },

    SaveSuccess: IGlioblastomaDetailFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },

    SaveFailure: IGlioblastomaDetailFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateGlioblastomaDetailForm: IGlioblastomaDetailFormCreateActionCreator = () => {

        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_CREATE
        }
    },

    CreateSuccess: IGlioblastomaDetailFormCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IGlioblastomaDetailFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IGlioblastomaDetailFormClearActionCreator = () => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORMS_CLEAR
        }
    };


export const
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD = "ACTION_GLIOBLASTOMADETAILFORMS_LOAD",
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_ID = "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_ID",
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_ID = "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_ID",
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_SUCCESS = "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_SUCCESS",
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_FAILURE = "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_FAILURE",

    ACTION_GLIOBLASTOMADETAILFORMS_SAVE = "ACTION_GLIOBLASTOMADETAILFORMS_SAVE",
    ACTION_GLIOBLASTOMADETAILFORMS_SAVE_SUCCESS = "ACTION_GLIOBLASTOMADETAILFORMS_SAVE_SUCCESS",
    ACTION_GLIOBLASTOMADETAILFORMS_SAVE_FAILURE = "ACTION_GLIOBLASTOMADETAILFORMS_SAVE_FAILURE",

    ACTION_GLIOBLASTOMADETAILFORMS_CREATE = "ACTION_GLIOBLASTOMADETAILFORMS_CREATE",
    ACTION_GLIOBLASTOMADETAILFORMS_CREATE_SUCCESS = "ACTION_GLIOBLASTOMADETAILFORMS_CREATE_SUCCESS",
    ACTION_GLIOBLASTOMADETAILFORMS_CREATE_FAILURE = "ACTION_GLIOBLASTOMADETAILFORMS_CREATE_FAILURE",

    ACTION_GLIOBLASTOMADETAILFORMS_CLEAR = "ACTION_GLIOBLASTOMADETAILFORMS_CLEAR";

export interface IGlioblastomaDetailFormLoadAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_LOAD";
}

export interface IGlioblastomaDetailFormLoadActionCreator {
    (): IGlioblastomaDetailFormLoadAction;
}

export interface IGlioblastomaDetailFormLoadByIdAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_ID";
    id: number;
}

export interface IGlioblastomaDetailFormLoadByIdActionCreator {
    (id: number): IGlioblastomaDetailFormLoadByIdAction;
}

export interface IGlioblastomaDetailFormLoadByPatientIdAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_ID";
    patientId: number;
}

export interface IGlioblastomaDetailFormLoadByPatientIdActionCreator {
    (patientId: number): IGlioblastomaDetailFormLoadByPatientIdAction;
}

export interface IGlioblastomaDetailFormLoadByPatientIdentifierAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface IGlioblastomaDetailFormLoadByPatientIdentifierActionCreator {
    (identifier: string): IGlioblastomaDetailFormLoadByPatientIdentifierAction;
}


export interface IGlioblastomaDetailFormLoadSuccessAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_SUCCESS";
    formData: Dtos.GlioblastomaDetailForm | Dtos.GlioblastomaDetailForm[];
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;

}

export interface IGlioblastomaDetailFormLoadSuccessActionCreator {
    (
        formData: Dtos.GlioblastomaDetailForm | Dtos.GlioblastomaDetailForm[],
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IGlioblastomaDetailFormLoadSuccessAction;
}

export interface IGlioblastomaDetailFormLoadFailureAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IGlioblastomaDetailFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGlioblastomaDetailFormLoadFailureAction;
}

export interface IGlioblastomaDetailFormSaveAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_SAVE";
    formData: Dtos.GlioblastomaDetailForm;
    institutionCode: string;
    patientIdentifier: string;

}

export interface IGlioblastomaDetailFormSaveActionCreator {
    (
        formData: Dtos.GlioblastomaDetailForm,
        institutionCode: string,
        patientIdentifier: string
    ): IGlioblastomaDetailFormSaveAction;
}

export interface IGlioblastomaDetailFormSaveSuccessAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_SAVE_SUCCESS";
    formData: Dtos.GlioblastomaDetailForm;
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IGlioblastomaDetailFormSaveSuccessActionCreator {
    (
        formData: Dtos.GlioblastomaDetailForm,
        formState: Dtos.RegistrationFormState,
        responseStatus: Dtos.ResponseStatus
    ): IGlioblastomaDetailFormSaveSuccessAction;
}

export interface IGlioblastomaDetailFormSaveFailureAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGlioblastomaDetailFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGlioblastomaDetailFormSaveFailureAction;
}

export interface IGlioblastomaDetailFormCreateAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_CREATE"
}

export interface IGlioblastomaDetailFormCreateActionCreator {
    (): IGlioblastomaDetailFormCreateAction
}

export interface IGlioblastomaDetailFormCreateSuccessAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_CREATE_SUCCESS";
    formData: Dtos.GlioblastomaDetailForm;
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IGlioblastomaDetailFormCreateSuccessActionCreator {
    (
        formData: Dtos.GlioblastomaDetailForm,
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[]
    ): IGlioblastomaDetailFormCreateSuccessAction;
}

export interface IGlioblastomaDetailFormCreateFailureAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IGlioblastomaDetailFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IGlioblastomaDetailFormCreateFailureAction;
}

export interface IGlioblastomaDetailFormClearAction {
    type: "ACTION_GLIOBLASTOMADETAILFORMS_CLEAR";
}

export interface IGlioblastomaDetailFormClearActionCreator {
    (): IGlioblastomaDetailFormClearAction;
}



export type GlioblastomaDetailFormAction = IGlioblastomaDetailFormLoadAction |
    IGlioblastomaDetailFormLoadByIdAction |
    IGlioblastomaDetailFormLoadByPatientIdAction |
    IGlioblastomaDetailFormLoadByPatientIdentifierAction |
    IGlioblastomaDetailFormLoadFailureAction |
    IGlioblastomaDetailFormLoadSuccessAction |
    IGlioblastomaDetailFormSaveAction |
    IGlioblastomaDetailFormSaveSuccessAction |
    IGlioblastomaDetailFormSaveFailureAction |
    IGlioblastomaDetailFormCreateAction |
    IGlioblastomaDetailFormCreateSuccessAction |
    IGlioblastomaDetailFormCreateFailureAction |
    IGlioblastomaDetailFormClearAction;
