import * as React from 'react';
import classNames from "classnames";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

import './SaeSummary.scss';
import * as FigDtos from '../../../dtos/Fig.dtos';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import sae from '../../../reducers/sae';
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../constants/reactTableConstants';
import { TableHeader } from '../../common';
import AnimateHeight from 'react-animate-height';
import ReactTable, { RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import { getSaeWebActionText } from '../../../helpers/sae';


interface ISaeSummaryProps {
    sae?: FigDtos.Sae;
    user: FigDtos.User;
    expandMedicalHistoryData?: boolean;
    expandInvestigativeProcedureData?: boolean;
    expandOtherMedicationData?: boolean;
    expandActionHistoryData?: boolean;

    toggleMedicalHistoryData?: () => void;
    toggleInvestigativeProcedureData?: () => void;
    toggleOtherMedicationData?: () => void;
    toggleActionHistoryData?: () => void;

}

class SaeSummary extends React.PureComponent<ISaeSummaryProps, any> {

    render() {

        const {
            sae,
            user
        } = this.props;

        return <div>
            <div className="sae-summary">
                <div className="summary-legend">
                    <div className="summary-legend-box"></div>
                    <div className="summary-legend-info">Latest value updated in the latest follow up.</div>
                </div>
                {
                    sae ?
                        <div>
                            <h2>Current Serious Adverse Event Entry</h2>
                            <div className="summary-cumulative">
                                <div className="table-wrapper">
                                    {
                                        this.renderSaeSummaryTableCumulative()
                                    }
                                </div>
                            </div>
                        </div> :
                        null
                }
                {
                    sae && !sae.isInitialSae ?
                        <div>
                            <h2>Initial Serious Adverse Event Entry</h2>
                            <div className="summary-initial">
                                <div className="table-wrapper">
                                    {
                                        this.renderSaeSummaryTableInitial()
                                    }
                                </div>
                            </div>
                        </div> :
                        null
                }
                <div className="summary-sub-tables">
                    {
                        this.renderSaeSummarySubTables()
                    }
                </div>
            </div>
        </div>;
    }

    renderSaeSummaryTableCumulative(): any {
        const {
            sae
        } = this.props;

        return <div>
            {
                sae ?
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr><th colSpan={2 as number}><h4 className="mb-0">Event details</h4></th></tr>
                            <tr>
                                <th>Institution</th>
                                <td>{sae.saeLatestDataCumulative.institutionName}</td>
                            </tr>
                            <tr>
                                <th>Registration Number</th>
                                <td>{sae.randNo}</td>
                            </tr>
                            <tr>
                                <th>SAE Number</th>
                                <td>{sae.saeLatestDataCumulative.saeNumber}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.reportingInvestigator, sae.saeLatestDataForm.reportingInvestigator) })}>
                                <th>Reporting Investigator</th>
                                <td>{sae.saeLatestDataCumulative.reportingInvestigator}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.ctcaeTerm, sae.saeLatestDataForm.ctcaeTerm) })}>
                                <th>
                                    SAE title <br />
                                    <small>CTCAE v5.0 term</small>
                                </th>
                                <td>{sae.saeLatestDataCumulative.ctcaeTerm}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.ctcaeCategory, sae.saeLatestDataForm.ctcaeCategory) })}>
                                <th>CTCAE Category</th>
                                <td>{sae.saeLatestDataCumulative.ctcaeCategory}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.ctcaeGrade, sae.saeLatestDataForm.ctcaeGrade) })}>
                                <th>CTCAE Grade</th>
                                <td>Grade {sae.saeLatestDataCumulative.ctcaeGrade}<br />{sae.saeLatestDataCumulative.ctcaeGradeDescription}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(convertToShortDateString(sae.saeLatestDataCumulative.onsetDate), convertToShortDateString(sae.saeLatestDataForm.onsetDate)) })}>
                                <th>
                                    Onset date <br />
                                    <small>(Date the event became serious)</small>
                                </th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.onsetDate)}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.reportingCriteria, sae.saeLatestDataForm.reportingCriteria) })}>
                                <th>Seriousness category</th>
                                <td dangerouslySetInnerHTML={{ __html: sae.saeLatestDataCumulative.reportingCriteria }}></td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.admissionDate, sae.saeLatestDataForm.admissionDate) })}>
                                <th>Admission date</th>
                                <td dangerouslySetInnerHTML={{ __html: convertToShortDateString(sae.saeLatestDataCumulative.admissionDate) }}></td>
                            </tr>
                            <tr className={
                                classNames(
                                    {
                                        "latest-data-field": this.isLatestFieldEntry(
                                            sae.saeLatestDataCumulative.dischargeDate, sae.saeLatestDataForm.dischargeDate) ||
                                            this.isLatestFieldEntry(
                                                sae.saeLatestDataCumulative.dischargeDateOngoing, sae.saeLatestDataForm.dischargeDateOngoing)
                                    })
                            }>
                                <th>Discharge date</th>
                                <td dangerouslySetInnerHTML={{
                                    __html: sae.saeLatestDataCumulative.dischargeDateOngoing === "Yes" &&
                                        !sae.saeLatestDataCumulative.dischargeDate ?
                                        'Ongoing' :
                                        convertToShortDateString(sae.saeLatestDataCumulative.dischargeDate)
                                }}></td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.severityGrade, sae.saeLatestDataForm.severityGrade) })}>
                                <th>Severity Grade of Event (CTCAE v5.0)</th>
                                <td>{sae.saeLatestDataCumulative.severityGrade}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.relationshipFETRadiotracer, sae.saeLatestDataForm.relationshipFETRadiotracer) })}>
                                <th>Relationship to FET Radiotracer (FET-F1-18)</th>
                                <td>{sae.saeLatestDataCumulative.relationshipFETRadiotracer}</td>
                            </tr>
                            <tr>
                                <td colSpan={2 as number}>
                                    <h5 className="mb-0">Cause of event</h5>
                                </td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.studyTreatmentFETTracer, sae.saeLatestDataForm.studyTreatmentFETTracer) })}>
                                <th>Study Treatment: FET Tracer</th>
                                <td>{sae.saeLatestDataCumulative.studyTreatmentFETTracer}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.progressiveDisease, sae.saeLatestDataForm.progressiveDisease) })}>
                                <th>Progressive disease</th>
                                <td>{sae.saeLatestDataCumulative.progressiveDisease}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.trialProcedure, sae.saeLatestDataForm.trialProcedure) })}>
                                <th>Trial procedure</th>
                                <td>{sae.saeLatestDataCumulative.trialProcedure}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.nameOfProcedure, sae.saeLatestDataForm.nameOfProcedure) })}>
                                <th className="indent-grouped-label">Name of procedure</th>
                                <td>{sae.saeLatestDataCumulative.nameOfProcedure}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.indicationOfProcedure, sae.saeLatestDataForm.indicationOfProcedure) })}>
                                <th className="indent-grouped-label">Indication of procedure</th>
                                <td>{sae.saeLatestDataCumulative.indicationOfProcedure}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.procedureDate, sae.saeLatestDataForm.procedureDate) })}>
                                <th className="indent-grouped-label">Procedure date</th>
                                <td dangerouslySetInnerHTML={{ __html: convertToShortDateString(sae.saeLatestDataCumulative.procedureDate) }}></td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.concurrentDisorder, sae.saeLatestDataForm.concurrentDisorder) })}>
                                <th>Concurrent disorder</th>
                                <td>{sae.saeLatestDataCumulative.concurrentDisorder}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.concurrentDisorderSpecify, sae.saeLatestDataForm.concurrentDisorderSpecify) })}>
                                <th className="indent-grouped-label">Please specify the disorder</th>
                                <td>{sae.saeLatestDataCumulative.concurrentDisorderSpecify}</td>
                            </tr>

                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.concomitantMedication, sae.saeLatestDataForm.concomitantMedication) })}>
                                <th>Concomitant medication</th>
                                <td>{sae.saeLatestDataCumulative.concomitantMedication}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComNameOfMedication, sae.saeLatestDataForm.conComNameOfMedication) })}>
                                <th className="indent-grouped-label">Name</th>
                                <td>{sae.saeLatestDataCumulative.conComNameOfMedication}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComIndication, sae.saeLatestDataForm.conComIndication) })}>
                                <th className="indent-grouped-label">Indication</th>
                                <td>{sae.saeLatestDataCumulative.conComIndication}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComStartDate, sae.saeLatestDataForm.conComStartDate) })}>
                                <th className="indent-grouped-label">Start date</th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.conComStartDate)}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComStopDate, sae.saeLatestDataForm.conComStopDate) })}>
                                <th className="indent-grouped-label">Stop date</th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.conComStopDate)}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComOnGoing, sae.saeLatestDataForm.conComOnGoing) })}>
                                <th className="indent-grouped-label">Ongoing</th>
                                <td>{sae.saeLatestDataCumulative.conComOnGoing}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComRoute, sae.saeLatestDataForm.conComRoute) })}>
                                <th className="indent-grouped-label">Route</th>
                                <td>{sae.saeLatestDataCumulative.conComRoute}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComDose, sae.saeLatestDataForm.conComDose) })}>
                                <th className="indent-grouped-label">Dose</th>
                                <td>{sae.saeLatestDataCumulative.conComDose}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComUnit, sae.saeLatestDataForm.conComUnit) })}>
                                <th className="indent-grouped-label">Unit</th>
                                <td>{sae.saeLatestDataCumulative.conComUnit}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComUnitOther, sae.saeLatestDataForm.conComUnitOther) })}>
                                <th className="indent-grouped-label">Unit: Other</th>
                                <td>{sae.saeLatestDataCumulative.conComUnitOther}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComFrequency, sae.saeLatestDataForm.conComFrequency) })}>
                                <th className="indent-grouped-label">Frequency</th>
                                <td>{sae.saeLatestDataCumulative.conComFrequency}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.conComFrequencyOther, sae.saeLatestDataForm.conComFrequencyOther) })}>
                                <th className="indent-grouped-label">Frequency: Other</th>
                                <td>{sae.saeLatestDataCumulative.conComFrequencyOther}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.other, sae.saeLatestDataForm.other) })}>
                                <th>Other</th>
                                <td>{sae.saeLatestDataCumulative.other}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.otherSpecify, sae.saeLatestDataForm.otherSpecify) })}>
                                <th className="indent-grouped-label">Please specify</th>
                                <td>{sae.saeLatestDataCumulative.otherSpecify}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.reactionAbateAfterStopping, sae.saeLatestDataForm.reactionAbateAfterStopping) })}>
                                <th>Did reaction abate after stopping treatment/medication?</th>
                                <td>{sae.saeLatestDataCumulative.reactionAbateAfterStopping}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.reactionReappeared, sae.saeLatestDataForm.reactionReappeared) })}>
                                <th>Did reaction reappear after reintroduction?</th>
                                <td>{sae.saeLatestDataCumulative.reactionReappeared}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.eventExpected, sae.saeLatestDataForm.eventExpected) })}>
                                <th>Was this an expected event?</th>
                                <td>{sae.saeLatestDataCumulative.eventExpected}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.eventDescription, sae.saeLatestDataForm.eventDescription) })}>
                                <th>Event description</th>
                                <td>{sae.saeLatestDataCumulative.eventDescription}</td>
                            </tr>
                            <tr>
                                <th colSpan={2 as number}>
                                    <h4 className="mb-0">Treatment details</h4>
                                </th>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.dateOfLastFETPET, sae.saeLatestDataForm.dateOfLastFETPET) })}>
                                <th>Date of last FET-PET (FET-F-18)</th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.dateOfLastFETPET)}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.doseReceived, sae.saeLatestDataForm.doseReceived) })}>
                                <th>Dose recieved</th>
                                <td>{sae.saeLatestDataCumulative.doseReceived}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.manufacturedBy, sae.saeLatestDataForm.manufacturedBy) })}>
                                <th>Manufactured By</th>
                                <td>{sae.saeLatestDataCumulative.manufacturedBy}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.modificationDueToEvent, sae.saeLatestDataForm.modificationDueToEvent) })}>
                                <th>Modification due to event</th>
                                <td>{sae.saeLatestDataCumulative.modificationDueToEvent}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.hasMedicalHistoryOrConditions, sae.saeLatestDataForm.hasMedicalHistoryOrConditions) })}>
                                <th>Does the participant have and medical history or current conditions relevant to the event</th>
                                <td>{sae.saeLatestDataCumulative.hasMedicalHistoryOrConditions}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.eventInvestigativeProcedures, sae.saeLatestDataForm.eventInvestigativeProcedures) })}>
                                <th>Did the participant have any investigative procedures for the event? (Please provide copies of relevant reports/results)</th>
                                <td>{sae.saeLatestDataCumulative.eventInvestigativeProcedures}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.prescribedMedications, sae.saeLatestDataForm.prescribedMedications) })}>
                                <th>Was the participant prescribed any medications to treat the event?</th>
                                <td>{sae.saeLatestDataCumulative.prescribedMedications}</td>
                            </tr>
                            <tr>
                                <th colSpan={2 as number}>
                                    <h4 className="mb-0">Event Outcome</h4>
                                </th>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.whatIsTheEventOutcome, sae.saeLatestDataForm.whatIsTheEventOutcome) })}>
                                <th>What is the Event Outcome?</th>
                                <td dangerouslySetInnerHTML={{ __html: sae.saeLatestDataCumulative.whatIsTheEventOutcome }}></td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.describeSequelae, sae.saeLatestDataForm.describeSequelae) })}>
                                <th className="indent-grouped-label">Describe sequelae</th>
                                <td>{sae.saeLatestDataCumulative.describeSequelae}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.causeOfDeath, sae.saeLatestDataForm.causeOfDeath) })}>
                                <th className="indent-grouped-label">Cause of death</th>
                                <td>{sae.saeLatestDataCumulative.causeOfDeath}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.dateOfAutopsy, sae.saeLatestDataForm.dateOfAutopsy) })}>
                                <th className="indent-grouped-label">Date of autopsy</th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.dateOfAutopsy)}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.autopsyNA, sae.saeLatestDataForm.autopsyNA) })}>
                                <th className="indent-grouped-label">N/A</th>
                                <td>{sae.saeLatestDataCumulative.autopsyNA == 1 ? 'X' : ''}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(sae.saeLatestDataCumulative.dateEventResolved, sae.saeLatestDataForm.dateEventResolved) })}>
                                <th>Date event resolved or date of death</th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.dateEventResolved)}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(convertToShortDateString(sae.saeLatestDataCumulative.dateEntered), convertToShortDateString(sae.saeLatestDataForm.dateEntered))})}>
                                <th>Date Created</th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.dateEntered)}</td>
                            </tr>
                            <tr className={classNames({ "latest-data-field": this.isLatestFieldEntry(convertToShortDateString(sae.saeLatestDataCumulative.dateModified), convertToShortDateString(sae.saeLatestDataForm.dateModified)) })}>
                                <th>Date Updated</th>
                                <td>{convertToShortDateString(sae.saeLatestDataCumulative.dateModified)}</td>
                            </tr>
                        </tbody>
                    </table> :
                    null
            }
        </div>
    }

    renderSaeSummaryTableInitial(): any {
        const {
            sae
        } = this.props;


        return <div>
            {
                sae ?
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr><th colSpan={2 as number}><h4 className="mb-0">Event details</h4></th></tr>
                            <tr>
                                <th>Institution</th>
                                <td>{sae.saeInitialData.institutionName}</td>
                            </tr>
                            <tr>
                                <th>Registration Number</th>
                                <td>{sae.randNo}</td>
                            </tr>
                            <tr>
                                <th>SAE Number</th>
                                <td>{sae.saeInitialData.saeNumber}</td>
                            </tr>
                            <tr>
                                <th>
                                    SAE title <br />
                                    <small>CTCAE v5.0 term</small>
                                </th>
                                <td>{sae.saeInitialData.ctcaeTerm}</td>
                            </tr>
                            <tr>
                                <th>
                                    Onset date <br />
                                    <small>(Date the event became serious)</small>
                                </th>
                                <td>{convertToShortDateString(sae.saeInitialData.onsetDate)}</td>
                            </tr>
                            <tr>
                                <th>Date Created</th>
                                <td>{convertToShortDateString(sae.saeInitialData.dateEntered)}</td>
                            </tr>
                            <tr>
                                <th>Date Updated</th>
                                <td>{convertToShortDateString(sae.saeInitialData.dateModified)}</td>
                            </tr>
                        </tbody>
                    </table> :
                    null
            }
        </div>
    }

    renderSaeSummarySubTables(): any {
        const {
            sae
        } = this.props;

        return <div>
            {
                this.renderMedicalHistoryDataTable()
            }
            {
                this.renderInvestigativeProcedureData()
            }
            {
                this.renderMedicationDataTable()
            }
            {
                this.renderSaeWebActionsTable()
            }
        </div>

    }

    isLatestFieldEntry(fieldValue: any, latestFieldValue: any): boolean {
        const {
            sae
        } = this.props;

        if (sae.isInitialSae) {
            return false;
        }

        return ((fieldValue == latestFieldValue) &&
            (latestFieldValue != null &&
                latestFieldValue != undefined &&
                latestFieldValue != '')
        );
    }

    renderMedicalHistoryDataTable(): any {
        const {
            sae,
            expandMedicalHistoryData,
            toggleMedicalHistoryData
        } = this.props

        const medicalHistoryData: FigDtos.SaeMedicalHistoryData[] = sae.saeMedicalHistoryData;
        const expandedTable: boolean = expandMedicalHistoryData != undefined ? expandMedicalHistoryData: true;

        const medicalHistoryDataExists: boolean =
            sae.saeMedicalHistoryData &&
            sae.saeMedicalHistoryData.length > 0;

        if (!medicalHistoryDataExists) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There is no medical history data to show for this SAE.</span>
            </div>
        }

        const showPagination = medicalHistoryData.length > DEFAULT_PAGE_SIZE;
        const medicalHistoryIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLIPBOARD} fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total Medical History Entries"
                title="Medical History"
                expanded={expandedTable}
                toggle={toggleMedicalHistoryData}
                count={medicalHistoryData.length}
                icon={medicalHistoryIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandedTable ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"medical-history-" + sae.saeId}
                        data={medicalHistoryData}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : medicalHistoryData.length}
                        pageSize={showPagination ? undefined : medicalHistoryData.length}
                        showPagination={showPagination}
                        className="-striped"
                        noDataText="There is currently no medical history data to show for this SAE"
                        resizable={false}
                        sortable={false}
                        getTrProps={(state, rowInfo: any, column) => {
                            return {

                                style: {
                                    background: rowInfo && rowInfo.original.latest ? "#d9edf7" : ""
                                }
                            };
                        }}
                        columns={
                            [
                                {
                                    Header: 'Disease/condition, surgical procedure',
                                    accessor: 'event', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center"
                                },
                                {
                                    Header: "Start Date",
                                    id: "eventStartDate",
                                    accessor: (saeMedicalHistoryData: FigDtos.SaeMedicalHistoryData) =>
                                        <div><span data-tip={convertToApproximateDateString(saeMedicalHistoryData.eventStartDate)}>{saeMedicalHistoryData.eventStartDate}</span><ReactTooltip /></div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Stop Date",
                                    id: "eventStopDate",
                                    accessor: (saeMedicalHistoryData: FigDtos.SaeMedicalHistoryData) =>
                                        <div><span data-tip={convertToApproximateDateString(saeMedicalHistoryData.eventStopDate)}>{saeMedicalHistoryData.eventStopDate}</span><ReactTooltip /></div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "NA",
                                    id: "eventOngoing",
                                    accessor: (saeMedicalHistoryData: FigDtos.SaeMedicalHistoryData) =>
                                        <div>{saeMedicalHistoryData.eventOngoing == 1 ? 'X' : ''}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>

    }

    renderInvestigativeProcedureData(): any {
        const {
            sae,
            expandInvestigativeProcedureData,
            toggleInvestigativeProcedureData
        } = this.props

        const investigativeProcedureData: FigDtos.SaeInvestigativeProcedureData[] = sae.saeInvestigativeProcedureData;
        const expandedTable: boolean = expandInvestigativeProcedureData != undefined ? expandInvestigativeProcedureData : true;

        const investigativeProcedureDataExists: boolean = investigativeProcedureData && investigativeProcedureData.length > 0;

        if (!investigativeProcedureDataExists) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There is no investigative procedure data to show for this SAE.</span>
            </div>
        }

        const showPagination = investigativeProcedureData.length > DEFAULT_PAGE_SIZE;
        const investigativeProcedureIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLIPBOARD} fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total Investigative Procedure Entries"
                title="Investigative Procedure Information"
                expanded={expandedTable}
                toggle={toggleInvestigativeProcedureData}
                count={investigativeProcedureData.length}
                icon={investigativeProcedureIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandedTable ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"investigative-procedure-data-" + sae.saeId}
                        data={investigativeProcedureData}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : investigativeProcedureData.length}
                        pageSize={showPagination ? undefined : investigativeProcedureData.length}
                        showPagination={showPagination}
                        className="-striped"
                        noDataText="There is currently no investigative procedure data to show for this SAE"
                        resizable={false}
                        sortable={false}
                        getTrProps={(state, rowInfo: any, column) => {
                            return {
                                style: {
                                    background: rowInfo && rowInfo.original.latest ? "#d9edf7" : ""
                                }
                            };
                        }}
                        columns={
                            [
                                {
                                    Header: 'Examination/Investigative procedure',
                                    accessor: 'procedure', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center"
                                },
                                {
                                    Header: "Date",
                                    id: "procedureDate",
                                    accessor: (saeInvestigativeProcedureData: FigDtos.SaeInvestigativeProcedureData) =>
                                        <div><span data-tip={convertToApproximateDateString(saeInvestigativeProcedureData.procedureDate)}>{convertToShortDateString(saeInvestigativeProcedureData.procedureDate)}</span><ReactTooltip /></div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: 'Result',
                                    accessor: 'procedureResult', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center"
                                },
                                {
                                    Header: "Pending",
                                    id: "procedurePending",
                                    accessor: (saeInvestigativeProcedureData: FigDtos.SaeInvestigativeProcedureData) =>
                                        <div>{saeInvestigativeProcedureData.procedurePending == 1 ? 'X' : ''}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>

    }

    renderMedicationDataTable(): any {
        const {
            sae,
            expandOtherMedicationData,
            toggleOtherMedicationData
        } = this.props

        const medicationData: FigDtos.SaeMedicationData[] = sae.saeMedicationData;
        const expandedTable: boolean = expandOtherMedicationData != undefined ? expandOtherMedicationData : true;

        const medicationDataExists: boolean = medicationData && medicationData.length > 0;

        if (!medicationDataExists) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There is no Medication data to show for this SAE.</span>
            </div>
        }

        const showPagination = medicationData.length > DEFAULT_PAGE_SIZE;
        const medicationDataIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLIPBOARD} fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total Medication Entries"
                title="Medications"
                expanded={expandedTable}
                toggle={toggleOtherMedicationData}
                count={medicationData.length}
                icon={medicationDataIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandedTable ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"medication-data-" + sae.saeId}
                        data={medicationData}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : medicationData.length}
                        pageSize={showPagination ? undefined : medicationData.length}
                        showPagination={showPagination}
                        className="-striped"
                        noDataText="There is no Medication data to show for this SAE."
                        resizable={false}
                        sortable={false}
                        getTrProps={(state, rowInfo: any, column) => {
                            return {
                                style: {
                                    background: rowInfo && rowInfo.original.latest ? "#d9edf7" : ""
                                }
                            };
                        }}
                        columns={
                            [
                                {
                                    Header: "Name",
                                    id: "medicationName",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationName}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Type",
                                    id: "medicationType",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationType}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Indication",
                                    id: "medicationIndication",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationIndication}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Start date",
                                    id: "medicationStartDate",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div><span >{convertToShortDateString(saeMedicationData.medicationStartDate)}</span><ReactTooltip /></div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Stop date",
                                    id: "medicationStopDate",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div><span data-tip={convertToApproximateDateString(saeMedicationData.medicationStopDate)}>{convertToShortDateString(saeMedicationData.medicationStopDate)}</span><ReactTooltip /></div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Ongoing",
                                    id: "medicationOngoing",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationOngoing}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Dose",
                                    id: "medicationDose",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationDose}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Unit",
                                    id: "medicationUnit",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationUnit}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Route",
                                    id: "medicationRoute",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationRoute}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Frequency",
                                    id: "medicationFrequency",
                                    accessor: (saeMedicationData: FigDtos.SaeMedicationData) =>
                                        <div>{saeMedicationData.medicationFrequency}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>
    }

    renderSaeWebActionsTable(): any {
        const {
            sae,
            expandActionHistoryData,
            toggleActionHistoryData
        } = this.props

        const webActionData: FigDtos.SaeWebAction[] = sae.saeWebActions;
        const expandedTable: boolean = expandActionHistoryData != undefined ? expandActionHistoryData : true;

        const webActionDataExists: boolean = webActionData && webActionData.length > 0;

        if (!webActionDataExists) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There is no action history to show for this SAE.</span>
            </div>
        }

        const showPagination = webActionData.length > DEFAULT_PAGE_SIZE;
        const webActionIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.CLIPBOARD} fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total Actions"
                title="Action History"
                expanded={expandedTable}
                toggle={toggleActionHistoryData}
                count={webActionData.length}
                icon={webActionIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandedTable ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"action-history-" + sae.saeId}
                        data={webActionData}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : webActionData.length}
                        pageSize={showPagination ? undefined : webActionData.length}
                        showPagination={showPagination}
                        className="-striped"
                        noDataText="There is no action history to show for this SAE."
                        resizable={false}
                        sortable={false}
                        getTrProps={(state, rowInfo: any, column) => {
                            return {
                                style: {
                                    background: rowInfo && rowInfo.original.latest ? "#d9edf7" : ""
                                }
                            };
                        }}
                        columns={
                            [
                                {
                                    Header: 'User',
                                    accessor: 'username', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center"
                                },
                                {
                                    Header: "Action Date",
                                    id: "dateAction",
                                    accessor: (saeWebAction: FigDtos.SaeWebAction) =>
                                        <div><span data-tip={convertToApproximateDateString(saeWebAction.dateAction)}>{convertToShortDateString(saeWebAction.dateAction)}</span><ReactTooltip /></div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Action",
                                    id: "actionTypeId",
                                    accessor: (saeWebAction: FigDtos.SaeWebAction) =>
                                        <div>{getSaeWebActionText(saeWebAction.actionTypeId)}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Notes",
                                    id: "notes",
                                    accessor: (saeWebAction: FigDtos.SaeWebAction) =>
                                        <div>{saeWebAction.notes}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    Header: "Recipients",
                                    id: "recipients",
                                    accessor: (saeWebAction: FigDtos.SaeWebAction) =>
                                        <div>{saeWebAction.recipients}</div>,
                                    className: "d-flex align-items-center justify-content-center",
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>

    }

}
export default SaeSummary;