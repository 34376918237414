import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleMessageModal: IClinicalAssessmentFormPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_CLINICALASSESSMENTFORM_PAGE_TOGGLE_MESSAGE_MODAL
        }
    }

export const
    ACTION_CLINICALASSESSMENTFORM_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_CLINICALASSESSMENTFORM_PAGE_TOGGLE_MESSAGE_MODAL"

/* Actions */

export interface IClinicalAssessmentFormPageToggleMessageModalAction {
    type: "ACTION_CLINICALASSESSMENTFORM_PAGE_TOGGLE_MESSAGE_MODAL"
}

/* Action Creators */
export interface IClinicalAssessmentFormPageToggleMessageModalActionCreator {
    (): IClinicalAssessmentFormPageToggleMessageModalAction;
}


/* Final Export of actions */
export type ClinicalAssessmentFormPageAction = IClinicalAssessmentFormPageToggleMessageModalAction;
