import * as React from 'react';
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

interface ILinkActions {
}


export interface ILinkProps {
    url: string;
    target?: string;
    className?: string;
    activeClassName?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export interface ILinkState {
}


type LinkProps = ILinkProps & ILinkActions;

class Link extends React.PureComponent<LinkProps, ILinkState> {
    constructor(props) {
        super(props)

        //this.handleClick = this.handleClick.bind(this);
    }


    render() {
        const {
            url,
            className,
            style,
            target,
            children,
            activeClassName
        } = this.props;

        if (this.isExternalLink()) {

            const aProps = {
                href: url,
                className,
                style,
                target
            };

            return <a
                {...aProps}
            >
                {children}
            </a>
        }
        else {
            return <NavLink className={className} style={style} to={url} activeClassName={activeClassName}>{children}</NavLink>
        }
        
    }

    isExternalLink() {
        const { url, target } = this.props;

        if (url.match(/(^\/\/)|(:\/\/)/g) ||
            (target && target != "_self")) {
            return true;
        }

        return false;
    }

}



export default Link;

