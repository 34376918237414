import * as React from 'react';
import { Header, NavMenu } from "./index";

interface IDefaultLayoutProps {
    children: any;
}

class DefaultLayout extends React.PureComponent<IDefaultLayoutProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {
        return <div>
            <Header header={'TROG 18.06 FIG'}></Header>
            <NavMenu></NavMenu>
            {this.props.children}
        </div>
    }
}

export default DefaultLayout;