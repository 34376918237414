/* Options:
Date: 2022-10-10 13:58:18
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:15220

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IGet
{
}

export interface IPost
{
}

export class PreRegistrationForm
{
    public id?: number;
    public patientId?: number;
    public patientInitials: string;
    public gender?: number;
    public dateOfBirth?: string;
    public ageAtConsent?: number;
    public registrationGroup?: number;
    public medicalOncologistName: string;
    public radiationOncologistName: string;
    public dateWrittenInformedConsent?: string;
    public medicareConsent?: number;
    public dateOfServicesAustraliaDataConsent?: string;
    public consentDetails?: number;
    public startDateOfDataCollection?: string;
    public stopDateOfDataCollection?: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PreRegistrationForm>) { (Object as any).assign(this, init); }
}

export enum PatientStatus
{
    PreEntry = 0,
    PreRegistered = 1,
    Submitted = 2,
    Registered = 3,
    Eligible = 4,
    Randomised = 5,
    Ineligible = 6,
    Hidden = 7,
}

export enum PatientStatusConfigurationAction
{
    AutomaticSystemUpdate = 1,
    IneligiblePatient = 2,
    HidePatient = 3,
    RestorePatient = 4,
    RegisterPatient = 5,
    WaiverPatient = 6,
    WaiverPatientForm = 7,
    WaiverPatientField = 8,
    WaiverPatientRule = 9,
    SubmitPatient = 10,
    EligiblePatient = 11,
    ReassignToSite = 12,
}

export class PatientStatusConfiguration
{
    public id?: number;
    public patientId?: number;
    public logItem?: boolean;
    public patientStatusConfigurationAction?: PatientStatusConfigurationAction;
    public statusBeforeAction?: PatientStatus;
    public statusPerformAction?: PatientStatus;
    public statusAfterAction?: PatientStatus;
    public reason: string;
    public registrationFormId?: number;
    public registrationFormPropertyId?: number;
    public registrationFormPropertyRuleId?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientStatusConfiguration>) { (Object as any).assign(this, init); }
}

export class TrialUser
{
    public username: string;
    public displayname: string;
    public email: string;
    public trialid: string;
    public instcode: string;
    public roleid?: number;
    public rolename: string;

    public constructor(init?: Partial<TrialUser>) { (Object as any).assign(this, init); }
}

export class SaeNotifyFormData
{
    public trialUsers: TrialUser[];
    public notes: string;

    public constructor(init?: Partial<SaeNotifyFormData>) { (Object as any).assign(this, init); }
}

export enum TrialUserType
{
    Investigators = 1,
    SiteTrialCoordinators = 2,
}

export class SaeReviewFormData
{
    public rejectReason: string;

    public constructor(init?: Partial<SaeReviewFormData>) { (Object as any).assign(this, init); }
}

export enum SaeReviewType
{
    None = 1,
    Approve = 2,
    Reject = 3,
}

export class WebsiteSetting
{
    public enableGroup2: boolean;

    public constructor(init?: Partial<WebsiteSetting>) { (Object as any).assign(this, init); }
}

export enum EmailTemplate
{
    ErrorEmail = 1,
    SuccessfulRegistration = 2,
    SuccessfulSubmitForEvaluation = 3,
    SuccessfulPreRegistration = 4,
    Ineligible = 5,
    ReassignToSite = 6,
    SaeNotifyTrialUsersNotificiation = 7,
    SaeReviewApproveNotification = 8,
    SaeReviewRejectNotification = 9,
    SaeNewEmailNotification = 10,
    SaeUpdatedEmailNotification = 11,
    SaeUnnaprovedEmailNotification = 12,
    SaeUnresolvedEmailnotification = 13,
}

export class ClinicalAssessmentFormConcomitant
{
    public id: number;
    public clinicalAssessmentFormId?: number;
    public medicationName: string;
    public medicationType?: number;
    public medicationIndication?: number;
    public medicationIndicationOther: string;
    public medicationStartDate?: string;
    public medicationStopDate?: string;
    public medicationOngoing?: boolean;
    public medicationDose?: number;
    public medicationUnit?: number;
    public medicationUnitOther: string;
    public medicationRoute?: number;
    public medicationFrequency?: number;
    public medicationFrequencyOther: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ClinicalAssessmentFormConcomitant>) { (Object as any).assign(this, init); }
}

export class ClinicalAssessmentForm
{
    public id?: number;
    public patientId?: number;
    public assessmentDate?: string;
    public ecog?: number;
    public otherMalignancies?: number;
    public otherMalignanciesTypeCarcinomaInSitu?: boolean;
    public otherMalignanciesTypeBasalCellCarcinoma?: boolean;
    public otherMalignanciesTypeSquamousCellCarcinoma?: boolean;
    public otherMalignanciesTypeSuperficialTransitionalCellCarcinoma?: boolean;
    public otherMalignanciesTypeOther?: boolean;
    public otherMalignanciesDiseaseFree?: number;
    public otherMalignanciesPrimaryTreatmentCompleted?: string;
    public priorChemotherapyOrRecentCranialRadiationLastTwoYears?: number;
    public concomitantMedications?: number;
    public fullBloodCountDate?: string;
    public wbcCount?: number;
    public platelets?: number;
    public absoluteNeutrophils?: number;
    public lymphocytes?: number;
    public haemoglobin?: number;
    public ureaElectrolytesCreatineDate?: string;
    public ureaElectrolytesCreatineDateSameAsFBC?: boolean;
    public sodium?: number;
    public potassium?: number;
    public urea?: number;
    public creatine?: number;
    public creatineULN?: number;
    public creatineClearance?: number;
    public creatineDeteriminedBy?: number;
    public chloride?: number;
    public bicarbonate?: number;
    public liverFunctionTestDate?: string;
    public liverFunctionTestDateSameAsFBC?: boolean;
    public alt?: number;
    public altUln?: number;
    public alp?: number;
    public ast?: number;
    public astUln?: number;
    public totalBilirubin?: number;
    public totalInstitutionUln?: number;
    public albumin?: number;
    public ggt?: number;
    public totalProtein?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public concomitants: ClinicalAssessmentFormConcomitant[];

    public constructor(init?: Partial<ClinicalAssessmentForm>) { (Object as any).assign(this, init); }
}

export enum FileType
{
    EligibilityConfirmation = 1,
    ConsentFormMain = 2,
    ConsentFormServicesAustraliaData = 3,
    PathologyReportTumor = 4,
    PathologyReportBlood = 5,
    PatientReportOutcomeEORTCQLQC30 = 6,
    Worksheet = 7,
    ImagingReportMri = 8,
    ImagingReportFETPET = 9,
    ImagingReportFDGPET = 10,
    OtherSourceDocument = 11,
}

export enum FileState
{
    Uploading = 1,
    Success = 2,
    Failure = 3,
    Cancelled = 4,
    Empty = 5,
}

export class TemporaryFile
{
    public id: string;
    public name: string;
    public contentType: string;
    public extension: string;
    public fileSize?: number;
    public image?: boolean;
    public dateUploaded?: string;

    public constructor(init?: Partial<TemporaryFile>) { (Object as any).assign(this, init); }
}

export class TemporaryFileInformation
{
    public state: FileState;
    public progress: string;
    public guid: string;
    public temporaryFile: TemporaryFile;

    public constructor(init?: Partial<TemporaryFileInformation>) { (Object as any).assign(this, init); }
}

export class SourceDocument
{
    public id?: number;
    public patientId?: number;
    public registrationFormId?: number;
    public fileType?: FileType;
    public name: string;
    public extension: string;
    public contentType: string;
    public fileSize: number;
    public exists: boolean;
    public image: boolean;
    public fileInformation: TemporaryFileInformation;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class DemographicDetailForm
{
    public id?: number;
    public patientId?: number;
    public indigenousStatus?: number;
    public countryOfBirth?: number;
    public primaryLanguage?: number;
    public primaryLanguageAsChild?: number;
    public postCode?: number;
    public postCodeNA?: boolean;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<DemographicDetailForm>) { (Object as any).assign(this, init); }
}

export class EligibilityCriteriaForm
{
    public id?: number;
    public patientId?: number;
    public confirmedBy: string;
    public confirmedDate?: string;
    public ageGreaterEighteen?: number;
    public histologicalGBMOrIDH?: number;
    public mgmt?: number;
    public ecog?: number;
    public lifeExpectancyGreater12Weeks?: number;
    public adequateBoneMarrowReserver?: number;
    public capableOfChildbearing?: number;
    public complyStudyRequirements?: number;
    public writtenConsent?: number;
    public radiotherapySuitable?: number;
    public undergoingRadiotherapy?: number;
    public commencedChemoradiationLessThanSevenWeeksBeforeSurgery?: number;
    public suitableForAdjuvantTMZTherapy?: number;
    public implantedMedicalElectronicDevice?: number;
    public concurrentConditions?: number;
    public historyOfAnotherMalignancyWithin2Years?: number;
    public priorChemotherapyWithin2Years?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<EligibilityCriteriaForm>) { (Object as any).assign(this, init); }
}

export class GlioblastomaDetailForm
{
    public id?: number;
    public patientId?: number;
    public dateOfSurgeryForGBM?: string;
    public extentOfResections?: number;
    public isTissueAvailableForBiologicalSubStudy?: number;
    public idH1StatusFromIHC?: number;
    public plannedChemoRTStartDate?: string;
    public expectedChemoRTCompletion?: string;
    public plannedDoseOfTemozolomide?: number;
    public plannedDoseOfTemozolomideTotal?: number;
    public plannedTemozolomideFrequency?: number;
    public plannedOtherSystemicTreatment?: number;
    public plannedOtherSystemicTreatmentName: string;
    public plannedOtherSystemicTreatmentDose: string;
    public plannedOtherSystemicTreatmentRoute?: number;
    public plannedOtherSystemicTreatmentFrequency: string;
    public plannedRTPrescription?: number;
    public temozolomideStartDate?: string;
    public temozolomideStopDate?: string;
    public temozolomideOngoing?: boolean;
    public temozolomideDose?: number;
    public temozolomideDoseTotal?: number;
    public temozolomideFrequency?: number;
    public otherSystemicTreatment?: number;
    public otherSystemicTreatmentName: string;
    public otherSystemicTreatmentStartDate?: string;
    public otherSystemicTreatmentStopDate?: string;
    public otherSystemicTreatmentOngoing?: boolean;
    public otherSystemicTreatmentDose: string;
    public otherSystemicTreatmentRoute?: number;
    public otherSystemicTreatmentFrequency: string;
    public radiotherapyStartDate?: string;
    public radiotherapyStopDate?: string;
    public radiotherapyOngoing?: boolean;
    public radiotherapyPrescription?: number;
    public isAdjuvantChemotherapyPlannedUsingTMZ?: number;
    public plannedStartDate?: string;
    public participatingInAnyOtherTrial?: number;
    public participatingInAnyOtherTrialName: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GlioblastomaDetailForm>) { (Object as any).assign(this, init); }
}

export class PatientReportedForm
{
    public id?: number;
    public patientId?: number;
    public timepoint?: number;
    public assessmentDate?: string;
    public eortcqlqC30FormCompleted?: number;
    public requiredAssistanceCompletingQuestionnaire?: number;
    public requiredAssistanceCompletingQuestionnaireSpecify: string;
    public howWasQuestionnaireAdministered?: number;
    public howWasQuestionnaireAdministeredSpecify: string;
    public languageUsed?: number;
    public languageUsedSpecify: string;
    public appropriateReasonNonCompletion?: number;
    public participantsLanguage: string;
    public nonCompletionIllnessRelated?: number;
    public notes: string;
    public troubleDoingStrenuousActivities?: number;
    public troubleTakingALongWalk?: number;
    public troubleTakingShortWalkOutsideHouse?: number;
    public needToStayInBedOrChairDuringDay?: number;
    public helpEatingDressinWashingToilet?: number;
    public limitedDoingWorkDailyActivities?: number;
    public limitedPursuingHobbies?: number;
    public shortOfBreath?: number;
    public pain?: number;
    public needARest?: number;
    public troubleSleeping?: number;
    public feltWeak?: number;
    public lackedAppetite?: number;
    public feltNauseated?: number;
    public vomited?: number;
    public consipated?: number;
    public diarrhoea?: number;
    public tired?: number;
    public painInterferedDailyActivities?: number;
    public difficultyConcentrating?: number;
    public tense?: number;
    public worry?: number;
    public irritable?: number;
    public depressed?: number;
    public difficultyRememberingThings?: number;
    public interferedFamily?: number;
    public interferedSocial?: number;
    public financialDifficulties?: number;
    public overallHealth?: number;
    public overallQualityOfLife?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<PatientReportedForm>) { (Object as any).assign(this, init); }
}

export class SourceDocumentsForm
{
    public id?: number;
    public patientId?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentsForm>) { (Object as any).assign(this, init); }
}

export class AppSettings
{
    public buildMode: string;
    public reportServerUrl: string;
    public reportFolder: string;
    public googleAnalyticsId: string;
    public group2Enabled: string;

    public constructor(init?: Partial<AppSettings>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class LookupValue
{
    public id?: number;
    public value: string;
    public rank?: number;

    public constructor(init?: Partial<LookupValue>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public type: string;
    public propertyName: string;
    public values: LookupValue[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export interface IHasLookups
{
    lookups: Lookup[];
}

export enum RegistrationFormStatus
{
    Enabled = 1,
    Disabled = 2,
}

export class RegistrationFormState
{
    public id: number;
    public registrationFormId: number;
    public patientStatus?: PatientStatus;
    public formState?: RegistrationFormStatus;
    public validate?: boolean;
    public blockSave?: boolean;

    public constructor(init?: Partial<RegistrationFormState>) { (Object as any).assign(this, init); }
}

export class RegistrationFormProperty
{
    public id: number;
    public registrationFormId: number;
    public formName: string;
    public propertyName: string;
    public label: string;

    public constructor(init?: Partial<RegistrationFormProperty>) { (Object as any).assign(this, init); }
}

export class RegistrationFormSummary
{
    public formName: string;
    public formGroup: string;
    public formDisplayName: string;
    public totalQuestions?: number;
    public answeredQuestions?: number;
    public errors: ResponseError[];
    public ineligibleErrors: ResponseError[];
    public rank: number;

    public constructor(init?: Partial<RegistrationFormSummary>) { (Object as any).assign(this, init); }
}

export class Institution
{
    public institutionCode: string;
    public institutionName: string;
    public state: string;
    public country: string;
    public dateActivated?: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class Patient
{
    public id?: number;
    public temporaryNumber?: number;
    public registrationNumber: string;
    public institutionCode: string;
    public siteNumber?: number;
    public initials: string;
    public age?: number;
    public dateOfBirth?: string;
    public dateSubmitted?: string;
    public registrationDate?: string;
    public status?: PatientStatus;
    public previousStatus?: PatientStatus;
    public dateEntered?: string;
    public dateModified?: string;
    public waivered?: boolean;
    public registrationGroup?: number;
    public registrationGroupName: string;
    public enteredBy: string;
    public modifiedBy: string;
    public patientStatusConfigurations: PatientStatusConfiguration[];

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export class WaivedPatient
{
    public id?: number;
    public patientId?: number;
    public statusWaiveredAt?: PatientStatus;
    public reason: string;
    public dateEntered?: string;
    public dateModified?: string;
    public enteredBy: string;
    public modifiedBy: string;

    public constructor(init?: Partial<WaivedPatient>) { (Object as any).assign(this, init); }
}

export enum SaeStatus
{
    New = 1,
    AwaitingReview = 2,
    Approved = 3,
    Rejected = 4,
    IncorrectlyIdentified = 5,
}

export enum SaeWebActionType
{
    ApprovalNotRequired = 0,
    NotifiedInvestigator = 1,
    Approved = 2,
    Rejected = 3,
    NotifiedSiteTC = 4,
    Reset = 5,
}

export class SaeWebAction
{
    public id?: number;
    public saeId?: number;
    public actionTypeId?: SaeWebActionType;
    public username: string;
    public dateAction?: string;
    public notes: string;
    public recipients: string;

    public constructor(init?: Partial<SaeWebAction>) { (Object as any).assign(this, init); }
}

export class SaeLatestDataCumulative
{
    public saeId?: number;
    public saeNumber?: number;
    public reportingInvestigator: string;
    public onsetDate?: string;
    public ctcaeTerm: string;
    public ctcaeDescription: string;
    public ctcaeGrade: string;
    public ctcaeGradeDescription: string;
    public ctcaeCategory: string;
    public descriptionOfEvent: string;
    public reportingCriteria: string;
    public resultsInDeath: string;
    public lifeThreatening: string;
    public inPatientHospitalisation: string;
    public admissionDate?: string;
    public dischargeDate?: string;
    public dischargeDateOngoing: string;
    public disabilityIncapacity: string;
    public congenitalAnomalyBirthDefect: string;
    public importantMedicalEvent: string;
    public severityGrade: string;
    public relationshipFETRadiotracer: string;
    public studyTreatmentFETTracer: string;
    public progressiveDisease: string;
    public trialProcedure: string;
    public nameOfProcedure: string;
    public indicationOfProcedure: string;
    public procedureDate?: string;
    public concurrentDisorder: string;
    public concurrentDisorderSpecify: string;
    public concomitantMedication: string;
    public conComNameOfMedication: string;
    public conComIndication: string;
    public conComStartDate?: string;
    public conComStopDate?: string;
    public conComOnGoing?: number;
    public conComRoute: string;
    public conComDose: string;
    public conComUnit: string;
    public conComUnitOther: string;
    public conComFrequency: string;
    public conComFrequencyOther: string;
    public other: string;
    public otherSpecify: string;
    public reactionAbateAfterStopping: string;
    public reactionReappeared: string;
    public eventExpected: string;
    public eventDescription: string;
    public dateOfLastFETPET?: string;
    public doseReceived: string;
    public manufacturedBy: string;
    public modificationDueToEvent: string;
    public hasMedicalHistoryOrConditions: string;
    public eventInvestigativeProcedures: string;
    public prescribedMedications: string;
    public whatIsTheEventOutcome: string;
    public describeSequelae: string;
    public causeOfDeath: string;
    public dateOfAutopsy?: string;
    public autopsyNA?: number;
    public dateEventResolved?: string;
    public dateEntered?: string;
    public dateModified?: string;
    public institutionName: string;
    public institutionCode: string;

    public constructor(init?: Partial<SaeLatestDataCumulative>) { (Object as any).assign(this, init); }
}

export class SaeInitialData
{
    public saeId?: number;
    public saeNumber?: number;
    public reportingInvestigator: string;
    public onsetDate?: string;
    public ctcaeTerm: string;
    public ctcaeDescription: string;
    public ctcaeGrade: string;
    public ctcaeGradeDescription: string;
    public ctcaeCategory: string;
    public descriptionOfEvent: string;
    public reportingCriteria: string;
    public resultsInDeath: string;
    public lifeThreatening: string;
    public inPatientHospitalisation: string;
    public admissionDate?: string;
    public dischargeDate?: string;
    public dischargeDateOngoing: string;
    public disabilityIncapacity: string;
    public congenitalAnomalyBirthDefect: string;
    public importantMedicalEvent: string;
    public severityGrade: string;
    public relationshipFETRadiotracer: string;
    public studyTreatmentFETTracer: string;
    public progressiveDisease: string;
    public trialProcedure: string;
    public nameOfProcedure: string;
    public indicationOfProcedure: string;
    public procedureDate?: string;
    public concurrentDisorder: string;
    public concurrentDisorderSpecify: string;
    public concomitantMedication: string;
    public conComNameOfMedication: string;
    public conComIndication: string;
    public conComStartDate?: string;
    public conComStopDate?: string;
    public conComOnGoing?: number;
    public conComRoute: string;
    public conComDose: string;
    public conComUnit: string;
    public conComUnitOther: string;
    public conComFrequency: string;
    public conComFrequencyOther: string;
    public other: string;
    public otherSpecify: string;
    public reactionAbateAfterStopping: string;
    public reactionReappeared: string;
    public eventExpected: string;
    public eventDescription: string;
    public dateOfLastFETPET?: string;
    public doseReceived: string;
    public manufacturedBy: string;
    public modificationDueToEvent: string;
    public hasMedicalHistoryOrConditions: string;
    public eventInvestigativeProcedures: string;
    public prescribedMedications: string;
    public whatIsTheEventOutcome: string;
    public describeSequelae: string;
    public causeOfDeath: string;
    public dateOfAutopsy?: string;
    public autopsyNA?: number;
    public dateEventResolved?: string;
    public dateEntered?: string;
    public dateModified?: string;
    public institutionName: string;
    public institutionCode: string;

    public constructor(init?: Partial<SaeInitialData>) { (Object as any).assign(this, init); }
}

export class SaeLatestDataForm
{
    public saeId?: number;
    public saeType: string;
    public saeNumber?: number;
    public reportingInvestigator: string;
    public onsetDate?: string;
    public ctcaeTerm: string;
    public ctcaeDescription: string;
    public ctcaeGrade: string;
    public ctcaeGradeDescription: string;
    public ctcaeCategory: string;
    public descriptionOfEvent: string;
    public reportingCriteria: string;
    public resultsInDeath: string;
    public lifeThreatening: string;
    public inPatientHospitalisation: string;
    public admissionDate?: string;
    public dischargeDate?: string;
    public dischargeDateOngoing: string;
    public disabilityIncapacity: string;
    public congenitalAnomalyBirthDefect: string;
    public importantMedicalEvent: string;
    public severityGrade: string;
    public relationshipFETRadiotracer: string;
    public studyTreatmentFETTracer: string;
    public progressiveDisease: string;
    public trialProcedure: string;
    public nameOfProcedure: string;
    public indicationOfProcedure: string;
    public procedureDate?: string;
    public concurrentDisorder: string;
    public concurrentDisorderSpecify: string;
    public concomitantMedication: string;
    public conComNameOfMedication: string;
    public conComIndication: string;
    public conComStartDate?: string;
    public conComStopDate?: string;
    public conComOnGoing?: number;
    public conComRoute: string;
    public conComDose: string;
    public conComUnit: string;
    public conComUnitOther: string;
    public conComFrequency: string;
    public conComFrequencyOther: string;
    public other: string;
    public otherSpecify: string;
    public reactionAbateAfterStopping: string;
    public reactionReappeared: string;
    public eventExpected: string;
    public eventDescription: string;
    public dateOfLastFETPET?: string;
    public doseReceived: string;
    public manufacturedBy: string;
    public modificationDueToEvent: string;
    public hasMedicalHistoryOrConditions: string;
    public eventInvestigativeProcedures: string;
    public prescribedMedications: string;
    public whatIsTheEventOutcome: string;
    public describeSequelae: string;
    public causeOfDeath: string;
    public dateOfAutopsy?: string;
    public autopsyNA?: number;
    public dateEventResolved?: string;
    public dateEntered?: string;
    public dateModified?: string;
    public institutionName: string;
    public institutionCode: string;

    public constructor(init?: Partial<SaeLatestDataForm>) { (Object as any).assign(this, init); }
}

export class SaeMedicalHistoryData
{
    public saeSubTableName: string;
    public saeId?: number;
    public saeType: string;
    public latest?: boolean;
    public registrationNumber: string;
    public eventName: string;
    public eventRepeat?: number;
    public itemGroupRepeat?: number;
    public rowId?: number;
    public parentTable: string;
    public parentId?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public event: string;
    public eventStartDate: string;
    public eventStopDate: string;
    public eventOngoing?: number;

    public constructor(init?: Partial<SaeMedicalHistoryData>) { (Object as any).assign(this, init); }
}

export class SaeInvestigativeProcedureData
{
    public saeSubTableName: string;
    public saeId?: number;
    public saeType: string;
    public latest?: boolean;
    public registrationNumber: string;
    public eventRepeat?: number;
    public itemGroupRepeat?: number;
    public rowId?: number;
    public parentTable: string;
    public parentId?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public procedure: string;
    public procedureDate?: string;
    public procedureResult: string;
    public procedurePending?: number;

    public constructor(init?: Partial<SaeInvestigativeProcedureData>) { (Object as any).assign(this, init); }
}

export class SaeMedicationData
{
    public saeSubTableName: string;
    public saeId?: number;
    public saeType: string;
    public latest?: boolean;
    public registrationNumber: string;
    public eventName: string;
    public eventRepeat?: number;
    public itemGroupRepeat?: number;
    public rowId?: number;
    public parentTable: string;
    public parentId?: number;
    public dateEntered?: string;
    public dateModified?: string;
    public medicationName: string;
    public medicationType: string;
    public medicationIndication: string;
    public medicationStartDate?: string;
    public medicationStopDate?: string;
    public medicationOngoing: string;
    public medicationDose?: number;
    public medicationUnit: string;
    public medicationRoute: string;
    public medicationFrequency: string;

    public constructor(init?: Partial<SaeMedicationData>) { (Object as any).assign(this, init); }
}

export class Sae
{
    public saeId?: number;
    public randNo: string;
    public eventName: string;
    public eventRepeat?: number;
    public itemGroupRepeat?: number;
    public teamEmailRequired?: boolean;
    public investEmailRequired?: boolean;
    public approvalRequired?: boolean;
    public dateCreated?: string;
    public dateUpdated?: string;
    public teamEmailDateSent?: string;
    public siteCoordEmailRequired?: boolean;
    public siteCoordEmailDateSent?: string;
    public saeStatus?: SaeStatus;
    public isInitialSae?: boolean;
    public institutionCode: string;
    public institutionName: string;
    public saeWebActions: SaeWebAction[];
    public saeLatestDataCumulative: SaeLatestDataCumulative;
    public saeInitialData: SaeInitialData;
    public saeLatestDataForm: SaeLatestDataForm;
    public saeMedicalHistoryData: SaeMedicalHistoryData[];
    public saeInvestigativeProcedureData: SaeInvestigativeProcedureData[];
    public saeMedicationData: SaeMedicationData[];

    public constructor(init?: Partial<Sae>) { (Object as any).assign(this, init); }
}

export class User
{
    public userName: string;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public email: string;
    public trialId: string;
    public groups: string[];
    public institutions: string[];
    public roles: { [index: string]: string[]; };

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class Timepoint
{
    public id?: number;
    public name: string;
    public rank?: number;

    public constructor(init?: Partial<Timepoint>) { (Object as any).assign(this, init); }
}

export class TimepointDrug
{
    public id?: number;
    public timepointId?: number;
    public drugId?: number;
    public dose: number;
    public doseCalculationType: number;

    public constructor(init?: Partial<TimepointDrug>) { (Object as any).assign(this, init); }
}

export class TimepointWithDrugsList
{
    public id?: number;
    public name: string;
    public rank?: number;
    public timepointDrugs: TimepointDrug[];

    public constructor(init?: Partial<TimepointWithDrugsList>) { (Object as any).assign(this, init); }
}

export class GetAppSettingsResponse
{
    public appSettings: AppSettings;

    public constructor(init?: Partial<GetAppSettingsResponse>) { (Object as any).assign(this, init); }
}

export class GetPreRegistrationFormsResponse
{
    public responseStatus: ResponseStatus;
    public preRegistrationForms: PreRegistrationForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetPreRegistrationFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetPreRegistrationFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public preRegistrationForm: PreRegistrationForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetPreRegistrationFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPreRegistrationFormByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public preRegistrationForm: PreRegistrationForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetPreRegistrationFormByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPreRegistrationFormByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public preRegistrationForm: PreRegistrationForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetPreRegistrationFormByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class SetPreRegistrationFormResponse
{
    public responseStatus: ResponseStatus;
    public preRegistrationForm: PreRegistrationForm;
    public registrationFormState: RegistrationFormState;
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<SetPreRegistrationFormResponse>) { (Object as any).assign(this, init); }
}

export class CreatePreRegistrationFormResponse
{
    public responseStatus: ResponseStatus;
    public preRegistrationForm: PreRegistrationForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<CreatePreRegistrationFormResponse>) { (Object as any).assign(this, init); }
}

export class GetRegistrationFormSummariesByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public registrationFormSummaries: RegistrationFormSummary[];

    public constructor(init?: Partial<GetRegistrationFormSummariesByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionsResponse
{
    public responseStatus: ResponseStatus;
    public institutions: Institution[];

    public constructor(init?: Partial<GetInstitutionsResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionByInstitutionCodeResponse
{
    public responseStatus: ResponseStatus;
    public institution: Institution;

    public constructor(init?: Partial<GetInstitutionByInstitutionCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionsByCountryResponse
{
    public responseStatus: ResponseStatus;
    public institutions: Institution[];

    public constructor(init?: Partial<GetInstitutionsByCountryResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionsByStateResponse
{
    public responseStatus: ResponseStatus;
    public institutions: Institution[];

    public constructor(init?: Partial<GetInstitutionsByStateResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];

    public constructor(init?: Partial<GetPatientsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];

    public constructor(init?: Partial<GetPatientsByInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByStatusResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];

    public constructor(init?: Partial<GetPatientsByStatusResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientByIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;

    public constructor(init?: Partial<GetPatientByIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientByIdResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;

    public constructor(init?: Partial<GetPatientByIdResponse>) { (Object as any).assign(this, init); }
}

export class SetPatientStatusByIdResponse
{
    public responseStatus: ResponseStatus;
    public statusUpdated: boolean;

    public constructor(init?: Partial<SetPatientStatusByIdResponse>) { (Object as any).assign(this, init); }
}

export class WaivePatientByIdResponse
{
    public responseStatus: ResponseStatus;
    public waivedPatient: WaivedPatient;

    public constructor(init?: Partial<WaivePatientByIdResponse>) { (Object as any).assign(this, init); }
}

export class ResendEmailForPatientByIdResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ResendEmailForPatientByIdResponse>) { (Object as any).assign(this, init); }
}

export class ReExportPatientFormToOcResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ReExportPatientFormToOcResponse>) { (Object as any).assign(this, init); }
}

export class GetSaesResponse
{
    public responseStatus: ResponseStatus;
    public saes: Sae[];

    public constructor(init?: Partial<GetSaesResponse>) { (Object as any).assign(this, init); }
}

export class GetSaeSummariesResponse
{
    public responseStatus: ResponseStatus;
    public saeSummaries: Sae[];

    public constructor(init?: Partial<GetSaeSummariesResponse>) { (Object as any).assign(this, init); }
}

export class GetSaeBySaeIdResponse
{
    public responseStatus: ResponseStatus;
    public sae: Sae;

    public constructor(init?: Partial<GetSaeBySaeIdResponse>) { (Object as any).assign(this, init); }
}

export class GetSaesByInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public saes: Sae[];

    public constructor(init?: Partial<GetSaesByInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class ProcessSaeNotificationNotRequiredResponse
{
    public responseStatus: ResponseStatus;
    public sae: Sae;

    public constructor(init?: Partial<ProcessSaeNotificationNotRequiredResponse>) { (Object as any).assign(this, init); }
}

export class ProcessSaeResetResponse
{
    public responseStatus: ResponseStatus;
    public sae: Sae;

    public constructor(init?: Partial<ProcessSaeResetResponse>) { (Object as any).assign(this, init); }
}

export class ProcessSaeNotifyResponse
{
    public responseStatus: ResponseStatus;
    public sae: Sae;

    public constructor(init?: Partial<ProcessSaeNotifyResponse>) { (Object as any).assign(this, init); }
}

export class ProcessSaeReviewResponse
{
    public responseStatus: ResponseStatus;
    public sae: Sae;

    public constructor(init?: Partial<ProcessSaeReviewResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialUsersResponse
{
    public responseStatus: ResponseStatus;
    public trialUsers: TrialUser[];

    public constructor(init?: Partial<GetTrialUsersResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialUsersByInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public trialUsers: TrialUser[];

    public constructor(init?: Partial<GetTrialUsersByInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialUsersByRoleIdsResponse
{
    public responseStatus: ResponseStatus;
    public trialUsers: TrialUser[];

    public constructor(init?: Partial<GetTrialUsersByRoleIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialUsersByUsernameResponse
{
    public responseStatus: ResponseStatus;
    public trialUser: TrialUser;

    public constructor(init?: Partial<GetTrialUsersByUsernameResponse>) { (Object as any).assign(this, init); }
}

export class GetTrialUsersByInstitutionRoleIdsResponse
{
    public responseStatus: ResponseStatus;
    public trialUsers: TrialUser[];

    public constructor(init?: Partial<GetTrialUsersByInstitutionRoleIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public user: User;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

export class GetWebsiteSettingResponse
{
    public responseStatus: ResponseStatus;
    public websiteSetting: WebsiteSetting;

    public constructor(init?: Partial<GetWebsiteSettingResponse>) { (Object as any).assign(this, init); }
}

export class PostWebsiteSettingResponse
{
    public responseStatus: ResponseStatus;
    public websiteSetting: WebsiteSetting;

    public constructor(init?: Partial<PostWebsiteSettingResponse>) { (Object as any).assign(this, init); }
}

export class ClearCacheClientResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<ClearCacheClientResponse>) { (Object as any).assign(this, init); }
}

export class ReturnCacheClientResponse
{
    public responseStatus: ResponseStatus;
    public cacheValues: { [index: string]: Object; };

    public constructor(init?: Partial<ReturnCacheClientResponse>) { (Object as any).assign(this, init); }
}

export class GetTimepointsResponse
{
    public responseStatus: ResponseStatus;
    public timepoints: Timepoint[];

    public constructor(init?: Partial<GetTimepointsResponse>) { (Object as any).assign(this, init); }
}

export class GetTimepointsWithDrugListResponse
{
    public responseStatus: ResponseStatus;
    public timepoints: TimepointWithDrugsList[];

    public constructor(init?: Partial<GetTimepointsWithDrugListResponse>) { (Object as any).assign(this, init); }
}

export class HelloResponse
{
    public result: string;

    public constructor(init?: Partial<HelloResponse>) { (Object as any).assign(this, init); }
}

export class GetClinicalAssessmentFormsResponse
{
    public responseStatus: ResponseStatus;
    public clinicalAssessmentForms: ClinicalAssessmentForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetClinicalAssessmentFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetClinicalAssessmentFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public clinicalAssessmentForm: ClinicalAssessmentForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetClinicalAssessmentFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetClinicalAssessmentFormByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public clinicalAssessmentForm: ClinicalAssessmentForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetClinicalAssessmentFormByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetClinicalAssessmentFormByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public clinicalAssessmentForm: ClinicalAssessmentForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetClinicalAssessmentFormByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class SetClinicalAssessmentFormResponse
{
    public responseStatus: ResponseStatus;
    public clinicalAssessmentForm: ClinicalAssessmentForm;
    public registrationFormState: RegistrationFormState;
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<SetClinicalAssessmentFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateClinicalAssessmentFormResponse
{
    public responseStatus: ResponseStatus;
    public clinicalAssessmentForm: ClinicalAssessmentForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<CreateClinicalAssessmentFormResponse>) { (Object as any).assign(this, init); }
}

export class GetDemographicDetailFormsResponse
{
    public responseStatus: ResponseStatus;
    public demographicDetailForms: DemographicDetailForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetDemographicDetailFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetDemographicDetailFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public demographicDetailForm: DemographicDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetDemographicDetailFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDemographicDetailFormByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public demographicDetailForm: DemographicDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetDemographicDetailFormByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDemographicDetailFormByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public demographicDetailForm: DemographicDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetDemographicDetailFormByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class SetDemographicDetailFormResponse
{
    public responseStatus: ResponseStatus;
    public demographicDetailForm: DemographicDetailForm;
    public registrationFormState: RegistrationFormState;
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<SetDemographicDetailFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateDemographicDetailFormResponse
{
    public responseStatus: ResponseStatus;
    public demographicDetailForm: DemographicDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<CreateDemographicDetailFormResponse>) { (Object as any).assign(this, init); }
}

export class GetEligibilityCriteriaFormsResponse
{
    public responseStatus: ResponseStatus;
    public eligibilityCriteriaForms: EligibilityCriteriaForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetEligibilityCriteriaFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetEligibilityCriteriaFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public eligibilityCriteriaForm: EligibilityCriteriaForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetEligibilityCriteriaFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetEligibilityCriteriaFormByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public eligibilityCriteriaForm: EligibilityCriteriaForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetEligibilityCriteriaFormByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetEligibilityCriteriaFormByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public eligibilityCriteriaForm: EligibilityCriteriaForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetEligibilityCriteriaFormByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class SetEligibilityCriteriaFormResponse
{
    public responseStatus: ResponseStatus;
    public eligibilityCriteriaForm: EligibilityCriteriaForm;
    public registrationFormState: RegistrationFormState;
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<SetEligibilityCriteriaFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateEligibilityCriteriaFormResponse
{
    public responseStatus: ResponseStatus;
    public eligibilityCriteriaForm: EligibilityCriteriaForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<CreateEligibilityCriteriaFormResponse>) { (Object as any).assign(this, init); }
}

export class GetGlioblastomaDetailFormsResponse
{
    public responseStatus: ResponseStatus;
    public glioblastomaDetailForms: GlioblastomaDetailForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetGlioblastomaDetailFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetGlioblastomaDetailFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public glioblastomaDetailForm: GlioblastomaDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetGlioblastomaDetailFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetGlioblastomaDetailFormByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public glioblastomaDetailForm: GlioblastomaDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetGlioblastomaDetailFormByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetGlioblastomaDetailFormByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public glioblastomaDetailForm: GlioblastomaDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetGlioblastomaDetailFormByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class SetGlioblastomaDetailFormResponse
{
    public responseStatus: ResponseStatus;
    public glioblastomaDetailForm: GlioblastomaDetailForm;
    public registrationFormState: RegistrationFormState;
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<SetGlioblastomaDetailFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateGlioblastomaDetailFormResponse
{
    public responseStatus: ResponseStatus;
    public glioblastomaDetailForm: GlioblastomaDetailForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<CreateGlioblastomaDetailFormResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientReportedFormsResponse
{
    public responseStatus: ResponseStatus;
    public patientReportedForms: PatientReportedForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetPatientReportedFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientReportedFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public patientReportedForm: PatientReportedForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetPatientReportedFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientReportedFormByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public patientReportedForm: PatientReportedForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetPatientReportedFormByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientReportedFormByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public patientReportedForm: PatientReportedForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetPatientReportedFormByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class SetPatientReportedFormResponse
{
    public responseStatus: ResponseStatus;
    public patientReportedForm: PatientReportedForm;
    public registrationFormState: RegistrationFormState;
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<SetPatientReportedFormResponse>) { (Object as any).assign(this, init); }
}

export class CreatePatientReportedFormResponse
{
    public responseStatus: ResponseStatus;
    public patientReportedForm: PatientReportedForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<CreatePatientReportedFormResponse>) { (Object as any).assign(this, init); }
}

export class GetSourceDocumentsFormsResponse
{
    public responseStatus: ResponseStatus;
    public sourceDocumentsForms: SourceDocumentsForm[];
    public lookups: Lookup[];

    public constructor(init?: Partial<GetSourceDocumentsFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetSourceDocumentsFormByIdResponse
{
    public responseStatus: ResponseStatus;
    public sourceDocumentsForm: SourceDocumentsForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetSourceDocumentsFormByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetSourceDocumentsFormByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public sourceDocumentsForm: SourceDocumentsForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetSourceDocumentsFormByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetSourceDocumentsFormByPatientIdentifierResponse
{
    public responseStatus: ResponseStatus;
    public sourceDocumentsForm: SourceDocumentsForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<GetSourceDocumentsFormByPatientIdentifierResponse>) { (Object as any).assign(this, init); }
}

export class SetSourceDocumentsFormResponse
{
    public responseStatus: ResponseStatus;
    public sourceDocumentsForm: SourceDocumentsForm;
    public registrationFormState: RegistrationFormState;
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<SetSourceDocumentsFormResponse>) { (Object as any).assign(this, init); }
}

export class CreateSourceDocumentsFormResponse
{
    public responseStatus: ResponseStatus;
    public sourceDocumentsForm: SourceDocumentsForm;
    public registrationFormState: RegistrationFormState;
    public lookups: Lookup[];
    public registrationFormProperties: RegistrationFormProperty[];

    public constructor(init?: Partial<CreateSourceDocumentsFormResponse>) { (Object as any).assign(this, init); }
}

export class UploadTempFileResponse
{
    public responseStatus: ResponseStatus;
    public guid: string;

    public constructor(init?: Partial<UploadTempFileResponse>) { (Object as any).assign(this, init); }
}

export class GetTempFileInfoResponse
{
    public responseStatus: ResponseStatus;
    public temporaryFile: TemporaryFile;

    public constructor(init?: Partial<GetTempFileInfoResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @Route("/login", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetLoginRedirect
{
    /**
    * The URL that the login redirect was requested from.
    */
    // @ApiMember(DataType="string", Description="The URL that the login redirect was requested from.", Name="ReferrerUrl", ParameterType="query")
    public referrerUrl: string;

    public constructor(init?: Partial<GetLoginRedirect>) { (Object as any).assign(this, init); }
}

// @Route("/logout", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetLogoutRedirect
{

    public constructor(init?: Partial<GetLogoutRedirect>) { (Object as any).assign(this, init); }
}

// @Route("/appsettings")
export class GetAppSettings implements IReturn<GetAppSettingsResponse>
{

    public constructor(init?: Partial<GetAppSettings>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAppSettingsResponse(); }
    public getTypeName() { return 'GetAppSettings'; }
}

// @Route("/appsettings.js")
export class GetAppSettingsJs
{

    public constructor(init?: Partial<GetAppSettingsJs>) { (Object as any).assign(this, init); }
}

// @Route("/api/pre-registration-forms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPreRegistrationForms implements IReturn<GetPreRegistrationFormsResponse>
{

    public constructor(init?: Partial<GetPreRegistrationForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPreRegistrationFormsResponse(); }
    public getTypeName() { return 'GetPreRegistrationForms'; }
}

// @Route("/api/pre-registration-form/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPreRegistrationFormById implements IReturn<GetPreRegistrationFormByIdResponse>
{
    /**
    * The id of the PreRegistration form
    */
    // @ApiMember(DataType="Integer", Description="The id of the PreRegistration form", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPreRegistrationFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPreRegistrationFormByIdResponse(); }
    public getTypeName() { return 'GetPreRegistrationFormById'; }
}

// @Route("/api/pre-registration-form/patient/id/{PatientId}", "GET")
// @ApiResponse(Description="Patient Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPreRegistrationFormByPatientId implements IReturn<GetPreRegistrationFormByPatientIdResponse>
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="integer", Description="The id of the patient", Format="int", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetPreRegistrationFormByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPreRegistrationFormByPatientIdResponse(); }
    public getTypeName() { return 'GetPreRegistrationFormByPatientId'; }
}

// @Route("/api/pre-registration-form/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPreRegistrationFormByPatientIdentifier implements IReturn<GetPreRegistrationFormByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetPreRegistrationFormByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPreRegistrationFormByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetPreRegistrationFormByPatientIdentifier'; }
}

// @Route("/api/pre-registration-form/save/{InstitutionCode}/{PatientIdentifier}", "POST")
// @Route("/api/pre-registration-form/save/{InstitutionCode}", "POST")
// @ApiResponse(Description="Id, institution code, patient identifier or form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPreRegistrationForm implements IReturn<SetPreRegistrationFormResponse>
{
    /**
    * The Pre-registration Form data to save
    */
    // @ApiMember(DataType="PreRegistrationForm", Description="The Pre-registration Form data to save", IsRequired=true, Name="preRegistrationForm", ParameterType="body")
    public preRegistrationForm: PreRegistrationForm;

    /**
    * The Institution Code
    */
    // @ApiMember(DataType="string", Description="The Institution Code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", Name="PatientIdentifier", ParameterType="path")
    public patientIdentifier: string;

    public constructor(init?: Partial<SetPreRegistrationForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetPreRegistrationFormResponse(); }
    public getTypeName() { return 'SetPreRegistrationForm'; }
}

// @Route("/api/pre-registration-form/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreatePreRegistrationForm implements IReturn<CreatePreRegistrationFormResponse>
{

    public constructor(init?: Partial<CreatePreRegistrationForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePreRegistrationFormResponse(); }
    public getTypeName() { return 'CreatePreRegistrationForm'; }
}

// @Route("/api/registration-form-summaries/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetRegistrationFormSummariesByPatientIdentifier implements IReturn<GetRegistrationFormSummariesByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient (site number, registration number, etc)
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient (site number, registration number, etc)", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetRegistrationFormSummariesByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRegistrationFormSummariesByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetRegistrationFormSummariesByPatientIdentifier'; }
}

// @Route("/api/institutions", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutions implements IReturn<GetInstitutionsResponse>
{

    public constructor(init?: Partial<GetInstitutions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionsResponse(); }
    public getTypeName() { return 'GetInstitutions'; }
}

// @Route("/api/institution/institutionCode/{InstitutionCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionByInstitutionCode implements IReturn<GetInstitutionByInstitutionCodeResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetInstitutionByInstitutionCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionByInstitutionCodeResponse(); }
    public getTypeName() { return 'GetInstitutionByInstitutionCode'; }
}

// @Route("/api/institutions/country/{Country}", "GET")
// @ApiResponse(Description="Status invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionsByCountry implements IReturn<GetInstitutionsByCountryResponse>
{
    /**
    * The country the institution belongs to
    */
    // @ApiMember(DataType="string", Description="The country the institution belongs to", IsRequired=true, Name="Country", ParameterType="path")
    public country: string;

    public constructor(init?: Partial<GetInstitutionsByCountry>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionsByCountryResponse(); }
    public getTypeName() { return 'GetInstitutionsByCountry'; }
}

// @Route("/api/institutions/state/{State}", "GET")
// @ApiResponse(Description="State number invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetInstitutionsByState implements IReturn<GetInstitutionsByStateResponse>
{
    /**
    * The country state of the institutions
    */
    // @ApiMember(DataType="string", Description="The country state of the institutions", Format="string", IsRequired=true, Name="State", ParameterType="path")
    public state: string;

    public constructor(init?: Partial<GetInstitutionsByState>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstitutionsByStateResponse(); }
    public getTypeName() { return 'GetInstitutionsByState'; }
}

// @Route("/api/patients", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatients implements IReturn<GetPatientsResponse>
{

    public constructor(init?: Partial<GetPatients>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientsResponse(); }
    public getTypeName() { return 'GetPatients'; }
}

// @Route("/api/patients/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientsByInstitution implements IReturn<GetPatientsByInstitutionResponse>
{
    /**
    * The institution of the patients
    */
    // @ApiMember(DataType="string", Description="The institution of the patients", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetPatientsByInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientsByInstitutionResponse(); }
    public getTypeName() { return 'GetPatientsByInstitution'; }
}

// @Route("/api/patients/status/{Status}", "GET")
// @ApiResponse(Description="Status invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientsByStatus implements IReturn<GetPatientsByStatusResponse>
{
    /**
    * The status of the patients
    */
    // @ApiMember(DataType="string", Description="The status of the patients", IsRequired=true, Name="Status", ParameterType="path")
    public status: PatientStatus;

    public constructor(init?: Partial<GetPatientsByStatus>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientsByStatusResponse(); }
    public getTypeName() { return 'GetPatientsByStatus'; }
}

// @Route("/api/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Registration number invalid", StatusCode=400)
// @ApiResponse(Description="Patient not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientByIdentifier implements IReturn<GetPatientByIdentifierResponse>
{
    /**
    * The patient identifier (registration number or temporary number)
    */
    // @ApiMember(DataType="string", Description="The patient identifier (registration number or temporary number)", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetPatientByIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientByIdentifierResponse(); }
    public getTypeName() { return 'GetPatientByIdentifier'; }
}

// @Route("/api/patient/id/{Id}", "GET")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Patient not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientById implements IReturn<GetPatientByIdResponse>, IGet
{
    /**
    * The primary key Id of the patient
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the patient", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPatientById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientByIdResponse(); }
    public getTypeName() { return 'GetPatientById'; }
}

// @Route("/api/patient/id/{Id}/status/set-status/{Status}", "POST")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Patient not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPatientStatusById implements IReturn<SetPatientStatusByIdResponse>
{
    /**
    * The primary key Id of the patient
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the patient", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * The status of the patients
    */
    // @ApiMember(DataType="string", Description="The status of the patients", IsRequired=true, Name="Status", ParameterType="path")
    public status: PatientStatus;

    public patientStatusConfiguration: PatientStatusConfiguration;

    public constructor(init?: Partial<SetPatientStatusById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetPatientStatusByIdResponse(); }
    public getTypeName() { return 'SetPatientStatusById'; }
}

// @Route("/api/patient/id/{Id}/waive", "POST")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class WaivePatientById implements IReturn<WaivePatientByIdResponse>
{
    /**
    * The primary key Id of the patient
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the patient", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    /**
    * Flag representing if the patient is to be registered during the waiver process
    */
    // @ApiMember(DataType="boolean", Description="Flag representing if the patient is to be registered during the waiver process", IsRequired=true, Name="RegisterAfterWaive", ParameterType="path")
    public registerAfterWaive: boolean;

    public constructor(init?: Partial<WaivePatientById>) { (Object as any).assign(this, init); }
    public createResponse() { return new WaivePatientByIdResponse(); }
    public getTypeName() { return 'WaivePatientById'; }
}

// @Route("/api/patient/id/{Id}/resendemail", "POST")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ResendEmailForPatientById implements IReturn<ResendEmailForPatientByIdResponse>
{
    /**
    * The primary key Id of the patient
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the patient", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<ResendEmailForPatientById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ResendEmailForPatientByIdResponse(); }
    public getTypeName() { return 'ResendEmailForPatientById'; }
}

// @Route("/api/patient/id/{patientId}/form/{formId}", "POST")
// @ApiResponse(Description="Id is invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ReExportPatientFormToOc implements IReturn<ReExportPatientFormToOcResponse>
{
    /**
    * The primary key Id of the patient
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the patient", IsRequired=true, Name="patientId", ParameterType="path")
    public patientId: number;

    /**
    * The primary key Id of the form in the mapping table
    */
    // @ApiMember(DataType="string", Description="The primary key Id of the form in the mapping table", IsRequired=true, Name="formId", ParameterType="path")
    public formId: number;

    public constructor(init?: Partial<ReExportPatientFormToOc>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReExportPatientFormToOcResponse(); }
    public getTypeName() { return 'ReExportPatientFormToOc'; }
}

// @Route("/api/saes", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSaes implements IReturn<GetSaesResponse>
{

    public constructor(init?: Partial<GetSaes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSaesResponse(); }
    public getTypeName() { return 'GetSaes'; }
}

// @Route("/api/sae/summaries", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSaeSummaries implements IReturn<GetSaeSummariesResponse>
{

    public constructor(init?: Partial<GetSaeSummaries>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSaeSummariesResponse(); }
    public getTypeName() { return 'GetSaeSummaries'; }
}

// @Route("/api/sae/{SaeId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSaeBySaeId implements IReturn<GetSaeBySaeIdResponse>
{
    /**
    * The Sae id
    */
    // @ApiMember(DataType="integer", Description="The Sae id", Format="int", IsRequired=true, Name="SaeId", ParameterType="path")
    public saeId: number;

    public constructor(init?: Partial<GetSaeBySaeId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSaeBySaeIdResponse(); }
    public getTypeName() { return 'GetSaeBySaeId'; }
}

// @Route("/api/saes/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSaesByInstitution implements IReturn<GetSaesByInstitutionResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetSaesByInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSaesByInstitutionResponse(); }
    public getTypeName() { return 'GetSaesByInstitution'; }
}

// @Route("/api/sae/notification-not-required/{InstitutionCode}/{SaeId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ProcessSaeNotificationNotRequired implements IReturn<ProcessSaeNotificationNotRequiredResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The Sae id
    */
    // @ApiMember(DataType="integer", Description="The Sae id", Format="int", IsRequired=true, Name="SaeId", ParameterType="path")
    public saeId: number;

    public constructor(init?: Partial<ProcessSaeNotificationNotRequired>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProcessSaeNotificationNotRequiredResponse(); }
    public getTypeName() { return 'ProcessSaeNotificationNotRequired'; }
}

// @Route("/api/sae/process-reset/{InstitutionCode}/{SaeId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ProcessSaeReset implements IReturn<ProcessSaeResetResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The Sae id
    */
    // @ApiMember(DataType="integer", Description="The Sae id", Format="int", IsRequired=true, Name="SaeId", ParameterType="path")
    public saeId: number;

    public constructor(init?: Partial<ProcessSaeReset>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProcessSaeResetResponse(); }
    public getTypeName() { return 'ProcessSaeReset'; }
}

// @Route("/api/sae/process-notify/{SaeNotifyFormData}/{InstitutionCode}/{SaeId}/{TrialUserType}", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ProcessSaeNotify implements IReturn<ProcessSaeNotifyResponse>
{
    /**
    * The form data submitted by the user
    */
    // @ApiMember(DataType="SaeNotifyFormData", Description="The form data submitted by the user", IsRequired=true, Name="SaeNotifyFormData", ParameterType="body")
    public saeNotifyFormData: SaeNotifyFormData;

    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The Sae id
    */
    // @ApiMember(DataType="integer", Description="The Sae id", Format="int", IsRequired=true, Name="SaeId", ParameterType="path")
    public saeId: number;

    /**
    * The type of trial users to receive the notification
    */
    // @ApiMember(DataType="string", Description="The type of trial users to receive the notification", IsRequired=true, Name="TrialUserType", ParameterType="path")
    public trialUserType: TrialUserType;

    public constructor(init?: Partial<ProcessSaeNotify>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProcessSaeNotifyResponse(); }
    public getTypeName() { return 'ProcessSaeNotify'; }
}

// @Route("/api/sae/process-review/{SaeReviewFormData}/{InstitutionCode}/{SaeId}/{SaeReviewType}", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ProcessSaeReview implements IReturn<ProcessSaeReviewResponse>
{
    /**
    * The form data submitted by the user
    */
    // @ApiMember(DataType="SaeReviewFormData", Description="The form data submitted by the user", IsRequired=true, Name="SaeReviewFormData", ParameterType="body")
    public saeReviewFormData: SaeReviewFormData;

    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The Sae id
    */
    // @ApiMember(DataType="integer", Description="The Sae id", Format="int", IsRequired=true, Name="SaeId", ParameterType="path")
    public saeId: number;

    /**
    * The review type being processed
    */
    // @ApiMember(DataType="string", Description="The review type being processed", IsRequired=true, Name="SaeReviewType", ParameterType="path")
    public saeReviewType: SaeReviewType;

    public constructor(init?: Partial<ProcessSaeReview>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProcessSaeReviewResponse(); }
    public getTypeName() { return 'ProcessSaeReview'; }
}

// @Route("/api/trial-users", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialUsers implements IReturn<GetTrialUsersResponse>
{

    public constructor(init?: Partial<GetTrialUsers>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialUsersResponse(); }
    public getTypeName() { return 'GetTrialUsers'; }
}

// @Route("/api/trial-users/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialUsersByInstitution implements IReturn<GetTrialUsersByInstitutionResponse>
{
    /**
    * The institution of the TrialUsers
    */
    // @ApiMember(DataType="string", Description="The institution of the TrialUsers", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetTrialUsersByInstitution>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialUsersByInstitutionResponse(); }
    public getTypeName() { return 'GetTrialUsersByInstitution'; }
}

// @Route("/api/trial-users/roles/{RoleIds}", "GET")
// @ApiResponse(Description="Status invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialUsersByRoleIds implements IReturn<GetTrialUsersByRoleIdsResponse>
{
    /**
    * List of role ids
    */
    // @ApiMember(DataType="array", Description="List of role ids", IsRequired=true, Name="RoleIds", ParameterType="path")
    public roleIds: number[];

    public constructor(init?: Partial<GetTrialUsersByRoleIds>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialUsersByRoleIdsResponse(); }
    public getTypeName() { return 'GetTrialUsersByRoleIds'; }
}

// @Route("/api/trial-user/username/{Username}", "GET")
// @ApiResponse(Description="Registration number invalid", StatusCode=400)
// @ApiResponse(Description="TrialUser not found", StatusCode=404)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialUsersByUsername implements IReturn<GetTrialUsersByUsernameResponse>
{
    /**
    * The TrialUser Username
    */
    // @ApiMember(DataType="string", Description="The TrialUser Username", IsRequired=true, Name="Username", ParameterType="path")
    public username: string;

    public constructor(init?: Partial<GetTrialUsersByUsername>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialUsersByUsernameResponse(); }
    public getTypeName() { return 'GetTrialUsersByUsername'; }
}

// @Route("/api/trial-users/institution/{InstitutionCode}/roles/{RoleIds}", "GET")
// @ApiResponse(Description="Status invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTrialUsersByInstitutionRoleIds implements IReturn<GetTrialUsersByInstitutionRoleIdsResponse>
{
    /**
    * The institution of the TrialUsers
    */
    // @ApiMember(DataType="string", Description="The institution of the TrialUsers", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * List of role ids
    */
    // @ApiMember(DataType="array", Description="List of role ids", IsRequired=true, Name="RoleIds", ParameterType="path")
    public roleIds: number[];

    public constructor(init?: Partial<GetTrialUsersByInstitutionRoleIds>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTrialUsersByInstitutionRoleIdsResponse(); }
    public getTypeName() { return 'GetTrialUsersByInstitutionRoleIds'; }
}

// @Route("/api/user", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetUser implements IReturn<GetUserResponse>
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserResponse(); }
    public getTypeName() { return 'GetUser'; }
}

// @Route("/api/websitesetting", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetWebsiteSetting implements IReturn<GetWebsiteSettingResponse>
{

    public constructor(init?: Partial<GetWebsiteSetting>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetWebsiteSettingResponse(); }
    public getTypeName() { return 'GetWebsiteSetting'; }
}

// @Route("/api/websitesetting", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class PostWebsiteSetting implements IReturn<PostWebsiteSettingResponse>
{
    public websiteSetting: WebsiteSetting;

    public constructor(init?: Partial<PostWebsiteSetting>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostWebsiteSettingResponse(); }
    public getTypeName() { return 'PostWebsiteSetting'; }
}

// @Route("/api/utils/clear-cache-client", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ClearCacheClient implements IReturn<ClearCacheClientResponse>
{

    public constructor(init?: Partial<ClearCacheClient>) { (Object as any).assign(this, init); }
    public createResponse() { return new ClearCacheClientResponse(); }
    public getTypeName() { return 'ClearCacheClient'; }
}

// @Route("/api/utils/return-cache-client", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ReturnCacheClient implements IReturn<ReturnCacheClientResponse>
{

    public constructor(init?: Partial<ReturnCacheClient>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReturnCacheClientResponse(); }
    public getTypeName() { return 'ReturnCacheClient'; }
}

// @Route("/api/timepoints", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepoints implements IReturn<GetTimepointsResponse>
{

    public constructor(init?: Partial<GetTimepoints>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTimepointsResponse(); }
    public getTypeName() { return 'GetTimepoints'; }
}

// @Route("/api/timepoints/drugslist", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepointsWithDrugList implements IReturn<GetTimepointsWithDrugListResponse>
{

    public constructor(init?: Partial<GetTimepointsWithDrugList>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTimepointsWithDrugListResponse(); }
    public getTypeName() { return 'GetTimepointsWithDrugList'; }
}

// @Route("/api/template/{EmailTemplate}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTemplate
{
    /**
    * The Email Template enumeration
    */
    // @ApiMember(DataType="string", Description="The Email Template enumeration", IsRequired=true, Name="EmailTemplate", ParameterType="path")
    public emailTemplate: EmailTemplate;

    public constructor(init?: Partial<GetTemplate>) { (Object as any).assign(this, init); }
}

// @Route("/hello")
// @Route("/hello/{Name}")
export class Hello implements IReturn<HelloResponse>
{
    public name: string;

    public constructor(init?: Partial<Hello>) { (Object as any).assign(this, init); }
    public createResponse() { return new HelloResponse(); }
    public getTypeName() { return 'Hello'; }
}

// @Route("/api/clinical-assessment", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetClinicalAssessmentForms implements IReturn<GetClinicalAssessmentFormsResponse>
{

    public constructor(init?: Partial<GetClinicalAssessmentForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClinicalAssessmentFormsResponse(); }
    public getTypeName() { return 'GetClinicalAssessmentForms'; }
}

// @Route("/api/clinical-assessment/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetClinicalAssessmentFormById implements IReturn<GetClinicalAssessmentFormByIdResponse>
{
    /**
    * The id of the ClinicalAssessment Form
    */
    // @ApiMember(DataType="Integer", Description="The id of the ClinicalAssessment Form", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetClinicalAssessmentFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClinicalAssessmentFormByIdResponse(); }
    public getTypeName() { return 'GetClinicalAssessmentFormById'; }
}

// @Route("/api/clinical-assessment/patient/id/{PatientId}", "GET")
// @ApiResponse(Description="Patient Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetClinicalAssessmentFormByPatientId implements IReturn<GetClinicalAssessmentFormByPatientIdResponse>
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="integer", Description="The id of the patient", Format="int", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetClinicalAssessmentFormByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClinicalAssessmentFormByPatientIdResponse(); }
    public getTypeName() { return 'GetClinicalAssessmentFormByPatientId'; }
}

// @Route("/api/clinical-assessment/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetClinicalAssessmentFormByPatientIdentifier implements IReturn<GetClinicalAssessmentFormByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetClinicalAssessmentFormByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClinicalAssessmentFormByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetClinicalAssessmentFormByPatientIdentifier'; }
}

// @Route("/api/clinical-assessment/save/{InstitutionCode}/{PatientIdentifier}", "POST")
// @Route("/api/clinical-assessment/save/{InstitutionCode}", "POST")
// @ApiResponse(Description="Id, institution code, patient identifier or form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetClinicalAssessmentForm implements IReturn<SetClinicalAssessmentFormResponse>
{
    /**
    * The ClinicalAssessment data to save
    */
    // @ApiMember(DataType="ClinicalAssessmentForm", Description="The ClinicalAssessment data to save", IsRequired=true, Name="ClinicalAssessmentForm", ParameterType="body")
    public clinicalAssessmentForm: ClinicalAssessmentForm;

    /**
    * The Institution Code
    */
    // @ApiMember(DataType="string", Description="The Institution Code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", Name="PatientIdentifier", ParameterType="path")
    public patientIdentifier: string;

    public constructor(init?: Partial<SetClinicalAssessmentForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetClinicalAssessmentFormResponse(); }
    public getTypeName() { return 'SetClinicalAssessmentForm'; }
}

// @Route("/api/clinical-assessment/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateClinicalAssessmentForm implements IReturn<CreateClinicalAssessmentFormResponse>
{

    public constructor(init?: Partial<CreateClinicalAssessmentForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateClinicalAssessmentFormResponse(); }
    public getTypeName() { return 'CreateClinicalAssessmentForm'; }
}

// @Route("/api/demographic-detail-form", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDemographicDetailForms implements IReturn<GetDemographicDetailFormsResponse>
{

    public constructor(init?: Partial<GetDemographicDetailForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDemographicDetailFormsResponse(); }
    public getTypeName() { return 'GetDemographicDetailForms'; }
}

// @Route("/api/demographic-detail-form/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDemographicDetailFormById implements IReturn<GetDemographicDetailFormByIdResponse>
{
    /**
    * The id of the DemographicDetail Form
    */
    // @ApiMember(DataType="Integer", Description="The id of the DemographicDetail Form", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDemographicDetailFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDemographicDetailFormByIdResponse(); }
    public getTypeName() { return 'GetDemographicDetailFormById'; }
}

// @Route("/api/demographic-detail-form/patient/id/{PatientId}", "GET")
// @ApiResponse(Description="Patient Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDemographicDetailFormByPatientId implements IReturn<GetDemographicDetailFormByPatientIdResponse>
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="integer", Description="The id of the patient", Format="int", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetDemographicDetailFormByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDemographicDetailFormByPatientIdResponse(); }
    public getTypeName() { return 'GetDemographicDetailFormByPatientId'; }
}

// @Route("/api/demographic-detail-form/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDemographicDetailFormByPatientIdentifier implements IReturn<GetDemographicDetailFormByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetDemographicDetailFormByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDemographicDetailFormByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetDemographicDetailFormByPatientIdentifier'; }
}

// @Route("/api/demographic-detail-form/save/{InstitutionCode}/{PatientIdentifier}", "POST")
// @Route("/api/demographic-detail-form/save/{InstitutionCode}", "POST")
// @ApiResponse(Description="Id, institution code, patient identifier or form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDemographicDetailForm implements IReturn<SetDemographicDetailFormResponse>
{
    /**
    * The DemographicDetail data to save
    */
    // @ApiMember(DataType="DemographicDetailForm", Description="The DemographicDetail data to save", IsRequired=true, Name="DemographicDetailForm", ParameterType="body")
    public demographicDetailForm: DemographicDetailForm;

    /**
    * The Institution Code
    */
    // @ApiMember(DataType="string", Description="The Institution Code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", Name="PatientIdentifier", ParameterType="path")
    public patientIdentifier: string;

    public constructor(init?: Partial<SetDemographicDetailForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetDemographicDetailFormResponse(); }
    public getTypeName() { return 'SetDemographicDetailForm'; }
}

// @Route("/api/demographic-detail-form/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateDemographicDetailForm implements IReturn<CreateDemographicDetailFormResponse>
{

    public constructor(init?: Partial<CreateDemographicDetailForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDemographicDetailFormResponse(); }
    public getTypeName() { return 'CreateDemographicDetailForm'; }
}

// @Route("/api/eligibility-criteria-form", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetEligibilityCriteriaForms implements IReturn<GetEligibilityCriteriaFormsResponse>
{

    public constructor(init?: Partial<GetEligibilityCriteriaForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEligibilityCriteriaFormsResponse(); }
    public getTypeName() { return 'GetEligibilityCriteriaForms'; }
}

// @Route("/api/eligibility-criteria-form/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetEligibilityCriteriaFormById implements IReturn<GetEligibilityCriteriaFormByIdResponse>
{
    /**
    * The id of the EligibilityCriteria Form
    */
    // @ApiMember(DataType="Integer", Description="The id of the EligibilityCriteria Form", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetEligibilityCriteriaFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEligibilityCriteriaFormByIdResponse(); }
    public getTypeName() { return 'GetEligibilityCriteriaFormById'; }
}

// @Route("/api/eligibility-criteria-form/patient/id/{PatientId}", "GET")
// @ApiResponse(Description="Patient Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetEligibilityCriteriaFormByPatientId implements IReturn<GetEligibilityCriteriaFormByPatientIdResponse>
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="integer", Description="The id of the patient", Format="int", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetEligibilityCriteriaFormByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEligibilityCriteriaFormByPatientIdResponse(); }
    public getTypeName() { return 'GetEligibilityCriteriaFormByPatientId'; }
}

// @Route("/api/eligibility-criteria-form/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetEligibilityCriteriaFormByPatientIdentifier implements IReturn<GetEligibilityCriteriaFormByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetEligibilityCriteriaFormByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEligibilityCriteriaFormByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetEligibilityCriteriaFormByPatientIdentifier'; }
}

// @Route("/api/eligibility-criteria-form/save/{InstitutionCode}/{PatientIdentifier}", "POST")
// @Route("/api/eligibility-criteria-form/save/{InstitutionCode}", "POST")
// @ApiResponse(Description="Id, institution code, patient identifier or form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetEligibilityCriteriaForm implements IReturn<SetEligibilityCriteriaFormResponse>
{
    /**
    * The EligibilityCriteria data to save
    */
    // @ApiMember(DataType="EligibilityCriteriaForm", Description="The EligibilityCriteria data to save", IsRequired=true, Name="EligibilityCriteriaForm", ParameterType="body")
    public eligibilityCriteriaForm: EligibilityCriteriaForm;

    /**
    * The Institution Code
    */
    // @ApiMember(DataType="string", Description="The Institution Code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", Name="PatientIdentifier", ParameterType="path")
    public patientIdentifier: string;

    public constructor(init?: Partial<SetEligibilityCriteriaForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetEligibilityCriteriaFormResponse(); }
    public getTypeName() { return 'SetEligibilityCriteriaForm'; }
}

// @Route("/api/eligibility-criteria-form/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateEligibilityCriteriaForm implements IReturn<CreateEligibilityCriteriaFormResponse>
{

    public constructor(init?: Partial<CreateEligibilityCriteriaForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateEligibilityCriteriaFormResponse(); }
    public getTypeName() { return 'CreateEligibilityCriteriaForm'; }
}

// @Route("/api/glioblastoma-detail-form", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGlioblastomaDetailForms implements IReturn<GetGlioblastomaDetailFormsResponse>
{

    public constructor(init?: Partial<GetGlioblastomaDetailForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGlioblastomaDetailFormsResponse(); }
    public getTypeName() { return 'GetGlioblastomaDetailForms'; }
}

// @Route("/api/glioblastoma-detail-form/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGlioblastomaDetailFormById implements IReturn<GetGlioblastomaDetailFormByIdResponse>
{
    /**
    * The id of the GlioblastomaDetail Form
    */
    // @ApiMember(DataType="Integer", Description="The id of the GlioblastomaDetail Form", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetGlioblastomaDetailFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGlioblastomaDetailFormByIdResponse(); }
    public getTypeName() { return 'GetGlioblastomaDetailFormById'; }
}

// @Route("/api/glioblastoma-detail-form/patient/id/{PatientId}", "GET")
// @ApiResponse(Description="Patient Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGlioblastomaDetailFormByPatientId implements IReturn<GetGlioblastomaDetailFormByPatientIdResponse>
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="integer", Description="The id of the patient", Format="int", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetGlioblastomaDetailFormByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGlioblastomaDetailFormByPatientIdResponse(); }
    public getTypeName() { return 'GetGlioblastomaDetailFormByPatientId'; }
}

// @Route("/api/glioblastoma-detail-form/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGlioblastomaDetailFormByPatientIdentifier implements IReturn<GetGlioblastomaDetailFormByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetGlioblastomaDetailFormByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGlioblastomaDetailFormByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetGlioblastomaDetailFormByPatientIdentifier'; }
}

// @Route("/api/glioblastoma-detail-form/save/{InstitutionCode}/{PatientIdentifier}", "POST")
// @Route("/api/glioblastoma-detail-form/save/{InstitutionCode}", "POST")
// @ApiResponse(Description="Id, institution code, patient identifier or form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetGlioblastomaDetailForm implements IReturn<SetGlioblastomaDetailFormResponse>
{
    /**
    * The GlioblastomaDetail data to save
    */
    // @ApiMember(DataType="GlioblastomaDetailForm", Description="The GlioblastomaDetail data to save", IsRequired=true, Name="GlioblastomaDetailForm", ParameterType="body")
    public glioblastomaDetailForm: GlioblastomaDetailForm;

    /**
    * The Institution Code
    */
    // @ApiMember(DataType="string", Description="The Institution Code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", Name="PatientIdentifier", ParameterType="path")
    public patientIdentifier: string;

    public constructor(init?: Partial<SetGlioblastomaDetailForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetGlioblastomaDetailFormResponse(); }
    public getTypeName() { return 'SetGlioblastomaDetailForm'; }
}

// @Route("/api/glioblastoma-detail-form/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateGlioblastomaDetailForm implements IReturn<CreateGlioblastomaDetailFormResponse>
{

    public constructor(init?: Partial<CreateGlioblastomaDetailForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGlioblastomaDetailFormResponse(); }
    public getTypeName() { return 'CreateGlioblastomaDetailForm'; }
}

// @Route("/api/patient-reported-form", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientReportedForms implements IReturn<GetPatientReportedFormsResponse>
{

    public constructor(init?: Partial<GetPatientReportedForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientReportedFormsResponse(); }
    public getTypeName() { return 'GetPatientReportedForms'; }
}

// @Route("/api/patient-reported-form/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientReportedFormById implements IReturn<GetPatientReportedFormByIdResponse>
{
    /**
    * The id of the PatientReported Form
    */
    // @ApiMember(DataType="Integer", Description="The id of the PatientReported Form", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPatientReportedFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientReportedFormByIdResponse(); }
    public getTypeName() { return 'GetPatientReportedFormById'; }
}

// @Route("/api/patient-reported-form/patient/id/{PatientId}", "GET")
// @ApiResponse(Description="Patient Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientReportedFormByPatientId implements IReturn<GetPatientReportedFormByPatientIdResponse>
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="integer", Description="The id of the patient", Format="int", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetPatientReportedFormByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientReportedFormByPatientIdResponse(); }
    public getTypeName() { return 'GetPatientReportedFormByPatientId'; }
}

// @Route("/api/patient-reported-form/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientReportedFormByPatientIdentifier implements IReturn<GetPatientReportedFormByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetPatientReportedFormByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatientReportedFormByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetPatientReportedFormByPatientIdentifier'; }
}

// @Route("/api/patient-reported-form/save/{InstitutionCode}/{PatientIdentifier}", "POST")
// @Route("/api/patient-reported-form/save/{InstitutionCode}", "POST")
// @ApiResponse(Description="Id, institution code, patient identifier or form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPatientReportedForm implements IReturn<SetPatientReportedFormResponse>
{
    /**
    * The PatientReported data to save
    */
    // @ApiMember(DataType="PatientReportedForm", Description="The PatientReported data to save", IsRequired=true, Name="PatientReportedForm", ParameterType="body")
    public patientReportedForm: PatientReportedForm;

    /**
    * The Institution Code
    */
    // @ApiMember(DataType="string", Description="The Institution Code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", Name="PatientIdentifier", ParameterType="path")
    public patientIdentifier: string;

    public constructor(init?: Partial<SetPatientReportedForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetPatientReportedFormResponse(); }
    public getTypeName() { return 'SetPatientReportedForm'; }
}

// @Route("/api/patient-reported-form/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreatePatientReportedForm implements IReturn<CreatePatientReportedFormResponse>
{

    public constructor(init?: Partial<CreatePatientReportedForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePatientReportedFormResponse(); }
    public getTypeName() { return 'CreatePatientReportedForm'; }
}

// @Route("/api/source-documents-forms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSourceDocumentsForms implements IReturn<GetSourceDocumentsFormsResponse>
{

    public constructor(init?: Partial<GetSourceDocumentsForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSourceDocumentsFormsResponse(); }
    public getTypeName() { return 'GetSourceDocumentsForms'; }
}

// @Route("/api/source-documents-form/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSourceDocumentsFormById implements IReturn<GetSourceDocumentsFormByIdResponse>
{
    /**
    * The id of the SourceDocuments Form
    */
    // @ApiMember(DataType="Integer", Description="The id of the SourceDocuments Form", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetSourceDocumentsFormById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSourceDocumentsFormByIdResponse(); }
    public getTypeName() { return 'GetSourceDocumentsFormById'; }
}

// @Route("/api/source-documents-form/patient/id/{PatientId}", "GET")
// @ApiResponse(Description="Patient Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSourceDocumentsFormByPatientId implements IReturn<GetSourceDocumentsFormByPatientIdResponse>
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="integer", Description="The id of the patient", Format="int", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetSourceDocumentsFormByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSourceDocumentsFormByPatientIdResponse(); }
    public getTypeName() { return 'GetSourceDocumentsFormByPatientId'; }
}

// @Route("/api/source-documents-form/patient/identifier/{Identifier}", "GET")
// @ApiResponse(Description="Patient identifier invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSourceDocumentsFormByPatientIdentifier implements IReturn<GetSourceDocumentsFormByPatientIdentifierResponse>
{
    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", IsRequired=true, Name="Identifier", ParameterType="path")
    public identifier: string;

    public constructor(init?: Partial<GetSourceDocumentsFormByPatientIdentifier>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSourceDocumentsFormByPatientIdentifierResponse(); }
    public getTypeName() { return 'GetSourceDocumentsFormByPatientIdentifier'; }
}

// @Route("/api/source-documents-form/save/{InstitutionCode}/{PatientIdentifier}", "POST")
// @Route("/api/source-documents-form/save/{InstitutionCode}", "POST")
// @ApiResponse(Description="Id, institution code, patient identifier or form data invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetSourceDocumentsForm implements IReturn<SetSourceDocumentsFormResponse>
{
    /**
    * The Source Documents Form data to save
    */
    // @ApiMember(DataType="SourceDocumentsForm", Description="The Source Documents Form data to save", IsRequired=true, Name="SourceDocumentsForm", ParameterType="body")
    public sourceDocumentsForm: SourceDocumentsForm;

    /**
    * The Institution Code
    */
    // @ApiMember(DataType="string", Description="The Institution Code", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    /**
    * The identifier of the patient
    */
    // @ApiMember(DataType="string", Description="The identifier of the patient", Name="PatientIdentifier", ParameterType="path")
    public patientIdentifier: string;

    public constructor(init?: Partial<SetSourceDocumentsForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetSourceDocumentsFormResponse(); }
    public getTypeName() { return 'SetSourceDocumentsForm'; }
}

// @Route("/api/source-documents-form/create", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateSourceDocumentsForm implements IReturn<CreateSourceDocumentsFormResponse>
{

    public constructor(init?: Partial<CreateSourceDocumentsForm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSourceDocumentsFormResponse(); }
    public getTypeName() { return 'CreateSourceDocumentsForm'; }
}

// @Route("/api/file/temp/upload", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class UploadTempFile implements IReturn<UploadTempFileResponse>
{
    public json: boolean;

    public constructor(init?: Partial<UploadTempFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadTempFileResponse(); }
    public getTypeName() { return 'UploadTempFile'; }
}

// @Route("/api/file/temp/info/{Guid}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTempFileInfo implements IReturn<GetTempFileInfoResponse>
{
    /**
    * The Guid (name) of the temporary file to retrieve
    */
    // @ApiMember(DataType="string", Description="The Guid (name) of the temporary file to retrieve", IsRequired=true, Name="Guid", ParameterType="path")
    public guid: string;

    public constructor(init?: Partial<GetTempFileInfo>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTempFileInfoResponse(); }
    public getTypeName() { return 'GetTempFileInfo'; }
}

// @Route("/api/file/temp/get/{Guid}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTempFile
{
    /**
    * The Guid (name) of the temporary file to retrieve
    */
    // @ApiMember(DataType="string", Description="The Guid (name) of the temporary file to retrieve", IsRequired=true, Name="Guid", ParameterType="path")
    public guid: string;

    public constructor(init?: Partial<GetTempFile>) { (Object as any).assign(this, init); }
}

// @Route("/source-document/{Id}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSourceDocumentFile
{
    /**
    * The Id of the source document
    */
    // @ApiMember(DataType="integer", Description="The Id of the source document", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetSourceDocumentFile>) { (Object as any).assign(this, init); }
}

// @Route("/source-document/download/{Id}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class DownloadSourceDocumentFile
{
    /**
    * The Id of the source document
    */
    // @ApiMember(DataType="integer", Description="The Id of the source document", Format="int", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<DownloadSourceDocumentFile>) { (Object as any).assign(this, init); }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

