import './ListGroup.scss';

import * as React from 'react';

interface IListGroupProps {

}

class ListGroup extends React.PureComponent<IListGroupProps, any> {

    render() {
        const {
            children
        } = this.props

        return <div className="list-group">
            {children}
        </div>
    }
}
export default ListGroup