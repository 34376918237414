import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Fig.dtos";

export const timepointApi = {
    getTimepoints: (): Observable<Dtos.GetTimepointsResponse> => {
        //Create the request.
        let request: Dtos.GetTimepoints = new Dtos.GetTimepoints();

        //Send request.
        const response: Promise<Dtos.GetTimepointsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getTimepointsWithDrugs: (): Observable<Dtos.GetTimepointsWithDrugListResponse> => {
        //Create the request.
        let request: Dtos.GetTimepointsWithDrugList = new Dtos.GetTimepointsWithDrugList();

        //Send request.
        const response: Promise<Dtos.GetTimepointsWithDrugListResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}