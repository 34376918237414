import * as FigDtos from "../../../dtos/Fig.dtos";

export const
    toggleNew: ISaePageToggleNewActionCreator = () => {
        return {
            type: ACTION_SAE_PAGE_TOGGLE_NEW,
        }
    },

    toggleAwaitingReview: ISaePageToggleAwaitingReviewActionCreator = () => {
        return {
            type: ACTION_SAE_PAGE_TOGGLE_AWAITING_REVIEW,
        }
    },

    toggleApproved: ISaePageToggleApprovedActionCreator = () => {
        return {
            type: ACTION_SAE_PAGE_TOGGLE_APPROVED,
        }
    },

    toggleRejected: ISaePageToggleRejectedActionCreator = () => {
        return {
            type: ACTION_SAE_PAGE_TOGGLE_REJECTED,
        }
    },

    toggleIncorrectlyIdentified: ISaePageToggleIncorrectlyIdentifiedActionCreator = () => {
        return {
            type: ACTION_SAE_PAGE_TOGGLE_INCORRECTLY_IDENTIFIED,
        }
    },

    updateCountry: ISaePageUpdateCountryActionCreator = (country: string) => {
        return {
            type: ACTION_SAE_PAGE_UPDATE_COUNTRY,
            country
        }

    }

export const
    ACTION_SAE_PAGE_TOGGLE_NEW = "ACTION_SAE_PAGE_TOGGLE_NEW",
    ACTION_SAE_PAGE_TOGGLE_AWAITING_REVIEW = "ACTION_SAE_PAGE_TOGGLE_AWAITING_REVIEW",
    ACTION_SAE_PAGE_TOGGLE_APPROVED = "ACTION_SAE_PAGE_TOGGLE_APPROVED",
    ACTION_SAE_PAGE_TOGGLE_REJECTED = "ACTION_SAE_PAGE_TOGGLE_REJECTED",
    ACTION_SAE_PAGE_TOGGLE_INCORRECTLY_IDENTIFIED = "ACTION_SAE_PAGE_TOGGLE_INCORRECTLY_IDENTIFIED",
    ACTION_SAE_PAGE_UPDATE_INSTITUTION_CODE = "ACTION_SAE_PAGE_UPDATE_INSTITUTION_CODE",
    ACTION_SAE_PAGE_UPDATE_COUNTRY = "ACTION_SAE_PAGE_UPDATE_COUNTRY"

/* Actions */

export interface ISaePageToggleNewAction {
    type: "ACTION_SAE_PAGE_TOGGLE_NEW";
}

export interface ISaePageToggleAwaitingReviewAction {
    type: "ACTION_SAE_PAGE_TOGGLE_AWAITING_REVIEW";
}

export interface ISaePageToggleApprovedAction {
    type: "ACTION_SAE_PAGE_TOGGLE_APPROVED";
}

export interface ISaePageToggleRejectedAction {
    type: "ACTION_SAE_PAGE_TOGGLE_REJECTED";
}

export interface ISaePageToggleIncorrectlyIdentifiedAction {
    type: "ACTION_SAE_PAGE_TOGGLE_INCORRECTLY_IDENTIFIED";
}

export interface ISaePageUpdateCountryAction {
    type: "ACTION_SAE_PAGE_UPDATE_COUNTRY",
    country: string
}

/* Action Creators */

export interface ISaePageToggleNewActionCreator {
    (): ISaePageToggleNewAction;
}

export interface ISaePageToggleAwaitingReviewActionCreator {
    (): ISaePageToggleAwaitingReviewAction;
}

export interface ISaePageToggleApprovedActionCreator {
    (): ISaePageToggleApprovedAction;
}

export interface ISaePageToggleRejectedActionCreator {
    (): ISaePageToggleRejectedAction;
}

export interface ISaePageToggleIncorrectlyIdentifiedActionCreator {
    (): ISaePageToggleIncorrectlyIdentifiedAction;
}

export interface ISaePageUpdateCountryActionCreator {
    (country: string): ISaePageUpdateCountryAction
}

/* Final Export of actions */
export type SaePageAction = ISaePageToggleNewAction |
    ISaePageToggleAwaitingReviewAction | 
    ISaePageToggleApprovedAction | 
    ISaePageToggleRejectedAction | 
    ISaePageToggleIncorrectlyIdentifiedAction |
    ISaePageUpdateCountryAction;
