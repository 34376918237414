import * as FigDtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export interface INotifyTrialUsers {
    [index: string]: boolean;
}

export interface INotifyTrialUserFormState {
    usersToNotify: INotifyTrialUsers;
    notesToReturn: string; 
}

export const initialNotifyTrialUsersFormState: INotifyTrialUserFormState = {
    usersToNotify: null,
    notesToReturn: null,
}

export interface INotifyTrialUserReduxFormState {
    usersToNotify: IFieldState<ITrialUserReduxFormState>;
    notesToReturn: IFieldState<string>;
}

export interface ITrialUserReduxFormState {
    [index: string]: IFieldState<boolean>
}
