import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_SUCCESS,
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_FAILURE,
    ACTION_REGISTRATIONFORMSUMMARIES_CLEAR,
    IRegistrationFormSummariesClearAction,
    IRegistrationFormSummariesLoadFailureAction,
    IRegistrationFormSummariesLoadSuccessAction,
    IRegistrationFormSummariesLoadByPatientIdentifierAction,
    LoadSuccess,
    LoadFailure
    
} from "../actions/registrationFormSummary";
import { registrationFormSummaryApi } from "../services/registrationFormSummary";


export const
    getRegistrationFormSummariesByPatientIdentifierEpic = (action$): Observable<IRegistrationFormSummariesLoadSuccessAction | IRegistrationFormSummariesLoadFailureAction> => {
        return action$
            .ofType(ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER)
            .mergeMap((action: IRegistrationFormSummariesLoadByPatientIdentifierAction) => {
                return registrationFormSummaryApi
                    .getRegistrationformSummariesByPatientIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.registrationFormSummaries)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    }
    

