import * as FigDtos from "../../../dtos/Fig.dtos";

export const
    toggleTreatmentData: ISaeViewPageToggleTreatmentDataActionCreator = () => {
        return {
            type: ACTION_SAE_VIEW_PAGE_TOGGLE_TREATMENT,
        }
    },

    toggleStudyDrugData: ISaeViewPageToggleStudyDrugDataActionCreator = () => {
        return {
            type: ACTION_SAE_VIEW_PAGE_TOGGLE_STUDY_DRUG,
        }
    },

    toggleMedicationData: ISaeViewPageToggleMedicationDataActionCreator = () => {
        return {
            type: ACTION_SAE_VIEW_PAGE_TOGGLE_MEDICATION,
        }
    },

    toggleActionHistoryData: ISaeViewPageToggleActionHistoryDataActionCreator = () => {
        return {
            type: ACTION_SAE_VIEW_PAGE_TOGGLE_ACTION_HISTORY,
        }
    },
    clearSaeViewPage: ISaeViewPageClearActionCreator = () => {
        return {
            type: ACTION_SAE_VIEW_PAGE_CLEAR
        }
    }

export const
    ACTION_SAE_VIEW_PAGE_TOGGLE_TREATMENT = "ACTION_SAE_VIEW_PAGE_TOGGLE_TREATMENT",
    ACTION_SAE_VIEW_PAGE_TOGGLE_STUDY_DRUG = "ACTION_SAE_VIEW_PAGE_TOGGLE_STUDY_DRUG",
    ACTION_SAE_VIEW_PAGE_TOGGLE_MEDICATION = "ACTION_SAE_VIEW_PAGE_TOGGLE_MEDICATION",
    ACTION_SAE_VIEW_PAGE_TOGGLE_ACTION_HISTORY = "ACTION_SAE_VIEW_PAGE_TOGGLE_ACTION_HISTORY",
    ACTION_SAE_VIEW_PAGE_CLEAR = "ACTION_SAE_VIEW_PAGE_CLEAR"

/* Actions */

export interface ISaeViewPageToggleTreatmentDataAction {
    type: "ACTION_SAE_VIEW_PAGE_TOGGLE_TREATMENT";
}

export interface ISaeViewPageToggleStudyDrugDataAction {
    type: "ACTION_SAE_VIEW_PAGE_TOGGLE_STUDY_DRUG";
}

export interface ISaeViewPageToggleMedicationDataAction {
    type: "ACTION_SAE_VIEW_PAGE_TOGGLE_MEDICATION";
}

export interface ISaeViewPageToggleActionHistoryDataAction {
    type: "ACTION_SAE_VIEW_PAGE_TOGGLE_ACTION_HISTORY";
}

export interface ISaeViewPageClearAction {
    type: "ACTION_SAE_VIEW_PAGE_CLEAR",
}

/* Action Creators */

export interface ISaeViewPageToggleTreatmentDataActionCreator {
    (): ISaeViewPageToggleTreatmentDataAction;
}

export interface ISaeViewPageToggleStudyDrugDataActionCreator {
    (): ISaeViewPageToggleStudyDrugDataAction;
}

export interface ISaeViewPageToggleMedicationDataActionCreator {
    (): ISaeViewPageToggleMedicationDataAction;
}

export interface ISaeViewPageToggleActionHistoryDataActionCreator {
    (): ISaeViewPageToggleActionHistoryDataAction;
}

export interface ISaeViewPageClearActionCreator {
    (): ISaeViewPageClearAction;
}


/* Final Export of actions */
export type SaeViewPageAction = ISaeViewPageToggleTreatmentDataAction |
    ISaeViewPageToggleStudyDrugDataAction |
    ISaeViewPageToggleMedicationDataAction |
    ISaeViewPageToggleActionHistoryDataAction | 
    ISaeViewPageClearAction;
