import './RadioButtonSetFormInput.scss';

import * as React from "react";
import classNames from "classnames";
import { IBaseFormInputProps } from '../';
import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";


export interface IRadioButtonSetFormInputProps {
    options: { label: string, value: string, children: any, extraClass?: string }[];
    additionalOptions: React.ReactNode;
    noSelection: number;
    radioActiveClassName: string;
    feedbackIcon?: string
}

class RadioButtonSetFormInput extends React.Component<IRadioButtonSetFormInputProps & IBaseFormInputProps, any> {

    render() {

        const {
            className,
            label,
            name,
            id,
            onKeyPress,
            onBlur,
            onChange,
            onFocus,
            value,
            disabled,
            valid,
            invalid,
            options,
            noSelection,
            additionalOptions,
            radioActiveClassName,
            feedbackIcon

        } = this.props;

        const derivedRadioClassName = className ? className : "btn btn-outline-input btn-radio mb-4"
        const derivedRadioActiveClassName = radioActiveClassName ?
            radioActiveClassName :
            className ?
                className + " active" :
                "btn btn-outline-primary btn-radio mb-4 active";
        const derivedGroupClassName = "btn-group d-flex flex-wrap justify-content-between align-items-stretch form-radio-group-default"

        return (<div className={
            classNames(
                "form-radio-group",
                derivedGroupClassName,

            )}>
            {
                options ?
                    options.map((option) => {
                        let inputId: string = id + "_" + option.value;
                        let selected: boolean = value == option.value && !noSelection;
                        let btnSelectedClass: string = classNames(className, "active");

                        let extraClass: string = "";

                        if (option.extraClass) {
                            extraClass = option.extraClass;
                        }

                        return <label
                            key={inputId + "_button"}
                            htmlFor={inputId}
                            className={classNames(
                                { [derivedRadioClassName]: !selected },
                                { "is-invalid": invalid && !disabled },
                                { [derivedRadioActiveClassName]: selected },
                                { "disabled": disabled }
                            )}
                        >
                            <input
                                className={classNames(derivedRadioClassName)}
                                type="radio"
                                key={inputId}
                                name={name}
                                id={inputId} checked={selected}
                                style={{ position: "absolute", zIndex: -100, opacity: 0 }}
                                onChange={(event) => { onChange(option.value) }}
                                // @ts-ignore
                                onKeyPress={(event) => { onKeyPress(option.value) }}
                                // eslint-disable-next-line
                                onFocus={(event) => { onFocus }}
                                // eslint-disable-next-line
                                onBlur={(event) => { onBlur }}
                                disabled={disabled}
                            />
                            {option.label}
                            {option.children}
                            {
                                invalid && !disabled ?
                                    <span className={classNames("form-control-feedback pr-2")}>
                                        <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
                                    </span> :
                                    null
                            }
                        </label>
                    }) :
                    null
            }
            {additionalOptions}
        </div>);
    }
}

export default RadioButtonSetFormInput;