import { client } from "../../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../../dtos/Fig.dtos";

export const clinicalAssessmentFormApi = {
    getClinicalAssessmentForms: (): Observable<Dtos.GetClinicalAssessmentFormsResponse> => {
        //Create the request.
        let request: Dtos.GetClinicalAssessmentForms = new Dtos.GetClinicalAssessmentForms();

        //Send request.
        const response: Promise<Dtos.GetClinicalAssessmentFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getClinicalAssessmentFormById: (id: number): Observable<Dtos.GetClinicalAssessmentFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetClinicalAssessmentFormById = new Dtos.GetClinicalAssessmentFormById();

        request.id = id;
        //Send request.
        const response: Promise<Dtos.GetClinicalAssessmentFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getClinicalAssessmentFormByPatientId: (patientId: number): Observable<Dtos.GetClinicalAssessmentFormByPatientIdResponse> => {
        //Create the request.
        let request: Dtos.GetClinicalAssessmentFormByPatientId = new Dtos.GetClinicalAssessmentFormByPatientId();

        request.patientId = patientId;

        //Send request.
        const response: Promise<Dtos.GetClinicalAssessmentFormByPatientIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getClinicalAssessmentFormByPatientIdentifier: (identifier: string): Observable<Dtos.GetClinicalAssessmentFormByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetClinicalAssessmentFormByPatientIdentifier = new Dtos.GetClinicalAssessmentFormByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetClinicalAssessmentFormByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setClinicalAssessmentForm: (ClinicalAssessmentForm: Dtos.ClinicalAssessmentForm,
        institutionCode: string,
        patientIdentifier: string): Observable<Dtos.SetClinicalAssessmentFormResponse> => {

        let request: Dtos.SetClinicalAssessmentForm = new Dtos.SetClinicalAssessmentForm();

        request.clinicalAssessmentForm = ClinicalAssessmentForm;
        request.institutionCode = institutionCode;
        request.patientIdentifier = patientIdentifier;

        // send request
        const response: Promise<Dtos.SetClinicalAssessmentFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createClinicalAssessmentForm: (): Observable<Dtos.CreateClinicalAssessmentFormResponse> => {
        //Create the request.
        let request: Dtos.CreateClinicalAssessmentForm = new Dtos.CreateClinicalAssessmentForm();

        //Send request.
        const response: Promise<Dtos.CreateClinicalAssessmentFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}