import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';

import {
    ACTION_PATIENTREPORTEDFORMS_CLEAR,
    ACTION_PATIENTREPORTEDFORMS_LOAD,
    ACTION_PATIENTREPORTEDFORMS_LOAD_BY_ID,
    ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_ID,
    ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_PATIENTREPORTEDFORMS_LOAD_FAILURE,
    ACTION_PATIENTREPORTEDFORMS_LOAD_SUCCESS,

    ACTION_PATIENTREPORTEDFORMS_SAVE,
    ACTION_PATIENTREPORTEDFORMS_SAVE_FAILURE,
    ACTION_PATIENTREPORTEDFORMS_SAVE_SUCCESS,

    ACTION_PATIENTREPORTEDFORMS_CREATE,
    ACTION_PATIENTREPORTEDFORMS_CREATE_SUCCESS,
    ACTION_PATIENTREPORTEDFORMS_CREATE_FAILURE,

    IPatientReportedFormLoadAction,
    IPatientReportedFormClearAction,
    IPatientReportedFormLoadByIdAction,
    IPatientReportedFormLoadByPatientIdAction,
    IPatientReportedFormLoadByPatientIdentifierAction,
    IPatientReportedFormLoadFailureAction,
    IPatientReportedFormLoadSuccessAction,

    IPatientReportedFormSaveAction,
    IPatientReportedFormSaveSuccessAction,
    IPatientReportedFormSaveFailureAction,

    IPatientReportedFormCreateAction,
    IPatientReportedFormCreateSuccessAction,
    IPatientReportedFormCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure


} from "../../actions/forms/patientReportedForm";

import { patientReportedFormApi } from "../../services/Forms/patientReportedForm";


export const
    getPatientReportedFormsEpic = (action$): Observable<IPatientReportedFormLoadSuccessAction | IPatientReportedFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTREPORTEDFORMS_LOAD)
            .mergeMap((action: IPatientReportedFormLoadAction) => {
                return patientReportedFormApi
                    .getPatientReportedForms()
                    .map(response =>
                        LoadSuccess(response.patientReportedForms, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientReportedFormsByIdEpic = (action$): Observable<IPatientReportedFormLoadSuccessAction | IPatientReportedFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTREPORTEDFORMS_LOAD_BY_ID)
            .mergeMap((action: IPatientReportedFormLoadByIdAction) => {
                return patientReportedFormApi
                    .getPatientReportedFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.patientReportedForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientReportedFormsByPatientIdEpic = (action$): Observable<IPatientReportedFormLoadSuccessAction | IPatientReportedFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_ID)
            .mergeMap((action: IPatientReportedFormLoadByPatientIdAction) => {
                return patientReportedFormApi
                    .getPatientReportedFormByPatientId(action.patientId)
                    .map(response =>
                        LoadSuccess(response.patientReportedForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientReportedFormsByPatientIdentifierEpic = (action$): Observable<IPatientReportedFormLoadSuccessAction | IPatientReportedFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTREPORTEDFORMS_LOAD_BY_PATIENT_IDENTIFIER)
            .mergeMap((action: IPatientReportedFormLoadByPatientIdentifierAction) => {
                return patientReportedFormApi
                    .getPatientReportedFormByPatientIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.patientReportedForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setPatientReportedFormsEpic = (action$): Observable<IPatientReportedFormSaveSuccessAction | IPatientReportedFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTREPORTEDFORMS_SAVE)
            .mergeMap((action: IPatientReportedFormSaveAction) => {
                return patientReportedFormApi
                    .setPatientReportedForm(action.formData, action.institutionCode, action.patientIdentifier)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.patientReportedForm, response.registrationFormState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createPatientReportedFormsEpic = (action$): Observable<IPatientReportedFormCreateSuccessAction | IPatientReportedFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTREPORTEDFORMS_CREATE)
            .mergeMap((action: IPatientReportedFormCreateAction) => {
                return patientReportedFormApi
                    .createPatientReportedForm()
                    .map(response =>
                        CreateSuccess(response.patientReportedForm, response.registrationFormState, response.registrationFormProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }





