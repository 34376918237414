import * as Dtos from "../../dtos/Fig.dtos";

export const
    LoadDemographicDetailForms: IDemographicDetailFormLoadActionCreator = () => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_LOAD
        }
    },
    LoadDemographicDetailFormById: IDemographicDetailFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID,
            id
        }
    },
    LoadDemographicDetailFormByPatientId: IDemographicDetailFormLoadByPatientIdActionCreator = (patientId) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID,
            patientId
        }
    },
    LoadDemographicDetailFormByPatientIdentifier: IDemographicDetailFormLoadByPatientIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: IDemographicDetailFormLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus

        }
    },
    LoadFailure: IDemographicDetailFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveDemographicDetailForm: IDemographicDetailFormSaveActionCreator = (formData, institutionCode, patientIdentifier) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_SAVE,
            formData,
            institutionCode,
            patientIdentifier
        }
    },

    SaveSuccess: IDemographicDetailFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },

    SaveFailure: IDemographicDetailFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateDemographicDetailForm: IDemographicDetailFormCreateActionCreator = () => {

        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_CREATE
        }
    },

    CreateSuccess: IDemographicDetailFormCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IDemographicDetailFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IDemographicDetailFormClearActionCreator = () => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORMS_CLEAR
        }
    };


export const
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD = "ACTION_DEMOGRAPHICDETAILFORMS_LOAD",
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID = "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID",
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID = "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID",
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_SUCCESS = "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_SUCCESS",
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_FAILURE = "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_FAILURE",

    ACTION_DEMOGRAPHICDETAILFORMS_SAVE = "ACTION_DEMOGRAPHICDETAILFORMS_SAVE",
    ACTION_DEMOGRAPHICDETAILFORMS_SAVE_SUCCESS = "ACTION_DEMOGRAPHICDETAILFORMS_SAVE_SUCCESS",
    ACTION_DEMOGRAPHICDETAILFORMS_SAVE_FAILURE = "ACTION_DEMOGRAPHICDETAILFORMS_SAVE_FAILURE",

    ACTION_DEMOGRAPHICDETAILFORMS_CREATE = "ACTION_DEMOGRAPHICDETAILFORMS_CREATE",
    ACTION_DEMOGRAPHICDETAILFORMS_CREATE_SUCCESS = "ACTION_DEMOGRAPHICDETAILFORMS_CREATE_SUCCESS",
    ACTION_DEMOGRAPHICDETAILFORMS_CREATE_FAILURE = "ACTION_DEMOGRAPHICDETAILFORMS_CREATE_FAILURE",

    ACTION_DEMOGRAPHICDETAILFORMS_CLEAR = "ACTION_DEMOGRAPHICDETAILFORMS_CLEAR";

export interface IDemographicDetailFormLoadAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_LOAD";
}

export interface IDemographicDetailFormLoadActionCreator {
    (): IDemographicDetailFormLoadAction;
}

export interface IDemographicDetailFormLoadByIdAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID";
    id: number;
}

export interface IDemographicDetailFormLoadByIdActionCreator {
    (id: number): IDemographicDetailFormLoadByIdAction;
}

export interface IDemographicDetailFormLoadByPatientIdAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID";
    patientId: number;
}

export interface IDemographicDetailFormLoadByPatientIdActionCreator {
    (patientId: number): IDemographicDetailFormLoadByPatientIdAction;
}

export interface IDemographicDetailFormLoadByPatientIdentifierAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface IDemographicDetailFormLoadByPatientIdentifierActionCreator {
    (identifier: string): IDemographicDetailFormLoadByPatientIdentifierAction;
}


export interface IDemographicDetailFormLoadSuccessAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_SUCCESS";
    formData: Dtos.DemographicDetailForm | Dtos.DemographicDetailForm[];
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;

}

export interface IDemographicDetailFormLoadSuccessActionCreator {
    (
        formData: Dtos.DemographicDetailForm | Dtos.DemographicDetailForm[],
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IDemographicDetailFormLoadSuccessAction;
}

export interface IDemographicDetailFormLoadFailureAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IDemographicDetailFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IDemographicDetailFormLoadFailureAction;
}

export interface IDemographicDetailFormSaveAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_SAVE";
    formData: Dtos.DemographicDetailForm;
    institutionCode: string;
    patientIdentifier: string;

}

export interface IDemographicDetailFormSaveActionCreator {
    (
        formData: Dtos.DemographicDetailForm,
        institutionCode: string,
        patientIdentifier: string
    ): IDemographicDetailFormSaveAction;
}

export interface IDemographicDetailFormSaveSuccessAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_SAVE_SUCCESS";
    formData: Dtos.DemographicDetailForm;
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IDemographicDetailFormSaveSuccessActionCreator {
    (
        formData: Dtos.DemographicDetailForm,
        formState: Dtos.RegistrationFormState,
        responseStatus: Dtos.ResponseStatus
    ): IDemographicDetailFormSaveSuccessAction;
}

export interface IDemographicDetailFormSaveFailureAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IDemographicDetailFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IDemographicDetailFormSaveFailureAction;
}

export interface IDemographicDetailFormCreateAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_CREATE"
}

export interface IDemographicDetailFormCreateActionCreator {
    (): IDemographicDetailFormCreateAction
}

export interface IDemographicDetailFormCreateSuccessAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_CREATE_SUCCESS";
    formData: Dtos.DemographicDetailForm;
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IDemographicDetailFormCreateSuccessActionCreator {
    (
        formData: Dtos.DemographicDetailForm,
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[]
    ): IDemographicDetailFormCreateSuccessAction;
}

export interface IDemographicDetailFormCreateFailureAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IDemographicDetailFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IDemographicDetailFormCreateFailureAction;
}

export interface IDemographicDetailFormClearAction {
    type: "ACTION_DEMOGRAPHICDETAILFORMS_CLEAR";
}

export interface IDemographicDetailFormClearActionCreator {
    (): IDemographicDetailFormClearAction;
}



export type DemographicDetailFormAction = IDemographicDetailFormLoadAction |
    IDemographicDetailFormLoadByIdAction |
    IDemographicDetailFormLoadByPatientIdAction |
    IDemographicDetailFormLoadByPatientIdentifierAction |
    IDemographicDetailFormLoadFailureAction |
    IDemographicDetailFormLoadSuccessAction |
    IDemographicDetailFormSaveAction |
    IDemographicDetailFormSaveSuccessAction |
    IDemographicDetailFormSaveFailureAction |
    IDemographicDetailFormCreateAction |
    IDemographicDetailFormCreateSuccessAction |
    IDemographicDetailFormCreateFailureAction |
    IDemographicDetailFormClearAction;
