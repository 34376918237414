import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Fig.dtos";

export const institutionApi = {
    getInstitutions: (): Observable<Dtos.GetInstitutionsResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutions = new Dtos.GetInstitutions();

        //Send request.
        const response: Promise<Dtos.GetInstitutionsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getInstitutionsByInstitutionCode: (institutionCode: string): Observable<Dtos.GetInstitutionByInstitutionCodeResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionByInstitutionCode = new Dtos.GetInstitutionByInstitutionCode();

        request.institutionCode = institutionCode;

        //Send request.
        const response: Promise<Dtos.GetInstitutionByInstitutionCodeResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getInstitutionsByCountry: (country: string): Observable<Dtos.GetInstitutionsByCountryResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionsByCountry = new Dtos.GetInstitutionsByCountry();

        request.country = country;

        //Send request.
        const response: Promise<Dtos.GetInstitutionsByCountryResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getInstitutionsByState: (state: number): Observable<Dtos.GetInstitutionsByStateResponse> => {
        //Create the request.
        let request: Dtos.GetInstitutionsByState = new Dtos.GetInstitutionsByState();

        request.state = state as any;

        //Send request.
        const response: Promise<Dtos.GetInstitutionsByStateResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}