import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {

    ACTION_SAES_LOAD,
    ACTION_SAES_LOAD_SUMMARIES,
    ACTION_SAES_LOAD_BY_INSTITUTION,
    ACTION_SAES_CLEAR,
    ACTION_SAES_LOAD_BY_SAEID,
    ACTION_SAES_LOAD_FAILURE,
    ACTION_SAES_LOAD_SUCCESS,

    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED,
    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_FAILURE,
    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_SUCCESS,

    ACTION_SAES_PROCESS_NOTIFY,
    ACTION_SAES_PROCESS_NOTIFY_FAILURE,
    ACTION_SAES_PROCESS_NOTIFY_SUCCESS,

    ACTION_SAES_PROCESS_RESET,
    ACTION_SAES_PROCESS_RESET_SUCCESS,
    ACTION_SAES_PROCESS_RESET_FAILURE,

    ACTION_SAES_PROCESS_REVIEW,
    ACTION_SAES_PROCESS_REVIEW_FAILURE,
    ACTION_SAES_PROCESS_REVIEW_SUCCESS,

    ISaesLoadAction,
    ISaesLoadSummariesAction,
    ISaesLoadByInstitutionAction,
    ISaesLoadBySaeIdAction,

    ISaesLoadSuccessAction,
    ISaesLoadFailureAction,

    ISaesClearAction,

    ISaesProcessNotificationNotRequiredAction,
    ISaesProcessNotificationNotRequiredSuccessAction,
    ISaesProcessNotificationNotRequiredFailureAction,

    ISaesProcessResetAction,
    ISaesProcessResetSuccessAction,
    ISaesProcessResetFailureAction,

    ISaesProcessNotifyAction,
    ISaesProcessNotifySuccessAction,
    ISaesProcessNotifyFailureAction,

    ISaesProcessReviewAction,
    ISaesProcessReviewSuccessAction,
    ISaesProcessReviewFailureAction,

    LoadSuccess,
    LoadFailure,

    ProcessSaeNotificationNotRequiredSuccess,
    ProcessSaeNotificationNotRequiredFailure,

    ProcessSaeResetSuccess,
    ProcessSaeResetFailure,

    ProcessSaeNotifySuccess,
    ProcessSaeNotifyFailure,

    ProcessSaeReviewSuccess,
    ProcessSaeReviewFailure
} from "../actions/sae";
import { saeApi } from "../services/sae";


export const
    getSaesEpic = (action$): Observable<ISaesLoadSuccessAction | ISaesLoadFailureAction> => {
        return action$
            .ofType(ACTION_SAES_LOAD)
            .mergeMap((action: ISaesLoadAction) => {
                return saeApi
                    .getSaes()
                    .map(response =>
                        LoadSuccess(response.saes)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getSaesByInstitutionEpic = (action$): Observable<ISaesLoadSuccessAction | ISaesLoadFailureAction> => {
        return action$
            .ofType(ACTION_SAES_LOAD_BY_INSTITUTION)
            .mergeMap((action: ISaesLoadByInstitutionAction) => {
                return saeApi
                    .getSaesByInstitution(action.institutionCode)
                    .map(response =>
                        LoadSuccess(response.saes)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getSaesBySaeIdEpic = (action$): Observable<ISaesLoadSuccessAction | ISaesLoadFailureAction> => {
        return action$
            .ofType(ACTION_SAES_LOAD_BY_SAEID)
            .mergeMap((action: ISaesLoadBySaeIdAction) => {
                return saeApi
                    .getSaeBySaeId(action.saeId)
                    .map(response =>
                        LoadSuccess(response.sae)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getSaesSummariesEpic = (action$): Observable<ISaesLoadSuccessAction | ISaesLoadFailureAction> => {
        return action$
            .ofType(ACTION_SAES_LOAD_SUMMARIES)
            .mergeMap((action: ISaesLoadSuccessAction) => {
                return saeApi
                    .getSaeSummaries()
                    .map(response =>
                        LoadSuccess(response.saeSummaries)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    ProcessSaeNotificationNotRequiredEpic = (action$): Observable<ISaesProcessNotificationNotRequiredSuccessAction | ISaesProcessNotificationNotRequiredFailureAction> => {
        return action$
            .ofType(ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED)
            .mergeMap((action: ISaesProcessNotificationNotRequiredAction) => {
                return saeApi
                    .processNotificationNotRequired(action.institutionCode, action.saeId)
                    .map(response =>
                        ProcessSaeNotificationNotRequiredSuccess(response.sae)
                    )
                    .catch(error =>
                        Observable.of(ProcessSaeNotificationNotRequiredFailure(error.responseStatus))
                    )
            });
    },

    ProcessSaeResetEpic = (action$): Observable<ISaesProcessResetSuccessAction | ISaesProcessResetFailureAction> => {
        return action$
            .ofType(ACTION_SAES_PROCESS_RESET)
            .mergeMap((action: ISaesProcessResetAction) => {
                return saeApi
                    .processReset(action.institutionCode, action.saeId)
                    .map(response =>
                        ProcessSaeResetSuccess(response.sae)
                    )
                    .catch(error =>
                        Observable.of(ProcessSaeResetFailure(error.responseStatus))
                    )
            });
    },
    ProcessSaeNotifyEpic = (action$): Observable<ISaesProcessNotifySuccessAction | ISaesProcessNotifyFailureAction> => {
        return action$
            .ofType(ACTION_SAES_PROCESS_NOTIFY)
            .mergeMap((action: ISaesProcessNotifyAction) => {
                return saeApi
                    .processNotify(action.formData, action.institutionCode, action.saeId, action.trialUserType)
                    .map(response =>
                        ProcessSaeNotifySuccess(response.sae)
                    )
                    .catch(error =>
                        Observable.of(ProcessSaeNotifyFailure(error.responseStatus))
                    )
            });
    },
    ProcessSaeReviewEpic = (action$): Observable<ISaesProcessReviewSuccessAction | ISaesProcessReviewFailureAction> => {
        return action$
            .ofType(ACTION_SAES_PROCESS_REVIEW)
            .mergeMap((action: ISaesProcessReviewAction) => {
                return saeApi
                    .processReview(action.formData, action.institutionCode, action.saeId, action.saeReviewType)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.errors &&
                            response.responseStatus.errors.length > 0) {
                            return ProcessSaeReviewFailure(response.responseStatus, action.saeReviewType)
                        }
                        else {
                            return ProcessSaeReviewSuccess(response.sae, action.saeReviewType)
                        }
                    })
                    .catch(error =>
                        Observable.of(ProcessSaeReviewFailure(error.responseStatus, action.saeReviewType))
                    )
            });
    }




