import * as Dtos from '../dtos/Fig.dtos';
import { IAuthorisationDefinition, IAuthorisationRole } from '../interfaces/authentication/authorisationDefinition';
import * as AuthenticationConstants from '../constants/authentication';
import * as AuthorisationDefinitions from '../constants/authorisationDefinitions';
import { ICreateAuthorisationsFn } from '../components/common/Authentication/AuthenticatedRoute';

export function authenticate(user: Dtos.User, authDefinitions?: IAuthorisationDefinition[]) {
    
    //debugger;
    if (authDefinitions) {
        for (let i = 0; i < authDefinitions.length; i++) {
            if (authenticateDefinition(user, authDefinitions[i])) {
                return true;
            }
        }
    }
    else {
        return authenticateDefinition(user, undefined);
    }

    return false;
}

function authenticateDefinition(user: Dtos.User, authDefinition?: IAuthorisationDefinition) {

    // Check if user exists
    if (!user) {
        return false;
    }

    // Check Auth Definition
    if (!authDefinition) {
        if (getInstitutions(user).length > 0 ||
            hasGroup(user, "super-user")) {
            return true;
        }
        else {
            return false;
        }
    }

    // If super user, no need to check groups, roles and institutions
    if (hasGroup(user, "super-user")) {
        return true;
    }

    if (hasGroup(user, "trog-admin")) {
        return true;
    }

    if (hasGroup(user, "management")) {
        return true;
    }

    if (hasGroup(user, "clinical-research-associate")) {
        return true;
    }

    if (hasGroup(user, "clinical-trial-assistant")) {
        return true;
    }

    let isTROG: boolean = hasInstitution(user, AuthenticationConstants.INSTITUTION_TROG);

    if (isTROG) {
        return true;
    }


    // Check Groups
    if (authDefinition.groups && authDefinition.groups.length > 0) {
        for (let authGroup of authDefinition.groups) {
            if (!hasGroup(user, authGroup)) {
                // Group not found on user. invalid group permissions
                return false;
            }
        }
    }

    if (authDefinition.institutions && authDefinition.institutions.length > 0) {
        for (let authInstitution of authDefinition.institutions) {
            if (!hasInstitution(user, authInstitution)) {
                // Group not found on user. invalid group permissions
                return false;
            }
        }
    }

    if (authDefinition.roles && authDefinition.roles.length > 0) {
        for (let role of authDefinition.roles) {
            if (!hasRole(user, role.roleId, role.instCode)) {
                return false;
            }

        }
    }

    return true;
}

function hasGroup(user: Dtos.User, group: string): boolean {
    let groups: string[] = getGroups(user);

    if (groups) {
        if (groups.filter(g => g == group).length > 0) {
            return true;
        }
    }

    return false;
}

function hasInstitution(user: Dtos.User, institution: string): boolean {
    let institutions: string[] = getInstitutions(user);

    if (institutions) {
        if (institutions.filter(i => i == institution).length > 0) {
            return true;
        }
    }

    return false;
}

function hasRole(user: Dtos.User, role: string, instCode?: string): boolean {

    let roles: { [index: string]: string[] } = getRoles(user); // trialid_instid_role
    if (roles) {
        if (instCode) {
            if (!roles[instCode]) {
                return false;
            }

            return roles[instCode].some(r => r == role)
        }

        for (let key of Object.keys(roles)) {
            if (roles[key].some(r => r == role)) {
                return true;
            }
        }
    }

    return false;
}

function getGroups(user: Dtos.User): string[] {
    if (user && user.groups) {
        return user.groups;
    }
    return [];
}

function getInstitutions(user: Dtos.User): string[] {
    if (user && user.institutions) {
        return user.institutions;
    }
    return [];
}

function getRoles(user: Dtos.User): { [index: string]: string[] } {

    if (user && user.roles) {
        return user.roles;
    }
    return {};
}

export function isTrog(user: Dtos.User): boolean {

    return authenticate(user, [AuthorisationDefinitions.TROG_USER]);
}

export function isSuperUser(user: Dtos.User): boolean {

    return authenticate(user, [AuthorisationDefinitions.SUPER_USER])
}

export function isTrogOrSuperUser(user: Dtos.User): boolean {

    return (isTrog(user) || isSuperUser(user) || isCra(user) || isManagement(user) || isTrogAdmin(user))
}

export function isCra(user: Dtos.User): boolean {

    return authenticate(user, [AuthorisationDefinitions.TROG_CRA_USER])
}

export function isManagement(user: Dtos.User): boolean {

    return authenticate(user, [AuthorisationDefinitions.TROG_MANAGEMENT_USER])
}

export function isTrogAdmin(user: Dtos.User): boolean {

    return authenticate(user, [AuthorisationDefinitions.TROG_ADMIN_USER])
}

export function isPrincipalInvestigator(user: Dtos.User): boolean {
    return authenticate(user, [AuthorisationDefinitions.PRINCIPAL_INVESTIGATOR_AUTH]);
}

export function isCoInvestigator(user: Dtos.User): boolean {
    return authenticate(user, [AuthorisationDefinitions.CO_INVESTIGATOR_AUTH])
}

export function isInvestigator(user: Dtos.User): boolean {
    return (isPrincipalInvestigator(user) || isCoInvestigator(user))
}

export function isTrogRestricted(user: Dtos.User): boolean {
    return authenticate(user, [AuthorisationDefinitions.TROG_RESTRICTED_AUTH]);
}

export function isMedicalReviewerorTrogRestricted(user: Dtos.User): boolean {
    return authenticate(user, [AuthorisationDefinitions.MEDICAL_REVIEWER_AUTH, AuthorisationDefinitions.TROG_RESTRICTED_AUTH]);
}

export function isMedicalReviewerSuperorTROGUser(user: Dtos.User): boolean {
    return authenticate(user, [AuthorisationDefinitions.MEDICAL_REVIEWER_AUTH, AuthorisationDefinitions.SUPER_USER, AuthorisationDefinitions.TROG_RESTRICTED_AUTH]);
}
/**
 * This function returns true if the user is a pharmacist or has permission to see things a pharmacist
 * should see (i.e. super user, Trog staff)
 * @param user User to check permissions of
 */
export function hasPharmacistPermissions(user: Dtos.User): boolean {
    return authenticate(user, [AuthorisationDefinitions.PHARMACIST_AUTH]);
}

export function hasMedicalReviewerPermissions(user: Dtos.User): boolean {
    return authenticate(user, [AuthorisationDefinitions.MEDICAL_REVIEWER_AUTH]);
}

/**
 * This function returns true if the user is only a pharmacist (i.e. not a super user or TROG staff)
 * @param user User to check permissions of
 */
export function isPharmacist(user: Dtos.User): boolean {
    if (user) {
        let roles: { [index: string]: string[] } = getRoles(user);
        if (Object.keys(roles).length > 0) {
            let onlyHasPharmacistRole: boolean = true;

            //this loop checks through all the user's roles and makes sure
            //the only role they have at each institution matches the definition of a pharmacist role
            for (let key of Object.keys(roles)) {
                onlyHasPharmacistRole = onlyHasPharmacistRole &&
                    (roles[key]
                        .every(r => AuthenticationConstants.PHARMACIST_ROLE
                            .some(ad => ad.roleId == r))
                    );
            }

            return onlyHasPharmacistRole && authenticate(user, [AuthorisationDefinitions.PHARMACIST_AUTH]);
        }
    }

    return false;
}

export function isMedicalReviewer(user: Dtos.User): boolean {
    if (user) {
        let roles: { [index: string]: string[] } = getRoles(user);
        if (Object.keys(roles).length > 0) {
            let onlyHasMedicalReviewerRole: boolean = true;

            //this loop checks through all the user's roles and makes sure
            //the only role they have at each institution matches the definition of a pharmacist role
            for (let key of Object.keys(roles)) {
                onlyHasMedicalReviewerRole = onlyHasMedicalReviewerRole &&
                    (roles[key]
                        .every(r => AuthenticationConstants.MEDICAL_REVIEWER_ROLE
                            .some(ad => ad.roleId == r))
                    );
            }

            return onlyHasMedicalReviewerRole && authenticate(user, [AuthorisationDefinitions.MEDICAL_REVIEWER_AUTH]);
        }
    }

    return false;
}

/* Authorisation Definition Functions */


export function createAuthorisationDefinitionByInstitutionCodeOrMedicalReviewerTROGRole(path, location, user, match): IAuthorisationDefinition[] | undefined {
    if (match && match.params || match.params.institutionCode) {
        return [
            {
                institutions: [
                    match.params.institutionCode
                ],
                groups: undefined,
                roles: AuthenticationConstants.TROG_RESTRICTED_ROLES ||
                    AuthenticationConstants.MEDICAL_REVIEWER_ROLE
            }
        ]
    }
    return undefined
}

export function createAuthorisationDefinitionByInstitutionCode(path, location, user, match): IAuthorisationDefinition[] | undefined {
    if (match && match.params && match.params.institutionCode) {
        return [
            {
                institutions: [
                    match.params.institutionCode
                ],
                groups: undefined,
                roles: undefined
            }
        ]
    }
    return undefined
}

export function createPharmacistDefinitionByInstitutionCode(path, location, user, match): IAuthorisationDefinition[] | undefined {
    if (match && match.params && match.params.institutionCode) {
        return [
            {
                institutions: [
                    match.params.institutionCode
                ],
                groups: undefined,
                roles: AuthenticationConstants.PHARMACIST_ROLE
            }
        ]
    }
    return undefined
}

export function createMedicalReviewerOrTROGDefinitionByInstitutionCode(path, location, user, match): IAuthorisationDefinition[] | undefined {
    if (match && match.params && match.params.institutionCode) {
        return [
            {
                institutions: [
                    match.params.institutionCode
                ],
                groups: undefined,
                roles:
                    AuthenticationConstants.TROG_RESTRICTED_ROLES ||
                    AuthenticationConstants.MEDICAL_REVIEWER_ROLE
            }
        ]
    }
    return undefined
}

function getInstitutionCodes(institutions: Dtos.Institution | Dtos.Institution[]): string[] {
    if (!institutions) {
        return [];
    }

    let institutionCodes: string[] = [];

    if ((institutions instanceof Dtos.Institution)) {
        institutionCodes.push((institutions as Dtos.Institution).institutionCode);
    }
    else if ((institutions instanceof Array)) {
        institutionCodes = institutions.map(institution => { return institution.institutionCode });
    }

    return institutionCodes;
}

// SAE

export function isTrialUserEnabledForSaeNotification(
    trialUser: Dtos.TrialUser
): boolean {

    return trialUser.rolename == AuthenticationConstants.PRINCIPAL_INVESTIGATOR ||
        trialUser.rolename == AuthenticationConstants.INVESTIGATOR;
}

export function isSaeSiteStaff(user: Dtos.User): boolean {
    return authenticate(user, [
        AuthorisationDefinitions.TRIAL_COORDINATOR_AUTH,
        AuthorisationDefinitions.PRINCIPAL_INVESTIGATOR_AUTH,
        AuthorisationDefinitions.CO_INVESTIGATOR_AUTH,
        AuthorisationDefinitions.TRIAL_COORDINATOR_AUTH,
        AuthorisationDefinitions.TROG_RESTRICTED_AUTH
        ]);
}

// Provides access to SAE resource for Super, TROG and Site users.
export function hasSaeAccess(
    user: Dtos.User,
    institutions: Dtos.Institution | Dtos.Institution[]
): boolean {

    if (!user || !institutions) {
        return false;
    }

    if (isTrogOrSuperUser(user)) {
        return true;
    }

    let institutionCodes: string[] = getInstitutionCodes(institutions);

    return authenticate(
        user,
        [
            {
                institutions: institutionCodes,
                groups: [
                    AuthenticationConstants.PRINCIPAL_INVESTIGATOR
                ],
                roles: undefined
            },
            {
                institutions: institutionCodes,
                groups: [
                    AuthenticationConstants.INVESTIGATOR
                ],
                roles: undefined
            },
            {
                institutions: institutionCodes,
                groups: [
                    AuthenticationConstants.COORDINATOR
                ],
                roles: undefined
            },
        ]
    );

    return false;
}


// Provides access to SAE resource for Super and TROG users.
export function hasSaeTrogAccess(user: Dtos.User): boolean {
    return isTrogOrSuperUser(user);
}

export function hasSaeReviewAccessForInstCode(user: Dtos.User, instCode: string): boolean {
    return hasSaeReviewAccess(user, [instCode]);
}

export function hasSaeReviewAccessForInstitutions(
    user: Dtos.User,
    institutions: Dtos.Institution[]
): boolean {
    let institutionCodes: string[] = getInstitutionCodes(institutions);

    return hasSaeReviewAccess(user, institutionCodes);
}

function hasSaeReviewAccess(user: Dtos.User, institutionCodes: string[]): boolean {

    return authenticate(
        user,
        [
            {
                institutions: institutionCodes,
                groups: [
                    AuthenticationConstants.PRINCIPAL_INVESTIGATOR
                ],
                roles: undefined
            },
            {
                institutions: institutionCodes,
                groups: [
                    AuthenticationConstants.INVESTIGATOR
                ],
                roles: undefined
            }
        ]
    );
}

// Provides access to the SAE Notify page for Super and TROG users.
export const createSaeNotifyAuthorisationDefinition: ICreateAuthorisationsFn<any> = (path, location, user, match) => {
    return isTrogOrSuperUser(user);
}

// Provides access to SAE routes for Super, TROG and Site users.
export const createSaeAuthorisationDefinition: ICreateAuthorisationsFn<any> = (path, location, user, match) => {

    let institution: Dtos.Institution = new Dtos.Institution();

    if (match && match.params && match.params.institutionCode) {
        institution.institutionCode = match.params.institutionCode;
    }
    
    return hasSaeAccess(user, institution);
}

export function isExclusivelyInvestigator(user: Dtos.User): boolean {
    if (user) {
        if (isTrogOrSuperUser(user)) {
            return false;
        }

        return authenticate(user, [AuthorisationDefinitions.PRINCIPAL_INVESTIGATOR_AUTH]) || authenticate(user, [AuthorisationDefinitions.CO_INVESTIGATOR_AUTH]);
    }

    return false;
}