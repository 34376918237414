import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_SOURCEDOCUMENTSFORM_PAGE_TOGGLE_MESSAGE_MODAL,
    SourceDocumentsFormPageAction
} from '../../../actions/pages/registration/sourceDocumentsFormPage';


export interface ISourceDocumentsFormPageState {
    messageModalOpen: boolean;
}

const initialState: ISourceDocumentsFormPageState = {
    messageModalOpen: false
};


function toggleMessageModal(state: ISourceDocumentsFormPageState): ISourceDocumentsFormPageState {
    const newState: ISourceDocumentsFormPageState = update(state, {
        messageModalOpen: {
            $set: !state.messageModalOpen
        }
    });

    return newState;
}

const SourceDocumentsFormPageReducer: Reducer<ISourceDocumentsFormPageState> = (state: ISourceDocumentsFormPageState = initialState, action: SourceDocumentsFormPageAction) => {
    switch (action.type) {
        case ACTION_SOURCEDOCUMENTSFORM_PAGE_TOGGLE_MESSAGE_MODAL:
            return toggleMessageModal(state);
    }

    return state;
}

export default SourceDocumentsFormPageReducer;