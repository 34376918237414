import './TextFormInput.scss';

import * as React from "react";
import classNames from "classnames";
import { IBaseFormInputProps } from '../';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

export interface ITextFormInputProps {
    maxLength?: number; mapProps
    autoFocus?: boolean;
    type?: string;
    placeholder?: string;
    onKeyDown?: (event: React.KeyboardEvent<any>) => void;
    addOnLeft?: React.ReactNode;
    addOnRight?: React.ReactNode;
    feedbackIcon?: string;
}

class TextFormInput extends React.PureComponent<ITextFormInputProps & IBaseFormInputProps, any> {
    render() {
        const {
            className,
            addOnLeft,
            addOnRight,
            autoFocus,
            disabled,
            focused,
            id,
            invalid,
            label,
            name,
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            onKeyPress,
            type,
            valid,
            value,
            maxLength,
            placeholder,
            feedbackIcon,
            hasSoftError,
            hasHardError
        } = this.props;

        const derivedClass = className ? className : "form-control";
        const derivedAddonClass = addOnLeft || addOnRight ? "input-group" : "";

        const textInput = <input
            autoFocus={autoFocus}
            type={type ? type : "text"}
            className={classNames(
                derivedClass,
                { "is-invalid": invalid && !disabled },
                { "rounded-right": invalid && !addOnRight },
                { "is-warning": hasSoftError && !disabled && (!invalid)})
            }
            name={name}
            id={id}
            onBlur={onBlur}
            onChange={(event) => { onChange(event.target.value ? event.target.value : null) }}
            onFocus={onFocus}
            onKeyPress={(event) => {
                if (onKeyPress) {
                    onKeyPress(event)
                }
            }}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            value={value ? value : ''}
            maxLength={maxLength}
            disabled={disabled}

        />;
        const hardFeedback = hasHardError && !disabled ?
            <span className={classNames("form-control-feedback block pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;


        const feedback = (invalid && !disabled) && !hasHardError ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        const softFeedback = hasSoftError && !disabled && (!invalid) ? 
            <span className={classNames("form-control-feedback warning pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;


        if (addOnLeft || addOnRight) {
            return <div className={classNames(
                derivedAddonClass,
                "input-group"
            )}>
                {
                    addOnLeft ?
                        <div onClick={onFocus} className="input-group-prepend" id={id + "-addon-left"}>
                            <span className="input-group-text">
                                {addOnLeft}
                            </span>

                        </div> :
                        null
                }
                {textInput}
                <div style={{ position: "relative" }}>
                    {hardFeedback}
                    {feedback}
                    {softFeedback}
                </div>
                {
                    addOnRight ?
                        <div onClick={onFocus} className="input-group-append" id={id + "-addon-right"}>
                            <span className="input-group-text">
                                {addOnRight}
                            </span>

                        </div> :
                        null
                }
            </div>
        }

        return <div style={{ position: "relative" }}>
            {textInput}
            {hardFeedback}
            {feedback}
            {softFeedback}
        </div>
    }
}

export default TextFormInput;