import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
import { ISourceDocumentModalState } from "./sourceDocument";

export const initialSourceDocumentsFormState: Dtos.SourceDocumentsForm & IRegistrationFormState & ISourceDocumentModalState = {
    id: null,
    patientId: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    sourceDocuments: [],
    openedModals: []
}


export interface ISourceDocumentsReduxFormState {
    id: IFieldState<number>,
    patientId: IFieldState<number>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    openedModals: IFieldState<string[]>
}