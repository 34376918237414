import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
import { ISourceDocumentModalState } from "./sourceDocument";

    
export const initialDemographicDetailFormState: Dtos.DemographicDetailForm & IRegistrationFormState & ISourceDocumentModalState = {
    id: null,
    patientId: null,
    indigenousStatus: null,
    countryOfBirth: null,
    primaryLanguage: null,
    primaryLanguageAsChild: null,
    postCode: null,
    postCodeNA: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    openedModals: [],
    sourceDocuments: [],
}

export interface IDemographicDetailReduxFormState {
    id: IFieldState<number>,
    patientId: IFieldState<number>,
    indigenousStatus: IFieldState<number>,
    countryOfBirth: IFieldState<number>,
    primaryLanguage: IFieldState<number>,
    primaryLanguageAsChild: IFieldState<number>,
    postCode: IFieldState<number>,
    postCodeNA: IFieldState<boolean>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    openedModals: IFieldState<string[]>
}
