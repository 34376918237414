import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_ELIGIBILITYCRITERIAFORM_PAGE_TOGGLE_MESSAGE_MODAL,
    EligibilityCriteriaFormPageAction
} from '../../../actions/pages/registration/eligibilityCriteriaFormPage';


export interface IEligibilityCriteriaFormPageState {
    messageModalOpen: boolean;
}

const initialState: IEligibilityCriteriaFormPageState = {
    messageModalOpen: false
};


function toggleMessageModal(state: IEligibilityCriteriaFormPageState): IEligibilityCriteriaFormPageState {
    const newState: IEligibilityCriteriaFormPageState = update(state, {
        messageModalOpen: {
            $set: !state.messageModalOpen
        }
    });

    return newState;
}

const EligibilityCriteriaFormPageReducer: Reducer<IEligibilityCriteriaFormPageState> = (state: IEligibilityCriteriaFormPageState = initialState, action: EligibilityCriteriaFormPageAction) => {
    switch (action.type) {
        case ACTION_ELIGIBILITYCRITERIAFORM_PAGE_TOGGLE_MESSAGE_MODAL:
            return toggleMessageModal(state);
    }

    return state;
}

export default EligibilityCriteriaFormPageReducer;