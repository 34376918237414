import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {

    ACTION_TRIAL_USERS_CLEAR,
    ACTION_TRIAL_USERS_LOAD,
    ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION,
    ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS,
    ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS,
    ACTION_TRIAL_USERS_LOAD_BY_USERNAME,
    ACTION_TRIAL_USERS_LOAD_FAILURE,
    ACTION_TRIAL_USERS_LOAD_SUCCESS,

    ITrialUsersLoadAction,
    ITrialUsersLoadByInstitutionAction,
    ITrialUsersLoadByRoleIdsAction,
    ITrialUsersLoadByInstitutionRoleIdsAction,
    ITrialUsersLoadByUsernameAction,

    ITrialUsersLoadSuccessAction,
    ITrialUsersLoadFailureAction,

    LoadSuccess,
    LoadFailure
} from "../actions/trialUser";
import { trialUserApi } from "../services/trialUser";


export const
    getTrialUsersEpic = (action$): Observable<ITrialUsersLoadSuccessAction | ITrialUsersLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIAL_USERS_LOAD)
            .mergeMap((action: ITrialUsersLoadAction) => {
                return trialUserApi
                    .getTrialUsers()
                    .map(response =>
                        LoadSuccess(response.trialUsers)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialUsersByInstitutionEpic = (action$): Observable<ITrialUsersLoadSuccessAction | ITrialUsersLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION)
            .mergeMap((action: ITrialUsersLoadByInstitutionAction) => {
                return trialUserApi
                    .getTrialUsersByInstitution(action.institutionCode)
                    .map(response =>
                        LoadSuccess(response.trialUsers)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialUsersByUsername = (action$): Observable<ITrialUsersLoadSuccessAction | ITrialUsersLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIAL_USERS_LOAD_BY_USERNAME)
            .mergeMap((action: ITrialUsersLoadByUsernameAction) => {
                return trialUserApi
                    .getTrialUsersByUsername(action.username)
                    .map(response =>
                        LoadSuccess(response.trialUser)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialUsersByRoleIds = (action$): Observable<ITrialUsersLoadSuccessAction | ITrialUsersLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS)
            .mergeMap((action: ITrialUsersLoadByRoleIdsAction) => {
                return trialUserApi
                    .getTrialUsersByRoleIds(action.roleIds)
                    .map(response =>
                        LoadSuccess(response.trialUsers)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getTrialUsersByInstitutionRoleIds = (action$): Observable<ITrialUsersLoadSuccessAction | ITrialUsersLoadFailureAction> => {
        return action$
            .ofType(ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS)
            .mergeMap((action: ITrialUsersLoadByInstitutionRoleIdsAction) => {
                return trialUserApi
                    .getTrialUsersByInstitutionRoleIds(action.institutionCode, action.roleIds)
                    .map(response =>
                        LoadSuccess(response.trialUsers)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    }



