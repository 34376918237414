import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleMessageModal: IPatientReportedFormPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_PATIENTREPORTEDFORM_PAGE_TOGGLE_MESSAGE_MODAL
        }
    }

export const
    ACTION_PATIENTREPORTEDFORM_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_PATIENTREPORTEDFORM_PAGE_TOGGLE_MESSAGE_MODAL"

/* Actions */

export interface IPatientReportedFormPageToggleMessageModalAction {
    type: "ACTION_PATIENTREPORTEDFORM_PAGE_TOGGLE_MESSAGE_MODAL"
}

/* Action Creators */
export interface IPatientReportedFormPageToggleMessageModalActionCreator {
    (): IPatientReportedFormPageToggleMessageModalAction;
}


/* Final Export of actions */
export type PatientReportedFormPageAction = IPatientReportedFormPageToggleMessageModalAction;
