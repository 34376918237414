import { client } from "../../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../../dtos/Fig.dtos";

export const glioblastomaDetailFormApi = {
    getGlioblastomaDetailForms: (): Observable<Dtos.GetGlioblastomaDetailFormsResponse> => {
        //Create the request.
        let request: Dtos.GetGlioblastomaDetailForms = new Dtos.GetGlioblastomaDetailForms();

        //Send request.
        const response: Promise<Dtos.GetGlioblastomaDetailFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getGlioblastomaDetailFormById: (id: number): Observable<Dtos.GetGlioblastomaDetailFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetGlioblastomaDetailFormById = new Dtos.GetGlioblastomaDetailFormById();

        request.id = id;
        //Send request.
        const response: Promise<Dtos.GetGlioblastomaDetailFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getGlioblastomaDetailFormByPatientId: (patientId: number): Observable<Dtos.GetGlioblastomaDetailFormByPatientIdResponse> => {
        //Create the request.
        let request: Dtos.GetGlioblastomaDetailFormByPatientId = new Dtos.GetGlioblastomaDetailFormByPatientId();

        request.patientId = patientId;

        //Send request.
        const response: Promise<Dtos.GetGlioblastomaDetailFormByPatientIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getGlioblastomaDetailFormByPatientIdentifier: (identifier: string): Observable<Dtos.GetGlioblastomaDetailFormByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetGlioblastomaDetailFormByPatientIdentifier = new Dtos.GetGlioblastomaDetailFormByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetGlioblastomaDetailFormByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setGlioblastomaDetailForm: (GlioblastomaDetailForm: Dtos.GlioblastomaDetailForm,
        institutionCode: string,
        patientIdentifier: string): Observable<Dtos.SetGlioblastomaDetailFormResponse> => {

        let request: Dtos.SetGlioblastomaDetailForm = new Dtos.SetGlioblastomaDetailForm();

        request.glioblastomaDetailForm = GlioblastomaDetailForm;
        request.institutionCode = institutionCode;
        request.patientIdentifier = patientIdentifier;

        // send request
        const response: Promise<Dtos.SetGlioblastomaDetailFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createGlioblastomaDetailForm: (): Observable<Dtos.CreateGlioblastomaDetailFormResponse> => {
        //Create the request.
        let request: Dtos.CreateGlioblastomaDetailForm = new Dtos.CreateGlioblastomaDetailForm();

        //Send request.
        const response: Promise<Dtos.CreateGlioblastomaDetailFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}