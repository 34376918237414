import * as React from 'react';

import classNames from "classnames";
import './SaePage.scss';

import { match } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';
import { Location, History } from "history";

import ReactSelectClass from "react-select";
import ReactTable, { Column, RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, UnrestrictedLayout, SubMenu } from "../../../components/layouts/index";

import * as SaeActions from '../../../actions/sae';
import * as SaePageActions from '../../../actions/pages/sae/saePage';
import * as InstitutionActions from '../../../actions/institution';

import * as FigDtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";
import { TrialUserType } from '../../../enumerations/TrialUserType';
import { Link } from "../../../components/routing/index";
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { ILinkProps } from "../../../components/routing/Link";
import { INavItem } from "../../../components/layouts/NavMenu/NavMenu";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "../../../constants/reactTableConstants";
import { Modal, NotificationAlert, TableHeader } from "../../../components/common/index";


import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { IInstitutionCodeParams } from '../../../interfaces/params/routeParams';
import AnimateHeight from 'react-animate-height';
import { toggleApproved, toggleRejected, toggleIncorrectlyIdentified } from '../../../actions/pages/sae/saePage';

interface ISaePageProps {

    location: Location;
    match: match<IInstitutionCodeParams>;
    history: History;
    institutionCode: string;

    // From state
    saes: FigDtos.Sae[];
    loadingSaes: boolean;
    loadSaesSuccess: boolean;
    loadSaesFailure: boolean;

    institutions: FigDtos.Institution[];
    loadingInstitutions: boolean;
    loadInstitutionsSuccess: boolean;
    loadInstitutionsFailure: boolean;

    expandNewSaes: boolean;
    expandAwaitingReviewSaes: boolean;
    expandApprovedSaes: boolean;
    expandRejectedSaes: boolean;
    expandIncorrectlyIdentifiedSaes: boolean;

    // Derived from state
    newSaes: FigDtos.Sae[];
    awaitingReviewSaes: FigDtos.Sae[];
    approvedSaes: FigDtos.Sae[];
    rejectedSaes: FigDtos.Sae[];
    incorrectlyIdentifiedSaes: FigDtos.Sae[];

    countries: string[];
    country: string;

    user: FigDtos.User
}

interface ISaePageActions {

    clearSaes: SaeActions.ISaesClearActionCreator;

    loadSaes: SaeActions.ISaesLoadSummariesActionCreator;

    toggleNewSaes: SaePageActions.ISaePageToggleNewActionCreator;
    toggleAwaitingReviewSaes: SaePageActions.ISaePageToggleAwaitingReviewActionCreator;
    toggleApprovedSaes: SaePageActions.ISaePageToggleApprovedActionCreator;
    toggleRejectedSaes: SaePageActions.ISaePageToggleRejectedActionCreator;
    toggleIncorrectlyIdentifiedSaes: SaePageActions.ISaePageToggleIncorrectlyIdentifiedActionCreator;

    updateCountry: SaePageActions.ISaePageUpdateCountryActionCreator;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    loadInstitutions: InstitutionActions.IInstitutionLoadActionCreator;
    clearInstitutions: InstitutionActions.IInstitutionClearActionCreator;
}

type SaePageProps = ISaePageProps & ISaePageActions;

const tableHeaderClass = "btn btn-primary btn-block rounded-0 p-3 d-flex"
const tableStyleClass: React.CSSProperties = {
    textAlign: "left",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer"
}

const patientRandomisationNumberColumn: number = 130;
const saeNumberColumn: number = 65;
const siteColumnWidth: number = 250;
const MainEventNameWidth: number = 300;
const MainEventNameMinWidth: number = 150;

class SaePage extends React.PureComponent<SaePageProps, any> {

    constructor(props: SaePageProps) {
        super(props);

        this.clearSaes = this.clearSaes.bind(this);
        this.clearInstitutions = this.clearInstitutions.bind(this);
    }

    componentDidMount() {
        const {
            loadSaes,
            loadInstitutions,
            institutions,
            navigate,
            navigateReplace
        } = this.props;


        loadSaes();
        loadInstitutions();

        if (institutions.length == 1) {
            navigateReplace("/sae/" + institutions[0].institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: SaePageProps) {
    }

    componentDidUpdate(prevProps: SaePageProps) {

        const {
            country,
            institutionCode,
            institutions,
            navigateReplace,
            loadSaes,
            loadInstitutions,
        } = this.props

        if (institutions.length == 1 &&
            !prevProps.institutionCode) {
            navigateReplace("/sae/" + institutions[0].institutionCode);
        }

        if (country != prevProps.country && institutionCode) {
            if (!this.getInstitution(institutionCode)) {
                navigateReplace("/sae");
            }
        }

    }

    componentWillUpdate(nextProps: SaePageProps) {
    }

    componentWillUnmount() {

        this.clearInstitutions();
        this.clearSaes();
    }

    clearInstitutions() {
        this.props.clearInstitutions();
    }

    clearSaes() {
        this.props.clearSaes();
    }

    showSaes(): boolean {

        const {
            loadingSaes,
            loadSaesSuccess,

        } = this.props;

        if (loadSaesSuccess) {
            return true;
        }

        return false;

    }

    render() {

        const {
            loadingSaes,
            loadingInstitutions,
            loadSaesSuccess,
            loadSaesFailure
        } = this.props;

        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={(loadingSaes && !loadSaesSuccess) || loadingInstitutions}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            institutions,
            countries,
            institutionCode,
            country,
            updateCountry,
            navigate,
            user
        } = this.props;

        const showCountry: boolean = countries && countries.length > 1

        //const isInvestigator: boolean = AuthenticationHelper.isInvestigator(user);

        return <div>
            <h1>FIG SAE Electronic Review and Investigator Signature</h1>
            {
                !this.showSaes() ?
                    "Loading Serious Adverse Events..." :
                    null
            }
            <div className="row">
                { // Country Dropdown
                    showCountry && this.showSaes() && AuthenticationHelper.hasSaeAccess(user, institutions) ?
                        <div className={"col-6 mb-2"}>
                            <ReactSelectClass
                                placeholder={"Select a country..."}
                                value={country}
                                onChange={option => {
                                    if (option && option.value) {
                                        updateCountry(option.value);
                                    }
                                    else {
                                        updateCountry(undefined);
                                    }
                                }}
                                options={
                                    countries.map((country) => {
                                        return {
                                            value: country,
                                            label: country
                                        };
                                    })
                                }
                            />
                        </div> :
                        null
                }
                { // Institution Dropdown
                    institutions && institutions.length >= 1 && this.showSaes() ?
                        <div className={"col-6 mb-2"}>
                            <ReactSelectClass
                                placeholder={"Select an institution..."}
                                value={institutionCode}
                                onChange={option => {
                                    if (option && option.value) {
                                        navigate("/sae/" + option.value);
                                    }
                                    else {
                                        navigate("/sae");
                                    }
                                }}
                                options={
                                    institutions.map((institution) => {
                                        return {
                                            value: institution.institutionCode,
                                            label: institution.institutionName
                                        };

                                    })
                                }
                            />
                        </div> :
                        null
                }
            </div>
            {
                this.showSaes() ?
                    <div className="sae-tables mt-2">
                        {
                            AuthenticationHelper.hasSaeAccess(user, institutions) &&
                            this.createNewSaesTable()
                        }
                        {
                            this.createAwaitingReviewSaesTable()
                        }
                        {
                            this.createApprovedSaesTable()
                        }
                        {
                            this.createRejectedSaesTable()
                        }
                        {
                            AuthenticationHelper.hasSaeAccess(user, institutions) &&
                            this.createIncorrectlyIdentifiedSaesTable()
                        }

                    </div> :
                    null
            }
            {
                //this.renderPatientStatusModal()
            }
        </div>
    }

    createNewSaesTable() {

        const {
            newSaes,
            expandNewSaes,
            toggleNewSaes,
            institutionCode,
            user,
            navigate,
            navigateReplace
        } = this.props

        if (!newSaes || newSaes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no new SAEs to view.</span>
            </div>
        }

        const showPagination = newSaes.length > DEFAULT_PAGE_SIZE;
        const newSaesIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.LIST} fixedWidth />
            <FontAwesomeIcon icon={FontAwesomeIcons.Regular.SEARCH} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total SAEs"
                title="New SAEs"
                expanded={expandNewSaes}
                toggle={toggleNewSaes}
                count={newSaes.length}
                icon={newSaesIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandNewSaes ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"preregistration-" + institutionCode}
                        data={newSaes}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : newSaes.length}
                        pageSize={showPagination ? undefined : newSaes.length}
                        showPagination={showPagination}
                        className="-striped -highlight"
                        noDataText="There are currently no new SAEs to view."
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    //const url: string = "/sae/" + rowInfo.row.institutionCode + '/view-sae/' + rowInfo.row.saeId;
                                    //this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Reg No',
                                    accessor: 'randNo', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientRandomisationNumberColumn
                                },
                                {
                                    id: "saeNumber",
                                    Header: "SAE No",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.saeNumber ?
                                            <span>{sae.saeInitialData.saeNumber}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    width: saeNumberColumn
                                },
                                {
                                    id: "onsetDate",
                                    Header: "Date Onset",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.onsetDate ?
                                            <div><span data-tip={convertToApproximateDateString(sae.saeInitialData.onsetDate)}>{convertToShortDateString(sae.saeInitialData.onsetDate)}</span><ReactTooltip /></div>
                                        : ''
                                    : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "mainEventName",
                                    Header: "Term",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.ctcaeTerm ?
                                            <span>{sae.saeInitialData.ctcaeTerm}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    //width: MainEventNameWidth
                                    minWidth: MainEventNameMinWidth
                                },
                                {
                                    id: "investigatorsName",
                                    Header: "Investigator",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.reportingInvestigator ?
                                            <span>{sae.saeInitialData.reportingInvestigator}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "institutionName",
                                    Header: "Site",
                                    accessor: (sae: FigDtos.Sae) => <span>{sae.institutionName}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: siteColumnWidth
                                },
                                // View column -- only for SAE staff
                                {
                                    id: "SaeConfiguration",
                                    Header: " ",
                                    accessor: (sae: FigDtos.Sae) =>
                                        <div>
                                            <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); navigate("/sae/" + sae.institutionCode + "/view-sae/" + sae.saeId)}} className="btn btn-sm btn-primary mr-2">View</div>
                                            {
                                                AuthenticationHelper.hasSaeTrogAccess(user) ? 
                                                    <div
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            navigate("/sae/" + sae.institutionCode + "/notify-sae/trial-user/" + TrialUserType.Investigators + "/sae/" + sae.saeId);
                                                        }}
                                                        className="btn btn-sm btn-primary"
                                                    >
                                                        Notify
                                                    </div> : 
                                                    null
                                            }
                                        </div>,
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span>{props.value}</span>,
                                    width: 200
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>
    }

    createAwaitingReviewSaesTable() {

        const {
            awaitingReviewSaes,
            expandAwaitingReviewSaes,
            toggleAwaitingReviewSaes,
            institutionCode,
            institutions,
            user,
            navigate,
            navigateReplace
        } = this.props

        if (!awaitingReviewSaes || awaitingReviewSaes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no SAEs awaiting review.</span>
            </div>
        }

        const showPagination = awaitingReviewSaes.length > DEFAULT_PAGE_SIZE;
        const awaitingReviewSaesIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.LIST} fixedWidth />
            <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CLOCK} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total SAEs"
                title="SAEs Awaiting Review"
                expanded={expandAwaitingReviewSaes}
                toggle={toggleAwaitingReviewSaes}
                count={awaitingReviewSaes.length}
                icon={awaitingReviewSaesIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandAwaitingReviewSaes ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"preregistration-" + institutionCode}
                        data={awaitingReviewSaes}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : awaitingReviewSaes.length}
                        pageSize={showPagination ? undefined : awaitingReviewSaes.length}
                        showPagination={showPagination}
                        className="-striped -highlight"
                        noDataText="There are currently no SAEs awaiting review."
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    //const url: string = "/sae/" + rowInfo.row.institutionCode + '/view-sae/' + rowInfo.row.saeId;
                                    //this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Reg No',
                                    accessor: 'randNo', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientRandomisationNumberColumn
                                },
                                {
                                    id: "saeNumber",
                                    Header: "SAE No",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.saeNumber ?
                                            <span>{sae.saeInitialData.saeNumber}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    width: saeNumberColumn
                                },
                                {
                                    id: "onsetDate",
                                    Header: "Date Onset",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.onsetDate ?
                                            <div><span data-tip={convertToApproximateDateString(sae.saeInitialData.onsetDate)}>{convertToShortDateString(sae.saeInitialData.onsetDate)}</span><ReactTooltip /></div>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "mainEventName",
                                    Header: "Term",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.ctcaeTerm ?
                                            <span>{sae.saeInitialData.ctcaeTerm}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    //width: MainEventNameWidth
                                    minWidth: MainEventNameMinWidth
                                },
                                {
                                    id: "investigatorsName",
                                    Header: "Investigator",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.reportingInvestigator ?
                                            <span>{sae.saeInitialData.reportingInvestigator}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "institutionName",
                                    Header: "Site",
                                    accessor: (sae: FigDtos.Sae) => <span>{sae.institutionName}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: siteColumnWidth
                                },
                                {
                                    id: "SaeConfiguration",
                                    Header: " ",
                                    accessor: (sae: FigDtos.Sae) =>
                                        <div>
                                            <div
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    navigate("/sae/" + sae.institutionCode + "/view-sae/" + sae.saeId)
                                                }}
                                                className="btn btn-sm btn-primary mr-2"
                                            >
                                            {
                                                AuthenticationHelper.hasSaeReviewAccessForInstitutions(user, institutions) ?
                                                    'Review' : 
                                                    'View'
                                            }
                                            </div> 
                                            {
                                                AuthenticationHelper.hasSaeTrogAccess(user) ?
                                                    <div
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            navigate("/sae/" + sae.institutionCode + "/notify-sae/trial-user/" + TrialUserType.Investigators + "/sae/" + sae.saeId)
                                                        }}
                                                        className="btn btn-sm btn-primary"
                                                    >
                                                        Notify
                                                    </div> : 
                                                    null
                                            }
                                        </div>,
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span>{props.value}</span>,
                                    width: 200
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>
    }

    createApprovedSaesTable() {

        const {
            approvedSaes,
            expandApprovedSaes,
            toggleApprovedSaes,
            institutionCode,
            user,
            navigate,
            navigateReplace
        } = this.props

        if (!approvedSaes || approvedSaes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no approved SAEs to view.</span>
            </div>
        }

        const showPagination = approvedSaes.length > DEFAULT_PAGE_SIZE;
        const approvedSaesIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.LIST} fixedWidth />
            <FontAwesomeIcon icon={FontAwesomeIcons.Regular.CHECK} transform="shrink-6 right-6 up-6" color="green" fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total SAEs"
                title="Approved SAEs"
                expanded={expandApprovedSaes}
                toggle={toggleApprovedSaes}
                count={approvedSaes.length}
                icon={approvedSaesIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandApprovedSaes ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"preregistration-" + institutionCode}
                        data={approvedSaes}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : approvedSaes.length}
                        pageSize={showPagination ? undefined : approvedSaes.length}
                        showPagination={showPagination}
                        className="-striped -highlight"
                        noDataText="There are currently no approved SAEs to view."
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    //const url: string = "/sae/" + rowInfo.row.institutionCode + '/view-sae/' + rowInfo.row.saeId;
                                    //this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Reg No',
                                    accessor: 'randNo', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientRandomisationNumberColumn
                                },
                                {
                                    id: "saeNumber",
                                    Header: "SAE No",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.saeNumber ?
                                            <span>{sae.saeInitialData.saeNumber}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    width: saeNumberColumn
                                },
                                {
                                    id: "onsetDate",
                                    Header: "Date Onset",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.onsetDate ?
                                            <div><span data-tip={convertToApproximateDateString(sae.saeInitialData.onsetDate)}>{convertToShortDateString(sae.saeInitialData.onsetDate)}</span><ReactTooltip /></div>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "mainEventName",
                                    Header: "Term",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.ctcaeTerm ?
                                            <span>{sae.saeInitialData.ctcaeTerm}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    //width: MainEventNameWidth
                                    minWidth: MainEventNameMinWidth
                                },
                                {
                                    id: "investigatorsName",
                                    Header: "Investigator",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.reportingInvestigator ?
                                            <span>{sae.saeInitialData.reportingInvestigator}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "institutionName",
                                    Header: "Site",
                                    accessor: (sae: FigDtos.Sae) => <span>{sae.institutionName}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: siteColumnWidth
                                },
                                // View column -- only for SAE staff
                                {
                                    id: "SaeConfiguration",
                                    Header: " ",
                                    accessor: (sae: FigDtos.Sae) =>
                                        <div>
                                            <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); navigate("/sae/" + sae.institutionCode + "/view-sae/" + sae.saeId) }} className="btn btn-sm btn-primary">View</div>
                                        </div>,
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span>{props.value}</span>,
                                    width: 200
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>
    }

    createRejectedSaesTable() {

        const {
            rejectedSaes,
            expandRejectedSaes,
            toggleRejectedSaes,
            institutionCode,
            user,
            navigate,
            navigateReplace
        } = this.props

        if (!rejectedSaes || rejectedSaes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no rejected SAEs to view.</span>
            </div>
        }

        const showPagination = rejectedSaes.length > DEFAULT_PAGE_SIZE;
        const rejectedSaesIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.LIST} fixedWidth />
            <FontAwesomeIcon icon={FontAwesomeIcons.Regular.TIMES} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total SAEs"
                title="Rejected SAEs"
                expanded={expandRejectedSaes}
                toggle={toggleRejectedSaes}
                count={rejectedSaes.length}
                icon={rejectedSaesIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandRejectedSaes ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"preregistration-" + institutionCode}
                        data={rejectedSaes}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : rejectedSaes.length}
                        pageSize={showPagination ? undefined : rejectedSaes.length}
                        showPagination={showPagination}
                        className="-striped -highlight"
                        noDataText="There are currently no rejected SAEs to view."
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    //const url: string = "/sae/" + rowInfo.row.institutionCode + '/view-sae/' + rowInfo.row.saeId;
                                    //this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Reg No',
                                    accessor: 'randNo', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientRandomisationNumberColumn
                                },
                                {
                                    id: "saeNumber",
                                    Header: "SAE No",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.saeNumber ?
                                            <span>{sae.saeInitialData.saeNumber}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    width: saeNumberColumn
                                },
                                {
                                    id: "onsetDate",
                                    Header: "Date Onset",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.onsetDate ?
                                            <div><span data-tip={convertToApproximateDateString(sae.saeInitialData.onsetDate)}>{convertToShortDateString(sae.saeInitialData.onsetDate)}</span><ReactTooltip /></div>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "mainEventName",
                                    Header: "Term",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.ctcaeTerm ?
                                            <span>{sae.saeInitialData.ctcaeTerm}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    //width: MainEventNameWidth
                                    minWidth: MainEventNameMinWidth
                                },
                                {
                                    id: "investigatorsName",
                                    Header: "Investigator",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.reportingInvestigator ?
                                            <span>{sae.saeInitialData.reportingInvestigator}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "institutionName",
                                    Header: "Site",
                                    accessor: (sae: FigDtos.Sae) => <span>{sae.institutionName}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: siteColumnWidth
                                },
                                // View column -- only for SAE staff
                                {
                                    id: "SaeConfiguration",
                                    Header: " ",
                                    accessor: (sae: FigDtos.Sae) =>
                                        <div>
                                            <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); navigate("/sae/" + sae.institutionCode + "/view-sae/" + sae.saeId) }} className="btn btn-sm btn-primary">View</div>
                                        </div>,
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span>{props.value}</span>,
                                    width: 200
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>
    }

    createIncorrectlyIdentifiedSaesTable() {

        const {
            incorrectlyIdentifiedSaes,
            expandIncorrectlyIdentifiedSaes,
            toggleIncorrectlyIdentifiedSaes,
            institutionCode,
            user,
            navigate,
            navigateReplace
        } = this.props

        if (!incorrectlyIdentifiedSaes || incorrectlyIdentifiedSaes.length == 0) {
            return <div className="alert alert-info d-flex">
                <div className="mr-2">
                    <FontAwesomeIcon icon={FontAwesomeIcons.Solid.INFO_SQUARE} fixedWidth />
                </div>
                <span>There are currently no incorrectly identified SAEs to view.</span>
            </div>
        }

        const showPagination = incorrectlyIdentifiedSaes.length > DEFAULT_PAGE_SIZE;
        const incorrectlyIdentifiedIcon = <span className="fa-layers fa-fw mr-2">
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.SQUARE} fixedWidth transform="grow-16" color="lightgray" />
            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.LIST} fixedWidth />
            <FontAwesomeIcon icon={FontAwesomeIcons.Regular.TIMES_CIRCLE} transform="shrink-6 right-6 up-6" color="red" fixedWidth />
        </span>

        return <div className="table-wrapper mb-3">
            <TableHeader
                badgeText="Total SAEs"
                title="Incorrectly Identified SAEs"
                expanded={expandIncorrectlyIdentifiedSaes}
                toggle={toggleIncorrectlyIdentifiedSaes}
                count={incorrectlyIdentifiedSaes.length}
                icon={incorrectlyIdentifiedIcon}
            />
            <AnimateHeight
                duration={500}
                height={expandIncorrectlyIdentifiedSaes ? "auto" : "0"}
            >
                {
                    <ReactTable
                        key={"preregistration-" + institutionCode}
                        data={incorrectlyIdentifiedSaes}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        defaultPageSize={showPagination ? DEFAULT_PAGE_SIZE : incorrectlyIdentifiedSaes.length}
                        pageSize={showPagination ? undefined : incorrectlyIdentifiedSaes.length}
                        showPagination={showPagination}
                        className="-striped -highlight"
                        noDataText="There are currently no incorrectly identified SAEs to view."
                        resizable={false}
                        getTdProps={(state, rowInfo: RowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {

                                    //const url: string = "/sae/" + rowInfo.row.institutionCode + '/view-sae/' + rowInfo.row.saeId;
                                    //this.props.navigate(url);

                                    if (handleOriginal) {
                                        handleOriginal()
                                    }

                                }
                            }
                        }}
                        columns={
                            [
                                {
                                    Header: 'Reg No',
                                    accessor: 'randNo', // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: patientRandomisationNumberColumn
                                },
                                {
                                    id: "saeNumber",
                                    Header: "SAE No",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.saeNumber ?
                                            <span>{sae.saeInitialData.saeNumber}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    width: saeNumberColumn
                                },
                                {
                                    id: "onsetDate",
                                    Header: "Date Onset",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.onsetDate ?
                                            <div><span data-tip={convertToApproximateDateString(sae.saeInitialData.onsetDate)}>{convertToShortDateString(sae.saeInitialData.onsetDate)}</span><ReactTooltip /></div>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "mainEventName",
                                    Header: "Term",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.ctcaeTerm ?
                                            <span>{sae.saeInitialData.ctcaeTerm}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                    //width: MainEventNameWidth
                                    minWidth: MainEventNameMinWidth
                                },
                                {
                                    id: "investigatorsName",
                                    Header: "Investigator",
                                    accessor: (sae: FigDtos.Sae) => sae.saeInitialData ?
                                        sae.saeInitialData.reportingInvestigator ?
                                            <span>{sae.saeInitialData.reportingInvestigator}</span>
                                            : ''
                                        : '',
                                    className: "d-flex align-items-center justify-content-center",
                                },
                                {
                                    id: "institutionName",
                                    Header: "Site",
                                    accessor: (sae: FigDtos.Sae) => <span>{sae.institutionName}</span>, // String-based value accessors!
                                    className: "d-flex align-items-center justify-content-center",
                                    width: siteColumnWidth
                                },
                                // View column -- only for SAE staff
                                {
                                    id: "SaeConfiguration",
                                    Header: " ",
                                    accessor: (sae: FigDtos.Sae) =>
                                        <div>
                                            <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); navigate("/sae/" + sae.institutionCode + "/view-sae/" + sae.saeId) }} className="btn btn-sm btn-primary mr-2">View</div>
                                        </div>,
                                    className: "d-flex align-items-center justify-content-center",
                                    Cell: (props) => <span>{props.value}</span>,
                                    width: 200
                                }
                            ]}
                    />
                }
            </AnimateHeight>
        </div>
    }
    createSubMenuItems(): INavItem[] {

        const {
            institutionCode,
            loadingInstitutions,
            //loadingPatients,
            //loadPatientsSuccess,
            loadInstitutionsSuccess
        } = this.props;

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionCode ? this.getInstitutionName(institutionCode) : "All Institutions"
            }
        ]
    }

    getInstitutionName(institutionCode: string): string {

        const institution = this.getInstitution(institutionCode);

        if (institution) {
            return institution.institutionName;
        }

        return undefined;
    }

    getInstitution(institutionCode: string): FigDtos.Institution {
        const { institutions } = this.props;

        if (institutions && institutionCode) {
            return institutions.find(i => i.institutionCode == institutionCode);
        }

        return undefined;
    }

}

const filterSaesByInstitutionCode = (saes: FigDtos.Sae[], institutionCode: string): FigDtos.Sae[] => {

    if (saes) {
        return saes.filter(sae => !institutionCode || sae.institutionCode == institutionCode);
    }

    return [];
}

const filterInstitutionsByCountry = (institutions: FigDtos.Institution[], country: string): FigDtos.Institution[] => {
    if (institutions) {
        return institutions.filter(i => !country || i.country == country);
    }

    return [];
}

const filterSaesByCountry = (saes: FigDtos.Sae[], institutions: FigDtos.Institution[], country: string): FigDtos.Sae[] => {

    if (saes) {
        return saes.filter(sae => !country ||
            institutions && institutions.find(i => i.institutionCode == sae.institutionCode && i.country == country));
    }

    return [];
}

const filterSaesByStatus = (saes: FigDtos.Sae[], status: FigDtos.SaeStatus): FigDtos.Sae[] => {

    if (saes) {
        return saes.filter(sae => sae.saeStatus == status);
    }

    return [];
}


const mapStateToProps = (state: IFigState, ownProps: SaePageProps): ISaePageProps => {
    let institutions: FigDtos.Institution[] = state.institutions.data instanceof Array ? state.institutions.data as FigDtos.Institution[] : undefined
    const countries: string[] = institutions ? Array.from(new Set(institutions.filter(c => c.country != undefined).map(country => country.country))) : [];

    institutions = filterInstitutionsByCountry(institutions, state.saePage.country);
    const institutionCode: string = ownProps.match ? ownProps.match.params.institutionCode : undefined;

    const saes: FigDtos.Sae[] =
        state.saes.data instanceof Array ?
            filterSaesByCountry(filterSaesByInstitutionCode(state.saes.data, institutionCode), institutions, state.saePage.country) :
            undefined


    return {

        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,
        saes: saes,
        newSaes: filterSaesByStatus(saes, FigDtos.SaeStatus.New),
        awaitingReviewSaes: filterSaesByStatus(saes, FigDtos.SaeStatus.AwaitingReview),
        approvedSaes: filterSaesByStatus(saes, FigDtos.SaeStatus.Approved),
        rejectedSaes: filterSaesByStatus(saes, FigDtos.SaeStatus.Rejected),
        incorrectlyIdentifiedSaes: filterSaesByStatus(saes, FigDtos.SaeStatus.IncorrectlyIdentified),

        expandNewSaes: state.saePage.expandNewSaes,
        expandAwaitingReviewSaes: state.saePage.expandAwaitingReviewSaes,
        expandApprovedSaes: state.saePage.expandApprovedSaes,
        expandRejectedSaes: state.saePage.expandRejectedSaes,
        expandIncorrectlyIdentifiedSaes: state.saePage.expandIncorrectlyIdentifiedSaes,

        loadingSaes: state.saes.loadState && state.saes.loadState.status == RequestState.Pending,
        loadSaesSuccess: state.saes.loadState && state.saes.loadState.status == RequestState.Success,
        loadSaesFailure: state.saes.loadState && state.saes.loadState.status == RequestState.Failure,

        institutions: institutions,
        loadingInstitutions: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionsSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionsFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        countries: countries,
        country: state.saePage.country,

        user: state.user.data
    };
};

const mapDispatchToProps = (dispatch): ISaePageActions => {
    return {

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadSaes: bindActionCreators(SaeActions.LoadSaesSummaries, dispatch),
        clearSaes: bindActionCreators(SaeActions.Clear, dispatch),

        toggleNewSaes: bindActionCreators(SaePageActions.toggleNew, dispatch),
        toggleAwaitingReviewSaes: bindActionCreators(SaePageActions.toggleAwaitingReview, dispatch),
        toggleApprovedSaes: bindActionCreators(SaePageActions.toggleApproved, dispatch),
        toggleRejectedSaes: bindActionCreators(SaePageActions.toggleRejected, dispatch),
        toggleIncorrectlyIdentifiedSaes: bindActionCreators(SaePageActions.toggleIncorrectlyIdentified, dispatch),

        loadInstitutions: bindActionCreators(InstitutionActions.LoadInstitutions, dispatch),
        clearInstitutions: bindActionCreators(InstitutionActions.Clear, dispatch),

        updateCountry: bindActionCreators(SaePageActions.updateCountry, dispatch),


    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(SaePage);
