import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_PATIENTS_LOAD,
    ACTION_PATIENTS_LOAD_BY_INSTITUTION,
    ACTION_PATIENTS_LOAD_BY_IDENTIFIER,
    ACTION_PATIENTS_LOAD_BY_ID,
    ACTION_PATIENTS_LOAD_BY_STATUS,
    IPatientLoadAction,
    IPatientLoadByInstitutionAction,
    IPatientLoadByStatusAction,
    IPatientLoadByIdentifierAction,
    IPatientLoadByIdAction,
    IPatientLoadFailureAction,
    IPatientLoadSuccessAction,
    LoadSuccess,
    LoadFailure,

    ACTION_PATIENT_SET_STATUS_BY_ID,
    ACTION_PATIENT_SET_STATUS_SUCCESS,
    ACTION_PATIENT_SET_STATUS_FAILURE,
    IPatientSetStatusByIdAction,
    IPatientSetStatusSuccessAction,
    IPatientSetStatusFailureAction,
    SetPatientStatusSuccess,
    SetPatientStatusFailure,

    ACTION_PATIENTS_RESENDEMAIL_BY_ID,
    IResendEmailForPatientByIdAction,
    IResendEmailForPatientSuccessAction,
    IResendEmailForPatientFailureAction,
    ResendEmailForPatientSuccess,
    ResendEmailForPatientFailure
} from "../actions/patient";
import { patientApi } from "../services/patients";


export const
    getPatientsEpic = (action$): Observable<IPatientLoadSuccessAction | IPatientLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTS_LOAD)
            .mergeMap((action: IPatientLoadAction) => {
                return patientApi
                    .getPatients()
                    .map(response =>
                        LoadSuccess(response.patients)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientsByInstitutionEpic = (action$): Observable<IPatientLoadSuccessAction | IPatientLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTS_LOAD_BY_INSTITUTION)
            .mergeMap((action: IPatientLoadByInstitutionAction) => {
                return patientApi
                    .getPatientsByInstitution(action.institutionCode)
                    .map(response =>
                        LoadSuccess(response.patients)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientsByStatusEpic = (action$): Observable<IPatientLoadSuccessAction | IPatientLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTS_LOAD_BY_STATUS)
            .mergeMap((action: IPatientLoadByStatusAction) => {
                return patientApi
                    .getPatientsByStatus(action.status)
                    .map(response =>
                        LoadSuccess(response.patients)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientsByIdentifierEpic = (action$): Observable<IPatientLoadSuccessAction | IPatientLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTS_LOAD_BY_IDENTIFIER)
            .mergeMap((action: IPatientLoadByIdentifierAction) => {
                return patientApi
                    .getPatientByIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.patient)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getPatientsByIdEpic = (action$): Observable<IPatientLoadSuccessAction | IPatientLoadFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTS_LOAD_BY_ID)
            .mergeMap((action: IPatientLoadByIdAction) => {
                return patientApi
                    .getPatientById(action.id)
                    .map(response =>
                        LoadSuccess(response.patient)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setPatientStatusByIdEpic = (action$): Observable<IPatientSetStatusSuccessAction | IPatientSetStatusFailureAction> => {
        return action$
            .ofType(ACTION_PATIENT_SET_STATUS_BY_ID)
            .mergeMap((action: IPatientSetStatusByIdAction) => {
                return patientApi
                    .setPatientStatusById(action.id, action.status, action.statusConfiguration)
                    .map(response =>
                        SetPatientStatusSuccess(response.statusUpdated)
                    )
                    .catch(error =>
                        Observable.of(SetPatientStatusFailure(error.responseStatus))
                    )
            });
    },
    resendEmailForPatientByIdEpic = (action$): Observable<IResendEmailForPatientSuccessAction | IResendEmailForPatientFailureAction> => {
        return action$
            .ofType(ACTION_PATIENTS_RESENDEMAIL_BY_ID)
            .mergeMap((action: IResendEmailForPatientByIdAction) => {
                return patientApi
                    .resendEmailForPatientById(action.id)
                    .map(response =>
                        ResendEmailForPatientSuccess()
                    )
                    .catch(error =>
                        Observable.of(ResendEmailForPatientFailure())
                    )
            });
    }

    

