import * as Dtos from "../dtos/Fig.dtos";

export const
    LoadInstitutions: IInstitutionLoadActionCreator = () => {
        return {
            type: ACTION_INSTITUTIONS_LOAD
        }
    },
    LoadInstitutionByInstitutionCode: IInstitutionLoadByInstitutionCodeActionCreator = (institutionCode) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE,
            institutionCode
        }
    },
    LoadInstitutionByCountry: IInstitutionLoadByCountryActionCreator = (country) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_BY_COUNTRY,
            country
        }
    },
    LoadInstitutionByState: IInstitutionLoadByStateActionCreator = (state) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_BY_STATE,
            state
        }
    },
    LoadSuccess: IInstitutionLoadSuccessActionCreator = (data) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_SUCCESS,
            data
        }
    },
    LoadFailure: IInstitutionLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_INSTITUTIONS_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: IInstitutionClearActionCreator = () => {
        return {
            type: ACTION_INSTITUTIONS_CLEAR
        }
    };


export const
    ACTION_INSTITUTIONS_LOAD = "ACTION_INSTITUTIONS_LOAD",
    ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE = "ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE",
    ACTION_INSTITUTIONS_LOAD_BY_COUNTRY = "ACTION_INSTITUTIONS_LOAD_BY_COUNTRY",
    ACTION_INSTITUTIONS_LOAD_BY_STATE = "ACTION_INSTITUTIONS_LOAD_BY_STATE",
    ACTION_INSTITUTIONS_LOAD_SUCCESS = "ACTION_INSTITUTIONS_LOAD_SUCCESS",
    ACTION_INSTITUTIONS_LOAD_FAILURE = "ACTION_INSTITUTIONS_LOAD_FAILURE",
    ACTION_INSTITUTIONS_CLEAR = "ACTION_INSTITUTIONS_CLEAR";

export interface IInstitutionLoadAction {
    type: "ACTION_INSTITUTIONS_LOAD";
}

export interface IInstitutionLoadActionCreator {
    (): IInstitutionLoadAction;
}

export interface IInstitutionLoadByInstitutionCodeAction {
    type: "ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE";
    institutionCode: string;
}

export interface IInstitutionLoadByInstitutionCodeActionCreator {
    (institutionCode: string): IInstitutionLoadByInstitutionCodeAction;
}

export interface IInstitutionLoadByCountryAction {
    type: "ACTION_INSTITUTIONS_LOAD_BY_COUNTRY";
    country: string;
}

export interface IInstitutionLoadByCountryActionCreator {
    (country: string): IInstitutionLoadByCountryAction;
}

export interface IInstitutionLoadByStateAction {
    type: "ACTION_INSTITUTIONS_LOAD_BY_STATE";
    state: number;
}

export interface IInstitutionLoadByStateActionCreator {
    (state: number): IInstitutionLoadByStateAction;
}

export interface IInstitutionLoadSuccessAction {
    type: "ACTION_INSTITUTIONS_LOAD_SUCCESS";
    data: Dtos.Institution | Dtos.Institution[];
}

export interface IInstitutionLoadSuccessActionCreator {
    (data: Dtos.Institution | Dtos.Institution[]): IInstitutionLoadSuccessAction;
}

export interface IInstitutionLoadFailureAction {
    type: "ACTION_INSTITUTIONS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IInstitutionLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IInstitutionLoadFailureAction;
}

export interface IInstitutionClearAction {
    type: "ACTION_INSTITUTIONS_CLEAR";
}

export interface IInstitutionClearActionCreator {
    (): IInstitutionClearAction;
}

export type InstitutionAction = IInstitutionLoadAction |
    IInstitutionLoadByInstitutionCodeAction |
    IInstitutionLoadByCountryAction |
    IInstitutionLoadByStateAction |
    IInstitutionLoadFailureAction |
    IInstitutionLoadSuccessAction | 
    IInstitutionClearAction;