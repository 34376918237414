import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as FigDtos from "../dtos/Fig.dtos";
import { TrialUserType } from "../enumerations/TrialUserType";

export const saeApi = {
    getSaes: (): Observable<FigDtos.GetSaesResponse> => {
        //Create the request.
        let request: FigDtos.GetSaes = new FigDtos.GetSaes();

        //Send request.
        const response: Promise<FigDtos.GetSaesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getSaesByInstitution: (institutionCode: string): Observable<FigDtos.GetSaesByInstitutionResponse> => {
        //Create the request.
        let request: FigDtos.GetSaesByInstitution = new FigDtos.GetSaesByInstitution();

        request.institutionCode = institutionCode;

        //Send request.
        const response: Promise<FigDtos.GetSaesByInstitutionResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getSaeBySaeId: (saeId: number): Observable<FigDtos.GetSaeBySaeIdResponse> => {
        //Create the request.
        let request: FigDtos.GetSaeBySaeId = new FigDtos.GetSaeBySaeId();

        request.saeId = saeId;

        //Send request.
        const response: Promise<FigDtos.GetSaeBySaeIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getSaeSummaries: (): Observable<FigDtos.GetSaeSummariesResponse> => {
        //Create the request.
        let request: FigDtos.GetSaeSummaries = new FigDtos.GetSaeSummaries();

        //Send request.
        const response: Promise<FigDtos.GetSaeSummariesResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    processNotificationNotRequired: (institutionCode: string, saeId: number): Observable<FigDtos.ProcessSaeNotificationNotRequiredResponse> => {
        //Create the request.
        let request: FigDtos.ProcessSaeNotificationNotRequired = new FigDtos.ProcessSaeNotificationNotRequired();

        request.institutionCode = institutionCode;
        request.saeId = saeId;

        //Send request.
        const response: Promise<FigDtos.ProcessSaeNotificationNotRequiredResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    processReset: (institutionCode: string, saeId: number): Observable<FigDtos.ProcessSaeResetResponse> => {
        //Create the request.
        let request: FigDtos.ProcessSaeReset = new FigDtos.ProcessSaeReset();

        request.institutionCode = institutionCode;
        request.saeId = saeId;

        //Send request.
        const response: Promise<FigDtos.ProcessSaeResetResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    processNotify: (saeNotifyFormData: FigDtos.SaeNotifyFormData, institutionCode: string, saeId: number, trialUserType: FigDtos.TrialUserType): Observable<FigDtos.ProcessSaeNotifyResponse> => {

        let request: FigDtos.ProcessSaeNotify = new FigDtos.ProcessSaeNotify();

        request.saeNotifyFormData = saeNotifyFormData;
        request.institutionCode = institutionCode;
        request.saeId = saeId;
        request.trialUserType = trialUserType;

        // send request
        const response: Promise<FigDtos.ProcessSaeNotifyResponse> = client.post(request);

        return Observable.from(response);

    },
    processReview: (saeNotifyFormData: FigDtos.SaeReviewFormData, institutionCode: string, saeId: number, saeReviewType: FigDtos.SaeReviewType): Observable<FigDtos.ProcessSaeReviewResponse> => {

        let request: FigDtos.ProcessSaeReview = new FigDtos.ProcessSaeReview();

        request.saeReviewFormData = saeNotifyFormData;
        request.institutionCode = institutionCode;
        request.saeId = saeId;
        request.saeReviewType = saeReviewType;

        // send request
        const response: Promise<FigDtos.ProcessSaeReviewResponse> = client.post(request);

        return Observable.from(response);

    },

}