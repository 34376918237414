import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as FigDtos from '../../../dtos/Fig.dtos';

import {
    ACTION_SAE_VIEW_PAGE_TOGGLE_ACTION_HISTORY,
    ACTION_SAE_VIEW_PAGE_TOGGLE_MEDICATION,
    ACTION_SAE_VIEW_PAGE_TOGGLE_STUDY_DRUG,
    ACTION_SAE_VIEW_PAGE_TOGGLE_TREATMENT,
    ACTION_SAE_VIEW_PAGE_CLEAR,
    SaeViewPageAction
} from '../../../actions/pages/sae/saeViewPage';


export interface ISaeViewPageState {
    expandTreatmentData: boolean;
    expandStudyDrugData: boolean;
    expandMedicationData: boolean;
    expandActionHistoryData: boolean;
}

const initialState: ISaeViewPageState = {
    expandTreatmentData: true,
    expandStudyDrugData: true,
    expandMedicationData: true,
    expandActionHistoryData: true,
};


function toggleTreatmentData(state: ISaeViewPageState): ISaeViewPageState {
    const newState: ISaeViewPageState = update(state, {
        expandTreatmentData: {
            $set: !state.expandTreatmentData
        }
    });

    return newState;
}

function toggleStudyDrugData(state: ISaeViewPageState): ISaeViewPageState {
    const newState: ISaeViewPageState = update(state, {
        expandStudyDrugData: {
            $set: !state.expandStudyDrugData
        }
    });

    return newState;
}

function toggleMedicationData(state: ISaeViewPageState): ISaeViewPageState {
    const newState: ISaeViewPageState = update(state, {
        expandMedicationData: {
            $set: !state.expandMedicationData
        }
    });

    return newState;
}

function toggleActionHistoryData(state: ISaeViewPageState): ISaeViewPageState {
    const newState: ISaeViewPageState = update(state, {
        expandActionHistoryData: {
            $set: !state.expandActionHistoryData
        }
    });

    return newState;
}

function clear(state: ISaeViewPageState): ISaeViewPageState {
    const newState: ISaeViewPageState = update(state, {
        expandTreatmentData: {
            $set: true
        },
        expandStudyDrugData: {
            $set: true
        },
        expandMedicationData: {
            $set: true
        },
        expandActionHistoryData: {
            $set: true
        }
    });
    return newState;
}


const SaeViewPageReducer: Reducer<ISaeViewPageState> = (state: ISaeViewPageState = initialState, action: SaeViewPageAction) => {
    switch (action.type) {
        case ACTION_SAE_VIEW_PAGE_TOGGLE_TREATMENT:
            return toggleTreatmentData(state);
        case ACTION_SAE_VIEW_PAGE_TOGGLE_STUDY_DRUG:
            return toggleStudyDrugData(state);
        case ACTION_SAE_VIEW_PAGE_TOGGLE_MEDICATION:
            return toggleMedicationData(state);
        case ACTION_SAE_VIEW_PAGE_TOGGLE_ACTION_HISTORY:
            return toggleActionHistoryData(state);
        case ACTION_SAE_VIEW_PAGE_CLEAR:
            return clear(state);
    }

    return state;
}

export default SaeViewPageReducer;