import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleMessageModal: IEligibilityCriteriaFormPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORM_PAGE_TOGGLE_MESSAGE_MODAL
        }
    }

export const
    ACTION_ELIGIBILITYCRITERIAFORM_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_ELIGIBILITYCRITERIAFORM_PAGE_TOGGLE_MESSAGE_MODAL"

/* Actions */

export interface IEligibilityCriteriaFormPageToggleMessageModalAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORM_PAGE_TOGGLE_MESSAGE_MODAL"
}

/* Action Creators */
export interface IEligibilityCriteriaFormPageToggleMessageModalActionCreator {
    (): IEligibilityCriteriaFormPageToggleMessageModalAction;
}


/* Final Export of actions */
export type EligibilityCriteriaFormPageAction = IEligibilityCriteriaFormPageToggleMessageModalAction;
