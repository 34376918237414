import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';

import {
    ACTION_ELIGIBILITYCRITERIAFORMS_CLEAR,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_FAILURE,
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_SUCCESS,

    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE,
    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_FAILURE,
    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_SUCCESS,

    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE,
    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_SUCCESS,
    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_FAILURE,

    IEligibilityCriteriaFormLoadAction,
    IEligibilityCriteriaFormClearAction,
    IEligibilityCriteriaFormLoadByIdAction,
    IEligibilityCriteriaFormLoadByPatientIdAction,
    IEligibilityCriteriaFormLoadByPatientIdentifierAction,
    IEligibilityCriteriaFormLoadFailureAction,
    IEligibilityCriteriaFormLoadSuccessAction,

    IEligibilityCriteriaFormSaveAction,
    IEligibilityCriteriaFormSaveSuccessAction,
    IEligibilityCriteriaFormSaveFailureAction,

    IEligibilityCriteriaFormCreateAction,
    IEligibilityCriteriaFormCreateSuccessAction,
    IEligibilityCriteriaFormCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure


} from "../../actions/forms/eligibilityCriteriaForm";

import { eligibilityCriteriaFormApi } from "../../services/Forms/eligibilityCriteriaForm";


export const
    getEligibilityCriteriaFormsEpic = (action$): Observable<IEligibilityCriteriaFormLoadSuccessAction | IEligibilityCriteriaFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_ELIGIBILITYCRITERIAFORMS_LOAD)
            .mergeMap((action: IEligibilityCriteriaFormLoadAction) => {
                return eligibilityCriteriaFormApi
                    .getEligibilityCriteriaForms()
                    .map(response =>
                        LoadSuccess(response.eligibilityCriteriaForms, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getEligibilityCriteriaFormsByIdEpic = (action$): Observable<IEligibilityCriteriaFormLoadSuccessAction | IEligibilityCriteriaFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID)
            .mergeMap((action: IEligibilityCriteriaFormLoadByIdAction) => {
                return eligibilityCriteriaFormApi
                    .getEligibilityCriteriaFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.eligibilityCriteriaForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getEligibilityCriteriaFormsByPatientIdEpic = (action$): Observable<IEligibilityCriteriaFormLoadSuccessAction | IEligibilityCriteriaFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID)
            .mergeMap((action: IEligibilityCriteriaFormLoadByPatientIdAction) => {
                return eligibilityCriteriaFormApi
                    .getEligibilityCriteriaFormByPatientId(action.patientId)
                    .map(response =>
                        LoadSuccess(response.eligibilityCriteriaForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getEligibilityCriteriaFormsByPatientIdentifierEpic = (action$): Observable<IEligibilityCriteriaFormLoadSuccessAction | IEligibilityCriteriaFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER)
            .mergeMap((action: IEligibilityCriteriaFormLoadByPatientIdentifierAction) => {
                return eligibilityCriteriaFormApi
                    .getEligibilityCriteriaFormByPatientIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.eligibilityCriteriaForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setEligibilityCriteriaFormsEpic = (action$): Observable<IEligibilityCriteriaFormSaveSuccessAction | IEligibilityCriteriaFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_ELIGIBILITYCRITERIAFORMS_SAVE)
            .mergeMap((action: IEligibilityCriteriaFormSaveAction) => {
                return eligibilityCriteriaFormApi
                    .setEligibilityCriteriaForm(action.formData, action.institutionCode, action.patientIdentifier)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.eligibilityCriteriaForm, response.registrationFormState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createEligibilityCriteriaFormsEpic = (action$): Observable<IEligibilityCriteriaFormCreateSuccessAction | IEligibilityCriteriaFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_ELIGIBILITYCRITERIAFORMS_CREATE)
            .mergeMap((action: IEligibilityCriteriaFormCreateAction) => {
                return eligibilityCriteriaFormApi
                    .createEligibilityCriteriaForm()
                    .map(response =>
                        CreateSuccess(response.eligibilityCriteriaForm, response.registrationFormState, response.registrationFormProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }





