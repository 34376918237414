
import * as React from 'react';
import { Router, Route, Switch, match, matchPath } from 'react-router';

import * as AuthenticationHelpers from '../../../helpers/authentication';
import * as Dtos from '../../../dtos/Fig.dtos';
import { OverlayLoading, NotificationAlert } from '..';
import { IAuthorisationDefinition } from '../../../interfaces/authentication/authorisationDefinition';
import { FontAwesomeIcons } from '../../../constants/fontAwesomeIcons';
import Alert from 'react-s-alert';
import { IFigState } from '../../../reducers';
import { connect } from 'react-redux';

interface ICreateAuthorisationDefinitions<T> {
    (path: string, location: Location, user: Dtos.User, match: match<T>) : IAuthorisationDefinition[]
}

export interface ICreateAuthorisationsFn<T> {
    (path: string, location: Location, user: Dtos.User, match: match<T>): boolean
}

interface IAuthenticatedRouteProps {
    user?: Dtos.User;
    loadingUser?: boolean;
    loadUserSuccess?: boolean;
    loadUserFailure?: boolean;
    checkedAuthentication?: boolean;
    authFn?: ICreateAuthorisationsFn<any>;
    authenticationDefinitions?: IAuthorisationDefinition[];
    createAuthorisationDefinitions?: ICreateAuthorisationDefinitions<any>
    path?: string;
    component?: any;
    location?: Location;
    navigate?: Function;
    institutionCode?: string;
    strict?: boolean;
    exact?: boolean;
    
}

interface IAuthenticatedRouteActions {

}

type AuthenticatedRouteProps = IAuthenticatedRouteProps & IAuthenticatedRouteActions

class AuthenticatedRoute extends React.Component<AuthenticatedRouteProps, any> {

    render() {
        const {
            user,
            path,
            component,
            authFn,
            authenticationDefinitions,
            navigate,
            location,
            loadingUser,
            loadUserFailure,
            loadUserSuccess,
            checkedAuthentication,
            institutionCode,
            createAuthorisationDefinitions,
            strict,
            exact
        } = this.props;

        if (!checkedAuthentication) {
            return <OverlayLoading />;
        }
        else if (checkedAuthentication && !user) {
            window.location.href = "/auth/openiddict?ReturnUrl=" + encodeURIComponent(location!.pathname + location!.search);
            return <OverlayLoading />;
        }
        else {

            let derivedAuthenticationDefinitions = authenticationDefinitions;

            //Authenticate user.
            if (!derivedAuthenticationDefinitions && createAuthorisationDefinitions) {
                let match: match<any> = this.computeMatch(location, path, strict, exact)!;
                derivedAuthenticationDefinitions = createAuthorisationDefinitions(path!, location!, user!, match);
            }

            if (authFn && authFn(path, location, user, this.computeMatch(location, path, strict, exact))) {
                return <Route path={path} component={component} strict={strict} exact={exact} />
            }
            else if (AuthenticationHelpers.authenticate(user!, derivedAuthenticationDefinitions)) {
                return <Route path={path} component={component} strict={strict} exact={exact} />
            }
            else {
                if (user) {
                    navigate!('/error/403');
                }
                else {
                    Alert.error(
                        <NotificationAlert
                            alertContent={<div>
                                    User Authentication Error.
                                </div>
                            }
                            icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                        />
                    );
                }

                return <OverlayLoading />;
            }
        }
    }

    computeMatch(location, path, strict, exact) {
        const pathname = location.pathname;

        return matchPath(pathname, { path, strict, exact });
    }
}

const mapStateToProps = (state: IFigState, ownProps: AuthenticatedRouteProps): IAuthenticatedRouteProps => {

    return {

    };
};

const mapDispatchToProps = (dispatch): IAuthenticatedRouteActions => {
    return {

    };
};

export default
    connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);