import './NavMenu.scss';

import * as React from 'react';
import classNames from "classnames";
import * as FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import * as Dtos from '../../../dtos/Fig.dtos';
import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';
import { flow } from "lodash";
import { Link } from "../../routing/index";
import { IFigState } from '../../../reducers';
import { connect } from 'react-redux';
import { isPharmacist } from '../../../helpers/authentication';
import { isMedicalReviewer } from '../../../helpers/authentication';
import { AppSettings } from '../../../helpers/AppSettings';

const isProduction = AppSettings.buildMode === 'Production';

interface INavMenuProps {
    user?: Dtos.User,
    institutions?: Dtos.Institution | Dtos.Institution[]
}

interface INavMenuActions {

}

export interface INavItem {
    url?: string;
    target?: string;
    icon: React.ReactNode;
    title: string;
}

type NavMenuProps = INavMenuProps & INavMenuActions;

class NavMenu extends React.PureComponent<INavMenuProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {

        const {
            user,
            institutions
        } = this.props

        let hasRegistrationAccess: boolean = false;
        let hasWebsiteSettingsAccess: boolean = false;
        if (user) {
            hasRegistrationAccess = AuthenticationHelper.authenticate(user) && !isPharmacist(user);
            hasWebsiteSettingsAccess = AuthenticationHelper.authenticate(user) && AuthenticationHelper.isTrogOrSuperUser(user);
        }         

        return <div className="container-fluid bg-primary">
            <nav className="container Capture-nav p-0 d-flex" style={{minHeight: 40}}>
                {
                    hasRegistrationAccess ?
                        <Link className="nav-link" url="/registration">
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.USER} fixedWidth />
                            <span>
                                &emsp;Registration
                            </span>
                        </Link> :
                        null
                }
                {
                    (!isProduction && AuthenticationHelper.hasSaeAccess(user, institutions)) ?
                        <Link className="nav-link" url="/sae">
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.LIST} fixedWidth />
                            <span>
                                &emsp;SAE Electronic Review
                            </span>
                        </Link> :
                        null
                }
                {
                    hasWebsiteSettingsAccess ?
                        <Link className="nav-link" url="/settings">
                            <FontAwesomeIcon icon={FontAwesomeIcons.Solid.COGS} fixedWidth />
                            <span>
                                &emsp;Settings
                            </span>
                        </Link> :
                        null
                }
            </nav>
        </div>
    }
}

const mapStateToProps = (state: IFigState, ownProps: NavMenuProps): INavMenuProps => {

    return {
        user: state.user.data,
        institutions: state.institutions.data
    };
};

const mapDispatchToProps = (dispatch): INavMenuActions => {
    return {

    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(NavMenu);