import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleMessageModal: IPreRegistrationFormPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_PREREGISTRATIONFORM_PAGE_TOGGLE_MESSAGE_MODAL
        }
    }

export const
    notifySetERStatus: IPreRegistrationFormPageNotifySetERStatusActionCreator = () => {
        return {
            type: ACTION_PREREGISTRATION_PAGE_NOTIFY_SET_ER_STATUS
        }
    }


export const
    ACTION_PREREGISTRATIONFORM_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_PREREGISTRATIONFORM_PAGE_TOGGLE_MESSAGE_MODAL"

export const
    ACTION_PREREGISTRATION_PAGE_NOTIFY_SET_ER_STATUS = "ACTION_PREREGISTRATION_PAGE_NOTIFY_SET_ER_STATUS"

/* Actions */

export interface IPreRegistrationFormPageToggleMessageModalAction {
    type: "ACTION_PREREGISTRATIONFORM_PAGE_TOGGLE_MESSAGE_MODAL"
}

export interface IPreRegistrationFormPageNotifySetERStatusAction {
    type: "ACTION_PREREGISTRATION_PAGE_NOTIFY_SET_ER_STATUS"
}

/* Action Creators */
export interface IPreRegistrationFormPageToggleMessageModalActionCreator {
    (): IPreRegistrationFormPageToggleMessageModalAction;
}

export interface IPreRegistrationFormPageNotifySetERStatusActionCreator {
    (): PreRegistrationFormPageAction;
}


/* Final Export of actions */
export type PreRegistrationFormPageAction = IPreRegistrationFormPageToggleMessageModalAction | IPreRegistrationFormPageNotifySetERStatusAction;