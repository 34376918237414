import './OutsideClick.scss';

import * as React from 'react';
import { addEventListener } from 'consolidated-events';

interface IOutsideClickProps {
    onOutsideClick: (event: React.MouseEvent<any>) => void;
}

class OutsideClick extends React.Component<IOutsideClickProps, any> {
    // @ts-ignore
    private container: HTMLDivElement = undefined;
    // @ts-ignore
    private removeEventListener: Function = undefined;

    constructor(props) {
        super(props);

        this.onOutsideClick = this.onOutsideClick.bind(this);
    }

    componentDidMount() {
        // `capture` flag is set to true so that a `stopPropagation` in the children
        // will not prevent all outside click handlers from firing - maja
        this.removeEventListener = addEventListener(
            document,
            'click',
            this.onOutsideClick,
            { capture: true },
        );
    }

    onOutsideClick(event: React.MouseEvent<any>) {
        const { onOutsideClick } = this.props;

        const isDescendantOfRoot = this.container && this.container.contains(event.target as any);

        if (!isDescendantOfRoot) {
            onOutsideClick(event);
        }
    }

    componentWillUnmount() {
        if (this.removeEventListener) { this.removeEventListener(); }
    }

    render() {
        const { children } = this.props;

        // @ts-ignore
        return <div ref={ref => this.container = ref}>
            {children}
        </div>
    }
}
export default OutsideClick