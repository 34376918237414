import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import { ACTION_WEBSITE_SETTINGS_LOAD,
    IWebsiteSettingsLoadAction,
    IWebsiteSettingsLoadFailureAction,
    IWebsiteSettingsLoadSuccessAction,
    LoadSuccess,
    LoadFailure,
    ACTION_WEBSITE_SETTINGS_SET,
    IWebsiteSettingsSetAction,
    IWebsiteSettingsSetSuccessAction,
    IWebsiteSettingsSetFailureAction,
    SetWebsiteSettingsSuccess,
    SetWebsiteSettingsFailure
} from "../actions/websiteSettings";
import { websiteSettingsApi } from "../services/websiteSettings";


export const
    getWebsiteSettingsEpic = (action$): Observable<IWebsiteSettingsLoadSuccessAction | IWebsiteSettingsLoadFailureAction> => {
        return action$
            .ofType(ACTION_WEBSITE_SETTINGS_LOAD)
            .mergeMap((action: IWebsiteSettingsLoadAction) => {
                return websiteSettingsApi
                    .getWebsiteSettings()
                    .map(response =>
                        LoadSuccess(response.websiteSetting)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setWebsiteSettingsEpic = (action$): Observable<IWebsiteSettingsSetSuccessAction | IWebsiteSettingsSetFailureAction> => {
        return action$
            .ofType(ACTION_WEBSITE_SETTINGS_SET)
            .mergeMap((action: IWebsiteSettingsSetAction) => {
                return websiteSettingsApi
                    .setWebsiteSettings(action.websiteSetting)
                    .map(response =>
                        SetWebsiteSettingsSuccess(response.websiteSetting)
                    )
                    .catch(error =>
                        Observable.of(SetWebsiteSettingsFailure(error.responseStatus))
                    )
            });
    }
   