import * as React from 'react';
import classNames from "classnames";
import './SaeManager.scss';
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import * as FigDtos from '../../../dtos/Fig.dtos';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import sae from '../../../reducers/sae';
import { convertToShortDateString, convertToDateTimeString, convertToApproximateDateString } from "../../../helpers/date";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../constants/reactTableConstants';
import { TableHeader } from '../../common';
import AnimateHeight from 'react-animate-height';
import ReactTable, { RowInfo } from 'react-table';
import * as ReactTooltip from 'react-tooltip';
import { SaeManagerState, SaeReviewType } from '../../../helpers/sae';
import { routerActions } from 'react-router-redux';
import { ISaesProcessNotificationNotRequiredAction } from '../../../actions/sae';
import { TrialUserType } from '../../../enumerations/TrialUserType';
import { Form, Control } from 'react-redux-form';
import { TextAreaFormInput } from '../../form';
import { ISaeReviewFormState } from '../../../reducers/reactReduxForms/saeReviewForm';

import * as AuthenticationHelper from '../../../helpers/authentication';

interface ISaeManagerProps {

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    saeId: number;
    institutionCode: string;
    saeManagerState: SaeManagerState;

    // Functions used in manager
    processSaeNotificationNotRequired: (institutionCode: string, saeId: number) => void;
    processSaeReview?: (formData: FigDtos.SaeReviewFormData, institutionCode: string, saeId: number, saeReviewType: FigDtos.SaeReviewType) => void; // approve or reject. form data for approve should be null. form data for reject should be reason why
    processSaeReset?: (institutionCode: string, saeId: number) => void; // reset back to no status

    reviewFormName: string;
    reviewFormHandleOnSubmitSuccess: (reviewForm: ISaeReviewFormState, saeReviewType: FigDtos.SaeReviewType) => void;
    reviewFormHandleOnSubmitFailure: (reviewForm: ISaeReviewFormState) => void;

    user: FigDtos.User

}


class SaeManager extends React.PureComponent<ISaeManagerProps, any> {
    render() {

        const { saeManagerState } = this.props

        switch (saeManagerState) {
            case "Notify": return <NotifyManager {...this.props} />;
            case "Reset": return <ResetManager {...this.props} />;
            case "Review": return <ReviewManager {...this.props} />;
            default: return null;
        }
    }
}

const NotifyManager = (props: ISaeManagerProps) => {

    return <div>
        {
            AuthenticationHelper.hasSaeTrogAccess(props.user) &&
            <div className="notify-manager">
                <div className="btn btn-primary mr-2" onClick={(event) => { event.stopPropagation(); event.preventDefault(); props.navigate("/sae/" + props.institutionCode + "/notify-sae/trial-user/" + TrialUserType.Investigators + "/sae/" + props.saeId) }}>
                    Notify Investigators
                 </div>
                <div className="btn btn-primary mr-2" onClick={(event) => { event.stopPropagation(); event.preventDefault(); props.processSaeNotificationNotRequired(props.institutionCode, props.saeId) }}>
                    Notification not required
                </div>
            </div>
        }
    </div>
};

const ReviewManager = (props: ISaeManagerProps) => {

    return (AuthenticationHelper.hasSaeReviewAccessForInstCode(props.user, props.institutionCode)) && <div className="review-manager">
        <div className="Review-heading mt-2">
            Please review the details below regarding a SAE at your site.
            <ul>
                <li>
                    Approve SAE: If the submitted details are correct, please click ‘Approve SAE’. This is equivalent to your electronic signature for this SAE.
                </li>
                <li>
                    Reject SAE: If the submitted details are incorrect or this adverse event does not constitute a SAE, please provide an explanation below, before clicking ‘Reject SAE’. This information will need to be amended within the OpenClinica database by the appropriately delegated staff member.
                </li>
            </ul>
        </div>
        <div className="btn btn-primary mr-2 mb-2" onClick={(event) => { event.stopPropagation(); event.preventDefault(); props.processSaeReview(undefined, props.institutionCode, props.saeId, FigDtos.SaeReviewType.Approve) }}>
            Approve SAE
        </div>
        <span className="mt-2 mb-2" style={{ display: "block" }}>OR</span>
        <div className="review-form">
            <Form model={props.reviewFormName} onSubmit={(val) => props.reviewFormHandleOnSubmitSuccess(val, FigDtos.SaeReviewType.Reject)} onSubmitFailed={(val) => props.reviewFormHandleOnSubmitFailure(val)}>

                <div className="mt-2 mb-2">
                    <span className="mt-2 mb-2"><strong>Reason for rejection:</strong></span>
                    <Control.textarea
                        key={"form-reject-reason"}
                        component={TextAreaFormInput}
                        model={`.rejectReason`}
                        controlProps={{
                            className: undefined,
                            label: "TROG Notes",
                            name: `reviewSaeForm.rejectReason.name`,
                            id: `reviewSaeForm.rejectReason.id`
                        }}
                        mapProps={{
                            valid: ({ fieldValue }) => fieldValue.valid,
                            touched: ({ fieldValue }) => fieldValue.touched,
                            focused: ({ fieldValue }) => fieldValue.focus,
                            value: ({ fieldValue }) => fieldValue.value
                        } as any}
                    />
                </div>

                <button type="submit" className="btn btn-primary">Reject SAE</button>
            </Form>
        </div>
    </div >
};

const ResetManager = (props: ISaeManagerProps) => {
    return AuthenticationHelper.hasSaeTrogAccess(props.user) && <div className="reset-manager">
        <div className="btn btn-primary mr-2" onClick={(event) => { event.stopPropagation(); event.preventDefault(); props.processSaeReset(props.institutionCode, props.saeId) }}>
            Reset SAE status
        </div>
    </div>
};

export default SaeManager;