import * as FigDtos from '../dtos/Fig.dtos';

// Method removes '.' from username and replaces with '/' to avoid issue of username being broken down by state on '.'
// E.g. users.username where username = 'c.test' would be broken down in React state to users.c.test instead of 
// users.'c.test' causing issues when attempting to evaluate.
export function sanitizeUsernameForReactState(username: string): string {

    if (!username) {
        return null;
    }

    let regex: any = /\./gi;

    return username.replace(regex, '/');
}