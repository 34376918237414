import './OverlayLoading.scss';

import * as React from 'react';
import { InlineLoading } from "../index";

interface IOverlayLoadingProps {
    size?: number;
    text?: string;
    onWhite?: boolean;
}

class OverlayLoading extends React.Component<IOverlayLoadingProps, any> {
    render() {
        const {
            size,
            text,
            onWhite
        } = this.props;

        let textSize = size ? size : 80;


        const loading = <div key={"loading"} className="loading-component">
            <InlineLoading size={textSize} text={text} absoluteCenter={true} />
        </div >

        if (onWhite) {
            return <div className="overlay-on-white">
                {loading}
            </div>
        }


        return (
            loading
        );
    }
}
export default OverlayLoading;