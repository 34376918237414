import './TextAreaFormInput.scss';

import * as React from "react";
import classNames from "classnames";
import { IBaseFormInputProps } from '../';

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";

export interface ITextAreaFormInputProps {
    maxLength?: number; mapProps
    autoFocus?: boolean;
    placeholder?: string;
    onKeyDown?: (event: React.KeyboardEvent<any>) => void;
    feedbackIcon?: string;

    cols?: number;
    rows?: number;
    resize?: boolean;
}

class TextAreaFormInput extends React.PureComponent<ITextAreaFormInputProps & IBaseFormInputProps, any> {
    render() {
        const {
            className,
            autoFocus,
            disabled,
            focused,
            id,
            invalid,
            label,
            name,
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            onKeyPress,
            valid,
            value,
            maxLength,
            placeholder,
            feedbackIcon,

            cols,
            rows,
            resize
        } = this.props;

        const derivedClass = className ? className : "form-control";
        const derivedCols = cols ? cols : 25;
        const derivedRows = rows ? rows : 10;
        const derivedResize = resize != undefined ? resize : true;


        const textAreaInput = <textarea
            autoFocus={autoFocus}
            className={classNames(
                derivedClass,
                { "is-invalid": invalid && !disabled },
                { "remove-resize": derivedResize} )
            }
            name={name}
            id={id}
            onBlur={onBlur}
            onChange={(event) => { onChange(event.target.value ? event.target.value : null) }}
            onFocus={onFocus}
            onKeyPress={(event) => {
                if (onKeyPress) {
                    onKeyPress(event)
                }
            }}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            value={value ? value : ''}
            maxLength={maxLength}
            disabled={disabled}
            rows={derivedRows}
            cols={derivedCols}

        />;

        const feedback = invalid && !disabled ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        return <div style={{ position: "relative" }}>
            {textAreaInput}
            {feedback}
        </div>
    }
}

export default TextAreaFormInput;