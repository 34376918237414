
//https://stackoverflow.com/questions/13459866/javascript-change-date-into-format-of-dd-mm-yyyy
/** @description Converts a date to a short date string (dd/MM/yyyy).  
 * @param {string} inputFormat Date in string format.  
 * @return {string}
 */
export function convertToShortDateString(inputFormat?: string): string | undefined {
    if (inputFormat) {
        if (isUtcDateString(inputFormat)) {
            const pad = (s) => { return (s < 10) ? '0' + s : s; }
            var d = new Date(inputFormat);
            return [
                pad(d.getDate()), 
                pad(d.getMonth()+1),
                d.getFullYear()
            ].join('/');
        }
        else if (isShortDateFR(inputFormat)) {
            return inputFormat;
        }
    }

    return undefined;
}

export function convertToErStatusShortString(input: string): string {
    return input == "1" ? "+" : input == "2" ? "-" : "";
}

//export function convertToErStatusString(input: string): string {
//    return input == "1" ? "Positive >1%" : input == "2" ? "Negative <1%" : "";
//}

export function convertToErStatusString(input: string): string {
    return input == "1" ? "Positive" : input == "2" ? "Negative" : "";
} 

/** @description Converts a date to an approximate date string (X time ago).  
 * @param {string} inputFormat Date in string format.  
 * @return {string}
 */
export function convertToApproximateDateString(inputFormat: string): string | undefined {
    if (isUtcDateString(inputFormat)) {
        let givenDate = new Date(inputFormat);
        let currentDate = new Date();

        let difference = (currentDate.getTime() - givenDate.getTime()) / 1000;

        let suffix = (difference < 0 ? "until" : "ago");
        difference = difference * (difference < 0 ? -1 : 1);

        if (difference < 60) //seconds
        {
            let seconds = Math.floor(difference);

            return seconds + " second" + (seconds == 1 ? '' : 's') + " " + suffix;
        }
        else if (difference < (60 * 60)) //minutes
        {
            let minutes = Math.floor(difference / 60);

            return minutes + " minute" + (minutes == 1 ? '' : 's') + " " + suffix;
        }
        else if (difference < (60 * 60 * 24)) //hours
        {
            let hours = Math.floor(difference / (60 * 60));

            return hours + " hour" + (hours == 1 ? '' : 's') + " " + suffix;
        }
        else if (difference < (60 * 60 * 24 * 7)) //days
        {
            let days = Math.floor(difference / (60 * 60 * 24));

            return days + " day" + (days == 1 ? '' : 's') + " " + suffix;
        }
        else if (difference < (60 * 60 * 24 * 7 * 52)) //weeks
        {
            let weeks = Math.floor(difference / (60 * 60 * 24 * 7));

            return weeks + " week" + (weeks == 1 ? '' : 's') + " " + suffix;
        }
        else //years
        {
            let years = Math.floor(difference / (60 * 60 * 24 * 7 * 52));

            return years + " year" + (years == 1 ? '' : 's') + " " + suffix;
        }
        
    }

    return undefined;
}

/** @description Converts a date to an approximate date string (X time ago).  
 * @param {string} inputFormat Date in string format.  
 * @return {string}
 */
export function dateToApproximateDateString(input: Date): string {
    let givenDate = input;
    let currentDate = new Date();

    let difference = (currentDate.getTime() - givenDate.getTime()) / 1000;

    let suffix = (difference < 0 ? "until" : "ago");
    difference = difference * (difference < 0 ? -1 : 1);

    if (difference < 60) //seconds
    {
        let seconds = Math.floor(difference);

        return seconds + " second" + (seconds == 1 ? '' : 's') + " " + suffix;
    }
    else if (difference < (60 * 60)) //minutes
    {
        let minutes = Math.floor(difference / 60);

        return minutes + " minute" + (minutes == 1 ? '' : 's') + " " + suffix;
    }
    else if (difference < (60 * 60 * 24)) //hours
    {
        let hours = Math.floor(difference / (60 * 60));

        return hours + " hour" + (hours == 1 ? '' : 's') + " " + suffix;
    }
    else if (difference < (60 * 60 * 24 * 7)) //days
    {
        let days = Math.floor(difference / (60 * 60 * 24));

        return days + " day" + (days == 1 ? '' : 's') + " " + suffix;
    }
    else if (difference < (60 * 60 * 24 * 7 * 52)) //weeks
    {
        let weeks = Math.floor(difference / (60 * 60 * 24 * 7));

        return weeks + " week" + (weeks == 1 ? '' : 's') + " " + suffix;
    }
    else //years
    {
        let years = Math.floor(difference / (60 * 60 * 24 * 7 * 52));

        return years + " year" + (years == 1 ? '' : 's') + " " + suffix;
    }
}

export function convertToDate(inputFormat: string): Date | undefined {
    if (isUtcDateString(inputFormat)) {
        var d = new Date(inputFormat);
        return d;
    }

    return undefined;
}

/** @description Converts a date to a short date string (dd/MM/yyyy hh:mm tt).  
 * @param {string} inputFormat Date in string format.  
 * @return {string}
 */
export function convertToDateTimeString(inputFormat: string): string | undefined {
    if (isUtcDateString(inputFormat)) {

        const pad = (s) => { return (s < 10) ? '0' + s : s; }

        var newDate = new Date(inputFormat);

        let sMonth: any = pad(newDate.getMonth() + 1);
        let sDay: any = pad(newDate.getDate());
        let sYear: any = newDate.getFullYear();
        let sHour: any = newDate.getHours();
        let sMinute: any = pad(newDate.getMinutes());
        let sAMPM: any = "AM";

        var iHourCheck = parseInt(sHour);

        if (iHourCheck > 12) {
            sAMPM = "PM";
            sHour = iHourCheck - 12;
        }
        else if (iHourCheck === 0) {
            sHour = "12";
        }

        sHour = pad(sHour);

        return sDay + "/" + sMonth + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
    }

    return undefined;
}

/** @description Checks if a string is in UTC ISO8601 format (yyyy-MM-ddTHH:mm:ss.fffffffZ).  
 * @param {string} inputFormat Date in string format.  
 * @return {boolean}
 */
export function isUtcDateString(inputFormat: string): boolean {
     var a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(inputFormat);

    if (a) {
        return true;
    }
    
    return false;
}

export function isShortDateFR(inputFormat: string): boolean {
    var a = /^$|(0?[1-9]|[12][0-9]|3[01])[- \/.](0?[1-9]|1[012])[- \/.](19|20)\d\d$/.exec(inputFormat);

    if (a) {
        return true;
    }

    return false;
}

export function isDateString(inputFormat: string): boolean {
    var a = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.exec(inputFormat);

    if (a) {
        return true;
    }

    return false;
}