import * as FigDtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export interface ISaeReviewFormState {
    rejectReason: string;
}

export const initialSaeReviewFormState: ISaeReviewFormState = {
    rejectReason: null
}

export interface ISaeReviewReduxFormState {
    rejectReason: IFieldState<string>;
}