import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleMessageModal: IDemographicDetailFormPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_DEMOGRAPHICDETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL
        }
    }

export const
    ACTION_DEMOGRAPHICDETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_DEMOGRAPHICDETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL"

/* Actions */

export interface IDemographicDetailFormPageToggleMessageModalAction {
    type: "ACTION_DEMOGRAPHICDETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL"
}

/* Action Creators */
export interface IDemographicDetailFormPageToggleMessageModalActionCreator {
    (): IDemographicDetailFormPageToggleMessageModalAction;
}


/* Final Export of actions */
export type DemographicDetailFormPageAction = IDemographicDetailFormPageToggleMessageModalAction;
