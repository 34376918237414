import { client } from "../../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../../dtos/Fig.dtos";

export const preRegistrationFormApi = {
    getPreRegistrationForms: (): Observable<Dtos.GetPreRegistrationFormsResponse> => {
        //Create the request.
        let request: Dtos.GetPreRegistrationForms = new Dtos.GetPreRegistrationForms();

        //Send request.
        const response: Promise<Dtos.GetPreRegistrationFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getPreRegistrationFormById: (id: number): Observable<Dtos.GetPreRegistrationFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPreRegistrationFormById = new Dtos.GetPreRegistrationFormById();

        request.id = id;
        //Send request.
        const response: Promise<Dtos.GetPreRegistrationFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getPreRegistrationFormByPatientId: (patientId: number): Observable<Dtos.GetPreRegistrationFormByPatientIdResponse> => {
        //Create the request.
        let request: Dtos.GetPreRegistrationFormByPatientId = new Dtos.GetPreRegistrationFormByPatientId();

        request.patientId = patientId;

        //Send request.
        const response: Promise<Dtos.GetPreRegistrationFormByPatientIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPreRegistrationFormByPatientIdentifier: (identifier: string): Observable<Dtos.GetPreRegistrationFormByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetPreRegistrationFormByPatientIdentifier = new Dtos.GetPreRegistrationFormByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetPreRegistrationFormByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setPreRegistrationForm: (preRegistrationForm: Dtos.PreRegistrationForm,
        institutionCode: string,
        patientIdentifier: string): Observable<Dtos.SetPreRegistrationFormResponse> => {

        let request: Dtos.SetPreRegistrationForm = new Dtos.SetPreRegistrationForm();

        request.preRegistrationForm = preRegistrationForm;
        request.institutionCode = institutionCode;
        request.patientIdentifier = patientIdentifier;

        // send request
        const response: Promise<Dtos.SetPreRegistrationFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createPreRegistrationForm: (): Observable<Dtos.CreatePreRegistrationFormResponse> => {
        //Create the request.
        let request: Dtos.CreatePreRegistrationForm = new Dtos.CreatePreRegistrationForm();

        //Send request.
        const response: Promise<Dtos.CreatePreRegistrationFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}