import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Fig.dtos";

export const trialUserApi = {
    getTrialUsers: (): Observable<Dtos.GetTrialUsersResponse> => {
        //Create the request.
        let request: Dtos.GetTrialUsers = new Dtos.GetTrialUsers();

        //Send request.
        const response: Promise<Dtos.GetTrialUsersResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialUsersByInstitution: (institutionCode: string): Observable<Dtos.GetTrialUsersByInstitutionResponse> => {
        //Create the request.
        let request: Dtos.GetTrialUsersByInstitution = new Dtos.GetTrialUsersByInstitution();

        request.institutionCode = institutionCode;

        //Send request.
        const response: Promise<Dtos.GetTrialUsersByInstitutionResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialUsersByUsername: (username: string): Observable<Dtos.GetTrialUsersByUsernameResponse> => {
        //Create the request.
        let request: Dtos.GetTrialUsersByUsername = new Dtos.GetTrialUsersByUsername();

        request.username = username;

        //Send request.
        const response: Promise<Dtos.GetTrialUsersByUsernameResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialUsersByRoleIds: (roleIds: number[]): Observable<Dtos.GetTrialUsersByRoleIdsResponse> => {
        //Create the request.
        let request: Dtos.GetTrialUsersByRoleIds = new Dtos.GetTrialUsersByRoleIds();

        request.roleIds = roleIds;

        //Send request.
        const response: Promise<Dtos.GetTrialUsersByRoleIdsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getTrialUsersByInstitutionRoleIds: (institutionCode: string, roleIds: number[]): Observable<Dtos.GetTrialUsersByInstitutionRoleIdsResponse> => {
        //Create the request.
        let request: Dtos.GetTrialUsersByInstitutionRoleIds = new Dtos.GetTrialUsersByInstitutionRoleIds();

        request.institutionCode = institutionCode;
        request.roleIds = roleIds;

        //Send request.
        const response: Promise<Dtos.GetTrialUsersByInstitutionRoleIdsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}