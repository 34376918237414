import './NumberFormInput.scss';

import * as React from "react";
import classNames from "classnames";
import { IBaseFormInputProps } from '../';
import * as NumericInput from 'react-numeric-input';
import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";
import { toFixedWithoutRounding } from "../../../../helpers/formHelper";

export interface INumberFormInputProps {

    minValue?: number;
    maxValue?: number;
    precision?: number;
    className?: number;
    strict?: boolean;
    maxLength?: number;
    pattern?: string;
    mobile?: boolean;
    addOnLeft?: any;
    addOnRight?: any;
    feedbackIcon?: string;
}

class NumberFormInput extends React.PureComponent<INumberFormInputProps & IBaseFormInputProps, any> {
    render() {
        const {
            minValue,
            maxValue,
            maxLength,
            precision,
            className,
            strict,
            pattern,
            invalid,
            valid,
            disabled,
            name,
            onBlur,
            focused,
            id,
            label,
            onChange,
            onFocus,
            onKeyPress,
            value,
            mobile,
            addOnLeft,
            addOnRight,
            feedbackIcon,
            hasSoftError,
            hasHardError
        } = this.props;

        const derivedClass = className ? className : "form-control";
        const derivedAddonClass = addOnLeft || addOnRight ? "input-group" : "";

        const derivedPrecision = precision ? precision : undefined;

        const numericInput = <NumericInput
            className={classNames(
                derivedClass,
                { "is-invalid": invalid && !disabled },
                { "rounded-right": invalid && !addOnRight },
                { "is-warning": hasSoftError && !disabled && (!invalid) }
            )
            }
            name={name}
            onBlur={onBlur}
            onChange={(valueAsNumber, valueAsString) => { onChange(valueAsNumber) }}
            onFocus={onFocus}
            onKeyPress={(event) => {
                if (onKeyPress) {
                    onKeyPress(event)
                }
            }}
            value={value}
            min={minValue ? minValue : undefined}
            max={maxValue ? maxValue : undefined}
            maxLength={maxLength ? maxLength : undefined}
            precision={precision ? precision : undefined}
            pattern={pattern ? pattern : undefined}
            disabled={disabled}
            mobile={mobile ? mobile : false}
            strict={strict ? strict : false}
        />;

        const hardFeedback = hasHardError && !disabled ?
            <span className={classNames("form-control-feedback block pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.BAN} fixedWidth />
            </span> :
            null;

        const feedback = (invalid && !disabled) && !hasHardError ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null;

        const softFeedback = hasSoftError && !disabled && (!invalid) ?
            <span className={classNames("form-control-feedback warning pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_TRIANGLE} fixedWidth />
            </span> :
            null;

        if (addOnLeft || addOnRight) {
            return <div className={classNames(
                derivedAddonClass
            )}>
                {
                    addOnLeft ?
                        <div className="input-group-prepend" id={id + "-addon-left"}>
                            <span className="input-group-text">
                                {addOnLeft}
                            </span>

                        </div> :
                        null
                }
                {numericInput}
                <div style={{ position: "relative" }}>
                    {hardFeedback}
                    {feedback}
                    {softFeedback}
                </div>
                {
                    addOnRight ?
                        <div className="input-group-append" id={id + "-addon-right"}>
                            <span className="input-group-text">
                                {addOnRight}
                            </span>

                        </div> :
                        null
                }
            </div>
        }

        return <div style={{ position: "relative" }}>
            {numericInput}
            {hardFeedback}
            {feedback}
            {softFeedback}
        </div>
    }

    isValidRange(valueAsNumber: number): boolean | undefined {

        const {
            maxValue,
            minValue,
            precision,
            strict,
        } = this.props

        
        if (valueAsNumber != undefined) {

            if (!minValue && !maxValue) {
                return true;
            }

            if (minValue && maxValue) {
                return valueAsNumber >= minValue && valueAsNumber <= maxValue
            }
            else if (minValue) {
                return valueAsNumber >= minValue
            }
            else if (maxValue) {
                return valueAsNumber <= maxValue
            }
            else {
                return false
            }

        }
    }
}

export default NumberFormInput;