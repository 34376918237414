import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as FigDtos from '../dtos/Fig.dtos';

import {
    ACTION_SAES_LOAD,
    ACTION_SAES_LOAD_SUMMARIES,
    ACTION_SAES_LOAD_BY_INSTITUTION,
    ACTION_SAES_LOAD_SUCCESS,
    ACTION_SAES_LOAD_FAILURE,
    ACTION_SAES_LOAD_BY_SAEID,
    ACTION_SAES_CLEAR,

    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED,
    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_FAILURE,
    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_SUCCESS,

    ACTION_SAES_PROCESS_RESET,
    ACTION_SAES_PROCESS_RESET_FAILURE,
    ACTION_SAES_PROCESS_RESET_SUCCESS,

    ACTION_SAES_PROCESS_NOTIFY,
    ACTION_SAES_PROCESS_NOTIFY_FAILURE,
    ACTION_SAES_PROCESS_NOTIFY_SUCCESS,

    ACTION_SAES_PROCESS_REVIEW,
    ACTION_SAES_PROCESS_REVIEW_FAILURE,
    ACTION_SAES_PROCESS_REVIEW_SUCCESS,
    
    SaeAction,
    ProcessSaeReset,
    ProcessSaeResetSuccess


} from '../actions/sae';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";
import { SaeWebAction } from '../dtos/Fig.dtos';

export type SaeUpdateAction = "NotificationNotRequired" | "Reset" | "NotifyTrialUsers" | "Review-Approve" | "Review-Reject";

export interface IUpdateAction {
    action: SaeUpdateAction
}

export interface ISaeState {
    data: FigDtos.Sae | FigDtos.Sae[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState & IUpdateAction;
}

const initialState: ISaeState = {
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None,
        action: undefined
    } as IRequestState & IUpdateAction
};


function loading(state: ISaeState, isLoading: boolean): ISaeState {
    const newState: ISaeState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: ISaeState, data: FigDtos.Sae | FigDtos.Sae[]): ISaeState {
    const newState: ISaeState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: ISaeState, responseStatus: FigDtos.ResponseStatus): ISaeState {
    const newState: ISaeState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ISaeState, propertiesToClear?: string[]): ISaeState {

    if (!propertiesToClear) {
        const newState: ISaeState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function processNotificationNotRequired(state: ISaeState, isUpdating: boolean): ISaeState {
    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Pending,
                action: "NotificationNotRequired"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}

function processNotificationNotRequiredSuccess(state: ISaeState, data: FigDtos.Sae | FigDtos.Sae[]): ISaeState {
    const newState: ISaeState = update(state, {
        data: {
            $set: data
        },
        updateState: {
            $set: {
                status: RequestState.Success,
                action: "NotificationNotRequired"
            } as IRequestState & IUpdateAction
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function processNotificationNotRequiredFailure(state: ISaeState, responseStatus: FigDtos.ResponseStatus): ISaeState {
    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                action: "NotificationNotRequired"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}

function processReset(state: ISaeState, isUpdating: boolean): ISaeState {
    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Pending,
                action: "Reset"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}

function processResetSuccess(state: ISaeState, data: FigDtos.Sae | FigDtos.Sae[]): ISaeState {
    const newState: ISaeState = update(state, {
        data: {
            $set: data
        },
        updateState: {
            $set: {
                status: RequestState.Success,
                action: "Reset"
            } as IRequestState & IUpdateAction
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function processResetFailure(state: ISaeState, responseStatus: FigDtos.ResponseStatus): ISaeState {
    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                action: "Reset"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}

function processNotify(state: ISaeState, isUpdating: boolean): ISaeState {
    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Pending,
                action: "NotifyTrialUsers"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}

function processNotifySuccess(state: ISaeState, data: FigDtos.Sae | FigDtos.Sae[]): ISaeState {
    const newState: ISaeState = update(state, {
        data: {
            $set: data
        },
        updateState: {
            $set: {
                status: RequestState.Success,
                action: "NotifyTrialUsers"
            } as IRequestState & IUpdateAction
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function processNotifyFailure(state: ISaeState, responseStatus: FigDtos.ResponseStatus): ISaeState {
    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                action: "NotifyTrialUsers"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}

/// review
function processReview(state: ISaeState, saeReviewType: FigDtos.SaeReviewType, isUpdating: boolean): ISaeState {

    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Pending,
                action: saeReviewType === FigDtos.SaeReviewType.Approve ? "Review-Approve" : "Review-Reject"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}

function processReviewSuccess(state: ISaeState, data: FigDtos.Sae | FigDtos.Sae[], saeReviewType: FigDtos.SaeReviewType): ISaeState {
    const newState: ISaeState = update(state, {
        data: {
            $set: data
        },
        updateState: {
            $set: {
                status: RequestState.Success,
                action: saeReviewType === FigDtos.SaeReviewType.Approve ? "Review-Approve" : "Review-Reject"
            } as IRequestState & IUpdateAction
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function processReviewFailure(state: ISaeState, saeReviewType: FigDtos.SaeReviewType, responseStatus: FigDtos.ResponseStatus): ISaeState {
    const newState: ISaeState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                action: saeReviewType === FigDtos.SaeReviewType.Approve ? "Review-Approve" : "Review-Reject"
            } as IRequestState & IUpdateAction
        }
    });

    return newState;
}


const SaeReducer: Reducer<ISaeState> = (state: ISaeState = initialState, action: SaeAction) => {
    switch (action.type) {
        case ACTION_SAES_LOAD:
        case ACTION_SAES_LOAD_SUMMARIES:
        case ACTION_SAES_LOAD_BY_INSTITUTION:
        case ACTION_SAES_LOAD_BY_SAEID:
            return loading(state, true);
        case ACTION_SAES_LOAD_SUCCESS:
            return loadSuccess(state, action.data);
        case ACTION_SAES_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_SAES_CLEAR:
            return clear(state, action.propertiesToClear);

        case ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED:
            return processNotificationNotRequired(state, true);
        case ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_SUCCESS:
            return processNotificationNotRequiredSuccess(state, action.sae);
        case ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_FAILURE:
            return processNotificationNotRequiredFailure(state, action.responseStatus);

        case ACTION_SAES_PROCESS_RESET:
            return processReset(state, true);
        case ACTION_SAES_PROCESS_RESET_SUCCESS:
            return processResetSuccess(state, action.sae);
        case ACTION_SAES_PROCESS_RESET_FAILURE:
            return processResetFailure(state, action.responseStatus);

        case ACTION_SAES_PROCESS_NOTIFY:
            return processNotify(state, true);
        case ACTION_SAES_PROCESS_NOTIFY_SUCCESS:
            return processNotifySuccess(state, action.sae);
        case ACTION_SAES_PROCESS_NOTIFY_FAILURE:
            return processNotifyFailure(state, action.responseStatus);

        case ACTION_SAES_PROCESS_REVIEW:
            return processReview(state, action.saeReviewType, true);
        case ACTION_SAES_PROCESS_REVIEW_SUCCESS:
            return processReviewSuccess(state, action.sae, action.saeReviewType);
        case ACTION_SAES_PROCESS_REVIEW_FAILURE:
            return processReviewFailure(state, action.saeReviewType, action.responseStatus);

    }

    return state;
}

export default SaeReducer;