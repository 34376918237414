import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_PATIENTSUMMARY_PAGE_UPDATE_SUMMARY_TREE_STATE,
    ACTION_PATIENTSUMMARY_PAGE_CLEAR,
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENT_IDENTIFIER,
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE,
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID,
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS,
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER,
    ACTION_PATIENTSUMMARY_PAGE_SET_RENDERTREE,
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION,
    PatientSummaryPageAction
} from '../../../actions/pages/registration/patientSummaryPage';

import { IPatientStatusModalData } from "../../interfaces/IPatientStatusModalData";

export interface IPatientSummaryPageState {
    patientIdentifier: string;
    summaryTreeState: string[];
    patientStatusModalOpen: boolean;
    patientStatusModalData: IPatientStatusModalData;
    renderTree: boolean;
}

const initialState: IPatientSummaryPageState = {
    patientIdentifier: undefined,
    summaryTreeState: [],
    patientStatusModalOpen: false,
    patientStatusModalData: undefined,
    renderTree: false
};

function toggleSummaryTreeState(state: IPatientSummaryPageState, id: string): IPatientSummaryPageState {
    if (state.summaryTreeState.some(identifier => identifier == id)) {
        const newState: IPatientSummaryPageState = update(state, {
            summaryTreeState: {
                $splice: [[state.summaryTreeState.indexOf(id), 1]]
            }
        });

        return newState;
    }

    const newState: IPatientSummaryPageState = update(state, {
        summaryTreeState: {
            $push: [id]
        }
    });

    return newState;
}

function setPatientIdentifier(state: IPatientSummaryPageState, patientIdentifier: string): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        patientIdentifier: {
            $set: patientIdentifier
        }
    });

    return newState;
}

function clear(state: IPatientSummaryPageState): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        patientIdentifier: {
            $set: undefined
        },
        summaryTreeState: {
            $set: []
        },
        renderTree: {
            $set: false
        }
    });
    return newState;
}

function setPatientStatusModalViewState(state: IPatientSummaryPageState, open: boolean): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        patientStatusModalOpen: {
            $set: open
        }
    });

    return newState;
}

function setPatientStatusModalPatientId(state: IPatientSummaryPageState, id: number): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        patientStatusModalData: {
            $set: {
                newStatus: state.patientStatusModalData ? state.patientStatusModalData.newStatus : undefined,
                patientId: id,
                patientSiteNumber: state.patientStatusModalData ? state.patientStatusModalData.patientSiteNumber : undefined
            } as IPatientStatusModalData
        }
    });

    return newState;
}

function setPatientStatusModalNewStatus(state: IPatientSummaryPageState, newStatus: Dtos.PatientStatus): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        patientStatusModalData: {
            $set: {
                newStatus: newStatus,
                patientId: state.patientStatusModalData ? state.patientStatusModalData.patientId : undefined,
                patientSiteNumber: state.patientStatusModalData ? state.patientStatusModalData.patientSiteNumber : undefined
            } as IPatientStatusModalData
        }
    });

    return newState;
}

function setPatientStatusModalPatientSiteNumber(state: IPatientSummaryPageState, siteNumber: number): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        patientStatusModalData: {
            $set: {
                newStatus: state.patientStatusModalData ? state.patientStatusModalData.newStatus : undefined,
                patientId: state.patientStatusModalData ? state.patientStatusModalData.patientId : undefined,
                patientSiteNumber: siteNumber
            } as IPatientStatusModalData
        }
    });

    return newState;
}

function setRenderTree(state: IPatientSummaryPageState, render: boolean, treeState: string[]): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        renderTree: {
            $set: render
        },
        summaryTreeState: {
            $set: treeState
        }
    });

    return newState;
}

function setPatientStatusModalStatusConfiguration(state: IPatientSummaryPageState, patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>): IPatientSummaryPageState {
    const newState: IPatientSummaryPageState = update(state, {
        patientStatusModalData: {
            patientStatusConfiguration: {
                $set: patientStatusConfiguration
            },
        }
    });

    return newState;
}

const PatientSummaryPageReducer: Reducer<IPatientSummaryPageState> = (state: IPatientSummaryPageState = initialState, action: PatientSummaryPageAction) => {
    switch (action.type) {
        case ACTION_PATIENTSUMMARY_PAGE_UPDATE_SUMMARY_TREE_STATE:
            return toggleSummaryTreeState(state, action.id);
        case ACTION_PATIENTSUMMARY_PAGE_SET_PATIENT_IDENTIFIER:
            return setPatientIdentifier(state, action.patientIdentifier);
        case ACTION_PATIENTSUMMARY_PAGE_CLEAR:
            return clear(state)
        case ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE:
            return setPatientStatusModalViewState(state, action.open)
        case ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID:
            return setPatientStatusModalPatientId(state, action.id);
        case ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS:
            return setPatientStatusModalNewStatus(state, action.newStatus);
        case ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER:
            return setPatientStatusModalPatientSiteNumber(state, action.siteNumber);
        case ACTION_PATIENTSUMMARY_PAGE_SET_RENDERTREE:
            return setRenderTree(state, action.render, action.treeState);
        case ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION:
            return setPatientStatusModalStatusConfiguration(state, action.patientStatusConfiguration);
    }

    return state;
}

export default PatientSummaryPageReducer;