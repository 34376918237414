import * as ReactGA from 'react-ga';
import { AppSettings } from './AppSettings';
import { History, Location } from 'history';

const debug = AppSettings.buildMode !== 'Production';

export const trackPageView = (location: Location): void => {
    ReactGA.set({
        page: location.pathname
    });
    ReactGA.pageview(location.pathname);
};

export const initGa = (history: History): void => {
    ReactGA.initialize(AppSettings.googleAnalyticsId, {
        debug
    });

    if (debug) {
        ReactGA.set({
            sendHitTask: null
        });
        
    }

    trackPageView(history.location);
    history.listen(trackPageView);
};