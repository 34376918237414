import * as Dtos from "../../dtos/Fig.dtos";

export const
    LoadSourceDocumentsForms: ISourceDocumentsFormLoadActionCreator = () => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_LOAD
        }
    },
    LoadSourceDocumentsFormById: ISourceDocumentsFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_ID,
            id
        }
    },
    LoadSourceDocumentsFormByPatientId: ISourceDocumentsFormLoadByPatientIdActionCreator = (patientId) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_ID,
            patientId
        }
    },
    LoadSourceDocumentsFormByPatientIdentifier: ISourceDocumentsFormLoadByPatientIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: ISourceDocumentsFormLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus


        }
    },
    LoadFailure: ISourceDocumentsFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveSourceDocumentsForm: ISourceDocumentsFormSaveActionCreator = (formData, institutionCode, patientIdentifier) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_SAVE,
            formData,
            institutionCode,
            patientIdentifier
        }
    },

    SaveSuccess: ISourceDocumentsFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },

    SaveFailure: ISourceDocumentsFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateSourceDocumentsForm: ISourceDocumentsFormCreateActionCreator = () => {

        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_CREATE
        }
    },

    CreateSuccess: ISourceDocumentsFormCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: ISourceDocumentsFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: ISourceDocumentsFormClearActionCreator = () => {
        return {
            type: ACTION_SOURCEDOCUMENTSFORMS_CLEAR
        }
    };


export const
    ACTION_SOURCEDOCUMENTSFORMS_LOAD = "ACTION_SOURCEDOCUMENTSFORMS_LOAD",
    ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_ID = "ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_ID",
    ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_ID = "ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_ID",
    ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_SOURCEDOCUMENTSFORMS_LOAD_SUCCESS = "ACTION_SOURCEDOCUMENTSFORMS_LOAD_SUCCESS",
    ACTION_SOURCEDOCUMENTSFORMS_LOAD_FAILURE = "ACTION_SOURCEDOCUMENTSFORMS_LOAD_FAILURE",

    ACTION_SOURCEDOCUMENTSFORMS_SAVE = "ACTION_SOURCEDOCUMENTSFORMS_SAVE",
    ACTION_SOURCEDOCUMENTSFORMS_SAVE_SUCCESS = "ACTION_SOURCEDOCUMENTSFORMS_SAVE_SUCCESS",
    ACTION_SOURCEDOCUMENTSFORMS_SAVE_FAILURE = "ACTION_SOURCEDOCUMENTSFORMS_SAVE_FAILURE",

    ACTION_SOURCEDOCUMENTSFORMS_CREATE = "ACTION_SOURCEDOCUMENTSFORMS_CREATE",
    ACTION_SOURCEDOCUMENTSFORMS_CREATE_SUCCESS = "ACTION_SOURCEDOCUMENTSFORMS_CREATE_SUCCESS",
    ACTION_SOURCEDOCUMENTSFORMS_CREATE_FAILURE = "ACTION_SOURCEDOCUMENTSFORMS_CREATE_FAILURE",

    ACTION_SOURCEDOCUMENTSFORMS_CLEAR = "ACTION_SOURCEDOCUMENTSFORMS_CLEAR";

export interface ISourceDocumentsFormLoadAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_LOAD";
}

export interface ISourceDocumentsFormLoadActionCreator {
    (): ISourceDocumentsFormLoadAction;
}

export interface ISourceDocumentsFormLoadByIdAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_ID";
    id: number;
}

export interface ISourceDocumentsFormLoadByIdActionCreator {
    (id: number): ISourceDocumentsFormLoadByIdAction;
}

export interface ISourceDocumentsFormLoadByPatientIdAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_ID";
    patientId: number;
}

export interface ISourceDocumentsFormLoadByPatientIdActionCreator {
    (patientId: number): ISourceDocumentsFormLoadByPatientIdAction;
}

export interface ISourceDocumentsFormLoadByPatientIdentifierAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface ISourceDocumentsFormLoadByPatientIdentifierActionCreator {
    (identifier: string): ISourceDocumentsFormLoadByPatientIdentifierAction;
}


export interface ISourceDocumentsFormLoadSuccessAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_LOAD_SUCCESS";
    formData: Dtos.SourceDocumentsForm | Dtos.SourceDocumentsForm[];
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;

}

export interface ISourceDocumentsFormLoadSuccessActionCreator {
    (
        formData: Dtos.SourceDocumentsForm | Dtos.SourceDocumentsForm[],
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): ISourceDocumentsFormLoadSuccessAction;
}

export interface ISourceDocumentsFormLoadFailureAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ISourceDocumentsFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISourceDocumentsFormLoadFailureAction;
}

export interface ISourceDocumentsFormSaveAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_SAVE";
    formData: Dtos.SourceDocumentsForm;
    institutionCode: string;
    patientIdentifier: string;

}

export interface ISourceDocumentsFormSaveActionCreator {
    (
        formData: Dtos.SourceDocumentsForm,
        institutionCode: string,
        patientIdentifier: string
    ): ISourceDocumentsFormSaveAction;
}

export interface ISourceDocumentsFormSaveSuccessAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_SAVE_SUCCESS";
    formData: Dtos.SourceDocumentsForm;
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface ISourceDocumentsFormSaveSuccessActionCreator {
    (
        formData: Dtos.SourceDocumentsForm,
        formState: Dtos.RegistrationFormState,
        responseStatus: Dtos.ResponseStatus
    ): ISourceDocumentsFormSaveSuccessAction;
}

export interface ISourceDocumentsFormSaveFailureAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ISourceDocumentsFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISourceDocumentsFormSaveFailureAction;
}

export interface ISourceDocumentsFormCreateAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_CREATE"
}

export interface ISourceDocumentsFormCreateActionCreator {
    (): ISourceDocumentsFormCreateAction
}

export interface ISourceDocumentsFormCreateSuccessAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_CREATE_SUCCESS";
    formData: Dtos.SourceDocumentsForm;
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
}

export interface ISourceDocumentsFormCreateSuccessActionCreator {
    (
        formData: Dtos.SourceDocumentsForm,
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[]
    ): ISourceDocumentsFormCreateSuccessAction;
}

export interface ISourceDocumentsFormCreateFailureAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface ISourceDocumentsFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ISourceDocumentsFormCreateFailureAction;
}

export interface ISourceDocumentsFormClearAction {
    type: "ACTION_SOURCEDOCUMENTSFORMS_CLEAR";
}

export interface ISourceDocumentsFormClearActionCreator {
    (): ISourceDocumentsFormClearAction;
}



export type SourceDocumentsFormAction = ISourceDocumentsFormLoadAction |
    ISourceDocumentsFormLoadByIdAction |
    ISourceDocumentsFormLoadByPatientIdAction |
    ISourceDocumentsFormLoadByPatientIdentifierAction |
    ISourceDocumentsFormLoadFailureAction |
    ISourceDocumentsFormLoadSuccessAction |
    ISourceDocumentsFormSaveAction |
    ISourceDocumentsFormSaveSuccessAction |
    ISourceDocumentsFormSaveFailureAction |
    ISourceDocumentsFormCreateAction |
    ISourceDocumentsFormCreateSuccessAction |
    ISourceDocumentsFormCreateFailureAction |
    ISourceDocumentsFormClearAction;
