import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_PATIENTREPORTEDFORM_PAGE_TOGGLE_MESSAGE_MODAL,
    PatientReportedFormPageAction
} from '../../../actions/pages/registration/patientReportedFormPage';


export interface IPatientReportedFormPageState {
    messageModalOpen: boolean;
}

const initialState: IPatientReportedFormPageState = {
    messageModalOpen: false
};


function toggleMessageModal(state: IPatientReportedFormPageState): IPatientReportedFormPageState {
    const newState: IPatientReportedFormPageState = update(state, {
        messageModalOpen: {
            $set: !state.messageModalOpen
        }
    });

    return newState;
}

const PatientReportedFormPageReducer: Reducer<IPatientReportedFormPageState> = (state: IPatientReportedFormPageState = initialState, action: PatientReportedFormPageAction) => {
    switch (action.type) {
        case ACTION_PATIENTREPORTEDFORM_PAGE_TOGGLE_MESSAGE_MODAL:
            return toggleMessageModal(state);
    }

    return state;
}

export default PatientReportedFormPageReducer;