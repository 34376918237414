import './TableHeader.scss';

import * as React from 'react';
import classNames from "classnames";
import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";

interface ITableHeaderProps {
    toggle?: () => void;
    expanded?: boolean;
    icon?: React.ReactNode;
    title?: string;
    badgeText?: string;
    count?: number;
    buttonGroup?: React.ReactNode;
}

class TableHeader extends React.PureComponent<ITableHeaderProps, any> {

    render() {

        const {
            toggle,
            expanded,
            icon,
            title,
            badgeText,
            count,
            buttonGroup
        } = this.props;

        return <div className={classNames("btn btn-primary btn-block rounded-0 p-3 d-flex table-header font-weight-bold text-left text-white align-items-center")} onClick={toggle}>
            <div className="d-flex align-items-center">
                <div className="mr-3">
                    <div style={{ transform: expanded ? "rotate(90deg)" : "rotate(0deg)", transition: "all .5s ease" }}>
                        {
                            <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.ANGLE_RIGHT} transform="grow-16" />
                        }
                    </div>
                </div>
                {icon}
            </div>
            <span>{title}</span>
            <div className="ml-auto">
                <span className="badge badge-secondary text-white">{badgeText}: {count}</span>
            </div>
            {
                buttonGroup ?
                    <div className="ml-2" onClick={this.cancelClick}>
                        {
                            buttonGroup
                        }
                    </div> :
                    null
            }
        </div>;
    }

    cancelClick(event: React.SyntheticEvent<any>): boolean {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }
}
export default TableHeader;