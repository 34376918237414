import * as Dtos from "../dtos/Fig.dtos";

export const
    loadRegistrationFormSummariesByPatientIdentifier: IRegistrationFormSummariesLoadByPatientIdentifierActionCreator = (identifier: string) => {
        return {
            type: ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: IRegistrationFormSummariesLoadSuccessActionCreator = (data) => {
        return {
            type: ACTION_REGISTRATIONFORMSUMMARIES_LOAD_SUCCESS,
            data
        }
    },
    LoadFailure: IRegistrationFormSummariesLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_REGISTRATIONFORMSUMMARIES_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: IRegistrationFormSummariesClearActionCreator = () => {
        return {
            type: ACTION_REGISTRATIONFORMSUMMARIES_CLEAR
        }
    };

export const
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_SUCCESS = "ACTION_REGISTRATIONFORMSUMMARIES_LOAD_SUCCESS",
    ACTION_REGISTRATIONFORMSUMMARIES_LOAD_FAILURE = "ACTION_REGISTRATIONFORMSUMMARIES_LOAD_FAILURE",
    ACTION_REGISTRATIONFORMSUMMARIES_CLEAR = "ACTION_REGISTRATIONFORMSUMMARIES_CLEAR"


/* Actions */

export interface IRegistrationFormSummariesLoadByPatientIdentifierAction {
    type: "ACTION_REGISTRATIONFORMSUMMARIES_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface IRegistrationFormSummariesLoadSuccessAction {
    type: "ACTION_REGISTRATIONFORMSUMMARIES_LOAD_SUCCESS";
    data: Dtos.RegistrationFormSummary | Dtos.RegistrationFormSummary[];
}

export interface IRegistrationFormSummariesLoadFailureAction {
    type: "ACTION_REGISTRATIONFORMSUMMARIES_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IRegistrationFormSummariesClearAction {
    type: "ACTION_REGISTRATIONFORMSUMMARIES_CLEAR";
}

/* Action Creators */
export interface IRegistrationFormSummariesLoadByPatientIdentifierActionCreator {
    (identifier: string): IRegistrationFormSummariesLoadByPatientIdentifierAction;
}

export interface IRegistrationFormSummariesLoadSuccessActionCreator {
    (data: Dtos.RegistrationFormSummary | Dtos.RegistrationFormSummary[]): IRegistrationFormSummariesLoadSuccessAction;
}

export interface IRegistrationFormSummariesLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IRegistrationFormSummariesLoadFailureAction;
}

export interface IRegistrationFormSummariesClearActionCreator {
    (): IRegistrationFormSummariesClearAction;
}

/* Final Export of actions */
export type RegistrationFormSummaryAction = IRegistrationFormSummariesLoadByPatientIdentifierAction |
    IRegistrationFormSummariesLoadSuccessAction |
    IRegistrationFormSummariesLoadFailureAction |
    IRegistrationFormSummariesClearAction;
