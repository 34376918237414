import { combineReducers } from "redux";
import { routerReducer, RouterState } from "react-router-redux";
import { combineForms, FormState, FieldState } from 'react-redux-form';

/* Common */
import user, { IUserState } from './user';
import patients, { IPatientState } from './patients';
import trialUsers, { ITrialUserState } from './TrialUser';
import institutions, { IInstitutionState } from './institutions';
import timepoints, { ITimepointState } from './timepoints';
import websiteSettings, { IWebsiteSettingsState } from './websiteSettings';

/* Sae System Reducers */
import sae, { ISaeState } from './sae';

/* Registration */

/* Registration Pages */
import registrationPage, { IRegistrationPageState } from './pages/registration/registrationPage';
import preRegistrationFormPage, { IPreRegistrationFormPageState } from './pages/registration/preRegistrationFormPage';
import patientSummaryPage, { IPatientSummaryPageState } from './pages/registration/patientSummaryPage';
import glioblastomaDetailFormPage, { IGlioblastomaDetailFormPageState } from './pages/registration/glioblastomaDetailFormPage';
import demographicDetailFormPage, { IDemographicDetailFormPageState } from './pages/registration/demographicDetailFormPage';
import clinicalAssessmentFormPage, { IClinicalAssessmentFormPageState } from './pages/registration/clinicalAssessmentFormPage';
import patientReportedFormPage, { IPatientReportedFormPageState } from './pages/registration/patientReportedFormPage';
import eligibilityCriteriaFormPage, { IEligibilityCriteriaFormPageState } from './pages/registration/eligibilityCriteriaFormPage';
import sourceDocumentsFormPage, { ISourceDocumentsFormPageState } from './pages/registration/sourceDocumentsFormPage';

/* Sae Pages */
import saePage, { ISaePageState } from './pages/sae/saePage';
import SaeViewPage, { ISaeViewPageState } from './pages/sae/saeViewPage';

/* Registration forms + other related data */
import preRegistrationForms, { IPreRegistrationFormState } from './forms/preRegistrationForm';
import registrationFormSummaries, { IRegistrationFormSummaryState } from './registrationFormSummaries';
import glioblastomaDetailForms, { IGlioblastomaDetailFormState } from './forms/glioblastomaDetailForm';
import demographicDetailForms, { IDemographicDetailFormState } from './forms/demographicDetailForm';
import clinicalAssessmentForms, { IClinicalAssessmentFormState } from './forms/clinicalAssessmentForm';
import patientReportedForms, { IPatientReportedFormState } from './forms/patientReportedForm';
import eligibilityCriteriaForms, { IEligibilityCriteriaFormState } from './forms/eligibilityCriteriaForm';
import sourceDocumentsForms, { ISourceDocumentsFormState } from './forms/sourceDocumentsForm';
import { initialWebsiteSettingFormState, IWebsiteSettingReduxFormState } from "./reactReduxForms/websiteSettingsForm";

import { initialPreRegistrationFormState, IPreRegistrationReduxFormState } from "./reactReduxForms/preRegistrationForm";
import { initialPatientStatusModalFormState, IPatientStatusModalFormState, IPatientStatusModalReduxFormState } from './reactReduxForms/patientStatusModalForm';
import { initialGlioblastomaDetailFormState, IGlioblastomaDetailReduxFormState } from "./reactReduxForms/glioblastomaDetailForm";
import { initialDemographicDetailFormState, IDemographicDetailReduxFormState } from "./reactReduxForms/demographicDetailForm";
import { initialClinicalAssessmentFormState, IClinicalAssessmentReduxFormState } from "./reactReduxForms/clinicalAssessmentForm";
import { initialPatientReportedFormState, IPatientReportedReduxFormState } from "./reactReduxForms/patientReportedForm";
import { initialEligibilityCriteriaFormState, IEligibilityCriteriaReduxFormState } from "./reactReduxForms/eligibilityCriteriaForm";
import { initialNotifyTrialUsersFormState, INotifyTrialUserReduxFormState } from './reactReduxForms/notifyTrialUsersForm';
import { initialSaeReviewFormState, ISaeReviewReduxFormState } from './reactReduxForms/saeReviewForm';

import { RequestState } from "../enumerations/RequestState";
import * as Dtos from "../dtos/Fig.dtos";

import { IRegistrationFormState, ILogDataState } from "./reactReduxForms/index";
import { ISourceDocumentModalState } from "./reactReduxForms/sourceDocument";
import { ISourceDocumentsReduxFormState, initialSourceDocumentsFormState } from "./reactReduxForms/sourceDocumentsForm";


export interface IRequestState {
    status: RequestState;
    errorCode: string;
    errorMessage: string;
    errors: Dtos.ResponseError[];
    meta: {[index: string]: string};
}

interface IRegistrationFormsState {
    preRegistrationForms: IPreRegistrationFormState;
    glioblastomaDetailForms: IGlioblastomaDetailFormState;
    demographicDetailForms: IDemographicDetailFormState;
    clinicalAssessmentForms: IClinicalAssessmentFormState;
    patientReportedForms: IPatientReportedFormState;
    eligibilityCriteriaForms: IEligibilityCriteriaFormState;
    sourceDocumentsForms: ISourceDocumentsFormState;
    websiteSettings: IWebsiteSettingsState;
}

interface IReduxFormsState {
    websiteSetting: Dtos.WebsiteSetting & IRegistrationFormState;
    preRegistrationForm: Dtos.PreRegistrationForm & IRegistrationFormState & ISourceDocumentModalState;
    patientStatusModalForm: IPatientStatusModalFormState;
    glioblastomaDetailForm: Dtos.GlioblastomaDetailForm & IRegistrationFormState & ISourceDocumentModalState;
    demographicDetailForm: Dtos.DemographicDetailForm & IRegistrationFormState & ISourceDocumentModalState;
    clinicalAssessmentForm: Dtos.ClinicalAssessmentForm & IRegistrationFormState & ISourceDocumentModalState;
    patientReportedForm: Dtos.PatientReportedForm & IRegistrationFormState & ISourceDocumentModalState;
    eligibilityCriteriaForm: Dtos.EligibilityCriteriaForm & IRegistrationFormState & ISourceDocumentModalState;
    sourceDocumentsForm: Dtos.SourceDocumentsForm & IRegistrationFormState & ISourceDocumentModalState;
    
    formState: {
        websiteSetting: IWebsiteSettingReduxFormState;
        preRegistrationForm: IPreRegistrationReduxFormState;
        patientStatusModalForm: IPatientStatusModalReduxFormState;
        glioblastomaDetailForm: IGlioblastomaDetailReduxFormState;
        demographicDetailForm: IDemographicDetailReduxFormState;
        clinicalAssessmentForm: IClinicalAssessmentReduxFormState;
        patientReportedForm: IPatientReportedReduxFormState;
        eligibilityCriteriaForm: IEligibilityCriteriaReduxFormState;
        sourceDocumentsForm: ISourceDocumentsReduxFormState;
        notifyTrialUsersForm: INotifyTrialUserReduxFormState;
        saeReviewForm: ISaeReviewReduxFormState;
    };

}

export interface IFigState {
    // React states
    routing: RouterState;

    user: IUserState;

    // Container states
    registrationPage: IRegistrationPageState;
    patientSummaryPage: IPatientSummaryPageState;
    preRegistrationFormPage: IPreRegistrationFormPageState;
    glioblastomaDetailFormPage: IGlioblastomaDetailFormPageState;
    demographicDetailFormPage: IDemographicDetailFormPageState;
    clinicalAssessmentFormPage: IClinicalAssessmentFormPageState;
    patientReportedFormPage: IPatientReportedFormPageState;
    eligibilityCriteriaFormPage: IEligibilityCriteriaFormPageState;
    sourceDocumentsFormPage: ISourceDocumentsFormPageState;

    saePage: ISaePageState;
    saeViewPage: ISaeViewPageState;

    // Model states
    patients: IPatientState;
    trialUsers: ITrialUserState;
    institutions: IInstitutionState;
    timepoints: ITimepointState;  
    registrationForms: IRegistrationFormsState;
    reduxForms: IReduxFormsState;
    registrationFormSummaries: IRegistrationFormSummaryState;
    websiteSettings: IWebsiteSettingsState;
    saes: ISaeState;
   
}

/** Combines all reducers into one for the overall state.
*   We can place all related reducers together (in order) so that they are
*   easier to find whilst debugging (redux dev tools)
*/
export default combineReducers({
    // React reducers
    routing: routerReducer,

    user: user,

    // Container reducers
    registrationPage: registrationPage,
    patientSummaryPage: patientSummaryPage,
    preRegistrationFormPage: preRegistrationFormPage,
    glioblastomaDetailFormPage: glioblastomaDetailFormPage,
    demographicDetailFormPage: demographicDetailFormPage,
    clinicalAssessmentFormPage: clinicalAssessmentFormPage,
    patientReportedFormPage: patientReportedFormPage,
    eligibilityCriteriaFormPage: eligibilityCriteriaFormPage,
    sourceDocumentsFormPage: sourceDocumentsFormPage,

    saePage: saePage,
    saeViewPage: SaeViewPage,

    // API Model reducers
    patients: patients,
    trialUsers: trialUsers,
    institutions: institutions,
    timepoints: timepoints,
    websiteSettings: websiteSettings,
    registrationForms: combineReducers({
        preRegistrationForms,
        glioblastomaDetailForms,
        demographicDetailForms,
        clinicalAssessmentForms,
        patientReportedForms,
        eligibilityCriteriaForms,
        sourceDocumentsForms
    }),
    registrationFormSummaries: registrationFormSummaries,
    saes: sae,
    // Redux Forms. Avoid clutter in dev tools by placing down the bottom.
    reduxForms: combineForms({
        preRegistrationForm: initialPreRegistrationFormState,
        glioblastomaDetailForm: initialGlioblastomaDetailFormState,
        demographicDetailForm: initialDemographicDetailFormState,
        clinicalAssessmentForm: initialClinicalAssessmentFormState,
        patientReportedForm: initialPatientReportedFormState,
        eligibilityCriteriaForm: initialEligibilityCriteriaFormState,
        sourceDocumentsForm: initialSourceDocumentsFormState,
        websiteSetting: initialWebsiteSettingFormState,
        notifyTrialUsersForm: initialNotifyTrialUsersFormState,
        saeReviewForm: initialSaeReviewFormState,

        patientStatusModalForm: initialPatientStatusModalFormState
    }, 'reduxForms', { key: 'formState' })

});