import * as React from 'react';
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

import { InfoPanel } from "../index";
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

interface ISummaryPanelProps {
    leftPanelTopText?: any;
    leftPanelBottomText?: any;
    leftPanelIcon?: IconDefinition;
    leftPanelBackgroundClass?: string;
    leftPanelToolTip?: any;

    middlePanelTopText?: any;
    middlePanelBottomText?: any;
    middlePanelIcon?: IconDefinition;
    middlePanelBackgroundClass?: string;
    middlePanelToolTip?: any;

    rightPanelTopText?: any;
    rightPanelBottomText?: any;
    rightPanelIcon?: IconDefinition;
    rightPanelBackgroundClass?: string;
    rightPanelToolTip?: any;

}

class SummaryPanel extends React.PureComponent<ISummaryPanelProps, any> {

    render() {

        const {
            leftPanelBackgroundClass,
            leftPanelBottomText,
            children,
            leftPanelIcon,
            leftPanelTopText,
            middlePanelBackgroundClass,
            middlePanelBottomText,
            middlePanelIcon,
            middlePanelTopText,
            rightPanelBackgroundClass,
            rightPanelBottomText,
            rightPanelIcon,
            rightPanelTopText,
            leftPanelToolTip,
            middlePanelToolTip,
            rightPanelToolTip
        } = this.props;

        return <div className="row mb-4">

            <InfoPanel
                backgroundClass={leftPanelBackgroundClass ? leftPanelBackgroundClass : "bg-primary"}
                icon={leftPanelIcon ? leftPanelIcon : FontAwesomeIcons.Solid.USER}
                topText={leftPanelTopText ? leftPanelTopText : ''}
                bottomText={leftPanelBottomText ? leftPanelBottomText : ''}
                toolTipText={leftPanelToolTip ? leftPanelToolTip : ''}
            />
            <InfoPanel
                backgroundClass={middlePanelBackgroundClass ? middlePanelBackgroundClass : "bg-secondary"}
                icon={middlePanelIcon ? middlePanelIcon : FontAwesomeIcons.Solid.BUILDING}
                topText={middlePanelTopText ? middlePanelTopText : ''}
                bottomText={middlePanelBottomText ? middlePanelBottomText : ''}
                toolTipText={middlePanelToolTip ? middlePanelToolTip : ''}
            />
            <InfoPanel
                backgroundClass={rightPanelBackgroundClass ? rightPanelBackgroundClass : "bg-tertiary"}
                icon={rightPanelIcon ? rightPanelIcon : FontAwesomeIcons.Solid.HEARTBEAT}
                topText={rightPanelTopText ? rightPanelTopText : ''}
                bottomText={rightPanelBottomText ? rightPanelBottomText : ''}
                toolTipText={rightPanelToolTip ? rightPanelToolTip : ''}
            />
        </div>;
    }
}
export default SummaryPanel;