import * as Dtos from "../dtos/Fig.dtos";

export const
    LoadTrialUsers: ITrialUsersLoadActionCreator = () => {
        return {
            type: ACTION_TRIAL_USERS_LOAD
        }
    },
    LoadTrialUsersByInstitution: ITrialUsersLoadByInstitutionActionCreator = (institutionCode) => {
        return {
            type: ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION,
            institutionCode
        }
    },
    LoadTrialUsersByUsername: ITrialUsersLoadByUsernameActionCreator = (username) => {
        return {
            type: ACTION_TRIAL_USERS_LOAD_BY_USERNAME,
            username
        }
    },
    LoadTrialUsersByRoleIds: ITrialUsersLoadByRoleIdsActionCreator = (roleIds) => {
        return {
            type: ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS,
            roleIds
        }
    },
    LoadTrialUsersByInstitutionRoleIds: ITrialUsersLoadByInstitutionRoleIdsActionCreator = (institutionCode, roleIds) => {
        return {
            type: ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS,
            institutionCode,
            roleIds
        }
    },
    LoadSuccess: ITrialUsersLoadSuccessActionCreator = (data) => {
        return {
            type: ACTION_TRIAL_USERS_LOAD_SUCCESS,
            data
        }
    },
    LoadFailure: ITrialUsersLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_TRIAL_USERS_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: ITrialUsersClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_TRIAL_USERS_CLEAR,
            propertiesToClear
        }
    }


export const
    ACTION_TRIAL_USERS_LOAD = "ACTION_TRIAL_USERS_LOAD",
    ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION = "ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION",
    ACTION_TRIAL_USERS_LOAD_BY_USERNAME = "ACTION_TRIAL_USERS_LOAD_BY_USERNAME",
    ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS = "ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS",
    ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS = "ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS",

    ACTION_TRIAL_USERS_LOAD_SUCCESS = "ACTION_TRIAL_USERS_LOAD_SUCCESS",
    ACTION_TRIAL_USERS_LOAD_FAILURE = "ACTION_TRIAL_USERS_LOAD_FAILURE",
    ACTION_TRIAL_USERS_CLEAR = "ACTION_TRIAL_USERS_CLEAR"

export interface ITrialUsersLoadAction {
    type: "ACTION_TRIAL_USERS_LOAD";
}

export interface ITrialUsersLoadActionCreator {
    (): ITrialUsersLoadAction;
}

export interface ITrialUsersLoadByInstitutionAction {
    type: "ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION";
    institutionCode: string;
}

export interface ITrialUsersLoadByInstitutionActionCreator {
    (institutionCode: string): ITrialUsersLoadByInstitutionAction;
}


export interface ITrialUsersLoadByUsernameAction {
    type: "ACTION_TRIAL_USERS_LOAD_BY_USERNAME";
    username: string;
}

export interface ITrialUsersLoadByUsernameActionCreator {
    (username: string): ITrialUsersLoadByUsernameAction;
}

export interface ITrialUsersLoadByRoleIdsAction {
    type: "ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS";
    roleIds: number[];
}

export interface ITrialUsersLoadByRoleIdsActionCreator {
    (roleIds: number[]): ITrialUsersLoadByRoleIdsAction;
}


export interface ITrialUsersLoadByInstitutionRoleIdsAction {
    type: "ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS";
    institutionCode: string;
    roleIds: number[];
}

export interface ITrialUsersLoadByInstitutionRoleIdsActionCreator {
    (institutionCode: string, roleIds: number[]): ITrialUsersLoadByInstitutionRoleIdsAction;
}

export interface ITrialUsersLoadSuccessAction {
    type: "ACTION_TRIAL_USERS_LOAD_SUCCESS";
    data: Dtos.TrialUser | Dtos.TrialUser[];
}

export interface ITrialUsersLoadSuccessActionCreator {
    (data: Dtos.TrialUser | Dtos.TrialUser[]): ITrialUsersLoadSuccessAction;
}

export interface ITrialUsersLoadFailureAction {
    type: "ACTION_TRIAL_USERS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface ITrialUsersLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): ITrialUsersLoadFailureAction;
}

export interface ITrialUsersClearAction {
    type: "ACTION_TRIAL_USERS_CLEAR";
    propertiesToClear?: string[];
}

export interface ITrialUsersClearActionCreator {
    (propertiesToClear?: string[]): ITrialUsersClearAction;
}

export type TrialUserAction = ITrialUsersLoadAction |
    ITrialUsersLoadByInstitutionAction |
    ITrialUsersLoadByUsernameAction |
    ITrialUsersLoadByRoleIdsAction |
    ITrialUsersLoadByInstitutionRoleIdsAction |
    ITrialUsersLoadFailureAction |
    ITrialUsersLoadSuccessAction |
    ITrialUsersClearAction;
