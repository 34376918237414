import { IAuthorisationRole } from "../interfaces/authentication/authorisationDefinition";

export const INSTITUTION_TROG = '0';
export const SUPER_USER = "super-user";
export const PRINCIPAL_INVESTIGATOR = "principal-investigator";
export const INVESTIGATOR = "investigator";
export const COORDINATOR = "coordinator";
export const TROG_ADMIN = "trog-admin";
export const CLINICAL_RESEARCH_ASSOCIATE = "clinical-research-associate";
export const CLINICAL_TRIAL_ASSISTANT = "clinical-trial-assistant";
export const MANAGEMENT = "management";
//export const SAE_ROLES: IAuthorisationRole[] = [
//    {
//        roleId: "8"
//    },
//    {
//        roleId: "13"
//    },
//    {
//        roleId: "18"
//    },
//    {
//        roleId: "23"
//    },
//    {
//        roleId: "24"
//    }
//]

export const PHARMACIST_ROLE: IAuthorisationRole[] = [
    {
        roleId: "6"
    }
]

export const TRIAL_COORDINATOR_ROLE: IAuthorisationRole[] = [
    {
        roleId: "9"
    }
]

export const PRINCIPAL_INVESTIGATOR_ROLE: IAuthorisationRole[] = [
    {
        roleId: "7"
    }
]

export const CO_INVESTIGATOR_ROLE: IAuthorisationRole[] = [
    {
        roleId: "8"
    }
]

export const TRIAL_COORDINATOR_BACKUP_ROLE: IAuthorisationRole[] = [
    {
        roleId: "23"
    }
]

export const TROG_RESTRICTED_ROLES: IAuthorisationRole[] = [
    {
        roleId: "24"
    }
]

export const MEDICAL_REVIEWER_ROLE: IAuthorisationRole[] = [
    {
        roleId: "48"
    }
]