// @ts-nocheck
import * as React from 'react';
import { FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";

import { getPatientStatusText } from "../../../helpers/patient";

import { InfoPanel } from "../index";

import * as Dtos from '../../../dtos/Fig.dtos';
import { convertToShortDateString } from '../../../helpers/date';

interface IPatientPanelProps {
    identifier?: string;
    patientInitials?: string;
    institutionCode?: string;
    institutionName?: string;
    patientStatus?: Dtos.PatientStatus;
    registrationDate?: string;
}

class PatientPanel extends React.PureComponent<IPatientPanelProps, any> {
    render() {

        const {
            identifier,
            patientInitials,
            institutionCode,
            institutionName,
            patientStatus,
            registrationDate
        } = this.props;

        return <div className="row mb-4">
            <InfoPanel
                backgroundClass="bg-primary"
                icon={FontAwesomeIcons.Solid.USER}
                topText={identifier}
                bottomText="Patient"
                toolTipText={patientInitials}
            />
            <InfoPanel
                backgroundClass="bg-secondary"
                icon={FontAwesomeIcons.Solid.BUILDING}
                topText={institutionCode}
                bottomText="Institution"
                toolTipText={institutionName}
            />
            <InfoPanel
                backgroundClass="bg-tertiary"
                icon={FontAwesomeIcons.Solid.HEARTBEAT}
                topText={getPatientStatusText(patientStatus)}
                bottomText="Status"
                toolTipText={convertToShortDateString(registrationDate)}
            />
        </div>;
    }
}
export default PatientPanel;