import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as FigDtos from '../../../dtos/Fig.dtos';

import {
    ACTION_SAE_PAGE_TOGGLE_APPROVED,
    ACTION_SAE_PAGE_TOGGLE_AWAITING_REVIEW,
    ACTION_SAE_PAGE_TOGGLE_INCORRECTLY_IDENTIFIED,
    ACTION_SAE_PAGE_TOGGLE_NEW,
    ACTION_SAE_PAGE_TOGGLE_REJECTED,
    ACTION_SAE_PAGE_UPDATE_COUNTRY,
    ACTION_SAE_PAGE_UPDATE_INSTITUTION_CODE,
    SaePageAction,

    toggleNew,
    toggleAwaitingReview
} from '../../../actions/pages/sae/saePage';


export interface ISaePageState {

    expandNewSaes: boolean;
    expandAwaitingReviewSaes: boolean;
    expandApprovedSaes: boolean;
    expandRejectedSaes: boolean;
    expandIncorrectlyIdentifiedSaes: boolean;
    country: string;
}

const initialState: ISaePageState = {
    expandNewSaes: true,
    expandAwaitingReviewSaes: true,
    expandApprovedSaes: true,
    expandRejectedSaes: true,
    expandIncorrectlyIdentifiedSaes: true,
    country: undefined
};


function toggleNewSaes(state: ISaePageState): ISaePageState {
    const newState: ISaePageState = update(state, {
        expandNewSaes: {
            $set: !state.expandNewSaes
        }
    });

    return newState;
}

function toggleAwaitingReviewSaes(state: ISaePageState): ISaePageState {
    const newState: ISaePageState = update(state, {
        expandAwaitingReviewSaes: {
            $set: !state.expandAwaitingReviewSaes
        }
    });

    return newState;
}

function toggleApprovedSaes(state: ISaePageState): ISaePageState {
    const newState: ISaePageState = update(state, {
        expandApprovedSaes: {
            $set: !state.expandApprovedSaes
        }
    });

    return newState;
}

function toggleRejectedSaes(state: ISaePageState): ISaePageState {
    const newState: ISaePageState = update(state, {
        expandRejectedSaes: {
            $set: !state.expandRejectedSaes
        }
    });

    return newState;
}

function toggleIncorrectlyIdentifiedSaes(state: ISaePageState): ISaePageState {
    const newState: ISaePageState = update(state, {
        expandIncorrectlyIdentifiedSaes: {
            $set: !state.expandIncorrectlyIdentifiedSaes
        }
    });

    return newState;
}

function updateCountry(state: ISaePageState, country: string): ISaePageState {
    const newState: ISaePageState = update(state, {
        country: {
            $set: country
        }
    });

    return newState;
}

const SaePageReducer: Reducer<ISaePageState> = (state: ISaePageState = initialState, action: SaePageAction) => {
    switch (action.type) {
        case ACTION_SAE_PAGE_TOGGLE_NEW:
            return toggleNewSaes(state);
        case ACTION_SAE_PAGE_TOGGLE_AWAITING_REVIEW:
            return toggleAwaitingReviewSaes(state);
        case ACTION_SAE_PAGE_TOGGLE_APPROVED:
            return toggleApprovedSaes(state);
        case ACTION_SAE_PAGE_TOGGLE_REJECTED:
            return toggleRejectedSaes(state);
        case ACTION_SAE_PAGE_TOGGLE_INCORRECTLY_IDENTIFIED:
            return toggleIncorrectlyIdentifiedSaes(state);
        case ACTION_SAE_PAGE_UPDATE_COUNTRY:
            return updateCountry(state, action.country);
    }

    return state;
}

export default SaePageReducer;