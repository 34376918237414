
import * as React from 'react';
import * as ReactDom from 'react-dom';
import classNames from "classnames";
import { copyDocumentStyles, copyDocumentStylesLegacy, copyDocumentStylesToString } from "../../../helpers/styles";


interface IExternalViewProps {
    selector?: string;
}

class ExternalView extends React.PureComponent<IExternalViewProps, any> {
    private containerEl: HTMLDivElement;
    private externalView: HTMLElement;

    constructor(props) {
        super(props);
        // STEP 1: create a container <div>
        this.containerEl = document.createElement('div');
        this.externalView = props.selector ? document.querySelector(props.selector) : document.body;

        this.externalView.appendChild(this.containerEl);
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDom.createPortal(this.props.children, this.containerEl);
    }

    componentWillUnmount() {
        if (this.externalView && this.containerEl) {
            this.externalView.removeChild(this.containerEl);
        }
    }
}

export default ExternalView