import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_USER_LOAD,
    IUserLoadAction,
    IUserLoadFailureAction,
    IUserLoadSuccessAction,
    LoadSuccess,
    LoadFailure,
} from "../actions/user";
import { usersApi } from "../services/users";


export const
    getUserEpic = (action$): Observable<IUserLoadSuccessAction | IUserLoadFailureAction> => {
        return action$
            .ofType(ACTION_USER_LOAD)
            .mergeMap((action: IUserLoadAction) => {
                return usersApi
                    .getUser()
                    .map(response =>
                        LoadSuccess(response.user)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    }
   