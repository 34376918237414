import * as FigDtos from "../dtos/Fig.dtos";
import { TrialUserType } from "../enumerations/TrialUserType";

export const
    LoadSaes: ISaesLoadActionCreator = () => {
        return {
            type: ACTION_SAES_LOAD
        }
    },
    LoadSaesSummaries: ISaesLoadSummariesActionCreator = () => {
        return {
            type: ACTION_SAES_LOAD_SUMMARIES
        }
    },
    LoadSaesByInstitution: ISaesLoadByInstitutionActionCreator = (institutionCode) => {
        return {
            type: ACTION_SAES_LOAD_BY_INSTITUTION,
            institutionCode
        }
    },
    LoadSaesBySaeId: ISaesLoadBySaeIdActionCreator = (saeId) => {
        return {
            type: ACTION_SAES_LOAD_BY_SAEID,
            saeId
        }
    },
    LoadSuccess: ISaesLoadSuccessActionCreator = (data) => {
        return {
            type: ACTION_SAES_LOAD_SUCCESS,
            data
        }
    },
    LoadFailure: ISaesLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SAES_LOAD_FAILURE,
            responseStatus
        }
    },
    Clear: ISaesClearActionCreator = (propertiesToClear?) => {
        return {
            type: ACTION_SAES_CLEAR,
            propertiesToClear
        }
    },
    ProcessSaeNotificationNotRequired: ISaesProcessNotificationNotRequiredActionCreator = (institutionCode: string, saeId: number) => {
        return {
            type: ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED,
            institutionCode: institutionCode,
            saeId: saeId
        }
    },
    ProcessSaeNotificationNotRequiredSuccess: ISaesProcessNotificationNotRequiredSuccessActionCreator = (sae) => {
        return {
            type: ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_SUCCESS,
            sae
        }
    },
    ProcessSaeNotificationNotRequiredFailure: ISaesProcessNotificationNotRequiredFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_FAILURE,
            responseStatus
        }
    },

    ProcessSaeReset: ISaesProcessResetActionCreator = (institutionCode: string, saeId: number) => {
        return {
            type: ACTION_SAES_PROCESS_RESET,
            institutionCode: institutionCode,
            saeId: saeId
        }
    },
    ProcessSaeResetSuccess: ISaesProcessResetSuccessActionCreator = (sae) => {
        return {
            type: ACTION_SAES_PROCESS_RESET_SUCCESS,
            sae
        }
    },
    ProcessSaeResetFailure: ISaesProcessResetFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SAES_PROCESS_RESET_FAILURE,
            responseStatus
        }
    },
    processSaeNotify: ISaesProcessNotifyActionCreator = (formData, institutionCode, saeId, trialUserType) => {
        return {
            type: ACTION_SAES_PROCESS_NOTIFY,
            formData,
            institutionCode,
            saeId,
            trialUserType
        }
    },
    ProcessSaeNotifySuccess: ISaesProcessNotifySuccessActionCreator = (sae) => {
        return {
            type: ACTION_SAES_PROCESS_NOTIFY_SUCCESS,
            sae
        }
    },
    ProcessSaeNotifyFailure: ISaesProcessNotifyFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_SAES_PROCESS_NOTIFY_FAILURE,
            responseStatus
        }
    },

    processSaeReview: ISaesProcessReviewActionCreator = (formData, institutionCode, saeId, saeReviewType) => {
        return {
            type: ACTION_SAES_PROCESS_REVIEW,
            formData,
            institutionCode,
            saeId,
            saeReviewType
        }
    },
    ProcessSaeReviewSuccess: ISaesProcessReviewSuccessActionCreator = (sae, saeReviewType) => {
        return {
            type: ACTION_SAES_PROCESS_REVIEW_SUCCESS,
            saeReviewType,
            sae
        }
    },
    ProcessSaeReviewFailure: ISaesProcessReviewFailureActionCreator = (responseStatus, saeReviewType) => {
        return {
            type: ACTION_SAES_PROCESS_REVIEW_FAILURE,
            responseStatus,
            saeReviewType,
        }
    }


export const
    ACTION_SAES_LOAD = "ACTION_SAES_LOAD",
    ACTION_SAES_LOAD_SUMMARIES = "ACTION_SAES_LOAD_SUMMARIES",
    ACTION_SAES_LOAD_BY_INSTITUTION = "ACTION_SAES_LOAD_BY_INSTITUTION",
    ACTION_SAES_LOAD_BY_SAEID = "ACTION_SAES_LOAD_BY_SAEID",

    ACTION_SAES_LOAD_SUCCESS = "ACTION_SAES_LOAD_SUCCESS",
    ACTION_SAES_LOAD_FAILURE = "ACTION_SAES_LOAD_FAILURE",
    ACTION_SAES_CLEAR = "ACTION_SAES_CLEAR",

    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED = "ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED",
    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_SUCCESS = "ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_SUCCESS",
    ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_FAILURE = "ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_FAILURE",

    ACTION_SAES_PROCESS_RESET = "ACTION_SAES_PROCESS_RESET",
    ACTION_SAES_PROCESS_RESET_SUCCESS = "ACTION_SAES_PROCESS_RESET_SUCCESS",
    ACTION_SAES_PROCESS_RESET_FAILURE = "ACTION_SAES_PROCESS_RESET_FAILURE",

    ACTION_SAES_PROCESS_NOTIFY = "ACTION_SAES_PROCESS_NOTIFY",
    ACTION_SAES_PROCESS_NOTIFY_SUCCESS = "ACTION_SAES_PROCESS_NOTIFY_SUCCESS",
    ACTION_SAES_PROCESS_NOTIFY_FAILURE = "ACTION_SAES_PROCESS_NOTIFY_FAILURE",

    ACTION_SAES_PROCESS_REVIEW = "ACTION_SAES_PROCESS_REVIEW",
    ACTION_SAES_PROCESS_REVIEW_SUCCESS = "ACTION_SAES_PROCESS_REVIEW_SUCCESS",
    ACTION_SAES_PROCESS_REVIEW_FAILURE = "ACTION_SAES_PROCESS_REVIEW_FAILURE"

export interface ISaesLoadAction {
    type: "ACTION_SAES_LOAD";
}

export interface ISaesLoadActionCreator {
    (): ISaesLoadAction;
}

export interface ISaesLoadSummariesAction {
    type: "ACTION_SAES_LOAD_SUMMARIES";
}

export interface ISaesLoadSummariesActionCreator {
    (): ISaesLoadSummariesAction;
}

export interface ISaesLoadByInstitutionAction {
    type: "ACTION_SAES_LOAD_BY_INSTITUTION";
    institutionCode: string;
}

export interface ISaesLoadByInstitutionActionCreator {
    (institutionCode: string): ISaesLoadByInstitutionAction;
}

export interface ISaesLoadBySaeIdAction {
    type: "ACTION_SAES_LOAD_BY_SAEID";
    saeId: number;
}

export interface ISaesLoadBySaeIdActionCreator {
    (saeId: number): ISaesLoadBySaeIdAction;
}

export interface ISaesLoadSuccessAction {
    type: "ACTION_SAES_LOAD_SUCCESS";
    data: FigDtos.Sae | FigDtos.Sae[];
}

export interface ISaesLoadSuccessActionCreator {
    (data: FigDtos.Sae | FigDtos.Sae[]): ISaesLoadSuccessAction;
}

export interface ISaesLoadFailureAction {
    type: "ACTION_SAES_LOAD_FAILURE";
    responseStatus: FigDtos.ResponseStatus;
}

export interface ISaesLoadFailureActionCreator {
    (responseStatus: FigDtos.ResponseStatus): ISaesLoadFailureAction;
}

export interface ISaesClearAction {
    type: "ACTION_SAES_CLEAR";
    propertiesToClear?: string[];
}

export interface ISaesClearActionCreator {
    (propertiesToClear?: string[]): ISaesClearAction;
}

export interface ISaesProcessNotificationNotRequiredAction {
    type: "ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED",
    institutionCode: string;
    saeId: number
}

export interface ISaesProcessNotificationNotRequiredActionCreator {
    (institutionCode: string, saeId: number): ISaesProcessNotificationNotRequiredAction;
}

export interface ISaesProcessNotificationNotRequiredSuccessAction {
    type: "ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_SUCCESS";
    sae: FigDtos.Sae | FigDtos.Sae[];
}

export interface ISaesProcessNotificationNotRequiredSuccessActionCreator {
    (sae: FigDtos.Sae | FigDtos.Sae[]): ISaesProcessNotificationNotRequiredSuccessAction;
}

export interface ISaesProcessNotificationNotRequiredFailureAction {
    type: "ACTION_SAES_PROCESS_NOTIFICATION_NOT_REQUIRED_FAILURE";
    responseStatus: FigDtos.ResponseStatus;
}

export interface ISaesProcessNotificationNotRequiredFailureActionCreator {
    (responseStatus: FigDtos.ResponseStatus): ISaesProcessNotificationNotRequiredFailureAction;
}

// Reset //
export interface ISaesProcessResetAction {
    type: "ACTION_SAES_PROCESS_RESET",
    institutionCode: string;
    saeId: number
}

export interface ISaesProcessResetActionCreator {
    (institutionCode: string, saeId: number): ISaesProcessResetAction;
}

export interface ISaesProcessResetSuccessAction {
    type: "ACTION_SAES_PROCESS_RESET_SUCCESS";
    sae: FigDtos.Sae | FigDtos.Sae[];
}

export interface ISaesProcessResetSuccessActionCreator {
    (sae: FigDtos.Sae | FigDtos.Sae[]): ISaesProcessResetSuccessAction;
}

export interface ISaesProcessResetFailureAction {
    type: "ACTION_SAES_PROCESS_RESET_FAILURE";
    responseStatus: FigDtos.ResponseStatus;
}

export interface ISaesProcessResetFailureActionCreator {
    (responseStatus: FigDtos.ResponseStatus): ISaesProcessResetFailureAction;
}

export interface ISaesProcessNotifyAction {
    type: "ACTION_SAES_PROCESS_NOTIFY";
    formData: FigDtos.SaeNotifyFormData;
    institutionCode: string;
    saeId: number;
    trialUserType: FigDtos.TrialUserType;

}

export interface ISaesProcessNotifyActionCreator {
    (
        formData: FigDtos.SaeNotifyFormData,
        institutionCode: string,
        saeId: number,
        trialUserType: FigDtos.TrialUserType
    ): ISaesProcessNotifyAction;
}

export interface ISaesProcessNotifySuccessAction {
    type: "ACTION_SAES_PROCESS_NOTIFY_SUCCESS";
    sae: FigDtos.Sae | FigDtos.Sae[];
}

export interface ISaesProcessNotifySuccessActionCreator {
    (sae: FigDtos.Sae | FigDtos.Sae[]): ISaesProcessNotifySuccessAction;
}

export interface ISaesProcessNotifyFailureAction {
    type: "ACTION_SAES_PROCESS_NOTIFY_FAILURE";
    responseStatus: FigDtos.ResponseStatus;
}

export interface ISaesProcessNotifyFailureActionCreator {
    (responseStatus: FigDtos.ResponseStatus): ISaesProcessNotifyFailureAction;
}


//review
export interface ISaesProcessReviewAction {
    type: "ACTION_SAES_PROCESS_REVIEW";
    formData: FigDtos.SaeReviewFormData;
    institutionCode: string;
    saeId: number;
    saeReviewType: FigDtos.SaeReviewType;

}

export interface ISaesProcessReviewActionCreator {
    (
        formData: FigDtos.SaeReviewFormData,
        institutionCode: string,
        saeId: number,
        saeReviewType: FigDtos.SaeReviewType
    ): ISaesProcessReviewAction;
}

export interface ISaesProcessReviewSuccessAction {
    type: "ACTION_SAES_PROCESS_REVIEW_SUCCESS";
    sae: FigDtos.Sae | FigDtos.Sae[];
    saeReviewType: FigDtos.SaeReviewType;
}

export interface ISaesProcessReviewSuccessActionCreator {
    (sae: FigDtos.Sae | FigDtos.Sae[], saeReviewType: FigDtos.SaeReviewType): ISaesProcessReviewSuccessAction;
}

export interface ISaesProcessReviewFailureAction {
    type: "ACTION_SAES_PROCESS_REVIEW_FAILURE";
    responseStatus: FigDtos.ResponseStatus;
    saeReviewType: FigDtos.SaeReviewType;
}

export interface ISaesProcessReviewFailureActionCreator {
    (responseStatus: FigDtos.ResponseStatus, saeReviewType: FigDtos.SaeReviewType): ISaesProcessReviewFailureAction;
}



export type SaeAction = ISaesLoadAction |
    ISaesLoadSummariesAction |
    ISaesLoadByInstitutionAction |
    ISaesLoadBySaeIdAction |
    ISaesLoadFailureAction |
    ISaesLoadSuccessAction |
    ISaesClearAction | 
    ISaesProcessNotificationNotRequiredAction | 
    ISaesProcessNotificationNotRequiredSuccessAction | 
    ISaesProcessNotificationNotRequiredFailureAction | 
    ISaesProcessResetAction | 
    ISaesProcessResetSuccessAction | 
    ISaesProcessResetFailureAction | 
    ISaesProcessNotifyAction | 
    ISaesProcessNotifySuccessAction | 
    ISaesProcessNotifyFailureAction | 
    ISaesProcessReviewAction | 
    ISaesProcessReviewSuccessAction | 
    ISaesProcessReviewFailureAction;
