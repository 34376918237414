import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleIneligible: IRegistrationPageToggleIneligibleActionCreator = () => {
        return {
            type: ACTION_REGISTRATION_PAGE_TOGGLE_INELIGIBLE,
        }
    },

    togglePreRegistered: IRegistrationPageTogglePreRegisteredActionCreator = () => {
        return {
            type: ACTION_REGISTRATION_PAGE_TOGGLE_PRE_REGISTERED,
        }
    },

    toggleRegistered: IRegistrationPageToggleRegisteredActionCreator = () => {
        return {
            type: ACTION_REGISTRATION_PAGE_TOGGLE_REGISTERED,
        }
    },

    toggleSubmitted: IRegistrationPageToggleSubmittedActionCreator = () => {
        return {
            type: ACTION_REGISTRATION_PAGE_TOGGLE_SUBMITTED,
        }
    },

    toggleEligable: IRegistrationPageToggleEligableActionCreator = () => {
        return {
            type: ACTION_REGISTRATION_PAGE_TOGGLE_ELIGABLE,
        }
    },

    toggleRandomised: IRegistrationPageToggleRandomisedActionCreator = () => {
        return {
            type: ACTION_REGISTRATION_PAGE_TOGGLE_RANDOMISED,
        }
    },

    updateCountry: IRegistrationPageUpdateCountryActionCreator = (country: string) => {
        return {
            type: ACTION_REGISTRATION_PAGE_UPDATE_COUNTRY,
            country
        }

    },
    setPatientStatusModalViewState: IRegistrationPageSetPatientStatusModalViewStateActionCreator = (open: boolean) => {
        return {
            type: ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE,
            open
        }
    },
    setPatientStatusModalPatientId: IRegistrationPageSetPatientStatusModalPatientIdActionCreator = (id: number) => {
        return {
            type: ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID,
            id
        }
    },
    setPatientStatusModalNewStatus: IRegistrationPageSetPatientStatusModalNewStatusActionCreator = (newStatus: Dtos.PatientStatus) => {
        return {
            type: ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS,
            newStatus
        }
    },
    setPatientStatusModalSiteNumber: IRegistrationPageSetPatientStatusModalPatientSiteNumberActionCreator = (siteNumber: number) => {
        return {
            type: ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER,
            siteNumber
        }
    },
    setPatientStatusModalStatusConfiguration: IRegistrationPageSetPatientStatusModalStatusConfigurationActionCreator = (patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>) => {
        return {
            type: ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION,
            patientStatusConfiguration
        }
    }

export const
    ACTION_REGISTRATION_PAGE_TOGGLE_INELIGIBLE = "ACTION_REGISTRATION_PAGE_TOGGLE_INELIGIBLE",
    ACTION_REGISTRATION_PAGE_TOGGLE_PRE_REGISTERED = "ACTION_REGISTRATION_PAGE_TOGGLE_PRE_REGISTERED",
    ACTION_REGISTRATION_PAGE_TOGGLE_REGISTERED = "ACTION_REGISTRATION_PAGE_TOGGLE_REGISTERED",
    ACTION_REGISTRATION_PAGE_TOGGLE_SUBMITTED = "ACTION_REGISTRATION_PAGE_TOGGLE_SUBMITTED",
    ACTION_REGISTRATION_PAGE_TOGGLE_ELIGABLE = "ACTION_REGISTRATION_PAGE_TOGGLE_ELIGABLE",
    ACTION_REGISTRATION_PAGE_TOGGLE_RANDOMISED = "ACTION_REGISTRATION_PAGE_TOGGLE_RANDOMISED",
    ACTION_REGISTRATION_PAGE_UPDATE_INSTITUTION_CODE = "ACTION_REGISTRATION_PAGE_UPDATE_INSTITUTION_CODE",
    ACTION_REGISTRATION_PAGE_UPDATE_COUNTRY = "ACTION_REGISTRATION_PAGE_UPDATE_COUNTRY",

    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE = "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE",
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID = "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID",
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER = "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER",
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS = "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS",
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION = "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION"

/* Actions */
export interface IRegistrationPageToggleIneligibleAction {
    type: "ACTION_REGISTRATION_PAGE_TOGGLE_INELIGIBLE";
}

export interface IRegistrationPageTogglePreRegisteredAction {
    type: "ACTION_REGISTRATION_PAGE_TOGGLE_PRE_REGISTERED";
}

export interface IRegistrationPageToggleRegisteredAction {
    type: "ACTION_REGISTRATION_PAGE_TOGGLE_REGISTERED";
}

export interface IRegistrationPageToggleSubmittedAction {
    type: "ACTION_REGISTRATION_PAGE_TOGGLE_SUBMITTED";
}

export interface IRegistrationPageToggleEligableAction {
    type: "ACTION_REGISTRATION_PAGE_TOGGLE_ELIGABLE";
}

export interface IRegistrationPageToggleRandomisedAction {
    type: "ACTION_REGISTRATION_PAGE_TOGGLE_RANDOMISED";
}

export interface IRegistrationPageUpdateCountryAction {
    type: "ACTION_REGISTRATION_PAGE_UPDATE_COUNTRY",
    country: string
}

export interface IRegistrationPageSetPatientStatusModalViewStateAction {
    type: "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE",
    open: boolean;
}

export interface IRegistrationPageSetPatientStatusModalPatientIdAction {
    type: "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID",
    id: number;
}

export interface IRegistrationPageSetPatientStatusModalPatientSiteNumberAction {
    type: "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER",
    siteNumber: number;
}

export interface IRegistrationPageSetPatientStatusModalNewStatusAction {
    type: "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS",
    newStatus: Dtos.PatientStatus;
}

export interface IRegistrationPageSetPatientStatusModalStatusConfigurationAction {
    type: "ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION",
    patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>;
}

/* Action Creators */
export interface IRegistrationPageToggleIneligibleActionCreator {
    (): IRegistrationPageToggleIneligibleAction;
}

export interface IRegistrationPageTogglePreRegisteredActionCreator {
    (): IRegistrationPageTogglePreRegisteredAction;
}

export interface IRegistrationPageToggleRegisteredActionCreator {
    (): IRegistrationPageToggleRegisteredAction;
}

export interface IRegistrationPageToggleSubmittedActionCreator {
    (): IRegistrationPageToggleSubmittedAction;
}

export interface IRegistrationPageToggleEligableActionCreator {
    (): IRegistrationPageToggleEligableAction;
}

export interface IRegistrationPageToggleRandomisedActionCreator {
    (): IRegistrationPageToggleRandomisedAction;
}

export interface IRegistrationPageUpdateCountryActionCreator {
    (country: string): IRegistrationPageUpdateCountryAction
}

export interface IRegistrationPageSetPatientStatusModalViewStateActionCreator {
    (open: boolean): IRegistrationPageSetPatientStatusModalViewStateAction;
}

export interface IRegistrationPageSetPatientStatusModalPatientIdActionCreator {
    (id: number): IRegistrationPageSetPatientStatusModalPatientIdAction;
}

export interface IRegistrationPageSetPatientStatusModalPatientSiteNumberActionCreator {
    (siteNumber: number): IRegistrationPageSetPatientStatusModalPatientSiteNumberAction;
}

export interface IRegistrationPageSetPatientStatusModalNewStatusActionCreator {
    (newStatus: Dtos.PatientStatus): IRegistrationPageSetPatientStatusModalNewStatusAction;
}

export interface IRegistrationPageSetPatientStatusModalStatusConfigurationActionCreator {
    (patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>): IRegistrationPageSetPatientStatusModalStatusConfigurationAction;
}

/* Final Export of actions */
export type RegistrationPageAction = IRegistrationPageToggleIneligibleAction |
    IRegistrationPageTogglePreRegisteredAction |
    IRegistrationPageToggleRegisteredAction | 
    IRegistrationPageToggleSubmittedAction | 
    IRegistrationPageToggleEligableAction | 
    IRegistrationPageToggleRandomisedAction |
    IRegistrationPageUpdateCountryAction |
    IRegistrationPageSetPatientStatusModalViewStateAction |
    IRegistrationPageSetPatientStatusModalPatientIdAction |
    IRegistrationPageSetPatientStatusModalNewStatusAction |
    IRegistrationPageSetPatientStatusModalPatientSiteNumberAction | 
    IRegistrationPageSetPatientStatusModalStatusConfigurationAction;
