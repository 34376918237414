import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
    
export const initialGlioblastomaDetailFormState: Dtos.GlioblastomaDetailForm & IRegistrationFormState = {
    id: null,
    patientId: null,
    dateOfSurgeryForGBM: null,
    extentOfResections: null,
    isTissueAvailableForBiologicalSubStudy: null,
    idH1StatusFromIHC: null,
    plannedChemoRTStartDate: null,
    expectedChemoRTCompletion: null,
    plannedDoseOfTemozolomide: null,
    plannedDoseOfTemozolomideTotal: null,
    plannedTemozolomideFrequency: null,
    plannedOtherSystemicTreatment: null,
    plannedOtherSystemicTreatmentName: null,
    plannedOtherSystemicTreatmentDose: null,
    plannedOtherSystemicTreatmentRoute: null,
    plannedOtherSystemicTreatmentFrequency: null,
    plannedRTPrescription: null,
    temozolomideStartDate: null,
    temozolomideStopDate: null,
    temozolomideOngoing: null,
    temozolomideDose: null,
    temozolomideDoseTotal: null,
    temozolomideFrequency: null,
    otherSystemicTreatment: null,
    otherSystemicTreatmentName: null,
    otherSystemicTreatmentStartDate: null,
    otherSystemicTreatmentStopDate: null,
    otherSystemicTreatmentOngoing: null,
    otherSystemicTreatmentDose: null,
    otherSystemicTreatmentRoute: null,
    otherSystemicTreatmentFrequency: null,
    radiotherapyStartDate: null,
    radiotherapyStopDate: null,
    radiotherapyOngoing: null,
    radiotherapyPrescription: null,
    isAdjuvantChemotherapyPlannedUsingTMZ: null,
    plannedStartDate: null,
    participatingInAnyOtherTrial: null,
    participatingInAnyOtherTrialName: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
}

export interface IGlioblastomaDetailReduxFormState {
    id: IFieldState<number>,
    patientId: IFieldState<number>,
    dateOfSurgeryForGBM: IFieldState<string>,
    extentOfResections: IFieldState<number>,
    isTissueAvailableForBiologicalSubStudy: IFieldState<number>,
    idH1StatusFromIHC: IFieldState<number>,
    plannedChemoRTStartDate: IFieldState<string>,
    expectedChemoRTCompletion: IFieldState<string>,
    plannedDoseOfTemozolomide: IFieldState<number>,
    plannedDoseOfTemozolomideTotal: IFieldState<number>,
    plannedTemozolomideFrequency: IFieldState<number>,
    plannedOtherSystemicTreatment: IFieldState<number>,
    plannedOtherSystemicTreatmentName: IFieldState<string>,
    plannedOtherSystemicTreatmentDose: IFieldState<string>,
    plannedOtherSystemicTreatmentRoute: IFieldState<number>,
    plannedOtherSystemicTreatmentFrequency: IFieldState<string>,
    plannedRTPrescription: IFieldState<number>,
    temozolomideStartDate: IFieldState<string>,
    temozolomideStopDate: IFieldState<string>,
    temozolomideOngoing: IFieldState<boolean>,
    temozolomideDose: IFieldState<number>,
    temozolomideDoseTotal: IFieldState<number>,
    temozolomideFrequency: IFieldState<number>,
    otherSystemicTreatment: IFieldState<number>,
    otherSystemicTreatmentName: IFieldState<string>,
    otherSystemicTreatmentStartDate: IFieldState<string>,
    otherSystemicTreatmentStopDate: IFieldState<string>,
    otherSystemicTreatmentOngoing: IFieldState<boolean>,
    otherSystemicTreatmentDose: IFieldState<string>,
    otherSystemicTreatmentRoute: IFieldState<number>,
    otherSystemicTreatmentFrequency: IFieldState<string>,
    radiotherapyStartDate: IFieldState<string>,
    radiotherapyStopDate: IFieldState<string>,
    radiotherapyOngoing: IFieldState<boolean>,
    radiotherapyPrescription: IFieldState<number>,
    isAdjuvantChemotherapyPlannedUsingTMZ: IFieldState<number>,
    plannedStartDate: IFieldState<string>,
    participatingInAnyOtherTrial: IFieldState<number>,
    participatingInAnyOtherTrialName: IFieldState<string>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>
}
