
import * as React from "react";
import { ErrorsProps, CustomComponentProps } from "react-redux-form";

import './NotificationAlert.scss';
import classNames from "classnames";
import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";


export interface INotificationAlertProps {
    alertContent: React.ReactNode;
    icon?: any;
    className?: string;
    maxWidth?: number;
    minWidth?: number;
}

class NotificationAlert extends React.Component<INotificationAlertProps, any> {
    render() {
        const {
            alertContent,
            icon,
            className,
            maxWidth,
            minWidth
        } = this.props;

        const derivedMaxWidth = maxWidth ? maxWidth : 300;
        const derivedMinWidth = minWidth ? minWidth : 300

        return <div>
            <div style={{maxWidth: derivedMaxWidth, minWidth: derivedMinWidth}} className={classNames("container alert-container", className )}>
                <div className="row">
                    {
                        icon ?
                            <div className="col alert-icon">
                                <FontAwesomeIcon icon={icon} size="4x" fixedWidth/>
                            </div> :
                            null
                    }
                    {
                        alertContent ?
                            <div className="col alert-content p-6">
                                {alertContent}
                            </div> :
                            null
                    }
                </div>
            </div>
        </div>
    }
}

export default NotificationAlert;