import * as React from "react";
import classNames from "classnames";
import * as Dtos from '../../../dtos/Fig.dtos';
import { actions, FieldAction, ChangeOptions, ModelAction } from 'react-redux-form';
import { IBaseFormInputProps, ValidationMessage } from "../index";

import './GenericFormGroup.scss';
import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";

export interface IGenericFormGroupProps {
    groupClassName: string;
    labelClassName: string;
    fieldClassName: string;
    validate: boolean;
    validateFn: (touched: boolean, focused: boolean) => boolean
    valid: boolean;
    touched: boolean;
    focused: boolean;
    label: React.ReactNode;

    onKeyPress: (value: any) => void;
    onBlur: () => FieldAction;
    onChange: (value: any, options?: ChangeOptions) => ModelAction;
    onFocus: () => FieldAction;

    inputId: string;
    inputName: string;
    inputValue: any;
    inputDisabled: any;
    inputType: React.ComponentType<IBaseFormInputProps>;
    inputClassName: string;
    inputProps: any;

    disableEnter?: boolean;

    validationMessageAbove: boolean;

    showValidation?: boolean;
    showSoftErrors?: boolean;
    showHardErrors?: boolean;

    removeContainer?: boolean;
}

class GenericFormGroup extends React.PureComponent<IGenericFormGroupProps, any> {
    constructor(props) {
        super(props);

        this.onKeyPressExtended = this.onKeyPressExtended.bind(this);
    }

    render() {

        const {
            groupClassName,
            inputClassName,
            fieldClassName,
            labelClassName,
            valid,
            validate,
            disableEnter,
            focused,
            inputDisabled,
            inputId,
            inputName,
            label,
            onBlur,
            onChange,
            onFocus,
            onKeyPress,
            touched,
            inputType,
            inputProps,
            inputValue,
            children,
            validateFn,
            validationMessageAbove,
            removeContainer,
            showValidation,
            showSoftErrors,
            showHardErrors
        } = this.props


        const classes = {
            group: groupClassName ? groupClassName : "form-group row border border-bottom-1 border-top-0 pb-4 pt-4",
            label: labelClassName ? labelClassName : "col-form-label col-sm-3",
            field: fieldClassName ?
                fieldClassName :
                removeContainer ?
                    "" :
                    "col-sm-9"
        }

        const derivedShowValidation = showValidation != undefined ? showValidation : touched && validate;

        const formInput = <div className={classNames(
            classes.field,
            { "focus": focused },
            "generic-form-field")}
        >
            <this.props.inputType
                className={
                    classNames(
                        inputClassName)}
                name={inputName}
                label={label}
                id={inputId}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                onKeyPress={this.onKeyPressExtended}
                value={inputValue}
                disabled={inputDisabled}
                invalid={derivedShowValidation && !valid}
                valid={derivedShowValidation && valid}
                focused={focused}
                hasSoftError={showSoftErrors && !inputDisabled}
                hasHardError={showHardErrors}
                {...inputProps}
            />
            {
                derivedShowValidation && !inputDisabled ?
                    <div className={classNames("validation-messages block", "px-4", "pt-2", { "above": focused && validationMessageAbove })}>
                        {children}
                    </div> :
                    null
            }
            {
                showSoftErrors && !inputDisabled ? 
                <div className={classNames("validation-messages soft", "px-4", "pt-2", { "above": focused && validationMessageAbove })}>
                    {children}
                </div> :
                null
            }
        </div>

        if (removeContainer) {
            return formInput;
        }

        return (
            <div
                className={
                    classNames(
                        classes.group
                    )
                }
            >
                <label
                    className={classNames(
                        classes.label,
                        {
                            "is-invalid": validate && !valid
                        }
                    )}
                    htmlFor={inputName}>
                    {label}

                </label>
                {formInput}
            </div>
        );
    }

    onKeyPressExtended(event) {

        const {
            disableEnter,
            onKeyPress
        } = this.props

        if (disableEnter) {
            if (event.key == "Enter") {
                event.preventDefault();
                return false;
            }
        }

        onKeyPress(event);
    }
}

export default GenericFormGroup;