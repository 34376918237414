import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';

import {
    ACTION_GLIOBLASTOMADETAILFORMS_CLEAR,
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD,
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_ID,
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_ID,
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_FAILURE,
    ACTION_GLIOBLASTOMADETAILFORMS_LOAD_SUCCESS,

    ACTION_GLIOBLASTOMADETAILFORMS_SAVE,
    ACTION_GLIOBLASTOMADETAILFORMS_SAVE_FAILURE,
    ACTION_GLIOBLASTOMADETAILFORMS_SAVE_SUCCESS,

    ACTION_GLIOBLASTOMADETAILFORMS_CREATE,
    ACTION_GLIOBLASTOMADETAILFORMS_CREATE_SUCCESS,
    ACTION_GLIOBLASTOMADETAILFORMS_CREATE_FAILURE,

    IGlioblastomaDetailFormLoadAction,
    IGlioblastomaDetailFormClearAction,
    IGlioblastomaDetailFormLoadByIdAction,
    IGlioblastomaDetailFormLoadByPatientIdAction,
    IGlioblastomaDetailFormLoadByPatientIdentifierAction,
    IGlioblastomaDetailFormLoadFailureAction,
    IGlioblastomaDetailFormLoadSuccessAction,

    IGlioblastomaDetailFormSaveAction,
    IGlioblastomaDetailFormSaveSuccessAction,
    IGlioblastomaDetailFormSaveFailureAction,

    IGlioblastomaDetailFormCreateAction,
    IGlioblastomaDetailFormCreateSuccessAction,
    IGlioblastomaDetailFormCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure


} from "../../actions/forms/glioblastomaDetailForm";

import { glioblastomaDetailFormApi } from "../../services/Forms/glioblastomaDetailForm";


export const
    getGlioblastomaDetailFormsEpic = (action$): Observable<IGlioblastomaDetailFormLoadSuccessAction | IGlioblastomaDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_GLIOBLASTOMADETAILFORMS_LOAD)
            .mergeMap((action: IGlioblastomaDetailFormLoadAction) => {
                return glioblastomaDetailFormApi
                    .getGlioblastomaDetailForms()
                    .map(response =>
                        LoadSuccess(response.glioblastomaDetailForms, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getGlioblastomaDetailFormsByIdEpic = (action$): Observable<IGlioblastomaDetailFormLoadSuccessAction | IGlioblastomaDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_ID)
            .mergeMap((action: IGlioblastomaDetailFormLoadByIdAction) => {
                return glioblastomaDetailFormApi
                    .getGlioblastomaDetailFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.glioblastomaDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getGlioblastomaDetailFormsByPatientIdEpic = (action$): Observable<IGlioblastomaDetailFormLoadSuccessAction | IGlioblastomaDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_ID)
            .mergeMap((action: IGlioblastomaDetailFormLoadByPatientIdAction) => {
                return glioblastomaDetailFormApi
                    .getGlioblastomaDetailFormByPatientId(action.patientId)
                    .map(response =>
                        LoadSuccess(response.glioblastomaDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getGlioblastomaDetailFormsByPatientIdentifierEpic = (action$): Observable<IGlioblastomaDetailFormLoadSuccessAction | IGlioblastomaDetailFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_GLIOBLASTOMADETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER)
            .mergeMap((action: IGlioblastomaDetailFormLoadByPatientIdentifierAction) => {
                return glioblastomaDetailFormApi
                    .getGlioblastomaDetailFormByPatientIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.glioblastomaDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setGlioblastomaDetailFormsEpic = (action$): Observable<IGlioblastomaDetailFormSaveSuccessAction | IGlioblastomaDetailFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_GLIOBLASTOMADETAILFORMS_SAVE)
            .mergeMap((action: IGlioblastomaDetailFormSaveAction) => {
                return glioblastomaDetailFormApi
                    .setGlioblastomaDetailForm(action.formData, action.institutionCode, action.patientIdentifier)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.glioblastomaDetailForm, response.registrationFormState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createGlioblastomaDetailFormsEpic = (action$): Observable<IGlioblastomaDetailFormCreateSuccessAction | IGlioblastomaDetailFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_GLIOBLASTOMADETAILFORMS_CREATE)
            .mergeMap((action: IGlioblastomaDetailFormCreateAction) => {
                return glioblastomaDetailFormApi
                    .createGlioblastomaDetailForm()
                    .map(response =>
                        CreateSuccess(response.glioblastomaDetailForm, response.registrationFormState, response.registrationFormProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }





