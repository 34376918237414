import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
import { ISourceDocumentModalState } from "./sourceDocument";

    
export const initialPatientReportedFormState: Dtos.PatientReportedForm & IRegistrationFormState & ISourceDocumentModalState = {
    id: null,
    patientId: null,
    timepoint: null,
    assessmentDate: null,
    eortcqlqC30FormCompleted: null,
    requiredAssistanceCompletingQuestionnaire: null,
    requiredAssistanceCompletingQuestionnaireSpecify: null,
    howWasQuestionnaireAdministered: null,
    howWasQuestionnaireAdministeredSpecify: null,
    languageUsed: null,
    languageUsedSpecify: null,
    appropriateReasonNonCompletion: null,
    participantsLanguage: null,
    nonCompletionIllnessRelated: null,
    notes: null,
    troubleDoingStrenuousActivities: null,
    troubleTakingALongWalk: null,
    troubleTakingShortWalkOutsideHouse: null,
    needToStayInBedOrChairDuringDay: null,
    helpEatingDressinWashingToilet: null,
    limitedDoingWorkDailyActivities: null,
    limitedPursuingHobbies: null,
    shortOfBreath: null,
    pain: null,
    needARest: null,
    troubleSleeping: null,
    feltWeak: null,
    lackedAppetite: null,
    feltNauseated: null,
    vomited: null,
    consipated: null,
    diarrhoea: null,
    tired: null,
    painInterferedDailyActivities: null,
    difficultyConcentrating: null,
    tense: null,
    worry: null,
    irritable: null,
    depressed: null,
    difficultyRememberingThings: null,
    interferedFamily: null,
    interferedSocial: null,
    financialDifficulties: null,
    overallHealth: null,
    overallQualityOfLife: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false,
    openedModals: [],
    sourceDocuments: [],
}

export interface IPatientReportedReduxFormState {
    id: IFieldState<number>,
    timepoint: IFieldState<number>,
    patientId: IFieldState<number>,
    assessmentDate: IFieldState<string>,
    eortcqlqC30FormCompleted: IFieldState<number>,
    requiredAssistanceCompletingQuestionnaire: IFieldState<number>,
    requiredAssistanceCompletingQuestionnaireSpecify: IFieldState<string>,
    howWasQuestionnaireAdministered: IFieldState<number>,
    howWasQuestionnaireAdministeredSpecify: IFieldState<string>,
    languageUsed: IFieldState<number>,
    languageUsedSpecify: IFieldState<string>,
    appropriateReasonNonCompletion: IFieldState<number>,
    participantsLanguage: IFieldState<string>,
    nonCompletionIllnessRelated: IFieldState<number>,
    notes: IFieldState<string>,
    troubleDoingStrenuousActivities: IFieldState<number>,
    troubleTakingALongWalk: IFieldState<number>,
    troubleTakingShortWalkOutsideHouse: IFieldState<number>,
    needToStayInBedOrChairDuringDay: IFieldState<number>,
    helpEatingDressinWashingToilet: IFieldState<number>,
    limitedDoingWorkDailyActivities: IFieldState<number>,
    limitedPursuingHobbies: IFieldState<number>,
    shortOfBreath: IFieldState<number>,
    pain: IFieldState<number>,
    needARest: IFieldState<number>,
    troubleSleeping: IFieldState<number>,
    feltWeak: IFieldState<number>,
    lackedAppetite: IFieldState<number>,
    feltNauseated: IFieldState<number>,
    vomited: IFieldState<number>,
    consipated: IFieldState<number>,
    diarrhoea: IFieldState<number>,
    tired: IFieldState<number>,
    painInterferedDailyActivities: IFieldState<number>,
    difficultyConcentrating: IFieldState<number>,
    tense: IFieldState<number>,
    worry: IFieldState<number>,
    irritable: IFieldState<number>,
    depressed: IFieldState<number>,
    difficultyRememberingThings: IFieldState<number>,
    interferedFamily: IFieldState<number>,
    interferedSocial: IFieldState<number>,
    financialDifficulties: IFieldState<number>,
    overallHealth: IFieldState<number>,
    overallQualityOfLife: IFieldState<number>,
    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    openedModals: IFieldState<string[]>
}
