import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';
import {
    ACTION_INSTITUTIONS_LOAD,
    ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE,
    ACTION_INSTITUTIONS_LOAD_BY_COUNTRY,
    ACTION_INSTITUTIONS_LOAD_BY_STATE,
    ACTION_INSTITUTIONS_LOAD_SUCCESS,
    ACTION_INSTITUTIONS_LOAD_FAILURE,
    ACTION_INSTITUTIONS_CLEAR,
    LoadSuccess,
    LoadFailure,
    IInstitutionClearAction, 
    IInstitutionLoadAction,
    IInstitutionLoadByCountryAction,
    IInstitutionLoadByInstitutionCodeAction,
    IInstitutionLoadByStateAction,
    IInstitutionLoadFailureAction,
    IInstitutionLoadSuccessAction
    
    
} from "../actions/institution";
import { institutionApi } from "../services/institutions";


export const
    getInstitutonsEpic = (action$): Observable<IInstitutionLoadSuccessAction | IInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_LOAD)
            .mergeMap((action: IInstitutionLoadAction) => {
                return institutionApi
                    .getInstitutions()
                    .map(response =>
                        LoadSuccess(response.institutions)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getInstitutionByInstitutionCodeEpic = (action$): Observable<IInstitutionLoadSuccessAction | IInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE)
            .mergeMap((action: IInstitutionLoadByInstitutionCodeAction) => {
                return institutionApi
                    .getInstitutionsByInstitutionCode(action.institutionCode)
                    .map(response =>
                        LoadSuccess(response.institution)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getInstitutionsByCountryEpic = (action$): Observable<IInstitutionLoadSuccessAction | IInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_LOAD_BY_COUNTRY)
            .mergeMap((action: IInstitutionLoadByCountryAction) => {
                return institutionApi
                    .getInstitutionsByCountry(action.country)
                    .map(response =>
                        LoadSuccess(response.institutions)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getInstitutionsByStateEpic = (action$): Observable<IInstitutionLoadSuccessAction | IInstitutionLoadFailureAction> => {
        return action$
            .ofType(ACTION_INSTITUTIONS_LOAD_BY_STATE)
            .mergeMap((action: IInstitutionLoadByStateAction) => {
                return institutionApi
                    .getInstitutionsByState(action.state)
                    .map(response =>
                        LoadSuccess(response.institutions)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    }



