import * as React from 'react';
import { DefaultLayout, INavItem, SubMenu, Footer } from "./index";
import { InlineLoading, OverlayLoading } from "../common/index";

interface IRestrictedLayoutProps {
    subMenuItems?: INavItem[];
    loading?: boolean;
    saving?: boolean;
}

class RestrictedLayout extends React.PureComponent<IRestrictedLayoutProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {
        const {
            subMenuItems,
            loading,
            saving
        } = this.props;

        return <DefaultLayout>
            {
                !loading && subMenuItems && subMenuItems.length > 0 ?
                    <SubMenu loading={loading} items={subMenuItems} /> :
                    null
            }
            <div className="container bg-white py-3 box-shadow-x" style={{ minHeight: "300px", position: loading ? "relative" : undefined }}>
                {
                    loading ?
                        <InlineLoading absoluteCenter={true} size={40} /> :
                        <div>
                            {
                                saving ? 
                                    <OverlayLoading size={40} /> :
                                    null
                            }
                            {this.props.children}
                        </div>
                }
            </div>
            <div className="container bg-white p-0 box-shadow-x">
                <Footer></Footer>
            </div>
        </DefaultLayout>
    }
}

export default RestrictedLayout;