import * as React from 'react';
import './SaeViewPage.scss';

import classNames from "classnames";
import { match } from 'react-router'
import { Location, History } from "history";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { routerActions } from 'react-router-redux';

import { flow } from "lodash";
import Alert from 'react-s-alert';

import { DefaultLayout, RestrictedLayout, INavItem, UnrestrictedLayout } from "../../../components/layouts/index";

import * as PatientActions from '../../../actions/patient';

import * as SaeActions from '../../../actions/sae';
import * as InstitutionActions from '../../../actions/institution';
import * as SaeViewPageActions from '../../../actions/pages/sae/saeViewPage';

import * as AuthenticationHelper from '../../../helpers/authentication';
import * as AuthorisationDefinitions from '../../../constants/authorisationDefinitions';

import * as FigDtos from '../../../dtos/Fig.dtos';
import { IFigState } from "../../../reducers/index";
import { RequestState } from "../../../enumerations/RequestState";

import { FontAwesomeIcons, FontAwesomeIcon } from "../../../constants/fontAwesomeIcons";
import { Link } from "../../../components/routing/index";
import { PatientPanel, Tree, TreeNode, ListGroupItem, NotificationAlert, Modal } from "../../../components/common/index";
import { ITreeNode, ITree } from "../../../components/common/Tree/Tree";
import { CamelToKebabCase } from "../../../functions/string";

//import { getPatientStatusText } from "../../../helpers/patient";
import { getSaeStatusText, getSaeManagerState, SaeReviewType } from "../../../helpers/sae";

import { setModalTitle } from "../../../helpers/modalHelper";
import SummaryPanel from '../../../components/common/SummaryPanel/SummaryPanel';
import SaeSummary from '../../../components/sae/SaeSummary/SaeSummary';
import { convertToShortDateString } from '../../../helpers/date';
import { clearSaeViewPage } from '../../../actions/pages/sae/saeViewPage';
import SaeManager from '../../../components/sae/SaeManager/SaeManager';
import { ProcessSaeReset, processSaeReview, ProcessSaeReviewSuccess } from '../../../actions/sae';
import { ISaeReviewFormState } from '../../../reducers/reactReduxForms/saeReviewForm';
import { actions } from 'react-redux-form';


interface ISaeViewPageParams {
    saeId: number;
    institutionCode: string;
}

interface ISaeViewPageProps {
    location: Location;
    match: match<ISaeViewPageParams>;
    history: History;
    saeId: number;
    institutionCode: string;

    sae: FigDtos.Sae;
    loadingSae: boolean;
    loadSaeSuccess: boolean;
    loadSaeFailure: boolean;

    institution: FigDtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    expandTreatmentData: boolean;
    expandStudyDrugData: boolean;
    expandMedicationData: boolean;
    expandActionHistoryData: boolean;

    processingNotificationNotRequired: boolean;
    notificationNotRequiredSuccess: boolean;
    notificationNotRequiredFailure: boolean;

    processingReset: boolean;
    resetSaeSuccess: boolean;
    resetSaeFailure: boolean;

    processingReviewApprove: boolean;
    processingReviewReject: boolean;

    reviewSaeApproveSuccess: boolean;
    reviewSaeApproveFailure: boolean;

    reviewSaeRejectSuccess: boolean;
    reviewSaeRejectFailure: boolean;

    user: FigDtos.User

    reviewSaeRejectErrors: FigDtos.ResponseError[];
}


interface ISaeViewPageActions {
    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    clearSae: SaeActions.ISaesClearActionCreator;
    clearSaeViewPage: SaeViewPageActions.ISaeViewPageClearActionCreator;
    //clearPatientUpdate: () => void;
    loadSae: SaeActions.ISaesLoadBySaeIdActionCreator;
    //setPatientStatusById: PatientActions.IPatientSetStatusByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;
    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    toggleTreatmentData: SaeViewPageActions.ISaeViewPageToggleTreatmentDataActionCreator;
    toggleStudyDrugData: SaeViewPageActions.ISaeViewPageToggleStudyDrugDataActionCreator;
    toggleMedicationData: SaeViewPageActions.ISaeViewPageToggleMedicationDataActionCreator;
    toggleActionHistoryData: SaeViewPageActions.ISaeViewPageToggleActionHistoryDataActionCreator;

    processNotificationNotRequired: SaeActions.ISaesProcessNotificationNotRequiredActionCreator;
    processReset: SaeActions.ISaesProcessResetActionCreator;
    processReview: SaeActions.ISaesProcessReviewActionCreator;

    resetReviewForm: typeof actions.reset;

}

type SaeViewPageProps = ISaeViewPageProps & ISaeViewPageActions;

const reduxReviewForm: string = "reduxForms.saeReviewForm"

class SaeViewPage extends React.PureComponent<SaeViewPageProps, any> {

    constructor(props: SaeViewPageProps) {
        super(props);

        this.clearSae = this.clearSae.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this);
        this.handleSubmitFailure = this.handleSubmitFailure.bind(this);
    }

    componentDidMount() {
        const {
            loadSae,
            saeId,
            institutionCode,
            loadInstitution

        } = this.props;

        if (saeId) {
            loadSae(saeId);
        }

        if (institutionCode) {
            loadInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: SaeViewPageProps) {
    }

    componentDidUpdate(prevProps: SaeViewPageProps) {
        const {
            sae,
            loadSae,
            loadingSae,
            loadSaeSuccess,
            loadSaeFailure,
            navigate,
            navigateReplace,
            institutionCode,
            loadInstitution,

            processingNotificationNotRequired,
            processingReset,
            processingReviewApprove,
            processingReviewReject,

            notificationNotRequiredSuccess,
            notificationNotRequiredFailure,

            resetSaeSuccess,
            resetSaeFailure,

            reviewSaeApproveSuccess,
            reviewSaeApproveFailure,
            reviewSaeRejectSuccess,
            reviewSaeRejectFailure,

            reviewSaeRejectErrors

        } = this.props;

        if (sae &&
            sae.saeId &&
            prevProps.sae &&
            prevProps.sae.saeId == null) {
            navigateReplace("/sae/" + institutionCode + "/view-sae" + sae.saeId);
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }

        if (!processingNotificationNotRequired && prevProps.processingNotificationNotRequired && notificationNotRequiredSuccess) {
            Alert.success(<NotificationAlert
                alertContent={this.renderNotificationNotrequiredSuccess()}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

        if (!processingReset && prevProps.processingReset && resetSaeSuccess) {
            Alert.success(<NotificationAlert
                alertContent={this.renderResetSuccess()}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

        if (!processingReviewApprove && prevProps.processingReviewApprove && reviewSaeApproveSuccess) {
            Alert.success(<NotificationAlert
                alertContent={this.renderApproveSuccess()}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

        if (!processingReviewReject && prevProps.processingReviewReject && reviewSaeRejectSuccess) {
            Alert.success(<NotificationAlert
                alertContent={this.renderRejectSuccess()}
                icon={FontAwesomeIcons.Solid.CHECK}
            />);
        }

        if (!processingReviewReject &&
            prevProps.processingReviewReject &&
            reviewSaeRejectFailure && 
            reviewSaeRejectErrors) {

            for (let error of reviewSaeRejectErrors) {
                Alert.warning(<NotificationAlert
                    alertContent={<span>{error.message}</span>}
                    icon={FontAwesomeIcons.Regular.TIMES}
                />);
            }
        }
    }

    componentWillUpdate(nextProps: SaeViewPageProps) {
    }

    componentWillUnmount() {
        const {
            clearSaeViewPage
        } = this.props;

        clearSaeViewPage();
        this.clearInstitution();
        this.clearSae();
        this.clearReviewFormData();
    }

    render() {

        const {
            loadingSae,
            loadingInstitution,
            processingReset,
            processingNotificationNotRequired,

            processingReviewApprove,
            processingReviewReject
        } = this.props

        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={(!this.showSae() || loadingSae || loadingInstitution)}
            saving={processingReset || processingNotificationNotRequired || processingReviewApprove || processingReviewReject}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {

        const {
            sae,
            institutionCode,
            loadingSae,
            loadSaeSuccess,
            loadSaeFailure,
            clearSae,
            institution,
            user,
            saeId,

            expandActionHistoryData,
            expandMedicationData,
            expandStudyDrugData,
            expandTreatmentData,
            toggleActionHistoryData,
            toggleMedicationData,
            toggleStudyDrugData,
            toggleTreatmentData,
            navigate,
            navigateReplace,

            processNotificationNotRequired,
            processReset,
            processReview
        } = this.props;

        const saeNoExists = sae && sae.saeInitialData && sae.saeInitialData.saeNumber;

        return <div>
            {
                this.showSae() && sae ?
                    <div>
                        <div>
                            <SummaryPanel
                                leftPanelTopText={saeNoExists ? sae.saeInitialData.saeNumber : sae.randNo}
                                leftPanelBottomText={saeNoExists ? 'SAE Number' : 'Registration number'}
                                leftPanelIcon={saeNoExists ? FontAwesomeIcons.Solid.LIST : FontAwesomeIcons.Solid.USER}
                                leftPanelToolTip={saeNoExists ? 'Date of Onset: ' + convertToShortDateString(sae.saeInitialData.onsetDate) : undefined}

                                middlePanelTopText={sae.institutionCode}
                                middlePanelBottomText={'Institution'}
                                middlePanelIcon={FontAwesomeIcons.Solid.BUILDING}
                                middlePanelToolTip={sae.institutionName}

                                rightPanelTopText={getSaeStatusText(sae.saeStatus)}
                                rightPanelBottomText={'Status'}
                                rightPanelIcon={FontAwesomeIcons.Solid.HEARTBEAT}
                                rightPanelToolTip={'Date Entered: ' + convertToShortDateString(sae.saeInitialData.dateEntered)}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <SaeManager
                                saeManagerState={getSaeManagerState(sae)}
                                saeId={sae.saeId}
                                institutionCode={sae.institutionCode}
                                navigate={navigate}
                                navigateReplace={navigateReplace}
                                processSaeNotificationNotRequired={processNotificationNotRequired}
                                processSaeReset={processReset}
                                processSaeReview={processReview}
                                reviewFormName={reduxReviewForm}
                                reviewFormHandleOnSubmitSuccess={this.handleSubmitSuccess}
                                reviewFormHandleOnSubmitFailure={this.handleSubmitFailure}
                                user={user}
                            />
                        </div>
                        <div className="mb-2 mt-4">
                            <SaeSummary
                                sae={sae}
                                user={user}
                                expandActionHistoryData={expandActionHistoryData}
                                expandMedicalHistoryData={expandTreatmentData}
                                expandInvestigativeProcedureData={expandStudyDrugData}
                                expandOtherMedicationData={expandMedicationData}
                                toggleMedicalHistoryData={toggleTreatmentData}
                                toggleInvestigativeProcedureData={toggleStudyDrugData}
                                toggleOtherMedicationData={toggleMedicationData}
                                toggleActionHistoryData={toggleActionHistoryData}
                            />
                        </div>
                    </div>
                    :
                    null

            }
        </div>
    }

    clearSae() {
        this.props.clearSae();
    }

    clearReviewFormData() {
        this.props.resetReviewForm(reduxReviewForm);
    }


    clearInstitution() {
        this.props.clearInstitution();
    }

    handleSubmitSuccess(saeReviewForm: ISaeReviewFormState, saeReviewType: FigDtos.SaeReviewType) {

        const {
            saeId,
            institutionCode,
            processReview
        } = this.props;

        let formData: FigDtos.SaeReviewFormData = {
            rejectReason: saeReviewForm.rejectReason
        };

        processReview(formData, institutionCode, saeId, saeReviewType);

    }

    handleSubmitFailure(saeReviewForm: ISaeReviewFormState) {
        Alert.danger(<NotificationAlert
            alertContent="Unable to process the SAE review. Please contact your system administrator."
            icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
        />);
    }

    showSae(): boolean {
        const {
            loadingSae,
            loadSaeSuccess,
            loadSaeFailure
        } = this.props

        if ((!loadingSae && loadSaeSuccess)) {
            return true;
        }

        return false;
    }

    createSubMenuItems(): INavItem[] {

        const {
            sae,
            institutionCode,
            institution
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = '';
        if (sae) {
            if (sae.saeInitialData.saeNumber) {
                displayIdentifier = sae.randNo + ' (SAE No: ' + sae.saeInitialData.saeNumber + ')'
            }
            else {
                displayIdentifier = sae.randNo;
            }

        }


        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/sae/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier
            }
        ]
    }

    renderNotificationNotrequiredSuccess(): any {

        const {
            saeId
        } = this.props

        return <div>Thank you, the investigator will not be notified.</div>
    }

    renderResetSuccess(): any {

        const {
            saeId
        } = this.props

        return <div>Thank you, the SAE status has been reset.</div>
    }

    renderApproveSuccess(): any {

        const {
            saeId
        } = this.props

        return <div>Thank you, the SAE has been marked as approved.</div>
    }

    renderRejectSuccess(): any {

        const {
            saeId
        } = this.props

        return <div>Thank you, the SAE has been marked as rejected.</div>
    }

}


const mapStateToProps = (state: IFigState, ownProps: SaeViewPageProps): ISaeViewPageProps => {

    //let registrationFormSummaries: FigDtos.RegistrationFormSummary[] = (state.registrationFormSummaries.data instanceof Array) ? state.registrationFormSummaries.data : undefined;

    //let expandedTree: string[] = expandedSummaryTree(registrationFormSummaries);

    //let summaryTreeState: string[] = state.patientSummaryPage.summaryTreeState ? state.patientSummaryPage.summaryTreeState : undefined;

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        saeId: ownProps.match ? ownProps.match.params.saeId : undefined,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,
        sae: !(state.saes.data instanceof Array) ? state.saes.data : undefined,
        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined,

        loadingSae: state.saes.loadState && state.saes.loadState.status == RequestState.Pending,
        loadSaeSuccess: state.saes.loadState && state.saes.loadState.status == RequestState.Success,
        loadSaeFailure: state.saes.loadState && state.saes.loadState.status == RequestState.Failure,

        processingNotificationNotRequired: state.saes.updateState && state.saes.updateState.status == RequestState.Pending && state.saes.updateState.action == "NotificationNotRequired",
        processingReset: state.saes.updateState && state.saes.updateState.status == RequestState.Pending && state.saes.updateState.action == "Reset",
        processingReviewApprove: state.saes.updateState && state.saes.updateState.status == RequestState.Pending && state.saes.updateState.action == "Review-Approve",
        processingReviewReject: state.saes.updateState && state.saes.updateState.status == RequestState.Pending && state.saes.updateState.action == "Review-Reject",

        notificationNotRequiredSuccess: state.saes.updateState && state.saes.updateState.status == RequestState.Success && state.saes.updateState.action == "NotificationNotRequired",
        notificationNotRequiredFailure: state.saes.updateState && state.saes.updateState.status == RequestState.Failure && state.saes.updateState.action == "NotificationNotRequired",

        resetSaeSuccess: state.saes.updateState && state.saes.updateState.status == RequestState.Success && state.saes.updateState.action == "Reset",
        resetSaeFailure: state.saes.updateState && state.saes.updateState.status == RequestState.Failure && state.saes.updateState.action == "Reset",

        reviewSaeApproveSuccess: state.saes.updateState && state.saes.updateState.status == RequestState.Success && state.saes.updateState.action == "Review-Approve",
        reviewSaeApproveFailure: state.saes.updateState && state.saes.updateState.status == RequestState.Failure && state.saes.updateState.action == "Review-Approve",
        reviewSaeRejectSuccess: state.saes.updateState && state.saes.updateState.status == RequestState.Success && state.saes.updateState.action == "Review-Reject",
        reviewSaeRejectFailure: state.saes.updateState && state.saes.updateState.status == RequestState.Failure && state.saes.updateState.action == "Review-Reject",
       
        //updatingPatient: state.patients.updateState && state.patients.updateState.status == RequestState.Pending,
        //updatePatientSuccess: state.patients.updateState && state.patients.updateState.status == RequestState.Success,
        //updatePatientFailure: state.patients.updateState && state.patients.updateState.status == RequestState.Failure,


        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        expandTreatmentData: state.saeViewPage.expandTreatmentData,
        expandStudyDrugData: state.saeViewPage.expandStudyDrugData,
        expandMedicationData: state.saeViewPage.expandMedicationData,
        expandActionHistoryData: state.saeViewPage.expandActionHistoryData,

        //summaryTreeState: summaryTreeState,
        //registrationFormSummaries: registrationFormSummaries,
        //storedIdentifier: state.patientSummaryPage.patientIdentifier,
        //loadingRegistrationFormSummaries: state.registrationFormSummaries.loadState && state.registrationFormSummaries.loadState.status == RequestState.Pending,
        //loadRegistrationFormSummariesSuccess: state.registrationFormSummaries.loadState && state.registrationFormSummaries.loadState.status == RequestState.Success,
        //loadRegistrationFormSummariesFailure: state.registrationFormSummaries.loadState && state.registrationFormSummaries.loadState.status == RequestState.Failure,

        //patientStatusModalOpen: state.patientSummaryPage.patientStatusModalOpen,
        //patientStatusModalData: state.patientSummaryPage.patientStatusModalData,

        user: state.user.data,
        reviewSaeRejectErrors: state.saes.updateState && state.saes.updateState.status == RequestState.Failure && state.saes.updateState.action == "Review-Reject" ? state.saes.updateState.errors : undefined

        //renderTree: state.patientSummaryPage.renderTree
    };
};

const mapDispatchToProps = (dispatch): ISaeViewPageActions => {
    return {

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        loadSae: bindActionCreators(SaeActions.LoadSaesBySaeId, dispatch),
        clearSae: bindActionCreators(SaeActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        toggleTreatmentData: bindActionCreators(SaeViewPageActions.toggleTreatmentData, dispatch),
        toggleStudyDrugData: bindActionCreators(SaeViewPageActions.toggleStudyDrugData, dispatch),
        toggleMedicationData: bindActionCreators(SaeViewPageActions.toggleMedicationData, dispatch),
        toggleActionHistoryData: bindActionCreators(SaeViewPageActions.toggleActionHistoryData, dispatch),

        clearSaeViewPage: bindActionCreators(SaeViewPageActions.clearSaeViewPage, dispatch),

        processNotificationNotRequired: bindActionCreators(SaeActions.ProcessSaeNotificationNotRequired, dispatch),
        processReset: bindActionCreators(SaeActions.ProcessSaeReset, dispatch),
        processReview: bindActionCreators(SaeActions.processSaeReview, dispatch),

        resetReviewForm: bindActionCreators(actions.reset, dispatch),
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(SaeViewPage);
