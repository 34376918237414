import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Fig.dtos';

import {
    ACTION_USER_LOAD,
    ACTION_USER_LOAD_FAILURE,
    ACTION_USER_LOAD_SUCCESS,
    ACTION_USER_CLEAR,
    UserAction
} from '../actions/user';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface IUserState {
    data: Dtos.User;
    checked: boolean;
    loadState: IRequestState;

}

const initialState: IUserState = {
    data: undefined,
    checked: false,
    loadState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IUserState, isLoading: boolean): IUserState {
    const newState: IUserState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IUserState, data: Dtos.User): IUserState {
    const newState: IUserState = update(state, {
        data: {
            $set: data
        },
        checked: {
            $set: true
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: IUserState, responseStatus: Dtos.ResponseStatus): IUserState {
    const newState: IUserState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        },
        checked: {
            $set: true
        }
    });

    return newState;
}

function clear(state: IUserState): IUserState {
    const newState: IUserState = update(initialState, {});

    return newState;
}

const UserReducer: Reducer<IUserState> = (state: IUserState = initialState, action: UserAction) => {
    switch (action.type) {
        case ACTION_USER_LOAD:
            return loading(state, true);
        case ACTION_USER_LOAD_SUCCESS:
            return loadSuccess(state, action.data);
        case ACTION_USER_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_USER_CLEAR:
            return clear(state);
    }

    return state;
}

export default UserReducer;