import { newContext } from 'immutability-helper';

export const update = newContext();

//Auto create missing objects.
update.extend('$auto', function (value, object) {
    return object ?
        update(object, value) :
        update({}, value);
});

//Auto create missing arrays.
update.extend('$autoArray', function (value, object) {
    return object ?
        update(object, value) :
        update([], value);
});