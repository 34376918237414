import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Fig.dtos';

import {
    ACTION_PATIENTS_LOAD,
    ACTION_PATIENTS_LOAD_BY_INSTITUTION,
    ACTION_PATIENTS_LOAD_BY_IDENTIFIER,
    ACTION_PATIENTS_LOAD_BY_ID,
    ACTION_PATIENTS_LOAD_BY_STATUS,
    ACTION_PATIENTS_LOAD_FAILURE,
    ACTION_PATIENTS_LOAD_SUCCESS,
    ACTION_PATIENTS_CLEAR,
    ACTION_PATIENT_SET_STATUS_BY_ID,
    ACTION_PATIENT_SET_STATUS_FAILURE,
    ACTION_PATIENT_SET_STATUS_SUCCESS,
    ACTION_PATIENTS_RESENDEMAIL_BY_ID,
    ACTION_PATIENTS_RESENDEMAIL_FAILURE,
    ACTION_PATIENTS_RESENDEMAIL_SUCCESS,
    PatientAction
} from '../actions/patient';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface IPatientState {
    data: Dtos.Patient | Dtos.Patient[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
    resendEmailState: IRequestState;
}

const initialState: IPatientState = {
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState,
    resendEmailState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IPatientState, isLoading: boolean): IPatientState {
    const newState: IPatientState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IPatientState, data: Dtos.Patient | Dtos.Patient[]): IPatientState {
    const newState: IPatientState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: IPatientState, responseStatus: Dtos.ResponseStatus): IPatientState {
    const newState: IPatientState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IPatientState, propertiesToClear?: string[]): IPatientState {

    if (!propertiesToClear) {
        const newState: IPatientState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

function settingPatientStatus(state: IPatientState, isSetting: boolean): IPatientState {
    const newState: IPatientState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function setPatientStatusSuccess(state: IPatientState, statusUpdated: boolean): IPatientState {
    const newState: IPatientState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function setPatientStatusFailure(state: IPatientState, responseStatus: Dtos.ResponseStatus): IPatientState {
    const newState: IPatientState = update(state, {
        updateState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}


function resending(state: IPatientState, isLoading: boolean): IPatientState {
    const newState: IPatientState = update(state, {
        resendEmailState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function resendingSuccess(state: IPatientState): IPatientState {
    const newState: IPatientState = update(state, {
        resendEmailState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function resendingFailure(state: IPatientState): IPatientState {
    const newState: IPatientState = update(state, {
        resendEmailState: {
            $set: {
                status: RequestState.Failure
            } as IRequestState
        }
    });

    return newState;
}


const PatientReducer: Reducer<IPatientState> = (state: IPatientState = initialState, action: PatientAction) => {
    switch (action.type) {
        case ACTION_PATIENTS_LOAD:
        case ACTION_PATIENTS_LOAD_BY_INSTITUTION:
        case ACTION_PATIENTS_LOAD_BY_IDENTIFIER:
        case ACTION_PATIENTS_LOAD_BY_ID:
        case ACTION_PATIENTS_LOAD_BY_STATUS:
            return loading(state, true);
        case ACTION_PATIENTS_LOAD_SUCCESS:
            return loadSuccess(state, action.data);
        case ACTION_PATIENTS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_PATIENTS_CLEAR:
            return clear(state, action.propertiesToClear);
        case ACTION_PATIENT_SET_STATUS_BY_ID:
            return settingPatientStatus(state, true);
        case ACTION_PATIENT_SET_STATUS_SUCCESS:
            return setPatientStatusSuccess(state, action.statusUpdated);
        case ACTION_PATIENT_SET_STATUS_FAILURE:
            return setPatientStatusFailure(state, action.responseStatus);
        case ACTION_PATIENTS_RESENDEMAIL_BY_ID:
            return resending(state, true);
        case ACTION_PATIENTS_RESENDEMAIL_SUCCESS:
            return resendingSuccess(state);
        case ACTION_PATIENT_SET_STATUS_FAILURE:
            return resendingFailure(state);
    }

    return state;
}

export default PatientReducer;