import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export interface IPatientStatusModalFormState {
    reason: string;
}

export const initialPatientStatusModalFormState: IPatientStatusModalFormState = {
    reason: null
}

export interface IPatientStatusModalReduxFormState {
    reason: IFieldState<string>;
}