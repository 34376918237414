import { client } from "../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../dtos/Fig.dtos";

export const registrationFormSummaryApi = {

    getRegistrationformSummariesByPatientIdentifier: (identifier: string): Observable<Dtos.GetRegistrationFormSummariesByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetRegistrationFormSummariesByPatientIdentifier = new Dtos.GetRegistrationFormSummariesByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetRegistrationFormSummariesByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }
}