import * as Dtos from "../../dtos/Fig.dtos";

export const
    LoadClinicalAssessmentForms: IClinicalAssessmentFormLoadActionCreator = () => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_LOAD
        }
    },
    LoadClinicalAssessmentFormById: IClinicalAssessmentFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_ID,
            id
        }
    },
    LoadClinicalAssessmentFormByPatientId: IClinicalAssessmentFormLoadByPatientIdActionCreator = (patientId) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_ID,
            patientId
        }
    },
    LoadClinicalAssessmentFormByPatientIdentifier: IClinicalAssessmentFormLoadByPatientIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: IClinicalAssessmentFormLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus

        }
    },
    LoadFailure: IClinicalAssessmentFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveClinicalAssessmentForm: IClinicalAssessmentFormSaveActionCreator = (formData, institutionCode, patientIdentifier) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_SAVE,
            formData,
            institutionCode,
            patientIdentifier
        }
    },

    SaveSuccess: IClinicalAssessmentFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },

    SaveFailure: IClinicalAssessmentFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateClinicalAssessmentForm: IClinicalAssessmentFormCreateActionCreator = () => {

        return {
            type: ACTION_CLINICALASSESSMENTFORMS_CREATE
        }
    },

    CreateSuccess: IClinicalAssessmentFormCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IClinicalAssessmentFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IClinicalAssessmentFormClearActionCreator = () => {
        return {
            type: ACTION_CLINICALASSESSMENTFORMS_CLEAR
        }
    };


export const
    ACTION_CLINICALASSESSMENTFORMS_LOAD = "ACTION_CLINICALASSESSMENTFORMS_LOAD",
    ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_ID = "ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_ID",
    ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_ID = "ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_ID",
    ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_CLINICALASSESSMENTFORMS_LOAD_SUCCESS = "ACTION_CLINICALASSESSMENTFORMS_LOAD_SUCCESS",
    ACTION_CLINICALASSESSMENTFORMS_LOAD_FAILURE = "ACTION_CLINICALASSESSMENTFORMS_LOAD_FAILURE",

    ACTION_CLINICALASSESSMENTFORMS_SAVE = "ACTION_CLINICALASSESSMENTFORMS_SAVE",
    ACTION_CLINICALASSESSMENTFORMS_SAVE_SUCCESS = "ACTION_CLINICALASSESSMENTFORMS_SAVE_SUCCESS",
    ACTION_CLINICALASSESSMENTFORMS_SAVE_FAILURE = "ACTION_CLINICALASSESSMENTFORMS_SAVE_FAILURE",

    ACTION_CLINICALASSESSMENTFORMS_CREATE = "ACTION_CLINICALASSESSMENTFORMS_CREATE",
    ACTION_CLINICALASSESSMENTFORMS_CREATE_SUCCESS = "ACTION_CLINICALASSESSMENTFORMS_CREATE_SUCCESS",
    ACTION_CLINICALASSESSMENTFORMS_CREATE_FAILURE = "ACTION_CLINICALASSESSMENTFORMS_CREATE_FAILURE",

    ACTION_CLINICALASSESSMENTFORMS_CLEAR = "ACTION_CLINICALASSESSMENTFORMS_CLEAR";

export interface IClinicalAssessmentFormLoadAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_LOAD";
}

export interface IClinicalAssessmentFormLoadActionCreator {
    (): IClinicalAssessmentFormLoadAction;
}

export interface IClinicalAssessmentFormLoadByIdAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_ID";
    id: number;
}

export interface IClinicalAssessmentFormLoadByIdActionCreator {
    (id: number): IClinicalAssessmentFormLoadByIdAction;
}

export interface IClinicalAssessmentFormLoadByPatientIdAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_ID";
    patientId: number;
}

export interface IClinicalAssessmentFormLoadByPatientIdActionCreator {
    (patientId: number): IClinicalAssessmentFormLoadByPatientIdAction;
}

export interface IClinicalAssessmentFormLoadByPatientIdentifierAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface IClinicalAssessmentFormLoadByPatientIdentifierActionCreator {
    (identifier: string): IClinicalAssessmentFormLoadByPatientIdentifierAction;
}


export interface IClinicalAssessmentFormLoadSuccessAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_LOAD_SUCCESS";
    formData: Dtos.ClinicalAssessmentForm | Dtos.ClinicalAssessmentForm[];
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;

}

export interface IClinicalAssessmentFormLoadSuccessActionCreator {
    (
        formData: Dtos.ClinicalAssessmentForm | Dtos.ClinicalAssessmentForm[],
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IClinicalAssessmentFormLoadSuccessAction;
}

export interface IClinicalAssessmentFormLoadFailureAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IClinicalAssessmentFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IClinicalAssessmentFormLoadFailureAction;
}

export interface IClinicalAssessmentFormSaveAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_SAVE";
    formData: Dtos.ClinicalAssessmentForm;
    institutionCode: string;
    patientIdentifier: string;

}

export interface IClinicalAssessmentFormSaveActionCreator {
    (
        formData: Dtos.ClinicalAssessmentForm,
        institutionCode: string,
        patientIdentifier: string
    ): IClinicalAssessmentFormSaveAction;
}

export interface IClinicalAssessmentFormSaveSuccessAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_SAVE_SUCCESS";
    formData: Dtos.ClinicalAssessmentForm;
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IClinicalAssessmentFormSaveSuccessActionCreator {
    (
        formData: Dtos.ClinicalAssessmentForm,
        formState: Dtos.RegistrationFormState,
        responseStatus: Dtos.ResponseStatus
    ): IClinicalAssessmentFormSaveSuccessAction;
}

export interface IClinicalAssessmentFormSaveFailureAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IClinicalAssessmentFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IClinicalAssessmentFormSaveFailureAction;
}

export interface IClinicalAssessmentFormCreateAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_CREATE"
}

export interface IClinicalAssessmentFormCreateActionCreator {
    (): IClinicalAssessmentFormCreateAction
}

export interface IClinicalAssessmentFormCreateSuccessAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_CREATE_SUCCESS";
    formData: Dtos.ClinicalAssessmentForm;
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IClinicalAssessmentFormCreateSuccessActionCreator {
    (
        formData: Dtos.ClinicalAssessmentForm,
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[]
    ): IClinicalAssessmentFormCreateSuccessAction;
}

export interface IClinicalAssessmentFormCreateFailureAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IClinicalAssessmentFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IClinicalAssessmentFormCreateFailureAction;
}

export interface IClinicalAssessmentFormClearAction {
    type: "ACTION_CLINICALASSESSMENTFORMS_CLEAR";
}

export interface IClinicalAssessmentFormClearActionCreator {
    (): IClinicalAssessmentFormClearAction;
}



export type ClinicalAssessmentFormAction = IClinicalAssessmentFormLoadAction |
    IClinicalAssessmentFormLoadByIdAction |
    IClinicalAssessmentFormLoadByPatientIdAction |
    IClinicalAssessmentFormLoadByPatientIdentifierAction |
    IClinicalAssessmentFormLoadFailureAction |
    IClinicalAssessmentFormLoadSuccessAction |
    IClinicalAssessmentFormSaveAction |
    IClinicalAssessmentFormSaveSuccessAction |
    IClinicalAssessmentFormSaveFailureAction |
    IClinicalAssessmentFormCreateAction |
    IClinicalAssessmentFormCreateSuccessAction |
    IClinicalAssessmentFormCreateFailureAction |
    IClinicalAssessmentFormClearAction;
