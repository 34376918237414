import { client } from "../../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../../dtos/Fig.dtos";

export const demographicDetailFormApi = {
    getDemographicDetailForms: (): Observable<Dtos.GetDemographicDetailFormsResponse> => {
        //Create the request.
        let request: Dtos.GetDemographicDetailForms = new Dtos.GetDemographicDetailForms();

        //Send request.
        const response: Promise<Dtos.GetDemographicDetailFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getDemographicDetailFormById: (id: number): Observable<Dtos.GetDemographicDetailFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetDemographicDetailFormById = new Dtos.GetDemographicDetailFormById();

        request.id = id;
        //Send request.
        const response: Promise<Dtos.GetDemographicDetailFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getDemographicDetailFormByPatientId: (patientId: number): Observable<Dtos.GetDemographicDetailFormByPatientIdResponse> => {
        //Create the request.
        let request: Dtos.GetDemographicDetailFormByPatientId = new Dtos.GetDemographicDetailFormByPatientId();

        request.patientId = patientId;

        //Send request.
        const response: Promise<Dtos.GetDemographicDetailFormByPatientIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getDemographicDetailFormByPatientIdentifier: (identifier: string): Observable<Dtos.GetDemographicDetailFormByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetDemographicDetailFormByPatientIdentifier = new Dtos.GetDemographicDetailFormByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetDemographicDetailFormByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setDemographicDetailForm: (DemographicDetailForm: Dtos.DemographicDetailForm,
        institutionCode: string,
        patientIdentifier: string): Observable<Dtos.SetDemographicDetailFormResponse> => {

        let request: Dtos.SetDemographicDetailForm = new Dtos.SetDemographicDetailForm();

        request.demographicDetailForm = DemographicDetailForm;
        request.institutionCode = institutionCode;
        request.patientIdentifier = patientIdentifier;

        // send request
        const response: Promise<Dtos.SetDemographicDetailFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createDemographicDetailForm: (): Observable<Dtos.CreateDemographicDetailFormResponse> => {
        //Create the request.
        let request: Dtos.CreateDemographicDetailForm = new Dtos.CreateDemographicDetailForm();

        //Send request.
        const response: Promise<Dtos.CreateDemographicDetailFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}