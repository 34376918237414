import { combineEpics } from "redux-observable";

import * as InstitutionEpics from './institution';
import * as PatientEpics from './patients';
import * as TrialUserEpics from './trialUser';
import * as UserEpics from './users';
import * as TimepointEpics from './timepoint'
import * as PreRegistrationFormEpics from './forms/preRegistrationForm';
import * as RegistrationFormSummaryEpics from './registrationFormSummary';
import * as GlioblastomaDetailFormEpics from './forms/glioblastomaDetailForm';
import * as DemographicDetailFormEpics from './forms/demographicDetailForm';
import * as ClinicalAssessmentFormEpics from './forms/clinicalAssessmentForm';
import * as PatientReportedForm from './forms/patientReportedForm';
import * as EligibilityCriteriaForm from './forms/eligibilityCriteriaForm';
import * as SourceDocumentsFormEpics from './forms/sourceDocumentsForm';
import * as WebsiteSettingsEpics from './websiteSettings';
import * as SaeEpics from './sae';

export const rootEpic = combineEpics<any, any, any>(
    UserEpics.getUserEpic,
    PatientEpics.getPatientsEpic,
    PatientEpics.getPatientsByStatusEpic,
    PatientEpics.getPatientsByIdentifierEpic,
    PatientEpics.getPatientsByIdEpic,
    PatientEpics.getPatientsByInstitutionEpic,
    PatientEpics.setPatientStatusByIdEpic,
    PatientEpics.resendEmailForPatientByIdEpic,
    WebsiteSettingsEpics.getWebsiteSettingsEpic,
    WebsiteSettingsEpics.setWebsiteSettingsEpic,
    TrialUserEpics.getTrialUsersEpic,
    TrialUserEpics.getTrialUsersByInstitutionEpic,
    TrialUserEpics.getTrialUsersByUsername,
    TrialUserEpics.getTrialUsersByRoleIds,
    TrialUserEpics.getTrialUsersByInstitutionRoleIds,
    InstitutionEpics.getInstitutionByInstitutionCodeEpic,
    InstitutionEpics.getInstitutionsByCountryEpic,
    InstitutionEpics.getInstitutionsByStateEpic,
    InstitutionEpics.getInstitutonsEpic,
    TimepointEpics.getTimepointsEpic,
    TimepointEpics.getTimepointsWithDrugsEpic, 
    RegistrationFormSummaryEpics.getRegistrationFormSummariesByPatientIdentifierEpic,
    PreRegistrationFormEpics.getPreRegistrationFormsEpic,
    PreRegistrationFormEpics.getPreRegistrationFormsByIdEpic,
    PreRegistrationFormEpics.getPreRegistrationFormsByPatientIdEpic,
    PreRegistrationFormEpics.getPreRegistrationFormsByPatientIdentifierEpic,
    PreRegistrationFormEpics.setPreRegistrationFormsEpic,
    PreRegistrationFormEpics.createPreRegistrationFormsEpic,
    GlioblastomaDetailFormEpics.getGlioblastomaDetailFormsEpic,
    GlioblastomaDetailFormEpics.getGlioblastomaDetailFormsByIdEpic,
    GlioblastomaDetailFormEpics.getGlioblastomaDetailFormsByPatientIdEpic,
    GlioblastomaDetailFormEpics.getGlioblastomaDetailFormsByPatientIdentifierEpic,
    GlioblastomaDetailFormEpics.setGlioblastomaDetailFormsEpic,
    GlioblastomaDetailFormEpics.createGlioblastomaDetailFormsEpic,
    DemographicDetailFormEpics.getDemographicDetailFormsEpic,
    DemographicDetailFormEpics.getDemographicDetailFormsByIdEpic,
    DemographicDetailFormEpics.getDemographicDetailFormsByPatientIdEpic,
    DemographicDetailFormEpics.getDemographicDetailFormsByPatientIdentifierEpic,
    DemographicDetailFormEpics.setDemographicDetailFormsEpic,
    DemographicDetailFormEpics.createDemographicDetailFormsEpic,
    ClinicalAssessmentFormEpics.getClinicalAssessmentFormsEpic,
    ClinicalAssessmentFormEpics.getClinicalAssessmentFormsByIdEpic,
    ClinicalAssessmentFormEpics.getClinicalAssessmentFormsByPatientIdEpic,
    ClinicalAssessmentFormEpics.getClinicalAssessmentFormsByPatientIdentifierEpic,
    ClinicalAssessmentFormEpics.setClinicalAssessmentFormsEpic,
    ClinicalAssessmentFormEpics.createClinicalAssessmentFormsEpic,
    PatientReportedForm.getPatientReportedFormsEpic,
    PatientReportedForm.getPatientReportedFormsByIdEpic,
    PatientReportedForm.getPatientReportedFormsByPatientIdEpic,
    PatientReportedForm.getPatientReportedFormsByPatientIdentifierEpic,
    PatientReportedForm.setPatientReportedFormsEpic,
    PatientReportedForm.createPatientReportedFormsEpic,
    EligibilityCriteriaForm.getEligibilityCriteriaFormsEpic,
    EligibilityCriteriaForm.getEligibilityCriteriaFormsByIdEpic,
    EligibilityCriteriaForm.getEligibilityCriteriaFormsByPatientIdEpic,
    EligibilityCriteriaForm.getEligibilityCriteriaFormsByPatientIdentifierEpic,
    EligibilityCriteriaForm.setEligibilityCriteriaFormsEpic,
    EligibilityCriteriaForm.createEligibilityCriteriaFormsEpic,
    SourceDocumentsFormEpics.getSourceDocumentsFormsEpic,
    SourceDocumentsFormEpics.getSourceDocumentsFormsByIdEpic,
    SourceDocumentsFormEpics.getSourceDocumentsFormsByPatientIdEpic,
    SourceDocumentsFormEpics.getSourceDocumentsFormsByPatientIdentifierEpic,
    SourceDocumentsFormEpics.setSourceDocumentsFormsEpic,
    SourceDocumentsFormEpics.createSourceDocumentsFormsEpic,
    SaeEpics.getSaesEpic,
    SaeEpics.getSaesByInstitutionEpic,
    SaeEpics.getSaesBySaeIdEpic,
    SaeEpics.getSaesSummariesEpic,
    SaeEpics.ProcessSaeNotificationNotRequiredEpic,
    SaeEpics.ProcessSaeResetEpic,
    SaeEpics.ProcessSaeNotifyEpic,
    SaeEpics.ProcessSaeReviewEpic
);