import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Fig.dtos';

import {
    ACTION_TIMEPOINTS_LOAD_ALL,
    ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS,
    ACTION_TIMEPOINTS_CLEAR,
    ACTION_TIMEPOINTS_LOAD_SUCCESS,
    ACTION_TIMEPOINTS_LOAD_FAILURE,
    TimepointAction
} from '../actions/timepoint';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface ITimepointState {
    data: Dtos.Timepoint[] | Dtos.TimepointWithDrugsList[],
    loadState: IRequestState;
}

const initialState: ITimepointState = {
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: ITimepointState, isLoading: boolean): ITimepointState {
    const newState: ITimepointState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: ITimepointState, data: Dtos.Timepoint | Dtos.Timepoint[]): ITimepointState {
    const newState: ITimepointState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: ITimepointState, responseStatus: Dtos.ResponseStatus): ITimepointState {
    const newState: ITimepointState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ITimepointState): ITimepointState {
    const newState: ITimepointState = update(initialState, {});

    return newState;
}

const TimepointReducer: Reducer<ITimepointState> = (state: ITimepointState = initialState, action: TimepointAction) => {
    switch (action.type) {
        case ACTION_TIMEPOINTS_LOAD_ALL:
        case ACTION_TIMEPOINTS_LOAD_ALL_WITH_DRUGS:
            return loading(state, true);
        case ACTION_TIMEPOINTS_LOAD_SUCCESS:
            return loadSuccess(state, action.data);
        case ACTION_TIMEPOINTS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TIMEPOINTS_CLEAR:
            return clear(state);
    }

    return state;
}

export default TimepointReducer;