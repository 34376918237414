import * as Dtos from "../../../dtos/Fig.dtos";

export const
    toggleMessageModal: IGlioblastomaDetailFormPageToggleMessageModalActionCreator = () => {
        return {
            type: ACTION_GLIOBLASTOMADETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL
        }
    }

export const
    ACTION_GLIOBLASTOMADETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL = "ACTION_GLIOBLASTOMADETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL"

/* Actions */

export interface IGlioblastomaDetailFormPageToggleMessageModalAction {
    type: "ACTION_GLIOBLASTOMADETAILFORM_PAGE_TOGGLE_MESSAGE_MODAL"
}

/* Action Creators */
export interface IGlioblastomaDetailFormPageToggleMessageModalActionCreator {
    (): IGlioblastomaDetailFormPageToggleMessageModalAction;
}


/* Final Export of actions */
export type GlioblastomaDetailFormPageAction = IGlioblastomaDetailFormPageToggleMessageModalAction;
