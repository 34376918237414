import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_CLINICALASSESSMENTFORM_PAGE_TOGGLE_MESSAGE_MODAL,
    ClinicalAssessmentFormPageAction
} from '../../../actions/pages/registration/clinicalAssessmentFormPage';


export interface IClinicalAssessmentFormPageState {
    messageModalOpen: boolean;
}

const initialState: IClinicalAssessmentFormPageState = {
    messageModalOpen: false
};


function toggleMessageModal(state: IClinicalAssessmentFormPageState): IClinicalAssessmentFormPageState {
    const newState: IClinicalAssessmentFormPageState = update(state, {
        messageModalOpen: {
            $set: !state.messageModalOpen
        }
    });

    return newState;
}

const ClinicalAssessmentFormPageReducer: Reducer<IClinicalAssessmentFormPageState> = (state: IClinicalAssessmentFormPageState = initialState, action: ClinicalAssessmentFormPageAction) => {
    switch (action.type) {
        case ACTION_CLINICALASSESSMENTFORM_PAGE_TOGGLE_MESSAGE_MODAL:
            return toggleMessageModal(state);
    }

    return state;
}

export default ClinicalAssessmentFormPageReducer;