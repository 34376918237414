import * as Dtos from "../../dtos/Fig.dtos";

export const
    LoadEligibilityCriteriaForms: IEligibilityCriteriaFormLoadActionCreator = () => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_LOAD
        }
    },
    LoadEligibilityCriteriaFormById: IEligibilityCriteriaFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID,
            id
        }
    },
    LoadEligibilityCriteriaFormByPatientId: IEligibilityCriteriaFormLoadByPatientIdActionCreator = (patientId) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID,
            patientId
        }
    },
    LoadEligibilityCriteriaFormByPatientIdentifier: IEligibilityCriteriaFormLoadByPatientIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: IEligibilityCriteriaFormLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus

        }
    },
    LoadFailure: IEligibilityCriteriaFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SaveEligibilityCriteriaForm: IEligibilityCriteriaFormSaveActionCreator = (formData, institutionCode, patientIdentifier) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_SAVE,
            formData,
            institutionCode,
            patientIdentifier
        }
    },

    SaveSuccess: IEligibilityCriteriaFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },

    SaveFailure: IEligibilityCriteriaFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreateEligibilityCriteriaForm: IEligibilityCriteriaFormCreateActionCreator = () => {

        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_CREATE
        }
    },

    CreateSuccess: IEligibilityCriteriaFormCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IEligibilityCriteriaFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IEligibilityCriteriaFormClearActionCreator = () => {
        return {
            type: ACTION_ELIGIBILITYCRITERIAFORMS_CLEAR
        }
    };


export const
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD = "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD",
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID = "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID",
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID = "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID",
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_SUCCESS = "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_SUCCESS",
    ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_FAILURE = "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_FAILURE",

    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE = "ACTION_ELIGIBILITYCRITERIAFORMS_SAVE",
    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_SUCCESS = "ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_SUCCESS",
    ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_FAILURE = "ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_FAILURE",

    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE = "ACTION_ELIGIBILITYCRITERIAFORMS_CREATE",
    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_SUCCESS = "ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_SUCCESS",
    ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_FAILURE = "ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_FAILURE",

    ACTION_ELIGIBILITYCRITERIAFORMS_CLEAR = "ACTION_ELIGIBILITYCRITERIAFORMS_CLEAR";

export interface IEligibilityCriteriaFormLoadAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD";
}

export interface IEligibilityCriteriaFormLoadActionCreator {
    (): IEligibilityCriteriaFormLoadAction;
}

export interface IEligibilityCriteriaFormLoadByIdAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_ID";
    id: number;
}

export interface IEligibilityCriteriaFormLoadByIdActionCreator {
    (id: number): IEligibilityCriteriaFormLoadByIdAction;
}

export interface IEligibilityCriteriaFormLoadByPatientIdAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_ID";
    patientId: number;
}

export interface IEligibilityCriteriaFormLoadByPatientIdActionCreator {
    (patientId: number): IEligibilityCriteriaFormLoadByPatientIdAction;
}

export interface IEligibilityCriteriaFormLoadByPatientIdentifierAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface IEligibilityCriteriaFormLoadByPatientIdentifierActionCreator {
    (identifier: string): IEligibilityCriteriaFormLoadByPatientIdentifierAction;
}


export interface IEligibilityCriteriaFormLoadSuccessAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_SUCCESS";
    formData: Dtos.EligibilityCriteriaForm | Dtos.EligibilityCriteriaForm[];
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;

}

export interface IEligibilityCriteriaFormLoadSuccessActionCreator {
    (
        formData: Dtos.EligibilityCriteriaForm | Dtos.EligibilityCriteriaForm[],
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IEligibilityCriteriaFormLoadSuccessAction;
}

export interface IEligibilityCriteriaFormLoadFailureAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IEligibilityCriteriaFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IEligibilityCriteriaFormLoadFailureAction;
}

export interface IEligibilityCriteriaFormSaveAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_SAVE";
    formData: Dtos.EligibilityCriteriaForm;
    institutionCode: string;
    patientIdentifier: string;

}

export interface IEligibilityCriteriaFormSaveActionCreator {
    (
        formData: Dtos.EligibilityCriteriaForm,
        institutionCode: string,
        patientIdentifier: string
    ): IEligibilityCriteriaFormSaveAction;
}

export interface IEligibilityCriteriaFormSaveSuccessAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_SUCCESS";
    formData: Dtos.EligibilityCriteriaForm;
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IEligibilityCriteriaFormSaveSuccessActionCreator {
    (
        formData: Dtos.EligibilityCriteriaForm,
        formState: Dtos.RegistrationFormState,
        responseStatus: Dtos.ResponseStatus
    ): IEligibilityCriteriaFormSaveSuccessAction;
}

export interface IEligibilityCriteriaFormSaveFailureAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IEligibilityCriteriaFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IEligibilityCriteriaFormSaveFailureAction;
}

export interface IEligibilityCriteriaFormCreateAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_CREATE"
}

export interface IEligibilityCriteriaFormCreateActionCreator {
    (): IEligibilityCriteriaFormCreateAction
}

export interface IEligibilityCriteriaFormCreateSuccessAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_SUCCESS";
    formData: Dtos.EligibilityCriteriaForm;
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IEligibilityCriteriaFormCreateSuccessActionCreator {
    (
        formData: Dtos.EligibilityCriteriaForm,
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[]
    ): IEligibilityCriteriaFormCreateSuccessAction;
}

export interface IEligibilityCriteriaFormCreateFailureAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IEligibilityCriteriaFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IEligibilityCriteriaFormCreateFailureAction;
}

export interface IEligibilityCriteriaFormClearAction {
    type: "ACTION_ELIGIBILITYCRITERIAFORMS_CLEAR";
}

export interface IEligibilityCriteriaFormClearActionCreator {
    (): IEligibilityCriteriaFormClearAction;
}



export type EligibilityCriteriaFormAction = IEligibilityCriteriaFormLoadAction |
    IEligibilityCriteriaFormLoadByIdAction |
    IEligibilityCriteriaFormLoadByPatientIdAction |
    IEligibilityCriteriaFormLoadByPatientIdentifierAction |
    IEligibilityCriteriaFormLoadFailureAction |
    IEligibilityCriteriaFormLoadSuccessAction |
    IEligibilityCriteriaFormSaveAction |
    IEligibilityCriteriaFormSaveSuccessAction |
    IEligibilityCriteriaFormSaveFailureAction |
    IEligibilityCriteriaFormCreateAction |
    IEligibilityCriteriaFormCreateSuccessAction |
    IEligibilityCriteriaFormCreateFailureAction |
    IEligibilityCriteriaFormClearAction;
