import * as Dtos from "../../dtos/Fig.dtos";

export const
    LoadPreRegistrationForms: IPreRegistrationFormLoadActionCreator = () => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_LOAD
        }
    },
    LoadPreRegistrationFormById: IPreRegistrationFormLoadByIdActionCreator = (id) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_LOAD_BY_ID,
            id
        }
    },
    LoadPreRegistrationFormByPatientId: IPreRegistrationFormLoadByPatientIdActionCreator = (patientId) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_ID,
            patientId
        }
    },
    LoadPreRegistrationFormByPatientIdentifier: IPreRegistrationFormLoadByPatientIdentifierActionCreator = (identifier) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_IDENTIFIER,
            identifier
        }
    },
    LoadSuccess: IPreRegistrationFormLoadSuccessActionCreator = (formData, formState, formProperties, lookups, responseStatus) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_LOAD_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups,
            responseStatus

        }
    },
    LoadFailure: IPreRegistrationFormLoadFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_LOAD_FAILURE,
            responseStatus
        }
    },

    SavePreRegistrationForm: IPreRegistrationFormSaveActionCreator = (formData, institutionCode, patientIdentifier) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_SAVE,
            formData,
            institutionCode,
            patientIdentifier
        }
    },

    SaveSuccess: IPreRegistrationFormSaveSuccessActionCreator = (formData, formState, responseStatus) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_SAVE_SUCCESS,
            formData,
            formState,
            responseStatus

        }
    },

    SaveFailure: IPreRegistrationFormSaveFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_SAVE_FAILURE,
            responseStatus
        }
    },

    CreatePreRegistrationForm: IPreRegistrationFormCreateActionCreator = () => {

        return {
            type: ACTION_PREREGISTRATIONFORMS_CREATE
        }
    },

    CreateSuccess: IPreRegistrationFormCreateSuccessActionCreator = (formData, formState, formProperties, lookups) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_CREATE_SUCCESS,
            formData,
            formState,
            formProperties,
            lookups
        }
    },
    CreateFailure: IPreRegistrationFormCreateFailureActionCreator = (responseStatus) => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_CREATE_FAILURE,
            responseStatus
        }
    },
    Clear: IPreRegistrationFormClearActionCreator = () => {
        return {
            type: ACTION_PREREGISTRATIONFORMS_CLEAR
        }
    };


export const
    ACTION_PREREGISTRATIONFORMS_LOAD = "ACTION_PREREGISTRATIONFORMS_LOAD",
    ACTION_PREREGISTRATIONFORMS_LOAD_BY_ID = "ACTION_PREREGISTRATIONFORMS_LOAD_BY_ID",
    ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_ID = "ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_ID",
    ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_IDENTIFIER = "ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_IDENTIFIER",
    ACTION_PREREGISTRATIONFORMS_LOAD_SUCCESS = "ACTION_PREREGISTRATIONFORMS_LOAD_SUCCESS",
    ACTION_PREREGISTRATIONFORMS_LOAD_FAILURE = "ACTION_PREREGISTRATIONFORMS_LOAD_FAILURE",

    ACTION_PREREGISTRATIONFORMS_SAVE = "ACTION_PREREGISTRATIONFORMS_SAVE",
    ACTION_PREREGISTRATIONFORMS_SAVE_SUCCESS = "ACTION_PREREGISTRATIONFORMS_SAVE_SUCCESS",
    ACTION_PREREGISTRATIONFORMS_SAVE_FAILURE = "ACTION_PREREGISTRATIONFORMS_SAVE_FAILURE",

    ACTION_PREREGISTRATIONFORMS_CREATE = "ACTION_PREREGISTRATIONFORMS_CREATE",
    ACTION_PREREGISTRATIONFORMS_CREATE_SUCCESS = "ACTION_PREREGISTRATIONFORMS_CREATE_SUCCESS",
    ACTION_PREREGISTRATIONFORMS_CREATE_FAILURE = "ACTION_PREREGISTRATIONFORMS_CREATE_FAILURE",

    ACTION_PREREGISTRATIONFORMS_CLEAR = "ACTION_PREREGISTRATIONFORMS_CLEAR";

export interface IPreRegistrationFormLoadAction {
    type: "ACTION_PREREGISTRATIONFORMS_LOAD";
}

export interface IPreRegistrationFormLoadActionCreator {
    (): IPreRegistrationFormLoadAction;
}

export interface IPreRegistrationFormLoadByIdAction {
    type: "ACTION_PREREGISTRATIONFORMS_LOAD_BY_ID";
    id: number;
}

export interface IPreRegistrationFormLoadByIdActionCreator {
    (id: number): IPreRegistrationFormLoadByIdAction;
}

export interface IPreRegistrationFormLoadByPatientIdAction {
    type: "ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_ID";
    patientId: number;
}

export interface IPreRegistrationFormLoadByPatientIdActionCreator {
    (patientId: number): IPreRegistrationFormLoadByPatientIdAction;
}

export interface IPreRegistrationFormLoadByPatientIdentifierAction {
    type: "ACTION_PREREGISTRATIONFORMS_LOAD_BY_PATIENT_IDENTIFIER";
    identifier: string;
}

export interface IPreRegistrationFormLoadByPatientIdentifierActionCreator {
    (identifier: string): IPreRegistrationFormLoadByPatientIdentifierAction;
}


export interface IPreRegistrationFormLoadSuccessAction {
    type: "ACTION_PREREGISTRATIONFORMS_LOAD_SUCCESS";
    formData: Dtos.PreRegistrationForm | Dtos.PreRegistrationForm[];
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[];
    responseStatus: Dtos.ResponseStatus;
    
}

export interface IPreRegistrationFormLoadSuccessActionCreator {
    (
        formData: Dtos.PreRegistrationForm | Dtos.PreRegistrationForm[],
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[],
        responseStatus: Dtos.ResponseStatus
    ): IPreRegistrationFormLoadSuccessAction;
}

export interface IPreRegistrationFormLoadFailureAction {
    type: "ACTION_PREREGISTRATIONFORMS_LOAD_FAILURE";
    responseStatus: Dtos.ResponseStatus;
}

export interface IPreRegistrationFormLoadFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPreRegistrationFormLoadFailureAction;
}

export interface IPreRegistrationFormSaveAction {
    type: "ACTION_PREREGISTRATIONFORMS_SAVE";
    formData: Dtos.PreRegistrationForm;
    institutionCode: string;
    patientIdentifier: string;

}

export interface IPreRegistrationFormSaveActionCreator {
    (
        formData: Dtos.PreRegistrationForm,
        institutionCode: string,
        patientIdentifier: string
    ): IPreRegistrationFormSaveAction;
}

export interface IPreRegistrationFormSaveSuccessAction {
    type: "ACTION_PREREGISTRATIONFORMS_SAVE_SUCCESS";
    formData: Dtos.PreRegistrationForm;
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus;
}

export interface IPreRegistrationFormSaveSuccessActionCreator {
    (
        formData: Dtos.PreRegistrationForm,
        formState: Dtos.RegistrationFormState,
        responseStatus: Dtos.ResponseStatus
    ): IPreRegistrationFormSaveSuccessAction;
}

export interface IPreRegistrationFormSaveFailureAction {
    type: "ACTION_PREREGISTRATIONFORMS_SAVE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPreRegistrationFormSaveFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPreRegistrationFormSaveFailureAction;
}

export interface IPreRegistrationFormCreateAction {
    type: "ACTION_PREREGISTRATIONFORMS_CREATE"
}

export interface IPreRegistrationFormCreateActionCreator {
    (): IPreRegistrationFormCreateAction
}

export interface IPreRegistrationFormCreateSuccessAction {
    type: "ACTION_PREREGISTRATIONFORMS_CREATE_SUCCESS";
    formData: Dtos.PreRegistrationForm;
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
}

export interface IPreRegistrationFormCreateSuccessActionCreator {
    (
        formData: Dtos.PreRegistrationForm,
        formState: Dtos.RegistrationFormState,
        formProperties: Dtos.RegistrationFormProperty[],
        lookups: Dtos.Lookup[]
    ): IPreRegistrationFormCreateSuccessAction;
}

export interface IPreRegistrationFormCreateFailureAction {
    type: "ACTION_PREREGISTRATIONFORMS_CREATE_FAILURE";
    responseStatus: Dtos.ResponseStatus
}

export interface IPreRegistrationFormCreateFailureActionCreator {
    (responseStatus: Dtos.ResponseStatus): IPreRegistrationFormCreateFailureAction;
}

export interface IPreRegistrationFormClearAction {
    type: "ACTION_PREREGISTRATIONFORMS_CLEAR";
}

export interface IPreRegistrationFormClearActionCreator {
    (): IPreRegistrationFormClearAction;
}

export type PreRegistrationFormAction = IPreRegistrationFormLoadAction |
    IPreRegistrationFormLoadByIdAction |
    IPreRegistrationFormLoadByPatientIdAction |
    IPreRegistrationFormLoadByPatientIdentifierAction | 
    IPreRegistrationFormLoadFailureAction |
    IPreRegistrationFormLoadSuccessAction |
    IPreRegistrationFormSaveAction | 
    IPreRegistrationFormSaveSuccessAction | 
    IPreRegistrationFormSaveFailureAction |
    IPreRegistrationFormCreateAction | 
    IPreRegistrationFormCreateSuccessAction | 
    IPreRegistrationFormCreateFailureAction |
    IPreRegistrationFormClearAction;
