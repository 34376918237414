import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";

export const initialClinicalAssessmentFormConcomitantState: Dtos.ClinicalAssessmentFormConcomitant = {
    id: null,
    clinicalAssessmentFormId: null,
    medicationName: null,
    medicationType: null,
    medicationIndication: null,
    medicationIndicationOther: null,
    medicationStartDate: null,
    medicationStopDate: null,
    medicationOngoing: null,
    medicationDose: null,
    medicationUnit: null,
    medicationUnitOther: null,
    medicationRoute:null,
    medicationFrequency: null,
    medicationFrequencyOther: null,
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
}


    
export const initialClinicalAssessmentFormState: Dtos.ClinicalAssessmentForm & IRegistrationFormState = {
    id: null,
    patientId: null,
    assessmentDate : null,
    ecog: null,
    otherMalignancies: null,
    otherMalignanciesTypeCarcinomaInSitu: null,
    otherMalignanciesTypeBasalCellCarcinoma: null,
    otherMalignanciesTypeSquamousCellCarcinoma: null,
    otherMalignanciesTypeSuperficialTransitionalCellCarcinoma: null,
    otherMalignanciesTypeOther: null,
    otherMalignanciesDiseaseFree: null,
    otherMalignanciesPrimaryTreatmentCompleted : null,
    priorChemotherapyOrRecentCranialRadiationLastTwoYears: null,
    concomitantMedications: null,
    fullBloodCountDate : null,
    wbcCount: null,
    platelets: null,
    absoluteNeutrophils: null,
    lymphocytes: null,
    haemoglobin: null,
    ureaElectrolytesCreatineDate : null,
    ureaElectrolytesCreatineDateSameAsFBC: null,
    sodium: null,
    potassium: null,
    urea: null,
    creatine: null,
    creatineULN: null,
    creatineClearance: null,
    creatineDeteriminedBy: null,
    chloride: null,
    bicarbonate: null,
    liverFunctionTestDate : null,
    liverFunctionTestDateSameAsFBC: null,
    alt: null,
    altUln: null,
    alp: null,
    ast: null,
    astUln: null,
    totalBilirubin: null,
    totalInstitutionUln: null,
    albumin: null,
    ggt: null,
    totalProtein: null,
    concomitants: [],
    dateEntered: null,
    dateModified: null,
    enteredBy: null,
    modifiedBy: null,
    saveAndReturn: false
}

export interface IClinicalAssessmentReduxFormState {
    id: IFieldState<number>,
    patientId: IFieldState<number>,
    assessmentDate : IFieldState<string>,
    ecog: IFieldState<number>,
    otherMalignancies: IFieldState<number>,
    otherMalignanciesTypeCarcinomaInSitu: IFieldState<number>,
    otherMalignanciesTypeBasalCellCarcinoma: IFieldState<number>,
    otherMalignanciesTypeSquamousCellCarcinoma: IFieldState<number>,
    otherMalignanciesTypeSuperficialTransitionalCellCarcinoma: IFieldState<number>,
    otherMalignanciesTypeOther: IFieldState<number>,
    otherMalignanciesDiseaseFree: IFieldState<number>,
    otherMalignanciesPrimaryTreatmentCompleted : IFieldState<string>,
    priorChemotherapyOrRecentCranialRadiationLastTwoYears: IFieldState<number>,
    concomitantMedications: IFieldState<number>,
    fullBloodCountDate : IFieldState<string>,
    wbcCount: IFieldState<number>,
    platelets: IFieldState<number>,
    absoluteNeutrophils: IFieldState<number>,
    lymphocytes: IFieldState<number>,
    haemoglobin: IFieldState<number>,
    ureaElectrolytesCreatineDate : IFieldState<string>,
    ureaElectrolytesCreatineDateSameAsFBC: IFieldState<boolean>,
    sodium: IFieldState<number>,
    potassium: IFieldState<number>,
    urea: IFieldState<number>,
    creatine: IFieldState<number>,
    creatineULN: IFieldState<number>,
    creatineClearance: IFieldState<number>,
    creatineDeteriminedBy: IFieldState<number>,
    chloride: IFieldState<number>,
    bicarbonate: IFieldState<number>,
    liverFunctionTestDate : IFieldState<string>,
    liverFunctionTestDateSameAsFBC: IFieldState<boolean>,
    alt: IFieldState<number>,
    altUln: IFieldState<number>,
    alp: IFieldState<number>,
    ast: IFieldState<number>,
    astUln: IFieldState<number>,
    totalBilirubin: IFieldState<number>,
    totalInstitutionUln: IFieldState<number>,
    albumin: IFieldState<number>,
    ggt: IFieldState<number>,
    totalProtein: IFieldState<number>,

    dateEntered: IFieldState<string>,
    dateModified: IFieldState<string>,
    enteredBy: IFieldState<string>,
    modifiedBy: IFieldState<string>,
    concomitants: IFieldState<Dtos.ClinicalAssessmentFormConcomitant[]>,
    sourceDocuments: IFieldState<Dtos.SourceDocument[]>,
    openedModals: IFieldState<string[]>
}
