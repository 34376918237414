import * as Dtos from '../dtos/Fig.dtos';
import * as Moment from 'moment';

/* Date Range Validators and Properties */
export enum DateRangeValidateType {
    InnerAfter = 1,
    InnerBefore = 2,
    Within = 3,
    OuterAfter = 4,
    OuterBefore = 5,
    Outside = 6,
}

export enum ComparisonTimeType {
    Days = 1,
    Weeks = 2,
    Months = 3,
    Years = 4
}

// Basic Validators

export const requiredText = function (value) {
    return value && value.length;
};

export const requiredNumber = function (value) {
    return value != undefined;
}

export const requiredBoolean = function (value) {
    return value != undefined
};

export const minLength = function (minLength, value) {
    return !value || (value && value.length >= minLength);
}

export const maxLength = function (maxLength, value) {
    return !value || (value && value.length < maxLength);
};

export const precisionZero = function (value) {
    return !value || countDecimals(value) == 0
}

export const precisionThree = function (value) {
    return !value || countDecimals(value) == 3
}

export const maxPrecisionOne = function (value) {
    return !value || countDecimals(value) <= 1
}

export const maxPrecisionTwo = function (value) {
    return !value || countDecimals(value) <= 2
}

export const maxPrecisionCustom = function (value, precision) {
    return !value || countDecimals(value) <= precision
}

export const requiredSourceDocument = function (value: Dtos.TemporaryFileInformation, form: Dtos.SourceDocument) {
    const hasTempFile: boolean = value && value.state == Dtos.FileState.Success && value.temporaryFile ? true : false;
    const hasSourceDocument: boolean = form.id ? true : false;

    return hasTempFile || hasSourceDocument;
}

/*
export const requiredAttachDocument = function (value: Dtos.TemporaryFileInformation, form: Dtos.AttachDocument) {
    const hasTempFile: boolean = value && value.state == Dtos.FileState.Success && value.temporaryFile ? true : false;
    const hasAttachDocument: boolean = form.id ? true : false;

    return hasTempFile || hasAttachDocument;
}
*/

export const validateByRegex = function (pattern: RegExp, value: string): boolean {
    if (value) {
        if (pattern.test(value) === true) {
            return true;
        }

        return false;
    }

    return true;
}

// Date range validation
export const validateDateRange = function (value: any, comparisonDate: Date, comparisonTimeType: ComparisonTimeType, comparisonTime: number, rangeType: DateRangeValidateType, inclusive: boolean, comparisonDateInclusive: boolean, dateOnly: boolean): boolean {
    {
        let valueDate: Date = Moment.default(value).toDate();
        if (Moment.isDate(valueDate) && Moment.default(valueDate).isValid()) {
            if (rangeType == DateRangeValidateType.InnerBefore) {
                return checkInnerBefore(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly);
            }
            else if (rangeType == DateRangeValidateType.InnerAfter) {
                return checkInnerAfter(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly);
            }
            else if (rangeType == DateRangeValidateType.OuterAfter) {

                return checkOuterAfter(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly);
            }
            else if (rangeType == DateRangeValidateType.OuterBefore) {
                return checkOuterBefore(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly);
            }
            else if (rangeType == DateRangeValidateType.Within) {
                return (
                    checkInnerBefore(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly) ||
                    checkInnerAfter(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly)
                );
            }
            else if (rangeType == DateRangeValidateType.Outside) {
                return (
                    checkOuterBefore(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly) ||
                    checkOuterAfter(valueDate, comparisonDate, comparisonTimeType, comparisonTime, inclusive, comparisonDateInclusive, dateOnly)
                );
            }
        }
        return true;
    }
}

function checkInnerBefore(valueDate: Date, comparisonDate: Date, comparisonTimeType: ComparisonTimeType, comparisonTime: number, inclusive: boolean, comparisonDateInclusive: boolean, dateOnly: boolean): boolean {
    let dateToCompare: Date = Moment.default(comparisonDate)
        .subtract(
            comparisonTime,
            comparisonTimeType == ComparisonTimeType.Days ? 'days' :
                comparisonTimeType == ComparisonTimeType.Weeks ? 'weeks' :
                    comparisonTimeType == ComparisonTimeType.Months ? 'months' :
                        comparisonTimeType == ComparisonTimeType.Years ? 'years' : 'days'
        ).toDate();

    if (dateOnly) {
        valueDate.setHours(0, 0, 0, 0);
        comparisonDate.setHours(0, 0, 0, 0);
        dateToCompare.setHours(0, 0, 0, 0);
    }

    if (Moment.isDate(dateToCompare) && Moment.isDate(comparisonDate)) {
        return (((inclusive && dateToCompare <= valueDate) || dateToCompare < valueDate) &&
            ((comparisonDateInclusive && valueDate <= comparisonDate) || valueDate < comparisonDate));
    }
    return true;
}

function checkInnerAfter(valueDate: Date, comparisonDate: Date, comparisonTimeType: ComparisonTimeType, comparisonTime: number, inclusive: boolean, comparisonDateInclusive: boolean, dateOnly: boolean): boolean {
    let dateToCompare: Date = Moment.default(comparisonDate)
        .add(
            comparisonTime,
            comparisonTimeType == ComparisonTimeType.Days ? 'days' :
                comparisonTimeType == ComparisonTimeType.Weeks ? 'weeks' :
                    comparisonTimeType == ComparisonTimeType.Months ? 'months' :
                        comparisonTimeType == ComparisonTimeType.Years ? 'years' : 'days'
        ).toDate();

    if (dateOnly) {
        valueDate.setHours(0, 0, 0, 0);
        comparisonDate.setHours(0, 0, 0, 0);
        dateToCompare.setHours(0, 0, 0, 0);
    }

    if (Moment.isDate(dateToCompare) && Moment.isDate(comparisonDate)) {
        return (((inclusive && dateToCompare >= valueDate) || dateToCompare > valueDate) &&
            ((comparisonDateInclusive && valueDate >= comparisonDate) || valueDate > comparisonDate));
    }
    return true;
}

function checkOuterAfter(valueDate: Date, comparisonDate: Date, comparisonTimeType: ComparisonTimeType, comparisonTime: number, inclusive: boolean, comparisonDateInclusive: boolean, dateOnly: boolean): boolean {
    let dateToCompare: Date = Moment.default(comparisonDate)
        .add(
            comparisonTime,
            comparisonTimeType == ComparisonTimeType.Days ? 'days' :
                comparisonTimeType == ComparisonTimeType.Weeks ? 'weeks' :
                    comparisonTimeType == ComparisonTimeType.Months ? 'months' :
                        comparisonTimeType == ComparisonTimeType.Years ? 'years' : 'days'
        ).toDate();

    if (dateOnly) {
        valueDate.setHours(0, 0, 0, 0);
        comparisonDate.setHours(0, 0, 0, 0);
        dateToCompare.setHours(0, 0, 0, 0);
    }

    if (Moment.isDate(dateToCompare) && Moment.isDate(comparisonDate)) {
        return ((inclusive && valueDate >= dateToCompare) || valueDate > dateToCompare);
    }
    return true;
}

function checkOuterBefore(valueDate: Date, comparisonDate: Date, comparisonTimeType: ComparisonTimeType, comparisonTime: number, inclusive: boolean, comparisonDateInclusive: boolean, dateOnly: boolean): boolean {
    let dateToCompare: Date = Moment.default(comparisonDate)
        .subtract(
            comparisonTime,
            comparisonTimeType == ComparisonTimeType.Days ? 'days' :
                comparisonTimeType == ComparisonTimeType.Weeks ? 'weeks' :
                    comparisonTimeType == ComparisonTimeType.Months ? 'months' :
                        comparisonTimeType == ComparisonTimeType.Years ? 'years' : 'days'
        ).toDate();

    if (dateOnly) {
        valueDate.setHours(0, 0, 0, 0);
        comparisonDate.setHours(0, 0, 0, 0);
        dateToCompare.setHours(0, 0, 0, 0);
    }

    if (Moment.isDate(dateToCompare) && Moment.isDate(comparisonDate)) {
        return ((inclusive && valueDate <= dateToCompare) || valueDate < dateToCompare);
    }
    return true;
}

// https://stackoverflow.com/a/17369245
const countDecimals = function (value): number {
    if (Math.floor(value) === value) return 0;

    return value.toString().split(".")[1].length || 0;
}

// Regular Expressions

export const validationRegex = {
    ALPHABETICAL: /^[A-Za-z ]+$/,
    ALPHABETICAL_LETTERS: /^[A-Za-z]+$/,
    NUMBER: /^\d+(\.\d+)?$/,
    NUMBER_WHOLE: /^\d+$/
}

export const softErrorMessages = {
    DIAGNOSTIC_AXILLA_ULTRASOUND_DATE_RANGE: 'Please check the date of ultrasound and amend if applicable.',
    GENERIC_CHECK_AMEND_DATE: 'Please check and amend the date if applicable',
    LARGEST_BREAST_LESION: 'Please report the largest tumour in the "Breast Lesion 1 (Primary Lesion)" row.'
}

export const errorMessages = {
    PRECISION_ZERO: 'Please enter whole numbers (no decimals)',
    PRECISION_ONE: 'Please enter values to one decimal places only',
    PRECISION_TWO: 'Please enter values to two decimal places only',
    LIVER_METASTASES_PRESENT: 'Please confirm liver metastases are present'
}

export const hardErrorMessages = {
    WHOLE_NUMBER_ONLY: 'Please enter whole numbers (no decimals).'
}
