import { client } from "../../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../../dtos/Fig.dtos";

export const patientReportedFormApi = {
    getPatientReportedForms: (): Observable<Dtos.GetPatientReportedFormsResponse> => {
        //Create the request.
        let request: Dtos.GetPatientReportedForms = new Dtos.GetPatientReportedForms();

        //Send request.
        const response: Promise<Dtos.GetPatientReportedFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getPatientReportedFormById: (id: number): Observable<Dtos.GetPatientReportedFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetPatientReportedFormById = new Dtos.GetPatientReportedFormById();

        request.id = id;
        //Send request.
        const response: Promise<Dtos.GetPatientReportedFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getPatientReportedFormByPatientId: (patientId: number): Observable<Dtos.GetPatientReportedFormByPatientIdResponse> => {
        //Create the request.
        let request: Dtos.GetPatientReportedFormByPatientId = new Dtos.GetPatientReportedFormByPatientId();

        request.patientId = patientId;

        //Send request.
        const response: Promise<Dtos.GetPatientReportedFormByPatientIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getPatientReportedFormByPatientIdentifier: (identifier: string): Observable<Dtos.GetPatientReportedFormByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetPatientReportedFormByPatientIdentifier = new Dtos.GetPatientReportedFormByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetPatientReportedFormByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setPatientReportedForm: (PatientReportedForm: Dtos.PatientReportedForm,
        institutionCode: string,
        patientIdentifier: string): Observable<Dtos.SetPatientReportedFormResponse> => {

        let request: Dtos.SetPatientReportedForm = new Dtos.SetPatientReportedForm();

        request.patientReportedForm = PatientReportedForm;
        request.institutionCode = institutionCode;
        request.patientIdentifier = patientIdentifier;

        // send request
        const response: Promise<Dtos.SetPatientReportedFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createPatientReportedForm: (): Observable<Dtos.CreatePatientReportedFormResponse> => {
        //Create the request.
        let request: Dtos.CreatePatientReportedForm = new Dtos.CreatePatientReportedForm();

        //Send request.
        const response: Promise<Dtos.CreatePatientReportedFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}