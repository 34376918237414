import * as React from "react";
import classNames from "classnames";
import InputMask from 'react-input-mask';
import { IBaseFormInputProps } from '../';
import { FontAwesomeIcons, FontAwesomeIcon } from "../../../../constants/fontAwesomeIcons";
import { PATIENT_INITIALS, STANDARD_INTERNATIONAL_CODING_SYSTEM } from "../../../../constants/inputMasks";


export interface IMaskedFormInputProps {
    mask: string;
    maskChar: any;
    type: string;
    placeHolder?: string;
    feedbackIcon?: string;
    formatChars?: any;
}

class MaskedFormInput extends React.Component<IMaskedFormInputProps & IBaseFormInputProps, any> {
    render() {
        const {
            id,
            name,
            onBlur,
            onChange,
            onFocus,
            onKeyPress,
            placeHolder,
            value,
            disabled,
            mask,
            maskChar,
            valid,
            invalid,
            className,
            feedbackIcon,
            formatChars

        } = this.props;

        let inputType = this.props.type ? this.props.type : "text";
        const derivedClassName = className ? className : "form-control";

        const textInput = <InputMask
            type={inputType}
            className={classNames(derivedClassName, { "is-invalid": invalid && !disabled })}
            name={name}
            id={id}
            onBlur={onBlur}
            onChange={(event) => { onChange(event.target.value ? event.target.value : null) }}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            placeholder={placeHolder}
            value={value ? value : ''}
            disabled={disabled}
            mask={mask}
            maskChar={maskChar ? maskChar : "_"}
            formatChars={formatChars ? formatChars : undefined}
        />

        const feedback = invalid && !disabled ?
            <span className={classNames("form-control-feedback pr-2")}>
                <FontAwesomeIcon icon={feedbackIcon ? feedbackIcon : FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE} fixedWidth />
            </span> :
            null


        return <div style={{ position: "relative" }}>
            {textInput}
            {feedback}
        </div>
    }
}

export default MaskedFormInput;