import { Reducer } from 'redux';
import { update } from '../../helpers/immutabilityHelper'
import * as Dtos from '../../dtos/Fig.dtos';

import {
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_SUCCESS,
    ACTION_DEMOGRAPHICDETAILFORMS_LOAD_FAILURE,

    ACTION_DEMOGRAPHICDETAILFORMS_SAVE,
    ACTION_DEMOGRAPHICDETAILFORMS_SAVE_SUCCESS,
    ACTION_DEMOGRAPHICDETAILFORMS_SAVE_FAILURE,

    ACTION_DEMOGRAPHICDETAILFORMS_CREATE,
    ACTION_DEMOGRAPHICDETAILFORMS_CREATE_SUCCESS,
    ACTION_DEMOGRAPHICDETAILFORMS_CREATE_FAILURE,

    ACTION_DEMOGRAPHICDETAILFORMS_CLEAR,
    DemographicDetailFormAction
} from '../../actions/forms/demographicDetailForm';

import { RequestState } from "../../enumerations/RequestState";
import { IRequestState } from "../index";
import { updateFailureIndexToId } from "../../helpers/formErrorHelper";

export interface IDemographicDetailFormState {
    formData: Dtos.DemographicDetailForm | Dtos.DemographicDetailForm[],
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    validationFailures: Dtos.ResponseError[];
    lookups: Dtos.Lookup[],
    loadState: IRequestState;
    saveState: IRequestState;
    createState: IRequestState;
}

const initialState: IDemographicDetailFormState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    lookups: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    createState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IDemographicDetailFormState, isLoading: boolean): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IDemographicDetailFormState,
    formData: Dtos.DemographicDetailForm | Dtos.DemographicDetailForm[],
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[], responseStatus: Dtos.ResponseStatus
): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        loadState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function loadFailure(state: IDemographicDetailFormState, responseStatus: Dtos.ResponseStatus): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function saving(state: IDemographicDetailFormState, isSaving: boolean): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function saveSuccess(
    state: IDemographicDetailFormState,
    formData: Dtos.DemographicDetailForm | Dtos.DemographicDetailForm[],
    formState: Dtos.RegistrationFormState,
    responseStatus: Dtos.ResponseStatus
): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        saveState: {
            $set: {
                status: RequestState.Success,
                errorCode: responseStatus ? responseStatus.errorCode : undefined,
                errorMessage: responseStatus ? responseStatus.message : undefined,
                errors: responseStatus ? responseStatus.errors : undefined,
                meta: responseStatus ? responseStatus.meta : undefined
            } as IRequestState
        },
        validationFailures: {
            $set: responseStatus ? updateFailureIndexToId(responseStatus.errors, formData) : undefined
        }
    });

    return newState;
}

function saveFailure(state: IDemographicDetailFormState, responseStatus: Dtos.ResponseStatus): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors,
                meta: responseStatus.meta
            } as IRequestState
        }
    });

    return newState;
}

function creating(state: IDemographicDetailFormState, isCreating: boolean): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        createState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function createSuccess(
    state: IDemographicDetailFormState,
    formData: Dtos.DemographicDetailForm,
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    lookups: Dtos.Lookup[]
): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        formData: {
            $set: formData
        },
        formState: {
            $set: formState
        },
        formProperties: {
            $set: formProperties
        },
        lookups: {
            $set: lookups
        },
        createState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function createFailure(state: IDemographicDetailFormState, responseStatus: Dtos.ResponseStatus): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(state, {
        createState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IDemographicDetailFormState): IDemographicDetailFormState {
    const newState: IDemographicDetailFormState = update(initialState, {});

    return newState;
}

const DemographicDetailFormReducer: Reducer<IDemographicDetailFormState> = (state: IDemographicDetailFormState = initialState, action: DemographicDetailFormAction) => {
    switch (action.type) {
        case ACTION_DEMOGRAPHICDETAILFORMS_LOAD:
        case ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_ID:
        case ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_ID:
        case ACTION_DEMOGRAPHICDETAILFORMS_LOAD_BY_PATIENT_IDENTIFIER:
            return loading(state, true);
        case ACTION_DEMOGRAPHICDETAILFORMS_LOAD_SUCCESS:
            return loadSuccess(state, action.formData, action.formState, action.formProperties, action.lookups, action.responseStatus);
        case ACTION_DEMOGRAPHICDETAILFORMS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_DEMOGRAPHICDETAILFORMS_SAVE:
            return saving(state, true);
        case ACTION_DEMOGRAPHICDETAILFORMS_SAVE_SUCCESS:
            return saveSuccess(state, action.formData, action.formState, action.responseStatus);
        case ACTION_DEMOGRAPHICDETAILFORMS_SAVE_FAILURE:
            return saveFailure(state, action.responseStatus);
        case ACTION_DEMOGRAPHICDETAILFORMS_CREATE:
            return creating(state, true);
        case ACTION_DEMOGRAPHICDETAILFORMS_CREATE_SUCCESS:
            return createSuccess(state, action.formData, action.formState, action.formProperties, action.lookups);
        case ACTION_DEMOGRAPHICDETAILFORMS_CREATE_FAILURE:
            return createFailure(state, action.responseStatus);
        case ACTION_DEMOGRAPHICDETAILFORMS_CLEAR:
            return clear(state);
    }

    return state;
}

export default DemographicDetailFormReducer;