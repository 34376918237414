import { History, Location } from "history";
import * as React from 'react';
import { connect } from "react-redux";
import { actions, Form } from "react-redux-form";
import { match } from 'react-router';
import { routerActions } from 'react-router-redux';
import Alert from 'react-s-alert';
import { bindActionCreators } from "redux";
import * as DemographicDetailFormActions from '../../../actions/forms/demographicDetailForm';
import * as InstitutionActions from '../../../actions/institution';
import * as DemographicDetailFormPageActions from '../../../actions/pages/registration/demographicDetailFormPage';
import * as PatientActions from '../../../actions/patient';
import { NotificationAlert } from "../../../components/common/index";
import { CheckboxFormInput, MaskedFormInput, SelectFormInput } from "../../../components/form/index";
import { INavItem, RestrictedLayout } from "../../../components/layouts/index";
import { FontAwesomeIcon, FontAwesomeIcons } from "../../../constants/fontAwesomeIcons";
import { LANGUAGE_SPOKEN, STANDARD_INTERNATIONAL_CODING_SYSTEM } from "../../../constants/inputMasks";
import * as Dtos from '../../../dtos/Fig.dtos';
import { RequestState } from "../../../enumerations/RequestState";
import { renderRegistrationFormSaveBlock, renderRegistrationFormSaveError, renderRegistrationFormSaveSuccess, renderRegistrationFormSaveWarning } from "../../../helpers/alert";
import { RegistrationComponent, renderFormControls } from "../../../helpers/formHelper";
import { findLookup, generateOptionsFromLookup } from "../../../helpers/lookupHelper";
import { RegistrationFormComponent } from "../../../interfaces/forms/IRegistrationComponent";
import { IFigState } from "../../../reducers/index";
import { IDemographicDetailReduxFormState, initialDemographicDetailFormState } from "../../../reducers/reactReduxForms/demographicDetailForm";
import { IRegistrationFormState } from "../../../reducers/reactReduxForms/index";
import { ISourceDocumentModalState } from "../../../reducers/reactReduxForms/sourceDocument";
import './DemographicDetailFormPage.scss';

interface IDemographicDetailFormPageParams {
    identifier: string;
    institutionCode: string;
}

interface IDemographicDetailFormPageActions {

    loadDemographicDetailForm: DemographicDetailFormActions.IDemographicDetailFormLoadByPatientIdentifierActionCreator,
    saveDemographicDetailForm: DemographicDetailFormActions.IDemographicDetailFormSaveActionCreator,
    createDemographicDetailForm: DemographicDetailFormActions.IDemographicDetailFormCreateActionCreator,
    clearDemographicDetailForm: DemographicDetailFormActions.IDemographicDetailFormClearActionCreator,

    clearPatient: PatientActions.IPatientClearActionCreator;

    loadPatient: PatientActions.IPatientLoadByIdentifierActionCreator;
    loadPatientById: PatientActions.IPatientLoadByIdActionCreator;

    clearInstitution: InstitutionActions.IInstitutionClearActionCreator;

    loadInstitution: InstitutionActions.IInstitutionLoadByInstitutionCodeActionCreator;

    loadForm: typeof actions.load;
    changeForm: typeof actions.change;
    resetForm: typeof actions.reset;

    navigate: typeof routerActions.push;
    navigateReplace: typeof routerActions.replace;

    toggleMessageModal: DemographicDetailFormPageActions.IDemographicDetailFormPageToggleMessageModalActionCreator;
}

interface IDemographicDetailFormPageProps {

    form: Dtos.DemographicDetailForm & IRegistrationFormState & ISourceDocumentModalState;
    formState: Dtos.RegistrationFormState;
    formProperties: Dtos.RegistrationFormProperty[];
    reduxFormState: IDemographicDetailReduxFormState;

    location: Location;
    match: match<IDemographicDetailFormPageParams>;
    history: History;
    identifier: string;
    institutionCode: string;
    patient: Dtos.Patient;
    loadingPatient: boolean;
    loadPatientSuccess: boolean;
    loadPatientFailure: boolean;

    institution: Dtos.Institution;
    loadingInstitution: boolean;
    loadInstitutionSuccess: boolean;
    loadInstitutionFailure: boolean;

    demographicDetailForm: Dtos.DemographicDetailForm
    lookups: Dtos.Lookup[];
    validationFailures: Dtos.ResponseError[]

    loadingDemographicDetailForm: boolean;
    loadDemographicDetailFormSuccess: boolean;
    loadDemographicDetailFormFailure: boolean;

    savingDemographicDetailForm: boolean;
    saveDemographicDetailFormSuccess: boolean;
    saveDemographicDetailFormFailure: boolean;

    creatingDemographicDetailForm: boolean;
    createDemographicDetailFormSuccess: boolean;
    createDemographicDetailFormFailure: boolean;

    messageModalOpen: boolean;
}

type DemographicDetailFormPageProps = IDemographicDetailFormPageProps & IDemographicDetailFormPageActions;

const formName: string = "DemographicDetailForm";
const reduxFormName: string = "reduxForms.demographicDetailForm";
let groupSubHeaderClassNames = "form-group row d-block border border-bottom-1 border-top-0 no-gutter";

const groupClassNames: string = 'form-group row border border-bottom-1 border-top-0 pb-4 pt-4';
const questionLabelClassNames: string = 'col-form-label col-sm-9';
const answerClassNames: string = 'col-sm-3 px-4';

class DemographicDetailFormPage extends React.PureComponent<DemographicDetailFormPageProps, any> {

    constructor(props: DemographicDetailFormPageProps) {
        super(props);

        this.clearPatient = this.clearPatient.bind(this);
        this.clearInstitution = this.clearInstitution.bind(this);
        this.clearDemographicDetailForm = this.clearDemographicDetailForm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this);
        this.loadForm = this.loadForm.bind(this);
    }

    componentDidMount() {
        const {
            loadPatient,
            identifier,
            institutionCode,
            loadInstitution,
            loadDemographicDetailForm,
            createDemographicDetailForm
        } = this.props;

        if (identifier) {
            loadPatient(identifier);
            loadDemographicDetailForm(identifier);
        }
        else if (!identifier) {
            createDemographicDetailForm();
        }
        if (institutionCode) {
            loadInstitution(institutionCode);
        }
    }

    componentWillReceiveProps(nextProps: DemographicDetailFormPageProps) {
    }

    componentDidUpdate(prevProps: DemographicDetailFormPageProps) {
        const {
            patient,
            loadForm,
            loadPatient,
            loadingPatient,
            loadPatientSuccess,
            loadPatientById,
            identifier,
            institutionCode,
            loadInstitution,
            loadingDemographicDetailForm,
            loadDemographicDetailFormSuccess,
            loadDemographicDetailFormFailure,
            savingDemographicDetailForm,
            saveDemographicDetailFormSuccess,
            saveDemographicDetailFormFailure,
            creatingDemographicDetailForm,
            createDemographicDetailFormSuccess,
            createDemographicDetailFormFailure,
            createDemographicDetailForm,
            demographicDetailForm,
            navigateReplace,
            validationFailures,
            form,
            reduxFormState,
            navigate,
            toggleMessageModal,

        } = this.props;

        if (!loadingPatient && prevProps.loadingPatient) {
            if (loadPatientSuccess && !identifier) {
                if (form.saveAndReturn) {
                    navigate("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/patient-summary");
                }
                else {
                    navigateReplace("/registration/" + institutionCode + "/" + (patient.registrationNumber ? patient.registrationNumber : patient.temporaryNumber) + "/pre-registration");
                }
            }

        }

        if (identifier && prevProps.identifier != identifier) {
            loadPatient(identifier);
        }
        else if (!identifier && prevProps.identifier) {
            createDemographicDetailForm();
        }

        if (institutionCode && prevProps.institutionCode != institutionCode) {
            loadInstitution(institutionCode);
        }


        if (!loadingDemographicDetailForm && prevProps.loadingDemographicDetailForm) {
            if (loadDemographicDetailFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }

        if (!savingDemographicDetailForm && prevProps.savingDemographicDetailForm) {
            if (saveDemographicDetailFormSuccess) {
                if (validationFailures && validationFailures.length > 0) {

                    Alert.warning(<NotificationAlert
                        alertContent={renderRegistrationFormSaveWarning(validationFailures)}
                        icon={FontAwesomeIcons.Solid.EXCLAMATION_CIRCLE}
                    />);

                    toggleMessageModal();

                }
                else {

                    Alert.success(<NotificationAlert
                        alertContent={renderRegistrationFormSaveSuccess()}
                        icon={FontAwesomeIcons.Solid.CHECK}
                    />);

                }

                if (form.saveAndReturn && identifier) {

                    navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary")
                }
                else if (!form.saveAndReturn) {

                    this.loadForm();
                }

                if (!identifier) {

                    loadPatientById(demographicDetailForm.patientId);
                }
            }
            else {

                Alert.error(
                    <NotificationAlert
                        alertContent={renderRegistrationFormSaveError()}
                        icon={FontAwesomeIcons.Solid.TIMES_OCTAGON}
                    />
                );

            }
        }

        if (!creatingDemographicDetailForm && prevProps.creatingDemographicDetailForm) {
            if (createDemographicDetailFormSuccess) {
                this.loadForm();
            }
            else {

            }
        }
    }

    componentWillUpdate(nextProps: DemographicDetailFormPageProps) {
    }

    componentWillUnmount() {

        const {
            resetForm,
            loadForm
        } = this.props

        this.clearInstitution();
        this.clearPatient();
        this.clearDemographicDetailForm();
        loadForm(reduxFormName, initialDemographicDetailFormState);
        resetForm(reduxFormName);
    }

    render() {

        const {
            loadingPatient,
            savingDemographicDetailForm,
            loadingInstitution,
            loadingDemographicDetailForm,
            loadDemographicDetailFormSuccess,
            createDemographicDetailFormSuccess,
            creatingDemographicDetailForm
        } = this.props



        return <RestrictedLayout
            subMenuItems={this.createSubMenuItems()}
            loading={loadingPatient || loadingInstitution || loadingDemographicDetailForm || creatingDemographicDetailForm}
            saving={savingDemographicDetailForm}
        >
            {this.renderContent()}
        </RestrictedLayout>;
    }

    renderContent() {
        const {
            form,
            patient,
            lookups,
            validationFailures,
            loadDemographicDetailFormSuccess,
            createDemographicDetailFormSuccess,
            saveDemographicDetailFormSuccess,
            reduxFormState,
            formState,
            messageModalOpen,
            toggleMessageModal,
            savingDemographicDetailForm,
            formProperties,
            changeForm
        } = this.props;


        const formDisabled: boolean = (formState && formState.formState == Dtos.RegistrationFormStatus.Disabled) || (patient && patient.status == Dtos.PatientStatus.Ineligible) || savingDemographicDetailForm;
        
        const {
            priorTreatmentsDisabled,
            postCodeNA,
        } = this.handleDisabledValues();

        const formComponents: { [index: string]: RegistrationFormComponent } = {
            indigenousStatus: {
                type: RegistrationComponent.RegistrationControl,
                inputType: SelectFormInput,
                inputDisabled: formDisabled,
                inputProps: {
                    options: generateOptionsFromLookup(findLookup("DemographicDetailForm", "IndigenousStatus", lookups))
                },
            },  
            countryOfBirth: {
                type: RegistrationComponent.RegistrationControl,
                inputType: MaskedFormInput,
                inputProps: {
                    mask: STANDARD_INTERNATIONAL_CODING_SYSTEM.mask
                },
                inputDisabled: formDisabled,
            },
            primaryLanguage: {
                type: RegistrationComponent.RegistrationControl,
                inputType: MaskedFormInput,
                inputProps: {
                    mask: LANGUAGE_SPOKEN.mask
                },
                inputDisabled: formDisabled,
            },
            primaryLanguageAsChild: {
                type: RegistrationComponent.RegistrationControl,
                inputType: MaskedFormInput,
                inputProps: {
                    mask: LANGUAGE_SPOKEN.mask
                },
                inputDisabled: formDisabled,
            },
            postCodeGroup: {
                type: RegistrationComponent.RegistrationElement,
                component: <div className={groupSubHeaderClassNames}>
                    {
                        renderFormControls(
                            form,
                            "DemographicDetailForm",
                            {
                                postCode: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: MaskedFormInput,
                                    inputProps: {
                                        mask: STANDARD_INTERNATIONAL_CODING_SYSTEM.mask
                                    },
                                    inputDisabled: formDisabled || postCodeNA,
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                                postCodeNA: {
                                    type: RegistrationComponent.RegistrationControl,
                                    inputType: CheckboxFormInput,
                                    inputDisabled: formDisabled,
                                    inputProps: {
                                        label: "N/A"
                                    },
                                    inputLabel: " ",
                                    changeAction: (model, value, form, parentModel) => {
                                        changeForm(model, value);
    
                                        if (value != false) {
                                            changeForm(reduxFormName + parentModel + '.postCode', null);
                                        }
                                    },
                                    groupClassName: "border-0 d-flex pt-4 pb-4",
                                    labelClassName: questionLabelClassNames,
                                    fieldClassName: answerClassNames,
                                },
                            },
                            lookups,
                            validationFailures,
                            formProperties,
                            changeForm
                        )
                    }
                </div>
            }
        };


        return <div>
            <h1>Demographic Detail Form</h1>
            <Form model={reduxFormName}
                onSubmit={(val, event) => this.handleOnSubmit(val)}
                onSubmitFailed={(val) => this.handleOnSubmitFailed(val)}
            >
                {
                    renderFormControls(form, "demographicDetailForm", formComponents, lookups, validationFailures, formProperties, changeForm)
                }
                <div className="mt-4 mb-4 text-right">
                    <button disabled={formDisabled}
                        onClick={
                            () => {
                                this.props.changeForm(reduxFormName + ".saveAndReturn", false);
                            }
                        }
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form
                    </button>

                    <button disabled={formDisabled}
                        onClick={() => {
                            this.props.changeForm(reduxFormName + ".saveAndReturn", true);
                        }}
                        type="submit"
                        className="btn btn-primary mr-2"
                    >
                        Submit Form And Return
                    </button>
                    <button onClick={this.onCancel} className="btn btn-primary mr-2" > Cancel </button>
                </div>
            </Form>
        </div>
    }

    clearPatient() {
        this.props.clearPatient();
    }

    clearInstitution() {
        this.props.clearInstitution();
    }

    clearDemographicDetailForm() {
        this.props.clearDemographicDetailForm();
    }

    onCancel() {
        const {
            navigate,
            resetForm,
            institutionCode,
            identifier
        } = this.props

        if (identifier) {

            navigate("/registration/" + institutionCode + "/" + identifier + "/patient-summary");
        }
        else {
            navigate("/registration/" + institutionCode);
        }



    }

    createSubMenuItems(): INavItem[] {

        const {
            identifier,
            institutionCode,
            institution,
            patient
        } = this.props

        let institutionName: string = ''
        if (institution) {
            institutionName = institution.institutionName;
        }

        let displayIdentifier: string = ''
        if (patient) {
            if (patient.registrationNumber) {
                displayIdentifier = patient.registrationNumber;
            }
            else if (patient.siteNumber) {
                displayIdentifier = patient.siteNumber.toString();
            }
        }

        return [
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.BUILDING} />,
                title: institutionName,
                url: "/registration/" + institutionCode
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.USER} />,
                title: displayIdentifier,
                url: identifier ? "/registration/" + institutionCode + "/" + identifier + "/patient-summary" : undefined
            },
            {
                icon: <FontAwesomeIcon fixedWidth icon={FontAwesomeIcons.Solid.FILE_ALT} />,
                title: "Demographic Detail Form"
            }
        ]
    }

    renderErrorAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was <strong> not </strong> saved.
            </div>

    }

    renderWarningAlert(): any {

        const {
            validationFailures,
        } = this.props

        return <div>
            The form was saved but was invalid with {validationFailures.length} errors.
        </div>

    }

    renderSaveSuccessAlert(): any {
        return <div>The form has been saved successfully</div>
    }

    handleDisabledValues() {
        const {
            form
        } = this.props

        let postCodeNA: boolean = false;
        if (form.postCodeNA != undefined) {
            postCodeNA = form.postCodeNA;
        }

        return {
            priorTreatmentsDisabled: false,
            postCodeNA
        }
    }


    handleOnSubmit(data: Dtos.DemographicDetailForm) {

        const {
            saveDemographicDetailForm,
            institutionCode,
            identifier,
            validationFailures,
            changeForm

        } = this.props;

        if (data && institutionCode) {

            const isDisabled = this.handleDisabledValues();

            let changes: Dtos.DemographicDetailForm = {} as Dtos.DemographicDetailForm;

            saveDemographicDetailForm(Object.assign({ ...data }, changes), institutionCode, identifier);
        }

    }


    handleOnSubmitFailed(data: Dtos.DemographicDetailForm) {

        const { institutionCode,
            identifier,
        } = this.props;

        Alert.error(<NotificationAlert
            minWidth={500}
            alertContent={renderRegistrationFormSaveBlock()}
            icon={FontAwesomeIcons.Solid.BAN}
        />);
    }

    toggleMessageModal() {
        const {
            toggleMessageModal
        } = this.props
        // eslint-disable-next-line
        toggleMessageModal;
    }

    loadForm() {
        const {
            loadForm,
            demographicDetailForm
        } = this.props

        let extraData: Dtos.DemographicDetailForm = {} as Dtos.DemographicDetailForm;

        loadForm(reduxFormName, Object.assign({ ...demographicDetailForm }, extraData));
    }
}

const mapStateToProps = (state: IFigState, ownProps: DemographicDetailFormPageProps): IDemographicDetailFormPageProps => {

    const demographicDetailForm = !(state.registrationForms.demographicDetailForms.formData instanceof Array) ? state.registrationForms.demographicDetailForms.formData : undefined;

    return {
        match: ownProps.match,
        history: ownProps.history,
        location: state.routing.location,
        identifier: ownProps.match ? ownProps.match.params.identifier : undefined,
        institutionCode: ownProps.match ? ownProps.match.params.institutionCode : undefined,

        patient: !(state.patients.data instanceof Array) ? state.patients.data : undefined,
        loadingPatient: state.patients.loadState && state.patients.loadState.status == RequestState.Pending,
        loadPatientSuccess: state.patients.loadState && state.patients.loadState.status == RequestState.Success,
        loadPatientFailure: state.patients.loadState && state.patients.loadState.status == RequestState.Failure,

        institution: !(state.institutions.data instanceof Array) ? state.institutions.data : undefined,
        loadingInstitution: state.institutions.loadState && state.institutions.loadState.status == RequestState.Pending,
        loadInstitutionSuccess: state.institutions.loadState && state.institutions.loadState.status == RequestState.Success,
        loadInstitutionFailure: state.institutions.loadState && state.institutions.loadState.status == RequestState.Failure,

        demographicDetailForm: demographicDetailForm,
        lookups: state.registrationForms.demographicDetailForms.lookups,
        loadingDemographicDetailForm: state.registrationForms.demographicDetailForms.loadState &&
            (state.registrationForms.demographicDetailForms.loadState.status == RequestState.Pending ||
                (state.registrationForms.demographicDetailForms.loadState.status == RequestState.None &&
                    state.registrationForms.demographicDetailForms.createState.status == RequestState.None)),
        loadDemographicDetailFormSuccess: state.registrationForms.demographicDetailForms.loadState && state.registrationForms.demographicDetailForms.loadState.status == RequestState.Success,
        loadDemographicDetailFormFailure: state.registrationForms.demographicDetailForms.loadState && state.registrationForms.demographicDetailForms.loadState.status == RequestState.Failure,

        savingDemographicDetailForm: state.registrationForms.demographicDetailForms.saveState && state.registrationForms.demographicDetailForms.saveState.status == RequestState.Pending,
        saveDemographicDetailFormSuccess: state.registrationForms.demographicDetailForms.saveState && state.registrationForms.demographicDetailForms.saveState.status == RequestState.Success,
        saveDemographicDetailFormFailure: state.registrationForms.demographicDetailForms.saveState && state.registrationForms.demographicDetailForms.saveState.status == RequestState.Failure,

        creatingDemographicDetailForm: state.registrationForms.demographicDetailForms.createState && state.registrationForms.demographicDetailForms.createState.status == RequestState.Pending,
        createDemographicDetailFormSuccess: state.registrationForms.demographicDetailForms.createState && state.registrationForms.demographicDetailForms.createState.status == RequestState.Success,
        createDemographicDetailFormFailure: state.registrationForms.demographicDetailForms.createState && state.registrationForms.demographicDetailForms.createState.status == RequestState.Failure,

        form: state.reduxForms.demographicDetailForm,
        formState: state.registrationForms.demographicDetailForms.formState,
        formProperties: state.registrationForms.demographicDetailForms.formProperties,

        reduxFormState: state.reduxForms.formState.demographicDetailForm,
        validationFailures: demographicDetailForm && demographicDetailForm.id ?
            state.registrationForms.demographicDetailForms.validationFailures :
            undefined,

        messageModalOpen: state.demographicDetailFormPage.messageModalOpen
    };
};

const mapDispatchToProps = (dispatch): IDemographicDetailFormPageActions => {
    return {
        loadPatient: bindActionCreators(PatientActions.LoadPatientByIdentifier, dispatch),
        loadPatientById: bindActionCreators(PatientActions.LoadPatientById, dispatch),
        clearPatient: bindActionCreators(PatientActions.Clear, dispatch),

        loadInstitution: bindActionCreators(InstitutionActions.LoadInstitutionByInstitutionCode, dispatch),
        clearInstitution: bindActionCreators(InstitutionActions.Clear, dispatch),

        loadDemographicDetailForm: bindActionCreators(DemographicDetailFormActions.LoadDemographicDetailFormByPatientIdentifier, dispatch),
        saveDemographicDetailForm: bindActionCreators(DemographicDetailFormActions.SaveDemographicDetailForm, dispatch),
        createDemographicDetailForm: bindActionCreators(DemographicDetailFormActions.CreateDemographicDetailForm, dispatch),
        clearDemographicDetailForm: bindActionCreators(DemographicDetailFormActions.Clear, dispatch),

        loadForm: bindActionCreators(actions.load, dispatch),
        changeForm: bindActionCreators(actions.change, dispatch),
        resetForm: bindActionCreators(actions.reset, dispatch),

        navigate: bindActionCreators(routerActions.push, dispatch),
        navigateReplace: bindActionCreators(routerActions.replace, dispatch),

        toggleMessageModal: bindActionCreators(DemographicDetailFormPageActions.toggleMessageModal, dispatch)
    }
};

export default
    connect(mapStateToProps, mapDispatchToProps)(DemographicDetailFormPage);
