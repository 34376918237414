import { client } from "../../dtos/Client";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import * as Dtos from "../../dtos/Fig.dtos";

export const sourceDocumentsFormApi = {
    getSourceDocumentsForms: (): Observable<Dtos.GetSourceDocumentsFormsResponse> => {
        //Create the request.
        let request: Dtos.GetSourceDocumentsForms = new Dtos.GetSourceDocumentsForms();

        //Send request.
        const response: Promise<Dtos.GetSourceDocumentsFormsResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getSourceDocumentsFormById: (id: number): Observable<Dtos.GetSourceDocumentsFormByIdResponse> => {
        //Create the request.
        let request: Dtos.GetSourceDocumentsFormById = new Dtos.GetSourceDocumentsFormById();

        request.id = id;
        //Send request.
        const response: Promise<Dtos.GetSourceDocumentsFormByIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },

    getSourceDocumentsFormByPatientId: (patientId: number): Observable<Dtos.GetSourceDocumentsFormByPatientIdResponse> => {
        //Create the request.
        let request: Dtos.GetSourceDocumentsFormByPatientId = new Dtos.GetSourceDocumentsFormByPatientId();

        request.patientId = patientId;

        //Send request.
        const response: Promise<Dtos.GetSourceDocumentsFormByPatientIdResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    getSourceDocumentsFormByPatientIdentifier: (identifier: string): Observable<Dtos.GetSourceDocumentsFormByPatientIdentifierResponse> => {
        //Create the request.
        let request: Dtos.GetSourceDocumentsFormByPatientIdentifier = new Dtos.GetSourceDocumentsFormByPatientIdentifier();

        request.identifier = identifier;

        //Send request.
        const response: Promise<Dtos.GetSourceDocumentsFormByPatientIdentifierResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    },
    setSourceDocumentsForm: (sourceDocumentsForm: Dtos.SourceDocumentsForm,
        institutionCode: string,
        patientIdentifier: string): Observable<Dtos.SetSourceDocumentsFormResponse> => {

        let request: Dtos.SetSourceDocumentsForm = new Dtos.SetSourceDocumentsForm();

        request.sourceDocumentsForm = sourceDocumentsForm;
        request.institutionCode = institutionCode;
        request.patientIdentifier = patientIdentifier;

        // send request
        const response: Promise<Dtos.SetSourceDocumentsFormResponse> = client.post(request);

        return Observable.from(response);

    },
    createSourceDocumentsForm: (): Observable<Dtos.CreateSourceDocumentsFormResponse> => {
        //Create the request.
        let request: Dtos.CreateSourceDocumentsForm = new Dtos.CreateSourceDocumentsForm();

        //Send request.
        const response: Promise<Dtos.CreateSourceDocumentsFormResponse> = client.get(request);

        //Return request observable (incomplete request, still processing).
        return Observable.from(response);
    }

}