import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Fig.dtos';

import {
    ACTION_WEBSITE_SETTINGS_LOAD,
    ACTION_WEBSITE_SETTINGS_LOAD_SUCCESS,
    ACTION_WEBSITE_SETTINGS_LOAD_FAILURE,
    ACTION_WEBSITE_SETTINGS_CLEAR,
    ACTION_WEBSITE_SETTINGS_SET,
    ACTION_WEBSITE_SETTINGS_SET_FAILURE,
    ACTION_WEBSITE_SETTINGS_SET_SUCCESS,
    WebsiteSettingsAction
} from '../actions/websiteSettings';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface IWebsiteSettingsState {
    formData: Dtos.PreRegistrationForm | Dtos.PreRegistrationForm[],
    formState: Dtos.RegistrationFormState,
    formProperties: Dtos.RegistrationFormProperty[],
    validationFailures: Dtos.ResponseError[];
    data: Dtos.WebsiteSetting;
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
}

const initialState: IWebsiteSettingsState = {
    formData: undefined,
    formState: undefined,
    formProperties: undefined,
    validationFailures: undefined,
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IWebsiteSettingsState, isLoading: boolean): IWebsiteSettingsState {
    const newState: IWebsiteSettingsState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IWebsiteSettingsState, data: Dtos.WebsiteSetting
    ): IWebsiteSettingsState {
    const newState: IWebsiteSettingsState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: IWebsiteSettingsState, responseStatus: Dtos.ResponseStatus): IWebsiteSettingsState {
    const newState: IWebsiteSettingsState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IWebsiteSettingsState): IWebsiteSettingsState {
    const newState: IWebsiteSettingsState = update(initialState, {});

    return newState;
}


function setWebsiteSettings(state: IWebsiteSettingsState): IWebsiteSettingsState {
    const newState: IWebsiteSettingsState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function setWebsiteSettingsSuccess(state: IWebsiteSettingsState, data: Dtos.WebsiteSetting): IWebsiteSettingsState {
    const newState: IWebsiteSettingsState = update(state, {
        data: {
            $set: data
        },
        saveState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function setWebsiteSettingsFailure(state: IWebsiteSettingsState, responseStatus: Dtos.ResponseStatus): IWebsiteSettingsState {
    const newState: IWebsiteSettingsState = update(state, {
        saveState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}


const WebsiteSettingsReducer: Reducer<IWebsiteSettingsState> = (state: IWebsiteSettingsState = initialState, action: WebsiteSettingsAction) => {
    switch (action.type) {
        case ACTION_WEBSITE_SETTINGS_LOAD:
            return loading(state, true);
        case ACTION_WEBSITE_SETTINGS_LOAD_SUCCESS:
            return loadSuccess(state, action.websiteSetting);
        case ACTION_WEBSITE_SETTINGS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_WEBSITE_SETTINGS_CLEAR:
            return clear(state);
        case ACTION_WEBSITE_SETTINGS_SET:
            return setWebsiteSettings(state);
        case ACTION_WEBSITE_SETTINGS_SET_SUCCESS:
            return setWebsiteSettingsSuccess(state, action.websiteSetting);
        case ACTION_WEBSITE_SETTINGS_SET_FAILURE:
            return setWebsiteSettingsFailure(state, action.responseStatus);
    }

    return state;
}

export default WebsiteSettingsReducer;