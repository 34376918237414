import './SubMenu.scss';

import * as React from 'react';
import classNames from "classnames";
import { Link } from "../../routing/index";
import { INavItem } from "../NavMenu/NavMenu";
import { InlineLoading } from "../../common/index";

interface ISubMenuProps {
    items: INavItem[];
    loading?: boolean;
}

class SubMenu extends React.PureComponent<ISubMenuProps, any> {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: any) {
    }

    componentDidUpdate(prevProps: any) {

    }

    componentWillUpdate(nextProps: any) {
    }

    componentWillUnmount() {
    }

    render() {
        const {
            items,
            loading
        } = this.props;

        return <nav className={
            classNames(
                "container",
                "p-0",
                "d-flex",
                "Capture-menu",
                "box-shadow-x",
                { "justify-content-around": loading }
            )
        }>
            {
                loading ?
                    <InlineLoading className="my-1" size={32}></InlineLoading> :
                    items && items.length > 0 ?
                        items.map(this.createItem) :
                        null
            }
        </nav>
    }

    createItem(item: INavItem, index: number, items: INavItem[]): React.ReactNode {
        return item.url ?
            <Link key={"sub-menu-" + index} className="nav-link" url={item.url} target={item.target}>
                {item.icon}
                <span>
                    &emsp;{item.title}
                </span>
            </Link> :
            <a key={"sub-menu-" + index} className="nav-link">
                {item.icon}
                <span>
                    &emsp;{item.title}
                </span>
            </a>

    }
}


export default SubMenu;