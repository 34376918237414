import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Fig.dtos';

import {
    ACTION_TRIAL_USERS_CLEAR,
    ACTION_TRIAL_USERS_LOAD,
    ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION,
    ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS,
    ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS,
    ACTION_TRIAL_USERS_LOAD_BY_USERNAME,
    ACTION_TRIAL_USERS_LOAD_FAILURE,
    ACTION_TRIAL_USERS_LOAD_SUCCESS,
    TrialUserAction,

    LoadSuccess
    
} from '../actions/trialUser';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface ITrialUserState {
    data: Dtos.TrialUser | Dtos.TrialUser[],
    loadState: IRequestState;
    saveState: IRequestState;
    updateState: IRequestState;
}

const initialState: ITrialUserState = {
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState,
    saveState: {
        status: RequestState.None
    } as IRequestState,
    updateState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: ITrialUserState, isLoading: boolean): ITrialUserState {
    const newState: ITrialUserState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: ITrialUserState, data: Dtos.TrialUser | Dtos.TrialUser[]): ITrialUserState {
    const newState: ITrialUserState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: ITrialUserState, responseStatus: Dtos.ResponseStatus): ITrialUserState {
    const newState: ITrialUserState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: ITrialUserState, propertiesToClear?: string[]): ITrialUserState {

    if (!propertiesToClear) {
        const newState: ITrialUserState = update(initialState, {});
        return newState;
    }

    let stateModifier: any = {};

    propertiesToClear.forEach(propertyToClear => {
        stateModifier[propertyToClear] = { $set: initialState[propertyToClear] }
    });

    const newState = update(state, stateModifier);

    return newState;
}

const TrialUserReducer: Reducer<ITrialUserState> = (state: ITrialUserState = initialState, action: TrialUserAction) => {
    switch (action.type) {

        case ACTION_TRIAL_USERS_LOAD:
        case ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION:
        case ACTION_TRIAL_USERS_LOAD_BY_USERNAME:
        case ACTION_TRIAL_USERS_LOAD_BY_ROLE_IDS:
        case ACTION_TRIAL_USERS_LOAD_BY_INSTITUTION_ROLE_IDS:
            return loading(state, true);
        case ACTION_TRIAL_USERS_LOAD_SUCCESS:
            return loadSuccess(state, action.data);
        case ACTION_TRIAL_USERS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_TRIAL_USERS_CLEAR:
            return clear(state, action.propertiesToClear);
    }

    return state;
}

export default TrialUserReducer;