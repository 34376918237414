import * as Dtos from "../../../dtos/Fig.dtos";

export const
    updateSummaryTreeState: IPatientSummaryPageUpdateSummaryTreeStateActionCreator = (id: string) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_UPDATE_SUMMARY_TREE_STATE,
            id
        }
    },
    clearPatientSummaryPage: IPatientSummaryPageClearActionCreator = () => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_CLEAR
        }
    },
    setPatientIdentifier: IPatientSummaryPageSetPatientIdentifierActionCreator = (patientIdentifier: string) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_SET_PATIENT_IDENTIFIER,
            patientIdentifier
        }
    },
    setPatientStatusModalViewState: IPatientSummaryPageSetPatientStatusModalViewStateActionCreator = (open: boolean) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE,
            open
        }
    },
    setPatientStatusModalPatientId: IPatientSummaryPageSetPatientStatusModalPatientIdActionCreator = (id: number) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID,
            id
        }
    },
    setPatientStatusModalNewStatus: IPatientSummaryPageSetPatientStatusModalNewStatusActionCreator = (newStatus: Dtos.PatientStatus) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS,
            newStatus
        }
    },
    setPatientStatusModalSiteNumber: IPatientSummaryPageSetPatientStatusModalPatientSiteNumberActionCreator = (siteNumber: number) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER,
            siteNumber
        }
    },
    setRenderTree: IPatientSummaryPageSetRenderTreeActionCreator = (render: boolean, treeState: string[]) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_SET_RENDERTREE,
            render,
            treeState
        }
    },
    setPatientStatusModalStatusConfiguration: IPatientSummaryPageSetPatientStatusModalStatusConfigurationActionCreator = (patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>) => {
        return {
            type: ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION,
            patientStatusConfiguration
        }
    }

export const
    ACTION_PATIENTSUMMARY_PAGE_UPDATE_SUMMARY_TREE_STATE = "ACTION_PATIENTSUMMARY_PAGE_UPDATE_SUMMARY_TREE_STATE",
    ACTION_PATIENTSUMMARY_PAGE_CLEAR = "ACTION_PATIENTSUMMARY_PAGE_CLEAR",
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENT_IDENTIFIER = "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENT_IDENTIFIER",
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE = "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE",
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID = "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID",
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER = "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER",
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS = "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS",
    ACTION_PATIENTSUMMARY_PAGE_SET_RENDERTREE = "ACTION_PATIENTSUMMARY_PAGE_SET_RENDERTREE",
    ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION = "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION"

/* Actions */

export interface IPatientSummaryPageUpdateSummaryTreeStateAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_UPDATE_SUMMARY_TREE_STATE",
    id: string
}

export interface IPatientSummaryPageClearAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_CLEAR",
}

export interface IPatientSummaryPageSetPatientIdentifierAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENT_IDENTIFIER",
    patientIdentifier: string
}

export interface IPatientSummaryPageSetPatientStatusModalViewStateAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE",
    open: boolean;
}

export interface IPatientSummaryPageSetPatientStatusModalPatientIdAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID",
    id: number;
}

export interface IPatientSummaryPageSetPatientStatusModalPatientSiteNumberAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER",
    siteNumber: number;
}

export interface IPatientSummaryPageSetPatientStatusModalNewStatusAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS",
    newStatus: Dtos.PatientStatus;
}

export interface IPatientSummaryPageSetRenderTreeAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_SET_RENDERTREE",
    render: boolean;
    treeState: string[];
}

export interface IPatientSummaryPageSetPatientStatusModalStatusConfigurationAction {
    type: "ACTION_PATIENTSUMMARY_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION",
    patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>;
}

/* Action Creators */
export interface IPatientSummaryPageUpdateSummaryTreeStateActionCreator {
    (id: string): IPatientSummaryPageUpdateSummaryTreeStateAction;
}

export interface IPatientSummaryPageClearActionCreator {
    (): IPatientSummaryPageClearAction;
}

export interface IPatientSummaryPageSetPatientIdentifierActionCreator {
    (patientIdentifier: string): IPatientSummaryPageSetPatientIdentifierAction;
}

export interface IPatientSummaryPageSetPatientStatusModalViewStateActionCreator {
    (open: boolean): IPatientSummaryPageSetPatientStatusModalViewStateAction;
}

export interface IPatientSummaryPageSetPatientStatusModalPatientIdActionCreator {
    (id: number ): IPatientSummaryPageSetPatientStatusModalPatientIdAction;
}

export interface IPatientSummaryPageSetPatientStatusModalPatientSiteNumberActionCreator {
    (siteNumber: number): IPatientSummaryPageSetPatientStatusModalPatientSiteNumberAction;
}

export interface IPatientSummaryPageSetPatientStatusModalNewStatusActionCreator {
    (newStatus: Dtos.PatientStatus): IPatientSummaryPageSetPatientStatusModalNewStatusAction;
}

export interface IPatientSummaryPageSetRenderTreeActionCreator {
    (render: boolean, treeState: string[]): IPatientSummaryPageSetRenderTreeAction;
}

export interface IPatientSummaryPageSetPatientStatusModalStatusConfigurationActionCreator {
    (patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>): IPatientSummaryPageSetPatientStatusModalStatusConfigurationAction;
}

/* Final Export of actions */
export type PatientSummaryPageAction = IPatientSummaryPageUpdateSummaryTreeStateAction |
    IPatientSummaryPageClearAction |
    IPatientSummaryPageSetPatientIdentifierAction |
    IPatientSummaryPageSetPatientStatusModalViewStateAction |
    IPatientSummaryPageSetPatientStatusModalPatientIdAction |
    IPatientSummaryPageSetPatientStatusModalNewStatusAction |
    IPatientSummaryPageSetPatientStatusModalPatientSiteNumberAction | 
    IPatientSummaryPageSetRenderTreeAction | 
    IPatientSummaryPageSetPatientStatusModalStatusConfigurationAction;
