import * as React from "react";
import { ErrorsProps, CustomComponentProps } from "react-redux-form";
import * as Dtos from '../../../dtos/Fig.dtos';

import { AlertType } from '../../../enumerations/AlertType'
import './ValidationMessage.scss';
import classNames from "classnames";

export interface IValidationMessageProps {
    className?: string;
    alertType?: string;
}

class ValidationMessage extends React.Component<IValidationMessageProps, any> {
    render() {

        const {
            children,
            className,
            alertType
        } = this.props;

        const derivedAlertType = alertType ? alertType : 'alert-danger';
            

        return <div>
            <div className={classNames
                (
                "alert",
                derivedAlertType,
                "validation-message",
                className
                )} role="alert">
                {
                    children
                }
            </div>
        </div>
    }
}

export default ValidationMessage;
