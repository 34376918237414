import { Reducer } from 'redux';
import { update } from '../helpers/immutabilityHelper'
import * as Dtos from '../dtos/Fig.dtos';

import {
    ACTION_INSTITUTIONS_CLEAR,
    ACTION_INSTITUTIONS_LOAD,
    ACTION_INSTITUTIONS_LOAD_FAILURE,
    ACTION_INSTITUTIONS_LOAD_SUCCESS,
    ACTION_INSTITUTIONS_LOAD_BY_COUNTRY,
    ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE,
    ACTION_INSTITUTIONS_LOAD_BY_STATE,
    InstitutionAction
} from '../actions/institution';
import { RequestState } from "../enumerations/RequestState";
import { IRequestState } from "./index";

export interface IInstitutionState {
    data: Dtos.Institution | Dtos.Institution[],
    loadState: IRequestState;
}

const initialState: IInstitutionState = {
    data: undefined,
    loadState: {
        status: RequestState.None
    } as IRequestState
};


function loading(state: IInstitutionState, isLoading: boolean): IInstitutionState {
    const newState: IInstitutionState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Pending
            } as IRequestState
        }
    });

    return newState;
}

function loadSuccess(state: IInstitutionState, data: Dtos.Institution | Dtos.Institution[]): IInstitutionState {
    const newState: IInstitutionState = update(state, {
        data: {
            $set: data
        },
        loadState: {
            $set: {
                status: RequestState.Success
            } as IRequestState
        }
    });

    return newState;
}

function loadFailure(state: IInstitutionState, responseStatus: Dtos.ResponseStatus): IInstitutionState {
    const newState: IInstitutionState = update(state, {
        loadState: {
            $set: {
                status: RequestState.Failure,
                errorCode: responseStatus.errorCode,
                errorMessage: responseStatus.message,
                errors: responseStatus.errors
            } as IRequestState
        }
    });

    return newState;
}

function clear(state: IInstitutionState): IInstitutionState {
    const newState: IInstitutionState = update(initialState, {});

    return newState;
}

const InstitutionReducer: Reducer<IInstitutionState> = (state: IInstitutionState = initialState, action: InstitutionAction) => {
    switch (action.type) {
        case ACTION_INSTITUTIONS_LOAD:
        case ACTION_INSTITUTIONS_LOAD_BY_INSTITUTION_CODE:
        case ACTION_INSTITUTIONS_LOAD_BY_COUNTRY:
        case ACTION_INSTITUTIONS_LOAD_BY_STATE:
            return loading(state, true);
        case ACTION_INSTITUTIONS_LOAD_SUCCESS:
            return loadSuccess(state, action.data);
        case ACTION_INSTITUTIONS_LOAD_FAILURE:
            return loadFailure(state, action.responseStatus);
        case ACTION_INSTITUTIONS_CLEAR:
            return clear(state);
    }

    return state;
}

export default InstitutionReducer;