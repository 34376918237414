import * as Dtos from '../../dtos/Fig.dtos';
import { IFieldState, IRegistrationFormState } from "./index";
    
export const initialWebsiteSettingFormState: Dtos.WebsiteSetting & IRegistrationFormState = {
    enableGroup2 : null,
    saveAndReturn: false,
}

export interface IWebsiteSettingReduxFormState {
    enableGroup2: IFieldState<boolean>
}
