import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'
import * as Dtos from '../../../dtos/Fig.dtos';

import {
    ACTION_REGISTRATION_PAGE_TOGGLE_INELIGIBLE,
    ACTION_REGISTRATION_PAGE_TOGGLE_PRE_REGISTERED,
    ACTION_REGISTRATION_PAGE_TOGGLE_REGISTERED,
    ACTION_REGISTRATION_PAGE_TOGGLE_SUBMITTED,
    ACTION_REGISTRATION_PAGE_TOGGLE_ELIGABLE,
    ACTION_REGISTRATION_PAGE_TOGGLE_RANDOMISED,
    ACTION_REGISTRATION_PAGE_UPDATE_COUNTRY,
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE,
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS,
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID,
    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER,

    RegistrationPageAction,

    ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION
} from '../../../actions/pages/registration/registrationPage';
import { IPatientStatusModalData } from "../../interfaces/IPatientStatusModalData";


export interface IRegistrationPageState {

    expandIneligiblePatients: boolean;
    expandPreRegisteredPatients: boolean;
    expandRegisteredPatients: boolean;
    expandSubmittedPatients: boolean;
    expandEligablePatients: boolean;
    expandRandomisedPatients: boolean;
    country: string;
    patientStatusModalOpen: boolean;
    patientStatusModalData: IPatientStatusModalData;
}

const initialState: IRegistrationPageState = {
    expandIneligiblePatients: true,
    expandPreRegisteredPatients: true,
    expandRegisteredPatients: true,
    expandSubmittedPatients: true,
    expandEligablePatients: true,
    expandRandomisedPatients: true,
    country: undefined,
    patientStatusModalOpen: false,
    patientStatusModalData: undefined
};


function toggleIneligiblePatients(state: IRegistrationPageState): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        expandIneligiblePatients: {
            $set: !state.expandIneligiblePatients
        }
    });

    return newState;
}

function togglePreRegisteredPatients(state: IRegistrationPageState): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        expandPreRegisteredPatients: {
            $set: !state.expandPreRegisteredPatients
        }
    });

    return newState;
}

function toggleRegisteredPatients(state: IRegistrationPageState): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        expandRegisteredPatients: {
            $set: !state.expandRegisteredPatients
        }
    });

    return newState;
}

function toggleSubmittedPatients(state: IRegistrationPageState): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        expandSubmittedPatients: {
            $set: !state.expandSubmittedPatients
        }
    });

    return newState;
}

function toggleEligablePatients(state: IRegistrationPageState): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        expandEligablePatients: {
            $set: !state.expandEligablePatients
        }
    });

    return newState;
}

function toggleRandomisedPatients(state: IRegistrationPageState): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        expandRandomisedPatients: {
            $set: !state.expandRandomisedPatients
        }
    });

    return newState;
}

function updateCountry(state: IRegistrationPageState, country: string): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        country: {
            $set: country
        }
    });

    return newState;
}
function setPatientStatusModalViewState(state: IRegistrationPageState, open: boolean): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        patientStatusModalOpen: {
            $set: open
        }
    });

    return newState;
}

function setPatientStatusModalPatientId(state: IRegistrationPageState, id: number): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        patientStatusModalData: {
            $set: {
                newStatus: state.patientStatusModalData ? state.patientStatusModalData.newStatus : undefined,
                patientId: id,
                patientSiteNumber: state.patientStatusModalData ? state.patientStatusModalData.patientSiteNumber : undefined
            } as IPatientStatusModalData
        }
    });

    return newState;
}

function setPatientStatusModalNewStatus(state: IRegistrationPageState, newStatus: Dtos.PatientStatus): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        patientStatusModalData: {
            $set: {
                newStatus: newStatus,
                patientId: state.patientStatusModalData ? state.patientStatusModalData.patientId : undefined,
                patientSiteNumber: state.patientStatusModalData ? state.patientStatusModalData.patientSiteNumber : undefined
            } as IPatientStatusModalData
        }
    });

    return newState;
}

function setPatientStatusModalPatientSiteNumber(state: IRegistrationPageState, siteNumber: number): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        patientStatusModalData: {
            $set: {
                newStatus: state.patientStatusModalData ? state.patientStatusModalData.newStatus : undefined,
                patientId: state.patientStatusModalData ? state.patientStatusModalData.patientId : undefined,
                patientSiteNumber: siteNumber
            } as IPatientStatusModalData
        }
    });

    return newState;
}

function setPatientStatusModalStatusConfiguration(state: IRegistrationPageState, patientStatusConfiguration: Partial<Dtos.PatientStatusConfiguration>): IRegistrationPageState {
    const newState: IRegistrationPageState = update(state, {
        patientStatusModalData: {
            patientStatusConfiguration: {
                $set: patientStatusConfiguration
            },
        }
    });

    return newState;
}


const RegistrationPageReducer: Reducer<IRegistrationPageState> = (state: IRegistrationPageState = initialState, action: RegistrationPageAction) => {
    switch (action.type) {
        case ACTION_REGISTRATION_PAGE_TOGGLE_INELIGIBLE:
            return toggleIneligiblePatients(state);
        case ACTION_REGISTRATION_PAGE_TOGGLE_PRE_REGISTERED:
            return togglePreRegisteredPatients(state);
        case ACTION_REGISTRATION_PAGE_TOGGLE_REGISTERED:
            return toggleRegisteredPatients(state);
        case ACTION_REGISTRATION_PAGE_TOGGLE_SUBMITTED:
            return toggleSubmittedPatients(state);
        case ACTION_REGISTRATION_PAGE_TOGGLE_ELIGABLE:
            return toggleEligablePatients(state);
        case ACTION_REGISTRATION_PAGE_TOGGLE_RANDOMISED:
            return toggleRandomisedPatients(state);
        case ACTION_REGISTRATION_PAGE_UPDATE_COUNTRY:
            return updateCountry(state, action.country);
        case ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_VIEW_STATE:
            return setPatientStatusModalViewState(state, action.open)
        case ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_ID:
            return setPatientStatusModalPatientId(state, action.id);
        case ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_NEW_STATUS:
            return setPatientStatusModalNewStatus(state, action.newStatus);
        case ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_PATIENT_SITE_NUMBER:
            return setPatientStatusModalPatientSiteNumber(state, action.siteNumber);
        case ACTION_REGISTRATION_PAGE_SET_PATIENTSTATUS_MODAL_STATUS_CONFIGURATION:
            return setPatientStatusModalStatusConfiguration(state, action.patientStatusConfiguration);
    }

    return state;
}

export default RegistrationPageReducer;