
import * as React from "react";

import './MessageModal.scss';


export interface IMessageModalProps {
    headerContent: React.ReactNode;
    bodyContent: React.ReactNode;
    footerContent?: React.ReactNode;
    icon?: any,

    onCloseFn: () => void
}

class MessageModal extends React.Component<IMessageModalProps, any> {
    render() {
        const {
            headerContent,
            bodyContent,
            footerContent,
            icon,
            onCloseFn
        } = this.props;

        return <div className="m-4">
            <div className="container">
                {
                    headerContent ?
                        <div className="row header-content">
                            <div className="col">
                                {headerContent}
                            </div>
                        </div>
                        :
                        null
                }
                {
                    bodyContent ?
                        <div className="row body-content">
                            <div className="col">
                                {bodyContent}
                            </div>
                        </div>
                        :
                        null
                }
                {
                    footerContent ?
                        <div className="row footer-content">
                            <div className="col">
                                {footerContent}
                            </div>
                        </div>
                        :
                        <div className="row footer-content text-right">
                            <div className="col">
                                <button onClick={onCloseFn} className="btn btn-primary mr-2">Dismiss</button>
                            </div>
                        </div>

                }
            </div>
        </div>
    }
}

export default MessageModal;