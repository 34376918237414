import * as FigDtos from '../dtos/Fig.dtos';

export function getSaeStatusText(saeStatus: FigDtos.SaeStatus): string {
    if (saeStatus) {
        switch (saeStatus) {
            case FigDtos.SaeStatus.New:
                return "Initial";
            case FigDtos.SaeStatus.AwaitingReview:
                return "Awaiting Review";
            case FigDtos.SaeStatus.Approved:
                return "Approved";
            case FigDtos.SaeStatus.Rejected:
                return "Rejected";
            case FigDtos.SaeStatus.IncorrectlyIdentified:
                return "Incorrectly Identified";
            default:
                return undefined;
        }
    }

    return undefined;
}

export function getSaeWebActionText(action: FigDtos.SaeWebActionType): string {
    if (action) {
        switch (+action) {
            case FigDtos.SaeWebActionType.ApprovalNotRequired:
                return "Approval Not Required";
            case FigDtos.SaeWebActionType.Approved:
                return "Approved";
            case FigDtos.SaeWebActionType.NotifiedInvestigator:
                return "Notified Investigator";
            case FigDtos.SaeWebActionType.NotifiedSiteTC:
                return "Notified Site TC";
            case FigDtos.SaeWebActionType.Rejected:
                return "Rejected";
            case FigDtos.SaeWebActionType.Reset:
                return "Reset";
            default:
                return undefined;
        }
    }

    return undefined;
}

export type SaeManagerState = "None" | "Review" | "Notify" | "Reset";

export type SaeReviewType = "None" | "Approve" | "Reject";

export function getSaeManagerState(sae: FigDtos.Sae): SaeManagerState {
    if (sae) {
        if (sae.investEmailRequired == true) { // needs notification to investigators
            return "Notify"
        }

        if (sae.approvalRequired == true && sae.investEmailRequired == false) {
            return "Review"
        }

        if (sae.approvalRequired == false) { // no need for approval. 
            return "Reset"
        }
    }
    return "None";
}
