import * as FontAwesomeIconApi from '@fortawesome/react-fontawesome';

import * as falUser from '@fortawesome/fontawesome-pro-light/faUser';
import * as falInfoCircle from '@fortawesome/fontawesome-pro-light/faInfoCircle';

import * as farUser from '@fortawesome/fontawesome-pro-regular/faUser';
import * as farTimes from '@fortawesome/fontawesome-pro-regular/faTimes';
import * as farCheck from '@fortawesome/fontawesome-pro-regular/faCheck';
import * as farQuestion from '@fortawesome/fontawesome-pro-regular/faQuestion';
import * as farHandPointRight from '@fortawesome/fontawesome-pro-regular/faHandPointRight';
import * as farSearch from '@fortawesome/fontawesome-pro-regular/faSearch';
import * as farClock from '@fortawesome/fontawesome-pro-regular/faClock';
import * as farTimesCircle from '@fortawesome/fontawesome-pro-regular/faTimesCircle';


import * as fasUser from '@fortawesome/fontawesome-pro-solid/faUser';
import * as fasCircle from '@fortawesome/fontawesome-pro-solid/faCircle';
import * as fasQuestion from '@fortawesome/fontawesome-pro-solid/faQuestion';
import * as fasSquare from '@fortawesome/fontawesome-pro-solid/faSquare';
import * as fasAngleUp from '@fortawesome/fontawesome-pro-solid/faAngleUp';
import * as fasAngleDown from '@fortawesome/fontawesome-pro-solid/faAngleDown';
import * as fasAngleRight from '@fortawesome/fontawesome-pro-solid/faAngleRight';
import * as fasAngleLeft from '@fortawesome/fontawesome-pro-solid/faAngleLeft';
import * as fasBuilding from '@fortawesome/fontawesome-pro-solid/faBuilding';
import * as fasInfoSquare from '@fortawesome/fontawesome-pro-solid/faInfoSquare';
import * as fasInfoCircle from '@fortawesome/fontawesome-pro-solid/faInfoCircle';
import * as fasFileAlt from '@fortawesome/fontawesome-pro-solid/faFileAlt';
import * as fasFile from '@fortawesome/fontawesome-pro-solid/faFile';
import * as fasCalendarAlt from '@fortawesome/fontawesome-pro-solid/faCalendarAlt';
import * as fasMedKit from '@fortawesome/fontawesome-pro-solid/faMedkit';
import * as fasExclamationCircle from '@fortawesome/fontawesome-pro-solid/faExclamationCircle';
import * as fasExclamationTriangle from '@fortawesome/fontawesome-pro-solid/faExclamationTriangle';
import * as fasTimesOctagon from '@fortawesome/fontawesome-pro-solid/faTimesOctagon';
import * as fasCheck from '@fortawesome/fontawesome-pro-solid/faCheck';
import * as fasTruck from '@fortawesome/fontawesome-pro-solid/faTruck';
import * as fasClipboard from '@fortawesome/fontawesome-pro-solid/faClipboard';
import * as fasHeartbeat from '@fortawesome/fontawesome-pro-solid/faHeartbeat';
import * as fasFlask from '@fortawesome/fontawesome-pro-solid/faFlask';
import * as fasPlus from '@fortawesome/fontawesome-pro-solid/faPlus';
import * as fasSignIn from '@fortawesome/fontawesome-pro-solid/faSignIn';
import * as fasSignOut from '@fortawesome/fontawesome-pro-solid/faSignOut';
import * as fasVial from '@fortawesome/fontawesome-pro-solid/faVial';
import * as fasVials from '@fortawesome/fontawesome-pro-solid/faVials';
import * as fasCloudUpload from '@fortawesome/fontawesome-pro-solid/faCloudUpload';
import * as fasCloudDownload from '@fortawesome/fontawesome-pro-solid/faCloudDownload';
import * as fasSearch from '@fortawesome/fontawesome-pro-solid/faSearch';
import * as fasBan from '@fortawesome/fontawesome-pro-solid/faBan';
import * as fasList from '@fortawesome/fontawesome-pro-solid/faList';
import * as fasCogs from '@fortawesome/fontawesome-pro-solid/faCogs'

export const FontAwesomeIcon = FontAwesomeIconApi;

export const FontAwesomeIcons = {
    Light: {
        USER: falUser,
        INFO_CIRCLE: falInfoCircle
    },
    Regular: {
        USER: farUser,
        TIMES: farTimes,
        CHECK: farCheck,
        QUESTION: farQuestion,
        HAND_POINT_RIGHT: farHandPointRight,
        SEARCH: farSearch,
        CLOCK: farClock,
        TIMES_CIRCLE: farTimesCircle
    },
    Solid: {
        USER: fasUser,
        CIRCLE: fasCircle,
        QUESTION: fasQuestion,
        SQUARE: fasSquare,
        ANGLE_UP: fasAngleUp,
        ANGLE_DOWN: fasAngleDown,
        ANGLE_LEFT: fasAngleLeft,
        ANGLE_RIGHT: fasAngleRight,
        BUILDING: fasBuilding,
        INFO_SQUARE: fasInfoSquare,
        INFO_CIRCLE: fasInfoCircle,
        FILE: fasFile,
        FILE_ALT: fasFileAlt,
        CALENDAR_ALT: fasCalendarAlt,
        MEDKIT: fasMedKit,
        EXCLAMATION_CIRCLE: fasExclamationCircle,
        EXCLAMATION_TRIANGLE: fasExclamationTriangle,
        TIMES_OCTAGON: fasTimesOctagon,
        CHECK: fasCheck,
        TRUCK: fasTruck,
        CLIPBOARD: fasClipboard,
        HEARTBEAT: fasHeartbeat,
        FLASK: fasFlask,
        PLUS: fasPlus,
        SIGN_IN: fasSignIn,
        SIGN_OUT: fasSignOut,
        VIAL: fasVial,
        VIALS: fasVials,
        CLOUD_UPLOAD: fasCloudUpload,
        CLOUD_DOWNLOAD: fasCloudDownload,
        SEARCH: fasSearch,
        BAN: fasBan,
        LIST: fasList,
        COGS: fasCogs
    },
};