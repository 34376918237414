import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { concat as concat$ } from 'rxjs/observable/concat';
import { from as from$ } from 'rxjs/observable/from';

import {
    ACTION_CLINICALASSESSMENTFORMS_CLEAR,
    ACTION_CLINICALASSESSMENTFORMS_LOAD,
    ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_ID,
    ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_ID,
    ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_IDENTIFIER,
    ACTION_CLINICALASSESSMENTFORMS_LOAD_FAILURE,
    ACTION_CLINICALASSESSMENTFORMS_LOAD_SUCCESS,

    ACTION_CLINICALASSESSMENTFORMS_SAVE,
    ACTION_CLINICALASSESSMENTFORMS_SAVE_FAILURE,
    ACTION_CLINICALASSESSMENTFORMS_SAVE_SUCCESS,

    ACTION_CLINICALASSESSMENTFORMS_CREATE,
    ACTION_CLINICALASSESSMENTFORMS_CREATE_SUCCESS,
    ACTION_CLINICALASSESSMENTFORMS_CREATE_FAILURE,

    IClinicalAssessmentFormLoadAction,
    IClinicalAssessmentFormClearAction,
    IClinicalAssessmentFormLoadByIdAction,
    IClinicalAssessmentFormLoadByPatientIdAction,
    IClinicalAssessmentFormLoadByPatientIdentifierAction,
    IClinicalAssessmentFormLoadFailureAction,
    IClinicalAssessmentFormLoadSuccessAction,

    IClinicalAssessmentFormSaveAction,
    IClinicalAssessmentFormSaveSuccessAction,
    IClinicalAssessmentFormSaveFailureAction,

    IClinicalAssessmentFormCreateAction,
    IClinicalAssessmentFormCreateSuccessAction,
    IClinicalAssessmentFormCreateFailureAction,

    LoadSuccess,
    LoadFailure,

    SaveSuccess,
    SaveFailure,

    CreateSuccess,
    CreateFailure


} from "../../actions/forms/clinicalAssessmentForm";

import { clinicalAssessmentFormApi } from "../../services/Forms/clinicalAssessmentForm";


export const
    getClinicalAssessmentFormsEpic = (action$): Observable<IClinicalAssessmentFormLoadSuccessAction | IClinicalAssessmentFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_CLINICALASSESSMENTFORMS_LOAD)
            .mergeMap((action: IClinicalAssessmentFormLoadAction) => {
                return clinicalAssessmentFormApi
                    .getClinicalAssessmentForms()
                    .map(response =>
                        LoadSuccess(response.clinicalAssessmentForms, undefined, undefined, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getClinicalAssessmentFormsByIdEpic = (action$): Observable<IClinicalAssessmentFormLoadSuccessAction | IClinicalAssessmentFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_ID)
            .mergeMap((action: IClinicalAssessmentFormLoadByIdAction) => {
                return clinicalAssessmentFormApi
                    .getClinicalAssessmentFormById(action.id)
                    .map(response =>
                        LoadSuccess(response.clinicalAssessmentForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getClinicalAssessmentFormsByPatientIdEpic = (action$): Observable<IClinicalAssessmentFormLoadSuccessAction | IClinicalAssessmentFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_ID)
            .mergeMap((action: IClinicalAssessmentFormLoadByPatientIdAction) => {
                return clinicalAssessmentFormApi
                    .getClinicalAssessmentFormByPatientId(action.patientId)
                    .map(response =>
                        LoadSuccess(response.clinicalAssessmentForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    getClinicalAssessmentFormsByPatientIdentifierEpic = (action$): Observable<IClinicalAssessmentFormLoadSuccessAction | IClinicalAssessmentFormLoadFailureAction> => {
        return action$
            .ofType(ACTION_CLINICALASSESSMENTFORMS_LOAD_BY_PATIENT_IDENTIFIER)
            .mergeMap((action: IClinicalAssessmentFormLoadByPatientIdentifierAction) => {
                return clinicalAssessmentFormApi
                    .getClinicalAssessmentFormByPatientIdentifier(action.identifier)
                    .map(response =>
                        LoadSuccess(response.clinicalAssessmentForm, response.registrationFormState, response.registrationFormProperties, response.lookups, response.responseStatus)
                    )
                    .catch(error =>
                        Observable.of(LoadFailure(error.responseStatus))
                    )
            });
    },
    setClinicalAssessmentFormsEpic = (action$): Observable<IClinicalAssessmentFormSaveSuccessAction | IClinicalAssessmentFormSaveFailureAction> => {
        return action$
            .ofType(ACTION_CLINICALASSESSMENTFORMS_SAVE)
            .mergeMap((action: IClinicalAssessmentFormSaveAction) => {
                return clinicalAssessmentFormApi
                    .setClinicalAssessmentForm(action.formData, action.institutionCode, action.patientIdentifier)
                    .map(response => {
                        if (response.responseStatus &&
                            response.responseStatus.meta &&
                            response.responseStatus.meta.blockSave &&
                            response.responseStatus.meta.blockSave == "1") {
                            return SaveFailure(response.responseStatus)
                        }

                        return SaveSuccess(response.clinicalAssessmentForm, response.registrationFormState, response.responseStatus)

                    })
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    },
    createClinicalAssessmentFormsEpic = (action$): Observable<IClinicalAssessmentFormCreateSuccessAction | IClinicalAssessmentFormCreateFailureAction> => {
        return action$
            .ofType(ACTION_CLINICALASSESSMENTFORMS_CREATE)
            .mergeMap((action: IClinicalAssessmentFormCreateAction) => {
                return clinicalAssessmentFormApi
                    .createClinicalAssessmentForm()
                    .map(response =>
                        CreateSuccess(response.clinicalAssessmentForm, response.registrationFormState, response.registrationFormProperties, response.lookups)
                    )
                    .catch(error =>
                        Observable.of(SaveFailure(error.responseStatus))
                    )
            });
    }





