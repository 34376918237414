import { Reducer } from 'redux';
import { update } from '../../../helpers/immutabilityHelper'

import {
    ACTION_PREREGISTRATIONFORM_PAGE_TOGGLE_MESSAGE_MODAL,
    ACTION_PREREGISTRATION_PAGE_NOTIFY_SET_ER_STATUS,
    PreRegistrationFormPageAction
} from '../../../actions/pages/registration/preRegistrationFormPage';


export interface IPreRegistrationFormPageState {
    messageModalOpen: boolean;
}

const initialState: IPreRegistrationFormPageState = {
    messageModalOpen: false
};


function toggleMessageModal(state: IPreRegistrationFormPageState): IPreRegistrationFormPageState {
    const newState: IPreRegistrationFormPageState = update(state, {
        messageModalOpen: {
            $set: !state.messageModalOpen
        }
    });

    return newState;
}

function notifyERStatus(state: IPreRegistrationFormPageState): IPreRegistrationFormPageState {
    return state;
}

const PreRegistrationFormPageReducer: Reducer<IPreRegistrationFormPageState> = (state: IPreRegistrationFormPageState = initialState, action: PreRegistrationFormPageAction) => {
    switch (action.type) {
        case ACTION_PREREGISTRATIONFORM_PAGE_TOGGLE_MESSAGE_MODAL:
            return toggleMessageModal(state);
        case ACTION_PREREGISTRATION_PAGE_NOTIFY_SET_ER_STATUS:
            return notifyERStatus(state);
    }

    return state;
}

export default PreRegistrationFormPageReducer;